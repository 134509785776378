import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router-dom';
import cn from 'classnames';

import { makeLinkDynamic } from 'routes';

import { menu } from './menu.js';
import { MenuItem, MenuItemSeparator } from '../types.js';

import styles from './style.module.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

class ProjectMenu extends React.PureComponent {

	static propTypes = {
		onToggleCollapse: PropTypes.func,
		collapsed: PropTypes.bool,
	}

	static defaultProps = {
		onToggleCollapse: () => {},
		collapsed: true,
	}

	renderSeparartor = sep => {
		return (
			<div key={sep.key} className={styles.separatorWrapper}>
				<div className={styles.separator}/>
			</div>
		);
	}

	renderMenuItem = (item, dymanics = {}) => {
		const { link, faIcon, routes, label } = item;
		const { collapsed, location } = this.props;
		const { pathname } = location;

		const isActive = routes.some(r => !!matchPath(pathname, { path: r, strict: false }));

		const itemLabel = !collapsed && <div className="ml-2">
			{label}
		</div>;

		const itemIcon = faIcon ? <FontAwesomeIcon icon={faIcon} fixedWidth size="lg"/> : null;

		return (
			<React.Fragment key={item.key}>
				<LinkContainer to={makeLinkDynamic(link, dymanics)} exact={true}>
					<div className={cn(
						{[styles.linkCollapsed]: collapsed},
						{[styles.link]: !collapsed},
						{[styles.linkActive]: isActive}
					)}>
						{itemIcon}
						{itemLabel}
					</div>
				</LinkContainer>
			</React.Fragment>
		);
	}

	renderSubMenu = activeItem => {
		if (!activeItem?.submenu) {
			return null;
		}
		const { pathname } = this.props.location;
		const { submenu, routes } = activeItem;

		const hasSubMatches = submenu
			.filter(smi => smi instanceof MenuItem)
			.some(smi => smi.routes?.some(r => !!matchPath(pathname, { path: r })))
		;

		if (!hasSubMatches) {
			return null;
		}

		const routesMatches = routes.map(r => matchPath(pathname, { path: r })).filter(m => m);
		const dymanics = Object.assign(...routesMatches.map(rm => rm.params).flat());

		return (
			<nav className={styles.contextMenu}>
				{submenu.map(item => {
					if (item instanceof MenuItem) {
						return this.renderMenuItem(item, dymanics);
					} else if (item instanceof MenuItemSeparator) {
						return this.renderSeparartor(item);
					}

					return null;
				})}
			</nav>
		);
	}

	render() {
		const { collapsed, onToggleCollapse, location } = this.props;
		const { pathname } = location;

		const activeItem = menu.find(m => m.routes.some(r => !!matchPath(pathname, { path: r, strict: false })));
		const contextMenu = this.renderSubMenu(activeItem);

		const collapseIcon = <FontAwesomeIcon
			fixedWidth
			icon={collapsed ? faChevronRight : faChevronLeft}
			onClick={onToggleCollapse}
			className={styles.iconCollapse}
		/>;

		return (
			<div className={styles.wrapper}>
				<div className={cn('d-flex', 'flex-column')}>
					{contextMenu}
				</div>
				<div className={cn('w-100', 'text-right')}>
					{collapseIcon}
				</div>
			</div>
		);
	}
}

export default withRouter(ProjectMenu);
