export class Options
{
	constructor(startDate, endDate)
	{
		if (!startDate) {
			throw new Error("startDate is required");
		}

		if (!endDate) {
			throw new Error("endDate is required and muste be more than 0");
		}

		this.startDate = startDate;
		this.endDate = endDate;
	}
}

export class HistoryValue
{
	constructor(date, value)
	{
		this.date = date;
		this.value = value;
	}
}

export class HistoryValueArray
{
	constructor()
	{
		this.data = [];
	}

	push(value)
	{
		if (!(value instanceof HistoryValue)) {
			throw new Error('value must be instance of HistoryValue');
		}

		this.data.push(value);
	}

	[Symbol.iterator]() { return this.data.values(); }
}

export class HistoryGroup
{
	constructor(jobId)
	{
		this.jobId = jobId;
		this.array = new HistoryValueArray();
	}

	push(value)
	{
		this.array.push(value);
	}

	[Symbol.iterator]() { return this.array.data.values(); }
}

export class HistoryGroupArray
{
	constructor()
	{
		this.groups = [];
	}

	push(group)
	{
		if (!(group instanceof HistoryGroup)) {
			throw new Error('group must be instance of Group');
		}

		this.groups.push(group);
	}

	[Symbol.iterator]() { return this.groups.values(); }
}
