
import LastNormalizer from './LastNormalizer';
import DeltaNormalizer from './DeltaNormalizer';


class NormalizerFactory
{
	constructor(type, data, options, params)
	{
		if (!type)
		{
			throw (new Error("type is required"));
		}

		this.type = type;
		this.data = data;
		this.options = options;
		this.params = params;
	}

	get()
	{
		const { type, data, options, params } = this;

		if (type === 'last') {
			return new LastNormalizer(data, options, params);
		} else if (type === 'delta') {
			return new DeltaNormalizer(data, options, params);
		}

		return null;
	}
}

export default NormalizerFactory;
