import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';


const Header = (props) => {
	const {
		clearSection,
		activeCategory,
		activeSection,
		handbookOpened,
		setHandbookOpened,
		inProgress
	} = props;

	return (
		<div className={styles.Header}>
			<div className={styles.Header__navigation}>
				{inProgress ? <FontAwesomeIcon
					className={styles.Header__icon}
					icon={faCircleNotch}
					size="sm"
					spin
				/> : null}
				{activeSection ? <div className={styles.Header__navigation__label}>
					{activeSection.title}
				</div> : null}
				{activeCategory ? <div className={styles.Header__navigation__label}>
					{"/"}
				</div> : null}
				{activeCategory ? <div className={styles.Header__navigation__label}>
					{activeCategory.title}
				</div> : null}
			</div>
			{!handbookOpened ? <div
				className={styles.Header__label}
				onClick={() => setHandbookOpened(true)}
			>
				Открыть справочник
			</div> : null}
			{handbookOpened && !activeSection?.id ? <div
				className={styles.Header__label}
				onClick={() => setHandbookOpened(false)}
			>
				Закрыть справочник
			</div> : null}
			{handbookOpened && activeSection?.id ? <div
				className={styles.Header__label}
				onClick={() => clearSection(null)}
			>
				Назад
			</div> : null }
		</div>
	);
};

Header.propTypes = {
	handbookOpened: PropTypes.bool,
	activeSection: PropTypes.object,
	activeCategory: PropTypes.object,
	inProgress: PropTypes.bool,
	setHandbookOpened: PropTypes.func,
	clearSection: PropTypes.func,
};

export default Header;
