import uuidv4 from 'uuid/v4';

export class MenuItem
{
	constructor({ label, routes, link, faIcon, submenu })
	{
		this.label = label;
		this.routes = routes;
		this.link = link;
		this.faIcon = faIcon;
		this.submenu = submenu;
		this.key = uuidv4();
	}
}

export class MenuItemSeparator
{
	constructor()
	{
		this.key = uuidv4();
	}
}
