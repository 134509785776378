import React from 'react';


class SourceSpecInfoCellRenderer extends React.PureComponent
{
	render() {

		const sourceSpecs = this.props.value || [];
		const titles = sourceSpecs.map(spec => spec && spec.title).join(', ');

		return (
			<div className="text-truncate">
				{titles}
			</div>
		);
	}
}

export default SourceSpecInfoCellRenderer;

