import React from 'react';
import PropTypes from 'prop-types';

import { CustomGrid } from 'components/ui/grid';
import { getColumnDefs, getGridOptions } from './defs';

class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onSelectedRowsChanged: PropTypes.func,
		onSelectionChanged: PropTypes.func,
		cellStyle: PropTypes.object,
		onCellValueChanged: PropTypes.func,
		onAddToOrder: PropTypes.func,
	}

	static defaultProps = {
		items: [],
		cellStyle: {},
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	onGridReady = params => {
		this.gridApi = params.api;

		if (this.props.onGridReady) {
			this.props.onGridReady(params);
		}
	}

	onSelectionChanged = (params) => {
		if (this.props.onSelectedRowsChanged) {
			const selectedRows = params.api.getSelectedRows();
			this.props.onSelectedRowsChanged(selectedRows);
		}

		if (this.props.onSelectionChanged) {
			this.props.onSelectionChanged(params);
		}
	}

	render()
	{
		const gridOptions = getGridOptions(this.props);
		const columnDefs = getColumnDefs(this.props);

		return (
			<CustomGrid
				rowData={this.props.items}
				gridOptions={gridOptions}
				columnDefs={columnDefs}
				onGridReady={this.onGridReady}
				onSelectionChanged={this.onSelectionChanged}
				suppressRowClickSelection={true}
				onFirstDataRendered={this.onFirstDataRendered}
			/>
		);
	}
}

export default Grid;
