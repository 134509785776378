import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import ru from "date-fns/locale/ru";
import 'react-datepicker/dist/react-datepicker.css';

registerLocale("ru", ru);


const DatePicker = (props) => {
	const [date, setDate] = useState(null);

	useEffect(() => {
		setDate(props.initialDate);
	}, []);

	const onDateChange = date => {
		props.onChange(date);
		if (!props.controlled) {
			setDate(date);
		}
	};

	const d = props.controlled ? props.value : date;

	return <ReactDatePicker
		{...props}
		selected={d}
		onChange={date => onDateChange(date)}
		locale="ru"
		dateFormat="dd.MM.yyyy"
	/>;
};

DatePicker.propTypes = {
	onChange: PropTypes.func,
	initialDate: PropTypes.any,
	value: PropTypes.any,
	controlled: PropTypes.bool,
};

DatePicker.defaultProps = {
	onChange: () => null,
	controlled: false,
	value: null,
};

export default DatePicker;
