import React from 'react';
import PropTypes from 'prop-types';

import { Form, Col } from 'react-bootstrap';
import { withItemEditModal } from 'components/ui/modals';
import PermissionsGrid from './components/PermissionsGrid';


class RoleEditForm extends React.PureComponent
{
	static propTypes = {
		entityTypes: PropTypes.arrayOf(PropTypes.object),
	}

	state = {}

	onChanged = () => {
		this.props.onChanged(this.state);
	}

	onCellValueChanged = (params) => {
		const permissions = [];

		params.api.forEachNode(node => {
			const id = node.data.id;
			const keys = node.data.permissions || [];
			for (const key of keys) {
				const permission = {};
				permission.entityTypeId = id;
				permission.permission = key;
				permissions.push(permission);
			}
		});

		this.setState({permissions}, this.onChanged);
	}

	onTitleChanged = (e) => {
		const title = e.target.value;
		this.setState({title}, this.onChanged);
	}

	render()
	{
		return (
			<Form>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>Наименование</Form.Label>
							<Form.Control
								onChange={this.onTitleChanged}
								defaultValue={this.props.editingItem.title}
							/>
						</Form.Group>
					</Col>
				</Form.Row>
				<Form.Row>
					<Col>
						<PermissionsGrid
							entityTypes={this.props.entityTypes}
							onCellValueChanged={this.onCellValueChanged}
						/>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

export default withItemEditModal(RoleEditForm);
