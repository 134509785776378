import React from 'react';
import { NewModal as Modal } from 'components/ui/modals';
import NewRequestDialog from './components/NewRequestDialog';
import PropTypes from 'prop-types';
import { toast, Slide } from 'react-toastify';

class RequestModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		locked: PropTypes.bool,
		currenciesMap: PropTypes.any.isRequired,
		purchaseRequestsMap: PropTypes.any.isRequired,
		purchaseRequestItems: PropTypes.arrayOf(PropTypes.object).isRequired,
		consumableSheetId: PropTypes.string.isRequired,
	}

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	render() {

		const {
			onConfirm,
			onCancel,
			visible,
			locked,
		} = this.props;

		const btnList = [
			{
				title: 'Создать',
				props: {
					onClick: () => {
						const transaction = this.requestDialogProps.getTransactionBody();

						if (!transaction) {
							this.showError('Количество заказываемых позиций должно быть больше 0.');
						}

						onConfirm(transaction);
					},
					disabled: locked,
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		const onDialogReady = props => {
			this.requestDialogProps = props;
		};

		return (
			<Modal
				title="Новая заявка"
				btnList={btnList}
				show={visible}
				onHide={() => onCancel()}
				size="xl"
			>
				<NewRequestDialog
					items={this.props.items}
					currenciesMap={this.props.currenciesMap}
					purchaseRequestItems={this.props.purchaseRequestItems}
					consumableSheetId={this.props.consumableSheetId}
					purchaseRequestsMap={this.props.purchaseRequestsMap}
					consumableSheetItems={this.props.consumableSheetItems}
					onDialogReady={onDialogReady}
				/>
			</Modal>
		);
	}
}

export default RequestModal;
