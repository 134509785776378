import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button } from 'react-bootstrap';
import { Toolbar } from 'components/ui';
import cn from 'classnames';


class PricelistsToolbar extends React.PureComponent
{

	static propTypes = {
		consumablePricelists: PropTypes.arrayOf(PropTypes.object),
		onConfirmPricelist: PropTypes.func,
	}

	static defaultProps = {
		consumablePricelists: [],
		onConfirmPricelist: () => {},
	}

	state = {
		value: null,
		disabled: false,
	}

	afterConfirm = () => {
		this.setState({ value: null, disabled: false });
	}

	onConfirm = () => {
		this.setState({ disabled: true }, () => {
			this.props.onConfirmPricelist(this.state.value, this.afterConfirm);
		});
	}

	render()
	{
		const { value, disabled } = this.state;
		const { consumablePricelists } = this.props;

		const options = consumablePricelists.map(cp => {
			return {
				value: cp.id,
				label: cp.title,
			};
		});

		return (
			<Toolbar>
				<div
					style={{ minWidth: '300px' }}
					className={cn('mx-1', 'mr-2')}
				>
					<Select
						options={options}
						onChange={value => this.setState({ value })}
						value={value}
						placeholder="Выберите прайс-лист"
						isMulti={true}
					/>
				</div>
				<Button
					className={cn({'invisible': !value?.length})}
					onClick={() => this.onConfirm()}
					disabled={disabled}
				>
					Загрузить цены
				</Button>
			</Toolbar>
		);
	}
}

export default PricelistsToolbar;
