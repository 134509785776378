import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import FormikForm from 'components/ui/forms/formik/Form';
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const prepareSubmitValues = (values) => {
	const pn = parsePhoneNumberFromString(values.phoneNumber, 'RU');

	return {
		...values,
		phoneNumber: `${pn.countryCallingCode}${pn.nationalNumber}`,
	};
};

class InvitationModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
	}

	render()
	{
		const { onConfirm, onCancel, visible } = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.form.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		const simplifiedSchema = [
			[{ id: 'phoneNumber', type: 'text', label: 'Номер телефона' }],
			[{ id: 'fullName', type: 'text', label: 'Имя сотрудника' }],
		];

		const inputsProps = {
			'phoneNumber': {
				placeholder: "+7 (xxx) xxx-xx-xx",
			},
			'fullName': {
				placeholder: "Введите имя сотрудника",
			},
		};

		const validationSchema = yup.object().shape({
			phoneNumber: yup.string()
				.test('valid', 'Неверный формат', value => {
					if (!value) {
						return false;
					}

					const phoneNumber = parsePhoneNumberFromString(value, 'RU');

					return phoneNumber?.isValid();
				})
		});

		return (
			<Modal
				btnList={btnList}
				size="md"
				title={'Пригласить пользователя'}
				show={visible}
				onHide={() => onCancel()}
			>
				<FormikForm
					onFormCreated={form => (this.form = form)}
					onSubmit={values => onConfirm(prepareSubmitValues(values))}
					changesOnly={true}
					simplifiedSchema={simplifiedSchema}
					validationSchema={validationSchema}
					validateOnChange={false}
					inputsProps={inputsProps}
				/>
			</Modal>
		);
	}
}

export default InvitationModal;
