import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';


const TextInput = (props) => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);


	return (
		<Form.Control
			type="text"
			value={value || ''}
			onChange={e => helpers.setValue(e.target.value)}
			{...props}
		/>
	);
};

export default TextInput;
