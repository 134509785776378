import { faComment, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import {
	EmptyCellRenderer,
	UserCellRenderer,
	SubtitleCellRenderer,
	ActionsCellRenderer } from 'components/ui/grid/renderers';

import { numberFormatter, dateFormatter } from 'components/ui/grid/formatters';

const getSuffix = params => params.data.consumableSheetItem?.consumableUnit?.suffix || '';
const getSymbol = params => params.data.consumableSheetItem?.priceCurrency?.symbol || '';

export const getGridOptions = params => {

	const { cellStyle } = params;
	const priceRenderer = params => `${params.valueFormatted} ${getSymbol(params)}`;
	const amountRenderer = params => `${params.valueFormatted} ${getSuffix(params)}`;

	const frameworkComponents = {
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
		priceRenderer,
		amountRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			resizable: true,
			sortable: true,
			editable: false,
			cellStyle,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {
	const {
		isDetail,
		consumableSheet,
		onOpenApproveAmountModal
	} = params;

	const titleRendererParams = params => {
		const item = params.data.consumableSheetItem;

		return {
			subtitle: `${item.partNumber} / ${item.manufacturerName}`
		};
	};

	const withCurrency = (value, currencyId) => {
		const rates = consumableSheet.currencyRates;
		const rate = rates[currencyId];

		return rate && rate * value || value;
	};

	const getPlanCost = params => {
		const item = params.data.consumableSheetItem;

		const { planPrice, retailPrice, retailDiscountPercents } = item;
		const disc = 1 - (retailDiscountPercents / 100);
		const price = planPrice || retailPrice * disc || retailPrice || 0;

		return params.data.amount * price;
	};

	const getNotesActions = params => {
		const actions = [
			{
				icon: faComment,
				popover: {
					title: 'Комментарий',
					content: params.value,
				}
			}
		];

		return { actions };
	};

	const getActions = (params) =>
	{
		if (params.data.isAmountApproved) {
			return [];
		}

		return {
			actions: [
				{
					icon: faCheckCircle,
					onClick: () => { onOpenApproveAmountModal(params); },
					hoverStyle: { color: '#48B95A', height: 16, width: 16 },
					style: {color: '#999999', height: 16, width: 16 }
				},
			]
		};
	};

	const overGetter = params => {
		const {consumableSheetItem, amount } = params.data;

		return Number(amount) + Number(consumableSheetItem.orderedAmount) - Number(consumableSheetItem.amount);
	};

	const colDefs = [
		{
			headerName: 'Наименование',
			field: 'consumableSheetItem.title',
			suppressSizeToFit: false,
			cellRendererParams: titleRendererParams,
			cellStyle: { lineHeight: 1.5 },
			minWidth: 500
		},
		{
			headerName: 'В смете',
			field: 'consumableSheetItem.amount',
			valueFormatter: params => `${params.value} ${getSuffix(params)}`,
			minWidth: 150
		},
		{
			headerName: 'Заказано',
			field: 'consumableSheetItem.orderedAmount',
			valueFormatter: params => `${params.value} ${getSuffix(params)}`,
			minWidth: 150
		},
		{
			headerName: 'В заявке',
			field: 'amount',
			valueFormatter: params => `${params.value} ${getSuffix(params)}`,
			cellStyle: params => (params?.data?.isAmountApproved ? { } : { color: 'red' }),
			minWidth: 150
		},
		{
			headerName: 'Превышение',
			field: 'overAmount',
			valueFormatter: params => `${params.value} ${getSuffix(params)}`,
			valueGetter: overGetter,
			minWidth: 150
		},
		{
			headerName: 'Заявка',
			field: 'request.sn',
			minWidth: 100
		},
		{
			headerName: 'Создана',
			field: 'request.creationTime',
			valueFormatter: dateFormatter,
			minWidth: 100
		},
		{
			headerName: 'Цена (план)',
			field: 'consumableSheetItem.planPrice',
			valueFormatter: numberFormatter,
			valueGetter: params => params.data && withCurrency(params.data.consumableSheetItem.planPrice, params.data.consumableSheetItem.priceCurrencyId),
			minWidth: 150
		},
		{
			headerName: 'Стоимость (план)',
			field: 'actualCost',
			valueFormatter: numberFormatter,
			valueGetter: params => params.data && withCurrency(getPlanCost(params), params.data.consumableSheetItem.priceCurrencyId),
			minWidth: 150
		},
		{
			headerName: 'Комментарий',
			field: 'notes',
			cellRendererParams: getNotesActions,
			minWidth: 300
		},
		{
			minWidth: 20,
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: getActions,
			sortable: false,
		},
	];

	const renderersMap = {
		'consumableSheetItem.title': isDetail ? null : { component: 'subtitleCellRenderer' },
		'notes': { component: 'actionsCellRenderer' },
		'planCost': { component: 'priceRenderer' },
		'actualCost': { component: 'priceRenderer' },
		'consumableSheetItem.planPrice': { component: 'priceRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
