import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CustomGrid } from 'components/ui/grid';
import { getGridOptions, getColumnDefs } from './defs';

class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onGridReady: PropTypes.func,
		onCellValueChanged: PropTypes.func,
		onRemoveNodes: PropTypes.func,
		jobUnits: PropTypes.arrayOf(PropTypes.object),
	};

	static defaultProps = {
		items: [],
	};

	state = {
		selectedNodes: []
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.props.onGridReady(params);
	}

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	onSelectionChanged = (params) => {
		const selectedNodes = params.api.getSelectedNodes();
		this.setState({ selectedNodes });
	}

	getContextMenuItems = (params) => {
		const isSelected = params.node.selected;
		const nodesToAction = isSelected ? this.gridApi.getSelectedNodes() : [params.node];

		return [
			{
				name: 'Удалить',
				action: () => this.props.onRemoveNodes(nodesToAction),
			}
		];
	}

	onCellValueChanged = (params) => {
		const field = params.colDef.field;
		const { api, node } = params;

		if (field === 'unitId') {
			api.refreshCells({
				rowNodes: [node],
				columns: ['productionRatePerShift'],
				force: true
			});
		}
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				animateRows={true}
				rowSelection={'multiple'}
				onSelectionChanged={this.onSelectionChanged}
				onFirstDataRendered={this.onFirstDataRendered}
				onGridReady={this.onGridReady}
				getContextMenuItems={this.getContextMenuItems}
				onCellValueChanged={this.onCellValueChanged}
				rowHeight={28}
			/>
		);
	}
}

const mapToProps = (state, props) => {
	const jobUnitsMap = props.jobUnits.hashById();

	return { jobUnitsMap };
};

export default connect(mapToProps)(Grid);
