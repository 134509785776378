import React from 'react';
import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from 'routes';
import cn from 'classnames';

import ProjectMenu from './components/ProjectMenu';
import MainMenu from './components/MainMenu';
import ActivityDrawer from './components/ActivityDrawer';

import styles from './style.module.sass';


class DefaultLayout extends React.PureComponent {

	state = {
		sidebarCollapsed: true,
	}

	onActivityDrawerOpen = (index) => {
		this.activityDrawer.setActiveIndex(index);
	}

	onToggleCollapse = () => {
		const sidebarCollapsed = !this.state.sidebarCollapsed;

		this.setState({ sidebarCollapsed });
	}

	render() {

		const collapsed = this.state.sidebarCollapsed;

		const isProject = !!matchPath(this.props.location.pathname, {
			path: '/projects/:projectId',
			exact: false
		});

		return (
			<div className={styles.Layout}>
				<div className={styles.Header}>
					<MainMenu onActivityDrawerOpen={this.onActivityDrawerOpen}/>
				</div>
				<div className={styles.Content}>
					<div className={cn(
						styles.Sidebar,
						{ [styles.Sidebar_collapsed]: isProject && collapsed },
						{ [styles.Sidebar_hidden]: !isProject }
					)}>
						<ProjectMenu
							onToggleCollapse={() => this.onToggleCollapse()}
							collapsed={collapsed}
						/>
					</div>

					<Switch className={styles.Activity}>
						{routes.map(((route, key) => {
							if (route.redirect) {
								return <Redirect
									from={route.path}
									to={route.to}
									key={key}
									exact={route.exact}
								/>;
							}

							return <Route
								path={route.path}
								key={key}
								exact={route.exact}
								component={route.component}
							/>;
						}))}
					</Switch>
				</div>
				<ActivityDrawer ref={(ref) => (this.activityDrawer = ref)}/>
			</div>
		);
	}
}

export default connect()(withRouter(DefaultLayout));
