import React from 'react';
import { connect } from 'react-redux';
import db from 'core/db' ;

import UploadView from './components/UploadView';
import CreationView from './components/CreationView';
import { withLoading, Page } from 'components/ui';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';


class ImportPage extends React.PureComponent
{
	renderComponent()
	{
		const consSpecId = this.props.consSpecId;
		const hasSource = this.props.sourceSpecs && this.props.sourceSpecs.length;

		if (!consSpecId) {
			return <CreationView projectId={this.props.projectId}/>;
		}

		if (!hasSource) {
			return <UploadView consSpecId={consSpecId}/>;
		}

		const projectId = this.props.projectId;
		const path = `/projects/${projectId}/supplies/estimate/specification`;

		return (
			<div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
				<div className="text-muted mb-2">
					Спецификации успешно импортированы!
				</div>
				<Link to={path}>
					<Button>
						Перейти к редактированию
					</Button>
				</Link>
			</div>
		);
	}

	render()
	{
		const component = this.renderComponent();

		return (
			<Page style={{backgroundColor: 'white'}} className="overflow-auto">
				{component}
			</Page>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consSpecs = db.tbsConsolidatedSpecifications.list(projectFilter);
	const consSpec = consSpecs[0] || null;
	const consSpecId = consSpec && consSpec.id;

	const consSpecFilter = { filter: { consolidatedSpecificationId: consSpecId } };
	const sourceSpecs = consSpecId && db.tbsSourceSpecifications.list(consSpecFilter) || [];

	const isLoading = consSpecs.isLoading || sourceSpecs.isLoading;

	if (isLoading) {
		return { isLoading };
	}

	return {
		projectId,
		consSpecId,
		consSpec,
		sourceSpecs,
	};
};

export default connect(mapToProps)(withLoading(ImportPage));
