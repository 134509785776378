import React from 'react';

import Workspace from 'layouts/Default/components/Workspace';
import { invoicesTabsRoutes } from '../../routes';

class Invoices extends React.PureComponent {
	render() {
		return (
			<Workspace routes={invoicesTabsRoutes} />
		);
	}
}

export default Invoices;
