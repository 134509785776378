const sendEmail = (email, subject, message) => {
	const body = {
		email,
		message: {
			subject,
			html: message
		}
	};

	return fetch('https://n8n.i.brigadier.app/webhook/5b6ce423-94a5-4b62-8fdc-d556b2ae6205', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=utf-8',
		},
		body: JSON.stringify(body)
	});
};

export default sendEmail;
