import { Options } from '../types.js';
import { eachDayOfInterval } from 'date-fns';

export default class RangeBuilder
{
	constructor(options)
	{
		if (!(options instanceof Options)) {
			throw new Error('options must be instance of Options');
		}

		this.options = options;
	}

	build()
	{
		const { startDate, endDate } = this.options;

		return eachDayOfInterval({ start: startDate, end: endDate});
	}
}
