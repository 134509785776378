import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AuthDownloadLink from '../AuthDownloadLink';
import cn from 'classnames';
import { faFileExcel, faFilePdf, faFile, faTimes, faFileWord, faFileImage } from '@fortawesome/free-solid-svg-icons';

import style from './style.module.sass';

class FileDownloadIcon extends React.PureComponent
{
	static propTypes = {
		file: PropTypes.shape({
			originalName: PropTypes.string,
			url: PropTypes.string,
		}).isRequired,
		icon: PropTypes.object,
		iconStyle: PropTypes.object,
		onRemoveClick: PropTypes.func,
		withAuth: PropTypes.bool,
		labelVisible: PropTypes.bool,
		size: PropTypes.string,
	}

	static defaultProps = {
		iconStyle: {},
		withAuth: false,
		labelVisible: true,
		size: 'lg',
	}

	state = {
		hovered: false,
	}

	onMouseEnterHandler = () => this.setState({ hovered: true })

	onMouseLeaveHandler = () => this.setState({ hovered: false })

	getIcon = () => {
		const { file } = this.props;
		const { mimeType } = file;

		if (!mimeType) {
			return faFile;
		}

		const iconsMimeTypesMap = {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': faFileExcel,
			'application/vnd.ms-excel': faFileExcel,
			'application/msword': faFileWord,
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': faFileWord,
			'application/pdf': faFilePdf,
			'image/jpeg': faFileImage,
			'image/png': faFileImage,
		};

		return iconsMimeTypesMap[mimeType] || faFileImage;
	}

	getIconColor = () => {
		const { file } = this.props;
		const { mimeType } = file;

		if (!mimeType) {
			return null;
		}

		const iconsMimeTypesMap = {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'green',
			'application/vnd.ms-excel': 'green',
			'application/msword': 'blue',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'blue',
			'application/pdf': 'red',
		};

		return iconsMimeTypesMap[mimeType];
	}

	render()
	{
		const {
			iconStyle,
			icon,
			file,
			onRemoveClick,
			labelVisible,
			size,
		} = this.props;

		const { hovered } = this.state;

		const popperConfig = {
			modifiers: [
				{
					name: 'preventOverflow',
					enabled: false
				}, {
					name: 'hide',
					enabled: false
				}
			]
		};

		const linkIcon = <FontAwesomeIcon
			style={{color: this.getIconColor(), ...iconStyle}}
			fixedWidth
			size={size}
			icon={icon || this.getIcon()}
		/>;

		const link = this.props.withAuth
			? <AuthDownloadLink href={file.url}>{linkIcon}</AuthDownloadLink>
			: <a href={file.url} target="_blank">{linkIcon}</a>
		;

		const label = labelVisible && (
			<span className={style.label}>
				{file.originalName}
			</span>
		);

		const removeIcon = !!onRemoveClick && <FontAwesomeIcon
			className={cn(
				{ [style.removeIconHovered]: hovered },
				{ [style.removeIcon]: !hovered }
			)}
			icon={faTimes}
			fixedWidth
			onClick={() => onRemoveClick(file)}
		/>;

		return (
			<OverlayTrigger
				trigger={["hover", "focus"]}
				popperConfig={popperConfig}
				overlay={
					<Tooltip>
						{file.originalName}
					</Tooltip>
				}
			>
				<div
					onMouseEnter={this.onMouseEnterHandler}
					onMouseLeave={this.onMouseLeaveHandler}
					className={style.wrapper}
				>
					{link}
					{label}
					{onRemoveClick && removeIcon}
				</div>
			</OverlayTrigger>
		);
	}
}

export default FileDownloadIcon;
