import React from 'react';
import PropTypes from 'prop-types';
import Grid from './components/Grid';

import api from 'core/api';

class NewRequestDialog extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		purchaseRequestItems: PropTypes.arrayOf(PropTypes.object),
		purchaseRequestsMap: PropTypes.any,
		onRequestCreated: PropTypes.func,
		onDialogReady: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	state = {
		loading: false,
	}

	componentDidMount()
	{
		this.props.onDialogReady({ getTransactionBody: this.getTransactionBody });
	}

	getTransactionBody = () =>
	{
		const itemsToCreate = [];
		const excessItemsToCreate = [];
		const transactionBody = [];
		let orderedAmount = 0;

		const { items, purchaseRequestItems } = this.props;

		for (const item of items) {
			if (!item.requestAmount) {
				return null;
			}

			orderedAmount = purchaseRequestItems.filter(purchaseRequestItem => {
				if (!purchaseRequestItem.isDeleted && purchaseRequestItem.isAmountApproved && purchaseRequestItem.consumableSheetItemId === item.id) {
					return true;
				}

				return false;
			}).reduce((acc, item) => {
				return acc + Number(item.amount);
			}, 0);

			if (orderedAmount + Number(item.requestAmount) > Number(item.amount)) {
				excessItemsToCreate.push({
					consumableSheetItemId: item.id,
					amount: item.requestAmount,
					notes: item.notes,
					//isApproved: false,
				});
			} else {
				itemsToCreate.push({
					consumableSheetItemId: item.id,
					amount: item.requestAmount,
					notes: item.notes,
					//isApproved: false, // че то тут какая то хунйя, всегда true создаётся, надо чекнуть
				});
			}
		}

		const requestToCreate = itemsToCreate.length ? api.tbsPurchaseRequests().create({
			consumableSheetId: this.props.consumableSheetId,
			items: itemsToCreate,
		}) : null;

		const unapprovedRequestToCreate = excessItemsToCreate.length ? api.tbsPurchaseRequests().create({
			consumableSheetId: this.props.consumableSheetId,
			items: excessItemsToCreate,
		}) : null;

		if (requestToCreate) {
			transactionBody.push(requestToCreate);
		}

		if (unapprovedRequestToCreate) {
			transactionBody.push(unapprovedRequestToCreate);
		}

		if (!transactionBody.length) {
			return null;
		}

		return transactionBody;
	}

	render()
	{
		const gridHeight = this.props.items.length * 38 + 42;

		return (
			<div
				className="d-flex"
				style={{
					height: gridHeight
				}}
			>
				<Grid
					classname="w-100"
					style={{
						height: '50vh',
						minHeight: '400px',
					}}
					items={this.props.items}
				/>
			</div>
		);
	}
}

export default NewRequestDialog;


