import HistoryDataIterator from '../HistoryDataIterator';
import HistoryBuilder from './HistoryBuilder';


export default class PerfomancePercentBuilder extends HistoryBuilder
{
	build()
	{
		const { history, params } = this;
		const { jobsMap, totalPerformance } = params;

		if (!jobsMap) {
			throw (new Error('jobsMap is Required!'));
		}

		if (totalPerformance === 0) {
			return new HistoryDataIterator(history).forEach(() => 0);
		}

		if (!totalPerformance) {
			throw (new Error('totalPerformance is Required!'));
		}

		return new HistoryDataIterator(history).forEach((value, jobId) => {
			const job = jobsMap.get(jobId);

			if (!job) {
				return 0;
			}

			const { productionRatePerShift } = job;

			if (!productionRatePerShift) {
				return 0;
			}

			return value * productionRatePerShift / totalPerformance * 100;
		});

	}
}
