import { parseISO, differenceInDays, isValid, add, eachDayOfInterval, formatISO } from 'date-fns';
import JobQualifier from 'utils/JobQualifier';


const normalizePerfomance = (data, startDate, endDate) => {
	if (!isValid(startDate) || !isValid(endDate)) {
		return data;
	}

	if (!data || data === {}) {
		return data;
	}

	const normalized = {};

	const maxDate = Object.keys(data).reduce((a, b) => (a > b ? a : b));
	const maxDateValue = data[maxDate];

	for (const day of eachDayOfInterval({ start: startDate, end: endDate })) {
		const key = formatISO(day, { representation: 'date' });

		normalized[key] = data[key] === undefined ? maxDateValue : data[key];
	}

	return normalized;
};

export default class ProjectQualifier
{
	static estimatedEndDate(project, jobs)
	{
		if (!project) {
			return null;
		}

		if (!jobs) {
			return null;
		}

		const { estimatedStartDate } = project;
		const startDate = parseISO(estimatedStartDate);

		if (!isValid(startDate)) {
			return null;
		}

		const now = new Date();

		const projectDaysDurationToNow = differenceInDays(now, startDate);

		if (projectDaysDurationToNow <= 0) {
			return null;
		}

		const productionRateCapacity = jobs.reduce((sum, job) => sum + JobQualifier.totalPerformance(job), 0);
		const currentPerformance = jobs.reduce((sum, job) => sum + JobQualifier.currentPerformance(job), 0);

		const dailyProductionRate = currentPerformance / projectDaysDurationToNow;

		if (dailyProductionRate <= 0) {
			return null;
		}

		const productionRateRemain = productionRateCapacity - currentPerformance;

		const estimatedDaysToEnd = Math.ceil(productionRateRemain / dailyProductionRate);

		return add(now, { days: estimatedDaysToEnd });
	}

	static plannedProgressOnDate(project, jobs, date = new Date())
	{
		const progress = ProjectQualifier.plannedPerformanceWithinInterval(project, jobs, date, date);

		return progress ? progress[0] : null;
	}

	static plannedPerformanceWithinInterval(project, jobs, startDate, endDate)
	{
		if (!project) {
			return null;
		}

		if (!jobs) {
			return null;
		}

		if (!isValid(startDate)) {
			return null;
		}

		if (!isValid(endDate)) {
			return null;
		}

		const jobsPerfomance = jobs
			.filter(job => job.estimatedStartDate && job.estimatedStartDate < formatISO(endDate, { representation: 'date' }))
			.map(job => normalizePerfomance(
				JobQualifier.plannedPerformance(job),
				job.estimatedStartDate ? parseISO(job.estimatedStartDate) : null,
				endDate
			))
			.filter(p => !!p)
		;

		const projectIntervalPerfomance = {};

		for (const day of eachDayOfInterval({ start: startDate, end: endDate })) {
			const key = formatISO(day, { representation: 'date' });

			const dayPerfomance = jobsPerfomance.map(jp => jp[key])
				.filter(jp => !!jp)
				.reduce((acc, value) => acc + value, 0)
			;

			projectIntervalPerfomance[key] = dayPerfomance;
		}

		return Array.from(Object.values(projectIntervalPerfomance));
	}

	static totalPerformance(jobs)
	{
		return jobs.reduce((acc, job) => {
			return acc + JobQualifier.totalPerformance(job);
		}, 0);
	}

	static plannedPerformancePercentWithinInterval(project, jobs, startDate, endDate)
	{
		const totalPerformance = jobs.reduce((acc, job) => acc + JobQualifier.totalPerformance(job), 0);

		if (!totalPerformance) {
			return null;
		}

		const perfomance = ProjectQualifier.plannedPerformanceWithinInterval(project, jobs, startDate, endDate);


		if (!perfomance) {
			return null;
		}

		return perfomance.map(v => v / totalPerformance * 100);
	}

	static plannedPerformancePercentOnDate(project, jobs, date = new Date())
	{
		const progress = ProjectQualifier.plannedPerformancePercentWithinInterval(project, jobs, date, date);

		return progress ? progress[0] : null;
	}

	static currentProgressPercents(project, jobs)
	{
		if (!project) {
			return null;
		}

		if (!jobs) {
			return null;
		}

		const productionRateCapacity = jobs.reduce((sum, job) => sum + JobQualifier.totalPerformance(job), 0);
		const currentPerformance = jobs.reduce((sum, job) => sum + JobQualifier.currentPerformance(job), 0);

		if (productionRateCapacity === 0) {
			return 0;
		}

		return currentPerformance / productionRateCapacity * 100;
	}

	static daysToEnd(project)
	{
		if (!project) {
			return null;
		}

		const { estimatedEndDate } = project;
		const endDate = parseISO(estimatedEndDate);

		if (!isValid(endDate)) {
			return null;
		}

		const days = differenceInDays(endDate, new Date());

		return days < 0 ? 0 : days;
	}

	static daysDelay(project)
	{
		if (!project) {
			return null;
		}

		const { estimatedEndDate } = project;
		const endDate = parseISO(estimatedEndDate);

		if (!isValid(endDate)) {
			return null;
		}

		const days = differenceInDays(new Date(), endDate);

		return days < 0 ? 0 : days;
	}

	static jobsWithoutReport(jobs, periodicReports, periodocReportItems)
	{
		if (!jobs || !periodicReports || !periodocReportItems) {
			return null;
		}

		const jobsInWork = jobs.filter(job => JobQualifier.hasStartedOnDate(job) && !JobQualifier.isDone(job));

		const now = formatISO(new Date(), { representation: 'date' });

		const todayReport = periodicReports.find(pr => pr.date === now);

		if (!todayReport) {
			return jobsInWork;
		}

		const todayReportJobIds = periodocReportItems
			.filter(pri => pri.periodicReportId === todayReport.id)
			.map(pri => pri.jobId)
		;

		return jobsInWork.filter(job => !todayReportJobIds.includes(job.id));
	}
}
