import React from 'react';
import { connect } from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { Empty } from 'components/ui';
import { ListGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import cn from 'classnames';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import ReportListItem from './components/ReportListItem';
import EditModal from './components/EditModal';

import style from './style.module.sass';


class ReportList extends React.PureComponent
{
	state = {
		reportItem: null,
		editModalVisible: false,
	}

	onApproveClicked = report => {
		this.props.dispatch(api.periodicReports().commit({
			periodicReportId: report.id,
		}))
			.then(() => db.jobs.list({refresh: true}))
			.catch(error => {
				toast.error("Не удалось утвердить отчёт", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onEditItemClicked = reportItem => {
		this.setState({ reportItem }, () => this.openEditModal());
	}

	saveChanges = (reportItem, changes) => {
		this.props.dispatch(api.periodicReportItems().update({
			periodicReportItemId: reportItem.id,
			changes,
		}))
			.then(() => this.setState({ reportItem: null }, () => this.closeEditModal()))
			.catch(err => {
				toast.error("Не удалось отредактировать отчёт", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	openEditModal = () => this.setState({ editModalVisible: true })

	closeEditModal = () => this.setState({ editModalVisible: false })

	getGroup = periodicReports => {
		const map = new Map();

		for (const pr of periodicReports) {
			const { date } = pr;

			const reports = map.get(date);

			if (reports) {
				reports.push(reports);
			} else {
				map.set(date, [pr]);
			}
		}

		return map;
	}

	renderGroup = group => {
		const { projectId } = this.props;

		return (
			Array.from(group.keys())
				.sort((a, b) => ((a > b) ? -1 : 1))
				.map(date => {
					return <div key={date}>
						<div className={style.date}>
							{moment(date).format('L')}
						</div>
						<ListGroup className={style.list}>
							{group.get(date).map((report, i) => {

								return (
									<ListGroup.Item key={i}>
										<ReportListItem
											projectId={projectId}
											periodicReport={report}
											periodicReportId={report?.id}
											onApproveClicked={report => this.onApproveClicked(report)}
											onEditItemClicked={item => this.onEditItemClicked(item)}
										/>
									</ListGroup.Item>
								);
							})}
						</ListGroup>
					</div>;
				})
		);
	}

	render()
	{
		const { periodicReports, isLoading } = this.props;

		if (isLoading || !periodicReports.length) {
			return <Empty
				title="Нет отчётов."
				className={cn('h-100', 'w-100')}
			/>;
		}

		const commitedReports = periodicReports.filter(pr => pr.isCommitted) || [];
		const notCommitedReports = periodicReports.filter(pr => !pr.isCommitted) || [];

		const commitedGroup = this.getGroup(commitedReports);
		const notCommitedGroup = this.getGroup(notCommitedReports);

		return (
			<div style={{
				padding: "8px"
			}}>
				{this.renderGroup(notCommitedGroup)}
				{this.renderGroup(commitedGroup)}
				<EditModal
					visible={this.state.editModalVisible}
					reportItem={this.state.reportItem}
					onCancel={() => this.closeEditModal()}
					onConfirm={(item, changes) => this.saveChanges(item, changes)}
					changesOnly={true}
				/>
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const periodicReports = db.periodicReports.list({ filter: { projectId } });

	return {
		periodicReports,
		projectId,
		isLoading: periodicReports.isLoading,
	};

};

export default connect(mapToProps)(ReportList);
