import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { faPlus, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import { TagToolbar, ActionsToolbar, FileDownloadToolbar, FilterToolbar, Toolbar } from 'components/ui';
import { Modal } from 'components/ui/modals';
import { uniq } from 'lodash';

class TopToolbar extends React.PureComponent
{
	static propTypes = {
		sourceSpecs: PropTypes.arrayOf(PropTypes.object).isRequired,
		consSpec: PropTypes.object.isRequired,
		onFilterChange: PropTypes.func,
		onUploadFinished: PropTypes.func,
		onMergeClicked: PropTypes.func,
		mergingItems: PropTypes.arrayOf(PropTypes.any),
		items: PropTypes.arrayOf(PropTypes.object),
		merging: PropTypes.bool,
		onUploadSourceSpec: PropTypes.func,
		onRemoveSourceSpec: PropTypes.func,
	}

	static defaultProps = {
		mergingItems: [],
		items: [],
		merging: false,
	}

	state = {
		uploading: false,
	}

	onRemoveSourceSpecClicked = (attachedFile) => {
		Modal.confirm({
			title: 'Удаление ИС',
			text: `Удалить ${attachedFile.originalName}?`,
			onConfirm: () => this.props.onRemoveSourceSpec(attachedFile),
		});
	}

	render()
	{
		const isApproved = this.props.consSpec.isApproved;

		const tagList = [
			{
				title: 'Утверждена',
				variant: 'success',
			},
		];

		const tagToolbar = isApproved && <TagToolbar
			tagList={tagList}
			className="p-2"
		/>;

		const sourceSpecsAttachedXlsxList = this.props.sourceSpecs.map(sourceSpec => {
			return {
				file: {
					id: sourceSpec.id,
					originalName: sourceSpec.attachment.originalName,
					url: sourceSpec.attachment.url,
				},
				icon: faFileExcel,
				iconStyle: { color: 'green' },
			};
		});

		const consSpecAttachedXlsxList = isApproved ? this.props.consSpec.artifacts.map(art => {
			return {
				file: {
					id: art.downloads.xlsx.url,
					originalName: art.title,
					url: art.downloads.xlsx.url,
				},
				icon: faFileExcel,
				iconStyle: { color: 'green' },
				withAuth: true,
			};
		}) : [];

		const attachedIconsList = [...sourceSpecsAttachedXlsxList, ...consSpecAttachedXlsxList];

		const uploadActions = [
			{
				icon: faPlus,
				tooltip: 'Загрузить файл спецификаций',
				onClick: () => this.uploadInputRef.click(),
			}
		];

		const uploadIcon = !isApproved &&
		<div>
			<ActionsToolbar
				actions={uploadActions}
				className="mx-2"
			/>
			<input
				ref={ref => (this.uploadInputRef = ref)}
				type="file"
				style={{ display: 'none' }}
				onChange={() => this.props.onUploadSourceSpec(this.uploadInputRef.files[0])}
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
			/>
		</div>;

		const attachedFilesToobar =
			<FileDownloadToolbar
				iconsList={attachedIconsList}
				onRemoveClick={isApproved ? null : this.onRemoveSourceSpecClicked}
				isRemovable={true}
			/>
		;

		const { sourceSpecs, items } = this.props;

		const filterToolbar = <FilterToolbar
			filters={[
				{
					key: 'sourceSpecificationId',
					options: sourceSpecs.map(ss => ({ value: ss.id, label: ss.title })),
					placeholder: 'Исходные спецификации',
					isMulti: true,
				},
				{
					key: 'manufacturerName',
					options: uniq(items.map(ssi => ssi.manufacturerName))
						.filter(mnu => mnu && mnu !== '')
						.map(mnu => ({ value: mnu, label: mnu })),
					placeholder: 'Производитель',
					isMulti: true,
				}
			]}
			onChange={this.props.onFilterChange}
		/>;

		const { mergingItems } = this.props;

		const mergeButton = !!mergingItems.length && (
			<Button
				variant="outline-primary"
				onClick={() => this.props.onMergeClicked()}
			>
				{`Объеденить (${mergingItems.length})`}
			</Button>
		);

		return (
			<Toolbar className="justify-content-between w-100">
				<div className="d-flex flex-row align-items-center">
					{tagToolbar}
					{filterToolbar}
				</div>
				<div className="d-flex flex-row align-items-center">
					{mergeButton}
					{uploadIcon}
					{attachedFilesToobar}
				</div>
			</Toolbar>
		);
	}
}

export default connect()(TopToolbar);
