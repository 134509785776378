import EventEmitter from './EventEmitter';

class ActivityIndicatorController
{
	constructor(delaySeconds)
	{
		this.emitter = new EventEmitter;
		this.delaySeconds = delaySeconds;
		this.currentStackSize = 0;
	}

	emit(...args)
	{
		this.emitter.emit(...args);
	}

	on(...args)
	{
		this.emitter.addListener(...args);
	}

	push()
	{
		this.currentStackSize++;

		if (this.currentStackSize === 1) {
			this.delayTimer = setTimeout(
				() => {
					this.emit('show');
				},
				this.delaySeconds * 1000
			);
		}
	}

	pop()
	{
		this.currentStackSize--;

		if (this.currentStackSize === 0) {
			clearTimeout(this.delayTimer);

			this.emit('hide');
		}
	}

	wrap(promise)
	{
		this.push();

		return promise
			.then(r => {
				this.pop();

				return r;
			})
			.catch(e => {
				this.pop();

				throw e;
			})
		;
	}
}

export default ActivityIndicatorController;
