import { isValid, parseISO, differenceInDays, isBefore, eachDayOfInterval, formatISO } from 'date-fns';


export default class JobQualifier
{
	static daysDuration(job)
	{
		const { estimatedStartDate, estimatedEndDate } = job;

		const jobStartDate = parseISO(estimatedStartDate);
		const jobEndDate = parseISO(estimatedEndDate);

		if (!isValid(jobStartDate) || !isValid(jobEndDate)) {
			return null;
		}

		return differenceInDays(jobEndDate, jobStartDate);
	}

	//! Индекс производительности труда
	static perfomance(job)
	{
		const { currentProgress, numberOfEmployedWorkers } = job;

		if (!numberOfEmployedWorkers || !currentProgress) {
			return 0;
		}

		return Number(currentProgress) / Number(numberOfEmployedWorkers);
	}

	static isDone(job)
	{
		const { currentProgress, maxProgress } = job;

		if (!maxProgress) {
			return null;
		}

		if (currentProgress !== maxProgress) {
			return false;
		}

		return true;
	}

	static isExpiredOnDate(job, date = new Date())
	{
		const { estimatedEndDate, currentProgress, maxProgress } = job;
		const jobEndDate = parseISO(estimatedEndDate);

		if (!isValid(date) || !isValid(jobEndDate) || currentProgress >= maxProgress) {
			return null;
		}

		return isBefore(jobEndDate, date);
	}

	static hasStartedOnDate(job, date = new Date())
	{
		const { estimatedStartDate } = job;
		const jobStartDate = parseISO(estimatedStartDate);

		if (!isValid(date) || !isValid(jobStartDate)) {
			return null;
		}

		return isBefore(jobStartDate, date);
	}

	static plannedPerformance(job)
	{
		const { estimatedStartDate, estimatedEndDate } = job;

		const jobStartDate = parseISO(estimatedStartDate);
		const jobEndDate = parseISO(estimatedEndDate);

		if (!isValid(jobStartDate) || !isValid(jobEndDate)) {
			return null;
		}

		const jobProductionRate = JobQualifier.totalPerformance(job);

		if (jobProductionRate === 0) {
			return null;
		}

		const jobDaysCount = differenceInDays(jobEndDate, jobStartDate);

		if (jobDaysCount <= 0) {
			return null;
		}

		const totalPerformance = JobQualifier.totalPerformance(job);

		if (!totalPerformance) {
			return null;
		}

		const days = eachDayOfInterval({ start: jobStartDate, end: jobEndDate });

		if (!days.length) {
			return null;
		}

		const dailyPerfomance = totalPerformance / days.length;

		const perfomanceByDate = {};

		for (let i = 0; i < days.length; ++i) {
			const day = days[i];
			const key = formatISO(day, { representation: 'date' });
			const perfomance = (i + 1) * dailyPerfomance;
			perfomanceByDate[key] = perfomance;
		}

		return perfomanceByDate;
	}

	static progressPercents(job, round = 1)
	{
		const { currentProgress, maxProgress } = job;

		if (!maxProgress || !currentProgress) {
			return 0;
		}

		return Number(currentProgress / maxProgress * 100).toFixed(round);
	}

	static totalPerformance = (job) => {
		const { maxProgress, productionRatePerShift } = job;

		if (!maxProgress || !productionRatePerShift) {
			return 0;
		}

		return maxProgress * productionRatePerShift;
	}

	static currentPerformance = job => {
		const { currentProgress, productionRatePerShift } = job;

		if (!currentProgress || !productionRatePerShift) {
			return 0;
		}

		return currentProgress * productionRatePerShift;
	}
}
