import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatISO, parseISO } from 'date-fns';
import { pickBy, identity } from 'lodash';

import FormikForm from 'components/ui/forms/formik/Form';
import db from 'core/db';

const prepareInitialValues = values => {
	const { estimatedStartDate, estimatedEndDate } = values;

	return {
		dates: [
			estimatedStartDate ? parseISO(estimatedStartDate) : null,
			estimatedEndDate ? parseISO(estimatedEndDate) : null,
		],
		...values
	};
};

const prepareSubmitValues = (values) => {
	const copy = { ...values };
	delete copy.workerDayHours;
	const { dates, ...other} = copy;

	return pickBy({
		estimatedStartDate: dates && dates[0] ? formatISO(dates[0], { representation: 'date' }) : null,
		estimatedEndDate: dates && dates[1] ? formatISO(dates[1], { representation: 'date' }) : null,
		...other,
	}, identity);
};

const JobEditForm = (props) => {
	const form = useRef(null);

	const simplifiedSchema = [
		[
			{ id: 'title', type: 'text', label: 'Наименование' }
		],
		[
			{ id: 'assignedUserId', type: 'select', label: 'Ответственный', sm: 6},
			{ id: 'dates', type: 'daterange', label: 'Даты начала и завершения'},
		],
	];

	const inputsProps = {
		'title': {
			placeholder: "Название участка или этапа",
		},
		'assignedUserId': {
			placeholder: 'Не назначен',
			options: props.users.map(u => ({ value: u.id, label: u.fullName || u.email || u.phoneNumber})),
		},
		'dates': { placeholders: ['Выберите дату начала', 'Выберите дату завершения'] },
	};

	return <FormikForm
		simplifiedSchema={simplifiedSchema}
		onFormCreated={f => {
			form.current = f;
			props.onFormCreated(f);
		}}
		initialValues={prepareInitialValues(props.initialValues)}
		changesOnly={props.changesOnly}
		inputsProps={inputsProps}
		onSubmit={changes => props.onSubmit(prepareSubmitValues(changes))}
		onCancel={() => props.onCancel()}
	/>;
};

JobEditForm.propTypes = {
	onFormCreated: PropTypes.func,
	onSubmit: PropTypes.func,
	initialValues: PropTypes.object,
	changesOnly: PropTypes.bool,
	onCancel: PropTypes.func,
};

const mapToProps = (state, props) => {

	const users = db.users.list();

	return { users };
};

export default connect(mapToProps)(JobEditForm);
