import React, { useEffect, useState } from 'react';
import { TimePicker } from 'components/ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';


const TimeInput = (props) => {
	const { placeholder, ...otherProps } = props;
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return <TimePicker
		onChange={date => helpers.setValue(date)}
		value={value}
		customInput={<Form.Control/>}
		placeholderText={placeholder}
		controlled
		{...otherProps}
	/>;
};


TimeInput.propTypes = {
	name: PropTypes.string.isRequired,
};

export default TimeInput;
