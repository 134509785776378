import React from 'react';
import PropTypes from 'prop-types';

import { CustomGrid } from 'components/ui/grid';

import { getColumnDefs, getGridOptions } from './defs';

class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		currenciesMap: PropTypes.any,
		onRemoveAttachedFile: PropTypes.func,
		onShowInvoiceInfo: PropTypes.func,
	};

	static defaultProps = {
		items: [],
	}

	onGridReady = params => {
		this.gridApi = params.api;
	}

	onFirstDataRendered = params => {
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressRowClickSelection={true}
				onGridReady={this.onGridReady}
				onFirstDataRendered={this.onFirstDataRendered}
				suppressCellSelection={true}
			/>
		);
	}
}

export default Grid;

