import React from 'react';
import PropTypes from 'prop-types';
import { CustomGrid } from 'components/ui/grid';

class ParseGrid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
	};

	static defaultProps = {
		items: [],
	}

	onFirstDataRendered = params => {
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const columnDefs = [
			{
				headerName: 'Позиции в смете',
				field: 'consumableSheetItem.title',
			},
			{
				headerName: 'Позиции в счёте',
				field: 'invoiceItemTitle',
			}
		];

		const gridOptions = {
			defaultColDef: {
				editable: false,
			}
		};

		const { items } = this.props;

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={items}
				onFirstDataRendered={this.onFirstDataRendered}
			/>
		);
	}
}

export default ParseGrid;
