import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';


class HorizontalLine extends React.PureComponent
{

	static propTypes = {
		className: PropTypes.string,
		text: PropTypes.string,
	}

	render()
	{
		const { text, className } = this.props;

		const lineStyle = {
			height: '1px',
			backgroundColor: 'rgba(0,0,0,0.2)',
		};

		const textComp = (
			<>
				<div className="mx-4">
					<h4 className={cn('text-uppercase', 'text-black-50')}>
						{text}
					</h4>
				</div>
				<div className={cn('flex-grow-1')} style={lineStyle}/>
			</>
		);

		return (
			<div className={cn('d-flex', 'flex-row', 'align-items-center', className)}>
				<div className={cn('flex-grow-1')} style={lineStyle}/>
				{text ? textComp : null}
			</div>
		);

	}
}

export default HorizontalLine;
