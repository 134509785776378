import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import ReportFrom from './components/ReportForm';


class ReportModal extends React.PureComponent
{
	static propTypes = {
		visible: PropTypes.bool,
		reportItem: PropTypes.object,
		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,
		changesOnly: PropTypes.bool,
	}

	render()
	{
		const { reportItem, visible, onCancel, onConfirm, changesOnly } = this.props;

		const btnList = [
			{
				title: 'Сохранить',
				props: {
					onClick: () => this.form.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
					variant: "secondary",
				}
			}
		];

		return (
			<Modal
				title="Отчёт о работе"
				show={visible}
				onHide={() => onCancel()}
				btnList={btnList}
			>
				<ReportFrom
					reportItem={reportItem}
					onFormCreated={props => (this.form = props)}
					onSubmit={values => onConfirm(reportItem, values)}
					changesOnly={changesOnly}
					onCancel={() => onCancel()}
				/>
			</Modal>
		);
	}
}

export default ReportModal;
