import React from 'react';
import { connect } from 'react-redux';

import db from 'core/db';
import ProjectQualifier from 'utils/ProjectQualifier';
import JobQualifier from 'utils/JobQualifier';

import Workspace from 'layouts/Default/components/Workspace';
import { Container } from 'components/ui';

import {
	FiltersCard,
	ProjectProgressCard,
	JobsDynamicCard,
	PerformanceCard,
	WeeklyHoursCard,
	JobsProgressCard,
	ValueCard,
} from './components/cards';


import style from './style.module.sass';


class ReportView extends React.PureComponent
{
	state = {
		dates: [],
	}

	render()
	{
		const { project, workers, periodicReports, periodicReportItems, jobs } = this.props;

		//Дней до конца проекта
		const daysToEnd = ProjectQualifier.daysToEnd(project);

		//Кол-во дней как истек срок сдачи
		const daysDelay = ProjectQualifier.daysDelay(project);

		//Текущих работ без отчёта
		const jobsWithoutReport = ProjectQualifier.jobsWithoutReport(jobs, periodicReports, periodicReportItems);

		//Работ с истекщим сроком сдачи
		const jobsExpired = jobs?.filter(job => JobQualifier.isExpiredOnDate(job));

		const [startDate, endDate] = this.state.dates;

		return (
			<Workspace>
				<div className={style.ReportPage}>
					<Container layout="row">
						<FiltersCard onDatesChanged={dates => this.setState({ dates })} />
					</Container>
					<Container layout="row" schema={[2, 3]} height={530} bind>
						<ProjectProgressCard
							startDate={startDate}
							endDate={endDate}
						/>
						<Container layout="column" schema={[0, 1]}>
							<Container layout="row" between >
								<ValueCard label="Дней до сдачи" value={daysToEnd} color="#E0F0E0"/>
								<ValueCard label="Людей на проекте" value={workers?.length} color="#E7ECF8"/>
								<ValueCard label="Работы без отчёта" value={jobsWithoutReport?.length} color="#FEEFC9"/>
								<ValueCard label="Отставание" value={daysDelay} color="#FFD5A5"/>
								<ValueCard label="Просрочено" value={jobsExpired?.length} color="#FBDBD7"/>
							</Container>
							<JobsProgressCard/>
						</Container>
					</Container>
					<Container layout="row" schema={[2, 3]} height={350} bind>
						<PerformanceCard
							startDate={startDate}
							endDate={endDate}
						/>
						<WeeklyHoursCard/>
					</Container>
					<Container layout="row" height={530} bind>
						<JobsDynamicCard
							startDate={startDate}
							endDate={endDate}
						/>
					</Container>
				</div>
			</Workspace>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };
	const companyId = state.me.defaultEmployee.companyId;

	const collects = {
		projects: db.projects.list({ filter: { id: projectId } }),
		jobs: db.jobs.listNotDeleted(projectIdFilter),
		periodicReports: db.periodicReports.list(projectIdFilter),
		periodicReportItems: db.periodicReportItems.list(),
		workers: db.workers.listNotDeleted({ filter: { companyId, projectId } }),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	return {
		projectId,
		project: collects.projects[0],
		isLoading,
		...collects,
	};
};

export default connect(mapToProps)(ReportView);
