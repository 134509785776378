import React from 'react';
import {connect} from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { toast } from 'react-toastify';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import Workspace from 'layouts/Default/components/Workspace';
import { Modal } from 'components/ui/modals';
import Grid from './components/Grid';
import InvitationModal from './components/InvitationModal';


class List extends React.PureComponent
{

	state = {
		invitationModalVisible: false
	}

	openInvitationModal = () => this.setState({ invitationModalVisible: true })

	closeInvitationModal = () => this.setState({ invitationModalVisible: false })

	onRemoveRole = (employee, role) => {
		this.props.dispatch(api.employees().update({
			employeeId: employee.id,
			changes: {
				roles: {
					deleted: [{id: role.id}]
				}
			}
		}))
			.catch((err) => {
				toast.error("Не удалось удалить роль.", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	onRemoveRoleModal = (employee, role) => {
		const { fullName, email } = employee.user;
		const phoneNumber = parsePhoneNumberFromString(employee.user.phoneNumber, 'RU').formatInternational();

		Modal.confirm({
			title: 'Удаление роли пользователя',
			text: `Удалить роль '${role.title}' у ${fullName || phoneNumber || email}?`,
			onConfirm: () => this.onRemoveRole(employee, role),
		});
	}

	onAddEmployeeRoleModal = (employee) => {
		const { fullName, email } = employee.user;
		const phoneNumber = parsePhoneNumberFromString(employee.user.phoneNumber, 'RU').formatInternational();

		Modal.select({
			title: 'Добавление роли',
			inputTitle: `Новая роль для ${fullName || phoneNumber || email}`,
			values: this.props.roles.map(role => role.id),
			labels: this.props.roles.map(role => role.title),
			onConfirm: (roleId) => this.onAddEmployeeRole(employee, roleId),
		});
	}

	onAddEmployeeRole = (employee, roleId) => {
		this.props.dispatch(api.employees().update({
			employeeId: employee.id,
			changes: {
				roles: {
					new: [{id: roleId}]
				}
			}
		}))
			.catch((err) => {
				toast.error("Не удалось добавить роль", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	onRemoveEmployeeModal = (employee) => {
		const { fullName, email } = employee.user;
		const phoneNumber = parsePhoneNumberFromString(employee.user.phoneNumber, 'RU').formatInternational();

		Modal.confirm({
			title: 'Удаление пользователя',
			text: `Удалить пользователя ${fullName || phoneNumber || email}?`,
			onConfirm: () => this.onRemoveEmployee(employee),
		});
	}


	onRemoveEmployee = (employee) => {
		this.props.dispatch(api.employees().deactivate({
			employeeId: employee.id
		}))
			.catch((error) => {
				toast.error("Не удалось удалить пользователя.", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	inviteEmployee = values => {
		this.props.dispatch(api.employees().invite({
			companyId: this.props.companyId,
			...values,
		}))
			.then(() => this.closeInvitationModal())
			.then(() => toast.success(`Приглашение ${values.phoneNumber} отправлено!`, {
				hideProgressBar: true,
			}))
			.catch((error) => {
				toast.error("Не удалось отправить приглашение.", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	render()
	{
		const actions = [
			{
				title: 'Пригласить сотрудника',
				onClick: () => this.openInvitationModal(),
			}
		];

		return (
			<Workspace actions={actions} hideTitle={true}>
				<Grid
					items={this.props.visibleEmployees}
					onRemoveEmployee={this.onRemoveEmployeeModal}
					onRemoveEmployeeRole={this.onRemoveRoleModal}
					onAddEmployeeRole={this.onAddEmployeeRoleModal}
					onInviteEmployee={employee => this.inviteEmployee({ phoneNumber: employee.user.phoneNumber })}
				/>
				<InvitationModal
					visible={this.state.invitationModalVisible}
					onCancel={() => this.closeInvitationModal()}
					onConfirm={values => this.inviteEmployee(values)}
				/>
			</Workspace>
		);
	}
}

const mapToProps = (state, props) => {
	const employees = db.employees.list();
	const roles = db.roles.list();
	const companyId = state.company.id;
	const visibleEmployees = employees.filter(e => !(e.inviteState === 'decliened' && !e.deactivationTime));

	return {
		employees: employees,
		visibleEmployees: visibleEmployees,
		roles: roles,
		companyId: companyId,
	};
};

export default connect(mapToProps)(List);
