import React from 'react';
import PropTypes from 'prop-types';
// TODO: Вынести в общие компоненты и назвать нормально
import ContextMenu from 'components/ui/TicketLayout/components/ContextMenu';


class ActionsCellRenderer extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		actions: []
	}

	render()
	{
		const { actions } = this.props;

		return (
			<div className={"d-flex justify-content-center align-items-center h-100 w-75 position-absolute"}>
				<ContextMenu
					actions={actions}
					placement="auto-start"
				/>
			</div>
		);
	}
}

export default ActionsCellRenderer;
