import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';

import api from 'core/api';

import LoginForm from './components/LoginForm';
import CodeForm from './components/CodeForm';

class AuthPage extends React.PureComponent
{
	state = {
		codeSent: false,
		isRegistered: false,
		codeConfirmError: null,
		login: null,
		isLoading: false,
		isCodeTimeout: false,
	}

	componentWillUnmount()
	{
		delete this.timeout;
	}

	onSubmitLogin = data => {
		const login = this.loginFormApi.getLogin(data);
		this.setState({ login }, () => this.getCode());
	}

	onConfirmCode = code => {
		if (this.state.isRegistered) {
			this.login(code);
		} else {
			this.register(code);
		}
	}

	onSendCodeAgain = () => {
		if (this.timeout) {
			return;
		}

		this.getCode();

		this.setState({ isCodeTimeout: true }, () => {
			this.timeout = setTimeout(() => {
				delete this.timeout;
				this.setState({ isCodeTimeout: false });
			}, 10000);
		});
	}

	getCode = () => {
		const { login } = this.state;
		let codeRequest = null;

		if (login.phoneNumber) {
			codeRequest = api.authentication().sendSmsCode({...login});
		}

		if (login.email) {
			codeRequest = api.authentication().sendEmailCode({...login});
		}

		const registerCheckRequest = api.authentication().isRegistered({...login});

		this.setState({ isLoading: true }, () => {
			this.props.dispatch(codeRequest)
				.then(() => this.props.dispatch(registerCheckRequest))
				.then(res => this.setState({ codeSent: true, isRegistered: res.registered }))
				.catch(error => {
					toast.error("Ошибка получения кода.", {
						position: toast.POSITION.TOP_CENTER,
						hideProgressBar: true,
					});
				})
				.finally(() => this.setState({ isLoading: false }))
			;
		});
	}

	register = code => {
		const { login } = this.state;

		const reg = api.authentication().register({...login, code});

		this.setState({ isLoading: true }, () => {
			this.props.dispatch(reg)
				.then((r) => {
					this.props.dispatch({
						type: 'LOGIN_WITH_TOKEN',
						token: r.token
					});
				})
				.catch(error => this.setState({ codeConfirmError: true }))
				.finally(() => this.setState({ isLoading: false }))
			;
		});
	}

	login = code => {
		const { login } = this.state;

		this.setState({ isLoading: true }, () => {
			this.props.dispatch(api.authentication().login({...login, code}))
				.then((r) => {
					this.props.dispatch({
						type: 'LOGIN_WITH_TOKEN',
						token: r.token
					});
				})
				.catch(error => this.setState({ codeConfirmError: true }))
				.finally(() => this.setState({ isLoading: false }))
			;
		});
	}

	render()
	{
		const { codeSent } = this.state;

		return (
			<React.Fragment>
				<h4 className={'mb-5'}>
						Вход в систему
				</h4>
				<div
					className={cn('mt-5', 'flex-grow-1')}
					style={{ width: '280px' }}
				>
					{!codeSent && <LoginForm
						onReady={form => (this.loginFormApi = form)}
						onSubmit={data => this.onSubmitLogin(data)}
						isLoading={this.state.isLoading}
					/>}
					{codeSent && <CodeForm
						isCodeTimeout={this.state.isCodeTimeout}
						codeConfirmError={this.state.codeConfirmError}
						onConfirmCode={code => this.onConfirmCode(code)}
						onSendCodeAgain={() => this.onSendCodeAgain()}
					/>}
				</div>
			</React.Fragment>
		);
	}
}

export default connect((state, props) => {
	return {
		token: state.token,
		me: state.entities.me,
	};
})(AuthPage);

