import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import styles from './style.module.sass';


class DayFormatCell extends React.PureComponent
{
	static propTypes = {
		date: PropTypes.any
	}

	render()
	{
		const { date } = this.props;

		return <div className={styles.Cell}>
			{format(date, 'iii')}
		</div>;
	}
}


export default DayFormatCell;
