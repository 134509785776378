import React from 'react';
import { connect } from 'react-redux';
import { toast, Slide } from 'react-toastify';
import cn from 'classnames';

import db from 'core/db';
import api from 'core/api';
import notify from 'utils/suppliesNotifications';

import { Empty } from 'components/ui';
import { Modal as StaticModal } from 'components/ui/modals';
import Grid from './components/Grid';
import PaymentModal from './components/PaymentModal';
import InvoiceInfoModal from './components/InvoiceInfoModal';
import ParseInvoiceModal from './components/ParseInvoiceModal';
import styles from './styles.module.sass';

class InvoicesView extends React.PureComponent
{
	state = {
		matchingOrder: null,
		invoice: null,
		paymentOrder: null,
		activeModals: {
			invoiceInfo: false,
			parse: false,
			payment: false
		}
	}

	toggleModal = (name, params = null) => {
		const o = {};
		o[name] = !this.state.activeModals[name];
		const activeModals = Object.assign(this.state.activeModals, o);
		this.setState({activeModals, ...params});
	}

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	updateOrder = (purchaseOrderId, changes, attach) => {
		if (!purchaseOrderId) {
			return;
		}

		const finallyFunc = () => this.setState({ loading: false });

		const catchFunc = error => {
			console.error(error);
			this.showError("Не удалось сохранить изменения.");
		};

		this.setState({ loading: true });

		if (attach) {
			const fileCreate = api.attachments().create({
				files: [attach]
			});

			this.props.dispatch(fileCreate)
				.then(res => {
					this.props.dispatch(api.tbsPurchaseOrders().update({
						purchaseOrderId,
						changes: {
							...changes,
							attachments: {
								new: [{ id: res.id }],
							}
						}
					}));
				})
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		} else {
			this.props.dispatch(api.tbsPurchaseOrders().update({
				purchaseOrderId,
				changes,
			}))
				.then(this.closeEditModal)
				.catch(catchFunc)
				.finally(finallyFunc)
			;
		}
	}

	onPayOrder = invoice => {
		this.toggleModal('payment', { invoice });
	}

	onShowInvoiceInfo = invoice => {
		this.toggleModal('invoiceInfo', { invoice });
	}

	onAttachOrder = invoice => {
		this.toggleModal('parse', { invoice });
	}

	onPlaceOrder = invoice => {
		this.props.dispatch(api.tbsPurchaseOrders().update({
			purchaseOrderId: invoice.order.id,
			changes: {
				isConfirmed: !invoice.order.isConfirmed
			}
		})).then(() => {
			notify(this.props.employees, 'order-placed');
		})
			.catch(error => {
				this.showError("Не удалось разместить счёт.");
				console.error(error);
			})
		;
	}

	onRemoveAttachedFile = (file, order) => {
		StaticModal.confirm({
			title: 'Удаление файла счёта',
			text: `Удалить файл ${file.originalName}?`,
			onConfirm: () => {
				this.props.dispatch(api.tbsPurchaseOrders().update({
					purchaseOrderId: order.id,
					changes: {
						attachments: {
							deleted: [{id: file.id}]
						}
					}
				}))
					.catch(() => {
						this.showError("Не удалось удалить файл");
					})
				;
			}
		});
	}

	saveInvoiceMatches = items => {
		const transactionBody = [];

		this.parseGridApi.forEachNode(node => {
			const { invoiceItemTitle, id } = node.data;
			if (invoiceItemTitle) {
				transactionBody.push(api.tbsPurchaseOrderItems().update({
					purchaseOrderItemId: id,
					changes: { invoiceItemTitle	}
				}));
			}
		});

		if (!transactionBody.length) {
			return;
		}

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => this.closeParseModal())
			.catch(() => {
				toast.error("Не удалось внести изменения", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	createPayment = (invoice, values) => {
		this.props.dispatch(api.tbsPurchaseInvoicePayments().create({
			purchaseInvoiceId: invoice.id,
			...values,
		}))
			.then(() => this.toggleModal('payment', { invoice: null }))
			.catch(() => {
				this.showError("Не удалось создать платёж.");
			})
		;

		this.props.dispatch(api.tbsPurchaseOrders().update({
			purchaseOrderId: invoice.order.id,
			changes: {
				isConfirmed: true//!invoice.order.isConfirmed
			}
		})).then(() => {
			notify(this.props.employees, 'order-placed');
		})
			.catch(error => {
				this.showError("Не удалось разместить счёт.");
				console.error(error);
			})
		;
	}

	aggregateIvoices = () => {
		const { purchaseOrdersMap, purchaseOrderItems, purchaseInvoices, purchaseInvoicePayments, projectsMap } = this.props;

		// Собирается Map для выборки позиций заказа по ID счёта.
		const orderItemsByInvoice = new Map();
		purchaseInvoices.forEach(invoice => {
			const orderItems = purchaseOrderItems.filter(orderItem => orderItem.purchaseOrderId === invoice.purchaseOrderId);
			orderItemsByInvoice.set(invoice.id, orderItems);
		});

		return purchaseInvoices.filter(invoice => {
			const orderItems = orderItemsByInvoice.get(invoice.id);

			return !invoice.isWaitingForApprove && orderItems.every(i => i.isApproved);
		}).map((invoice) => {
			const order = purchaseOrdersMap.get(invoice.purchaseOrderId);

			return {
				...invoice,
				project: projectsMap.get(order.projectId),
				order,
				orderItems: orderItemsByInvoice.get(invoice.id),
				payments: purchaseInvoicePayments.filter(p => p.purchaseInvoiceId === invoice.id),
			};
		});
	}

	render()
	{
		if (this.props.isLoading) {
			return <Empty className="w-100 h-100" title="Загрузка списка счетов..."/>;
		}

		const invoices = this.aggregateIvoices();

		if (!invoices.length) {
			return <Empty className="w-100 h-100" title="Счета отсутствуют."/>;
		}

		return (
			<div className={cn('w-100', 'h-100', 'd-flex', 'flex-column', styles.tableContainer)}>
				<Grid
					items={invoices}
					currenciesMap={this.props.currenciesMap}
					onPayOrder={this.onPayOrder}
					onPlaceOrder={this.onPlaceOrder}
					onAttachOrder={this.onAttachOrder}
					onRemoveAttachedFile={this.onRemoveAttachedFile}
					onShowInvoiceInfo={this.onShowInvoiceInfo}
				/>
				<InvoiceInfoModal
					visible={this.state.activeModals.invoiceInfo}
					onClose={() => this.toggleModal('invoiceInfo', { invoice: null })}
					invoice={this.state.invoice}
					purchaseRequestItemsMap={this.props.purchaseRequestItemsMap}
					consumableSheetItemsMap={this.props.consumableSheetItemsMap}
				/>
				{this.state.invoice && <ParseInvoiceModal
					visible={this.state.activeModals.parse}
					onClose={() => this.toggleModal('parse', { invoice: null })}
					onConfirm={() => this.saveInvoiceMatches()}
					onGridReady={params => (this.parseGridApi = params.api)}
					purchaseRequestItemsMap={this.props.purchaseRequestItemsMap}
					consumableSheetItemsMap={this.props.consumableSheetItemsMap}
					invoice={this.state.invoice}
				/>}
				{this.state.invoice && <PaymentModal
					visible={this.state.activeModals.payment}
					onClose={() => { this.toggleModal('payment', { invoice: null }); }}
					invoice={this.state.invoice}
					onConfirm={(invoice, values) => this.createPayment(invoice, values)}
				/>}
			</div>
		);
	}
}

const mapToProps = (state, props) => {

	const collects = {
		purchaseOrders: db.tbsPurchaseOrders.listNotDeleted(),
		purchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		purchaseRequests: db.tbsPurchaseRequests.listNotDeleted(),
		purchaseRequestItems: db.tbsPurchaseRequestItems.listNotDeleted(),
		consumableSheetItems: db.tbsConsumableSheetItems.list(),
		currencies: db.currencies.list(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted({
			filter: {
				isWaitingForApprove: false
			}
		}),
		purchaseInvoicePayments: db.tbsPurchaseInvoicePayments.list(),
		projects: db.projects.list(),
		employees: db.employees.list(),
	};

	const maps = {
		purchaseOrdersMap: collects.purchaseOrders.hashById(),
		purchaseRequestItemsMap: collects.purchaseRequestItems.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		currenciesMap: collects.currencies.hashById(),
		projectsMap: collects.projects.hashById()
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	return {
		...collects,
		...maps,
	};
};

export default connect(mapToProps)(InvoicesView);
