import { NumberCellEditor } from 'components/ui/grid/editors';
import { EmptyCellRenderer, ActionsCellRenderer, defaultCurrencyCellRenderer } from 'components/ui/grid/renderers/';
import { numberFormatter } from 'components/ui/grid/formatters';

export const getGridOptions = params => {

	const amountCellRenderer = params => `${params.valueFormatted} ${params.data.consumableUnit.suffix}`;

	const frameworkComponents = {
		actionsCellRenderer: ActionsCellRenderer,
		numberCellEditor: NumberCellEditor,
		amountCellRenderer: amountCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		defaultCurrencyCellRenderer: defaultCurrencyCellRenderer,
	};

	const gridOptions = {
		enableCellChangeFlash: true,
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			width: 100,
			resizable: true,
			sortable: true,
			editable: false,
			suppressSizeToFit: true,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params =>
{
	const editableCellStyle = { backgroundColor: '#00dcff21' };

	const colDefs = [
		{
			headerName: 'Наименование',
			field: 'title',
			suppressSizeToFit: false,
		},
		{
			headerName: 'Заказано',
			field: 'orderedAmount',
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Остаток',
			field: 'remainAmount',
			valueFormatter: numberFormatter,
			valueGetter: 'data.amount - data.orderedAmount >= 0 ? data.amount - data.orderedAmount : 0',
		},
		{
			headerName: 'В заказ',
			field: 'requestAmount',
			editable: true,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: '0.01' } },
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Примечание',
			field: 'notes',
			editable: true,
			cellEditor: 'agLargeTextCellEditor',
		},
	];

	const renderersMap = {
		'amount': { component: 'amountCellRenderer' },
		'orderedAmount': { component: 'amountCellRenderer' },
		'remainAmount': { component: 'amountCellRenderer' },
		'requestAmount': { component: 'amountCellRenderer' },
		'price': { component: 'defaultCurrencyCellRenderer' },
		'cost': { component: 'defaultCurrencyCellRenderer' },
	};


	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {

		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		if (column.editable) {
			column.cellStyle = editableCellStyle;
		}

		return column;
	});
};
