import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.sass';
import cn from 'classnames';


class Select extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		keyField: PropTypes.string,
		displayFields: PropTypes.arrayOf(PropTypes.string),
		placeholder: PropTypes.string,
		defaultValue: PropTypes.string,
		onChange: PropTypes.func,
		id: PropTypes.string,
		className: PropTypes.string,
		value: PropTypes.any,
	}

	static defaultProps = {
		items: [],
		keyField: "id",
		displayFields: [],
		placeholder: "Выберите значение",
		defaultValue: "",
		className: "",
		value: undefined,
	}

	getOptionValue = item => {
		const { displayFields } = this.props;

		for (let i = 0; i < displayFields.length; ++i) {
			const field = displayFields[i];

			if (item[field]) {
				return item[field];
			}
		}

		return undefined;
	}

	render()
	{
		const {
			items,
			keyField,
			placeholder,
			id,
			className,
			value,
			onChange,
		} = this.props;

		return (
			<select
				id={id}
				required
				className={cn(style.selectCustom, className)}
				onChange={e => onChange(e.target.value)}
				value={value}
			>
				<option hidden value="">
					{placeholder}
				</option>
				{items.map((item, index) => {
					const value = this.getOptionValue(item);
					const id = item[keyField];

					return (
						<option
							key={id}
							value={id}
						>
							{value}
						</option>
					);
				})}
			</select>
		);
	}
}

export default Select;
