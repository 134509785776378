const round100 = x => Math.round(x * 100) / 100;

export const withCurrency = (value, priceCurrencyId, currencyRates) => {
	if (!currencyRates || !priceCurrencyId) {
		return value;
	}

	const isFunc = currencyRates instanceof Function;
	const rate = isFunc ? currencyRates()[priceCurrencyId] : currencyRates[priceCurrencyId];

	if (!rate) {
		return value;
	}

	return value * rate;
};

export const retailPriceGetter = ({ retailPrice, planPrice, retailDiscountPercents }) => {
	const disc = 1 - round100((retailDiscountPercents / 100));

	if (retailPrice) {
		return retailPrice;
	} else if (planPrice) {
		return disc === 0 ? planPrice : (planPrice / disc);
	}

	return null;
};

export const planPriceGetter = ({ retailPrice, planPrice, retailDiscountPercents }) => {
	const disc = round100(retailDiscountPercents / 100);

	if (planPrice) {
		return planPrice;
	} else if (retailPrice) {
		return retailPrice - retailPrice * disc;
	}

	return null;
};

export const retailCostGetter = ({ retailPrice, planPrice, retailDiscountPercents, amount }) => {
	const price = retailPriceGetter({ retailPrice, planPrice, retailDiscountPercents });

	if (!price || !amount) {
		return null;
	}

	return price * Number(amount);
};

export const planCostGetter = ({ retailPrice, planPrice, retailDiscountPercents, amount }) => {
	const price = planPriceGetter({ retailPrice, planPrice, retailDiscountPercents });

	if (!price || !amount) {
		return null;
	}

	return price * Number(amount);
};

export const customerPriceGetter = data => {
	const planPrice = planPriceGetter(data);
	const retailPrice = retailPriceGetter(data);

	if (!planPrice || !retailPrice) {
		return null;
	}

	const { priceCorrectionPercents } = data;
	const correction = priceCorrectionPercents ? round100(priceCorrectionPercents / 100) : 0;

	return round100((correction > 0 ? planPrice : retailPrice) * (correction + 1));
};

export const customerCostGetter = data => {
	const { planPriceOverride, amount } = data;
	const customerPrice = customerPriceGetter(data);

	if (!customerPrice || !amount) {
		return null;
	}

	return Number(planPriceOverride) === 0 ? customerPrice * Number(amount) : planPriceOverride * Number(amount);
};

export const marginValueGetter = data => {
	const customerCost = customerCostGetter(data);
	const planCost = planCostGetter(data);

	if (!customerCost || !planCost) {
		return null;
	}

	if (customerCost === 0) {
		return 0;
	}

	return round100((customerCost - planCost) / planCost) * 100;
};

export const remainAmountGetter = ({ amount, orderedAmount }) => {

	if (!amount || !orderedAmount) {
		return null;
	}

	const diff = Number(amount) - Number(orderedAmount);

	return diff;
};
