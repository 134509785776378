import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'components/ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import cn from 'classnames';


const DateRangeInput = (props) => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return <DateRangePicker
		className={cn("form-control", "border-0")}
		onDatesChange={dates => helpers.setValue(dates)}
		customInput={<Form.Control/>}
		dates={value}
		controlled
		{...props}
	/>;
};

DateRangeInput.propTypes = {
	name: PropTypes.string.isRequired,
};

export default DateRangeInput;
