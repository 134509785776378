import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import style from './style.module.sass';


class PhotoGalleryImage extends React.PureComponent
{
	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		onClick: PropTypes.func,
		attachment: PropTypes.shape({
			url: PropTypes.string.isRequired,
		}).isRequired,
		renderImage: PropTypes.func,
	}

	render()
	{
		const { attachment, onClick, index, height, width, renderImage } = this.props;
		const { url } = attachment;

		const defaultImg = (
			<div
				style={{ height, width}}
				className={style.wrapper}
				onClick={event => onClick(event, index)}
			>
				<Image
					src={url}
					className={style.image}
				/>
			</div>
		);

		if (renderImage) {
			return renderImage(defaultImg, this.props);
		}

		return defaultImg;
	}
}

export default PhotoGalleryImage;
