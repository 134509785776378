import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import ru from "date-fns/locale/ru";
import { formatISO } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Field } from 'formik';

import 'react-datepicker/dist/react-datepicker.css';
import style from './daterange.module.sass';

registerLocale("ru", ru);

const RangeSelector = (props) => {
	const [startDate, setStartDate] = useState(props.initialValues[0]);
	const [endDate, setEndDate] = useState(props.initialValues[1]);
	const { start, end } = props.rangeFields;

	return (
		<React.Fragment>
			<DatePicker
				selected={startDate}
				onChange={date => {
					setStartDate(date);
					props.onSelect(start, date);
				}}
				selectsStart
				startDate={startDate}
				endDate={endDate}
				className={style.dateRange}
				dateFormat="dd.MM.yyyy"
			/>
			<DatePicker
				selected={endDate}
				onChange={date => {
					setEndDate(date);
					props.onSelect(end, date);
				}}
				selectsEnd
				startDate={startDate}
				endDate={endDate}
				minDate={startDate}
				className={style.dateRange}
				dateFormat="dd.MM.yyyy"
			/>
		</React.Fragment>
	);
};

const DateRange = props => {
	return (
		<Field>
			{({ field, form, meta }) => {
				const { start, end } = props.rangeFields;

				return (
					<Form.Control
						initialValues={[
							field.value.estimatedStartDate ? new Date(field.value[start]) : new Date(),
							field.value.estimatedEndDate ? new Date(field.value[end]) : new Date(),
						]}
						onSelect={(field, value) => {
							const v = (!value || value === '') ? null : formatISO(value, { representation: 'date' });
							form.setFieldValue(field, v);
						}}
						rangeFields={props.rangeFields}
						as={RangeSelector}
						placeholderText={props.placeholder}
						autoComplete="off"
					/>
				);
			}}
		</Field>
	);
};

export default DateRange;
