import { HistoryGroupArray } from "../types";

export default class HistoryBuilder
{
	constructor(history, params)
	{
		this.history = history;

		if (!(history instanceof HistoryGroupArray)) {
			throw new Error('history must ne instance of HistoryGroupArray');
		}

		this.params = params;
	}

	build() {}
}
