import React from 'react';
import { NewModal as Modal } from 'components/ui/modals';
import PropTypes from 'prop-types';
import { ConsumableItemForm } from '.';


class ConsumableItemModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		consumableUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
		item: PropTypes.object.isRequired,
	}

	render()
	{
		const {
			onConfirm,
			onCancel,
			visible,
			item,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.changingItemFormProps.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		const onFormReady = props => {
			this.changingItemFormProps = props;
		};

		return (
			<Modal
				btnList={btnList}
				size="md"
				title="Замена позиции"
				show={visible}
				onHide={() => onCancel()}
			>
				<ConsumableItemForm
					onFormReady={onFormReady}
					onSubmit={item => onConfirm(item)}
					onCancel={() => onCancel()}
					consumableUnits={this.props.consumableUnits}
					initialItem={item}
				/>
			</Modal>
		);
	}
}

export default ConsumableItemModal;
