import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseManager } from "ag-grid-enterprise";

import config from './config';
import store from 'core/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

LicenseManager.setLicenseKey(config.aggridKey);

const render = Component => {
	return ReactDOM.render(
		<React.StrictMode>
			<Component store={store} />
		</React.StrictMode>,
		document.getElementById('root')
	);
};

render(App);

if (module.hot) {
	module.hot.accept('./App', () => {
		const NextApp = require('./App').default;
		render(NextApp);
	});
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
