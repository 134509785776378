import React from 'react';
import PropTypes from 'prop-types';
import Icon from './components/FileDownloadIcon';
import cn from 'classnames';

class FileDownloadToolbar extends React.PureComponent
{
	static propTypes = {
		iconsList: PropTypes.arrayOf(PropTypes.object),
		style: PropTypes.object,
		onRemoveClick: PropTypes.func,
	};

	static defaultProps = {
		iconsList: [],
	};

	render() {

		const { className, iconsList, onRemoveClick } = this.props;

		return (
			<div className={cn('d-flex', 'flex-row', 'align-items-center', className)}>
				{iconsList.map((icon, index) => {
					return (
						<Icon
							key={index}
							onRemoveClick={onRemoveClick}
							{...icon}
						/>
					);
				})}
			</div>
		);
	}
}

export default FileDownloadToolbar;
