import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { format, isWeekend } from 'date-fns';
import ColorsGenerator from './ColorsGenerator';


const defaultStyles = {
	titleFont: {
		size: 14,
		bold: true
	},
	columnWide: 32,
	columnShort: 8,
	workdayFill: {
		type: 'pattern',
		pattern: 'solid',
		fgColor: { argb: 'FFE5FFCC' }
	},
	undefinedProjectFill: {
		type: 'pattern',
		pattern: 'solid',
		fgColor: { argb: 'C0C0C0' }
	}
};

class Document {
	constructor(xlsx)
	{
		this.xlsx = xlsx;
	}

	save(filename)
	{
		this.xlsx.writeBuffer({ base64: true })
			.then((data) => {
				const file = new File([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				saveAs(file, filename);
			})
		;
	}
}

class ReportFormatter
{
	static createXLSX(project, report, projectsMap, styles = defaultStyles)
	{
		//генерируем цвета для проектов
		const colorsGenerator = new ColorsGenerator(0.4);
		const colorsIterator = colorsGenerator.iterator();
		const projectColorsMap = new Map();

		for (const chunck of report) {

			const { workersReports } = chunck;

			for (const wr of workersReports) {

				const arrayOfWorkerDays = wr.arrayOfWorkerDays.filter(wd => !!wd?.projectId);

				for (const wd of arrayOfWorkerDays) {

					if (projectColorsMap.has(wd.projectId)) {
						continue;
					}

					const color = colorsIterator.next().value;
					projectColorsMap.set(wd.projectId, {
						project: projectsMap.get(wd.projectId),
						fill: {
							type: 'pattern',
							pattern: 'solid',
							fgColor: { argb: `ff${color}` }
						},
						color,
					});
				}
			}
		}

		//создаем документ
		const now = new Date();
		const workbook = new ExcelJS.Workbook();
		workbook.created = now;
		workbook.properties.date1904 = true;
		workbook.calcProperties.fullCalcOnLoad = true;

		const worksheet = workbook.addWorksheet('Табель');
		let rowIndex = 1;

		//Колонки
		worksheet.getColumn(1).width = styles.columnWide;
		worksheet.getColumn(2).width = styles.columnWide;
		worksheet.getColumn(3).width = styles.columnWide;

		//Название проекта
		worksheet.addRow([project.title]);
		worksheet.findCell(rowIndex, 1).font = styles.titleFont;
		rowIndex++;

		for (const chunck of report) {
			//Название месяца
			worksheet.addRow([format(chunck.month, 'MMMM')]);
			worksheet.findCell(rowIndex, 1).alignment = { horizontal: 'left' };
			worksheet.findCell(rowIndex, 1).font = { bold: true };
			rowIndex++;

			//Заглавная строка таблицы
			const days = chunck.days.map(d => format(d, 'dd.MM'));
			worksheet.addRow(["ФИО", "Итого", "Часы на других объектах", ...days]);
			worksheet.findCell(rowIndex, 1).alignment = { horizontal: 'left' };
			worksheet.findCell(rowIndex, 1).font = { bold: true };
			worksheet.findCell(rowIndex, 2).alignment = { horizontal: 'right' };
			worksheet.findCell(rowIndex, 3).alignment = { horizontal: 'right' };
			for (let i = 4; i < chunck.days.length + 4; ++i) {
				worksheet.findCell(rowIndex, i).alignment = { horizontal: 'right' };
				if (isWeekend(chunck.days[i - 4])) {
					worksheet.findCell(rowIndex, i).font = { bold: true };
				}
			}
			rowIndex++;

			//Строки с данными по часам
			const workersReportsSorted = chunck.workersReports.sort((a, b) => (a.worker.name < b.worker.name ? -1 : 1));

			for (const wr of workersReportsSorted) {
				const { worker, arrayOfWorkerDays, totalNumberOfHours, otherProjectsNumberOfHours } = wr;
				const values = arrayOfWorkerDays.map(d => (d?.workedHours ? d.workedHours - 0 : ''));
				const row = [worker.name, totalNumberOfHours - 0, otherProjectsNumberOfHours - 0, ...values];
				worksheet.addRow(row);

				//Стилёчки
				for (let i = 4; i < arrayOfWorkerDays.length + 4; ++i) {
					const workerDay = arrayOfWorkerDays[i - 4];
					if (!workerDay) {
						continue;
					}

					worksheet.findCell(rowIndex, i).alignment = { horizontal: 'right' };
					if (workerDay.projectId) {
						worksheet.findCell(rowIndex, i).fill = projectColorsMap.get(workerDay.projectId).fill;
					} else {
						worksheet.findCell(rowIndex, i).fill = styles.undefinedProjectFill;
					}
				}

				rowIndex++;
			}

			worksheet.addRow([]);
			worksheet.addRow([]);
			rowIndex += 2;
		}

		//легенда
		for (const key of projectColorsMap.keys()) {
			const value = projectColorsMap.get(key);
			const { fill, project } = value;

			worksheet.addRow([project.title || 'Без названия']);
			worksheet.getCell(rowIndex, 1).fill = fill;
			rowIndex++;
		}

		worksheet.addRow(['*Проект не указан*']);
		worksheet.findCell(rowIndex, 1).fill = styles.undefinedProjectFill;
		rowIndex++;

		return new Document(workbook.xlsx);
	}
}

export default ReportFormatter;
