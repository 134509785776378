import React from 'react';
import { connect } from 'react-redux';
import api from 'core/api';
import db from 'core/db';
import { ButtonToolbar, withLoading } from 'components/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

import style from './styles/invite.module.sass';

class InviteReceived extends React.PureComponent {

	state = {
		loading: false,
	}

	onDeclinePress = () => {
		const id = this.props?.invite?.id;

		this.setState({loading: true});
		this.props.dispatch(api.meInvites().decline({ id }))
			.then(() => db.meInvites.list({refresh: true}))
			.catch((err) => {
				toast.error("Не удалось отклонить приглашение...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	onAcceptPress = () => {
		const id = this.props?.invite?.id;

		this.setState({loading: true});
		this.props.dispatch(api.meInvites().accept({ id }))
			.then(() => db.meInvites.list({refresh: true}))
			.catch((err) => {
				toast.error("Не удалось принять приглашение...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	render () {
		const invite = this.props.invite;
		const invitedBy = invite?.invitedByUser?.fullName || invite?.invitedByUser?.phoneNumber || '@username';

		const btnList = [
			{
				title: 'Принять',
				props: {
					onClick: this.onAcceptPress,
					disabled: this.state.loading,
				},
			},
			{
				title: 'Отклонить',
				props: {
					onClick: this.onDeclinePress,
					disabled: this.state.loading,
				},
			}
		];

		const icon = <FontAwesomeIcon
			fixedWidth
			icon={faEnvelope}
			style={{ fontSize: '2.5em' }}
		/>;

		return (
			<div
				className={["d-flex flex-column", style.invite].join(' ')}
			>
				<div
					className={["d-flex flex-column w-100 h-100 p-2"]}
				>
					<div
						className="flex-grow-1 text-secondary p-3"
					>
						<div className="w-100 d-flex flex-column align-items-center mb-5">
							<h4>Новое приглашение</h4>
							{icon}
						</div>
						<p>
							<span>
								Вас пригласил пользователь
							</span>
							<span className="ml-1 font-weight-bold">
								{invitedBy}
							</span>
						</p>
						<p>Примите приглашение чтобы присоединиться к совместной работе.</p>
					</div>
					<ButtonToolbar
						btnList={btnList}
					/>
				</div>
			</div>
		);
	}
}

export default connect()(withLoading(InviteReceived));
