export default class Aggregator
{
	constructor(params)
	{
		this.params = params;
	}

	getGridItems()
	{
		const { stages, jobs, stagesMap } = this.params;

		const getStagePath = stage => {
			const path = [stage.id];

			let parentStageId = stage.parentStageId;

			while (parentStageId) {
				path.push(parentStageId);
				const stage = stagesMap.get(parentStageId);
				parentStageId = stage.parentStageId;
			}

			return path.reverse();
		};

		const getJobPath = job => {

			const path = [];
			const stage = stagesMap.get(job.stageId);

			if (stage) {
				path.push(...getStagePath(stage));
			}

			path.push(job.id);

			return path;
		};

		const jobsItems = jobs.map(job => {
			return {
				...job,
				itemType: 'job',
				dataPath: getJobPath(job),
			};
		});

		const stagesItems = stages.map(stage => {
			return {
				...stage,
				itemType: 'stage',
				dataPath: getStagePath(stage),
			};
		});

		stagesItems.sort((a, b) => a.order.localeCompare(b.order));
		jobsItems.sort((a, b) => a.order.localeCompare(b.order));

		const items = [...stagesItems, ...jobsItems];

		return items;
	}
}
