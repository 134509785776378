import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sub } from 'date-fns';

import db from 'core/db';

import { Container } from 'components/ui';
import * as Aggregator from './helpers/WeeklyHoursAggregator';
import { ChartLayout, Card } from '../../layouts';
import LabeledValue from '../../LabeledValue';
import WeeklyHoursGrid from '../../WeeklyHoursGrid';


class WeeklyHoursCard extends React.PureComponent
{
	getInterval = () => {
		const end = new Date();
		const start = sub(end, { days: 29 });

		return { start, end };
	}

	render()
	{
		const { workerDays, workers, ...props } = this.props;

		const interval = this.getInterval();

		const hours = Aggregator.hours(workerDays);
		const averageHoursPerDay = Aggregator.averageHoursPerDay(interval, workerDays);
		const averageAbsencePerWorker = Aggregator.averageAbsencePerWorker(interval, workerDays);
		const loadAveragePercent = Aggregator.loadAveragePercent(workerDays, workers, interval);

		return (
			<Card {...props} layout="column">
				<Container layout="row" width="100%" height="100%" schema={[4, 1]} bind>
					<ChartLayout
						header="Рабочие часы и загрузка по дням "
						title="Сумма рабочих часов за каждый день в течение последнего месяца.
						Цветовая индикация показывает уровень относительной загрузки сотрудников."
					>
						<WeeklyHoursGrid
							data={hours}
							date={sub(new Date(), { weeks: 4 })}
							weekCount={5}
						/>
					</ChartLayout>
					<Container between padding="30px 0">
						<LabeledValue
							title="Загрузка"
							label="Среднее значение за месяц"
							value={loadAveragePercent}
							suffix="%"
							color="#D45B4A"
						/>
						<LabeledValue
							title="Смена"
							label="Среднее количество часов за смену"
							value={averageHoursPerDay}
						/>
						<LabeledValue
							title="Прогулы"
							label="Среднее количество пропущенных дней на человека"
							value={averageAbsencePerWorker}
						/>
					</Container>
				</Container>
			</Card>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };

	const collects = {
		projects: db.projects.list({ filter: { id: projectId } }),
		workerDays: db.workerDays.list(projectIdFilter),
		workers: db.workers.list(projectIdFilter),
	};

	const maps = {
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	const project = collects.projects[0];

	return {
		project,
		projectId: project?.id,
		isLoading,
		...collects,
		...maps,
	};
};

export default withRouter(connect(mapToProps)(WeeklyHoursCard));
