import React from 'react';
import PropTypes from 'prop-types';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import { FileDownloadToolbar, TagToolbar, Toolbar, FilterToolbar } from 'components/ui';
import CurrencyRatesToolbar from './CurrencyRatesToolbar';

import { uniq } from 'lodash';


class TopToolbar extends React.PureComponent
{

	static propTypes = {
		consumableSheet: PropTypes.object.isRequired,
		currenciesMap: PropTypes.any,
		onRatesEditClick: PropTypes.func,
		items: PropTypes.arrayOf(PropTypes.object),
		onFilterChange: PropTypes.func,
		sourceSpecs: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		consumableSheet: {},
		currenciesMap: new Map(),
		items: [],
		sourceSpecs: [],
	}

	render()
	{
		const tagList = [
			{
				title: 'Утверждена',
				variant: 'success',
			},
		];

		const { onRatesEditClick, consumableSheet, currenciesMap } = this.props;
		const { currencyRates, isApproved, artifacts } = consumableSheet;

		const tagToolbar = isApproved && <TagToolbar
			tagList={tagList}
			className="p-2"
		/>;

		const currencyToolbar = !isApproved && <CurrencyRatesToolbar
			rates={currencyRates || {}}
			onEditClick={onRatesEditClick}
			currenciesMap={currenciesMap}
		/>;

		const consumableSheetAttachedXlsxList = artifacts.map(art => {
			return {
				file: {
					id: art.downloads.xlsx.url,
					originalName: art.title,
					url: art.downloads.xlsx.url,
				},
				icon: faFileExcel,
				iconStyle: { color: 'green' },
				withAuth: true,
			};
		});

		const attachedFilesToobar =
			<FileDownloadToolbar
				iconsList={consumableSheetAttachedXlsxList}
			/>
		;

		const { items, sourceSpecs } = this.props;

		const filterToolbar = <FilterToolbar
			filters={[
				{
					key: 'sourceSpecIds',
					options: sourceSpecs.map(ss => ({ value: ss.id, label: ss.title })),
					placeholder: 'Исходные спецификации',
					isMulti: true,
				},
				{
					key: 'manufacturerName',
					options: uniq(items.map(ssi => ssi.manufacturerName))
						.filter(mnu => mnu && mnu !== '')
						.map(mnu => ({ value: mnu, label: mnu })),
					placeholder: 'Производитель',
					isMulti: true,
				},
			]}
			onChange={this.props.onFilterChange}
		/>;

		return (
			<Toolbar className="justify-content-between">
				<div className="d-flex flex-row align-items-center">
					{tagToolbar}
					{filterToolbar}
				</div>
				<div className="d-flex">
					{currencyToolbar}
					{attachedFilesToobar}
				</div>
			</Toolbar>
		);
	}
}

export default TopToolbar;

