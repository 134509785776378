import { forIn, isNil, isArray, isObject } from 'lodash';

export const getFormChanges = (values, initialValues = {}) => {
	//!! Функция проверят изменения сделанные в форме
	//!! Без вложенности, исключая массивы и объекты
	const changes = {};

	forIn(values, (value, key) => {
		const isPlain = !(isNil(value) || isArray(value) || isObject(value));

		if (isPlain) {
			if (initialValues[key] !== value) {
				changes[key] = value;
			}
		}
	});

	return changes;
};
