import React from 'react';
import { Form } from 'react-bootstrap';
import formikField from '../formikField';

import './input.module.sass';


class NumberInput extends React.PureComponent
{
	render()
	{
		const {
			forwardedRef,
			inputProps,
			formikParams,
			...innerProps
		} = this.props;

		const { field, form: { values } } = formikParams;
		const { value, onChange, ...otherInputProps } = inputProps;

		const initialValue = values[field.name] || '';

		return (
			<Form.Control
				ref={forwardedRef}
				id={field.name}
				value={value || initialValue}
				onChange={e => onChange(e.target.value)}
				type="number"
				{...innerProps}
				{...otherInputProps}
			>
			</Form.Control>
		);
	}
}

export default formikField(NumberInput);
