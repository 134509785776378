import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


class AuthGuard extends React.PureComponent {
	static propTypes = {
		authComponent: PropTypes.element,
		appComponent: PropTypes.element,
	}

	render () {
		const { token, me } = this.props;

		if (me?.id) {
			return this.props.appComponent;
		}

		// Return NULL while waiting store, @todo replace by preloader indicator
		if (token) {
			return null;
		}

		return this.props.authComponent;
	}
}

export default connect((state, props) => {
	return {
		token: state.token,
		me: state.entities.me,

	};
})(AuthGuard);
