import React from 'react';
import PropTypes from 'prop-types';

import { CustomGrid } from 'components/ui/grid';
import { getColumnDefs, getGridOptions } from './defs';


class Grid extends React.Component
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		stagesMap: PropTypes.any,
		onEditJob: PropTypes.func,
		onEditStage: PropTypes.func,
		onGridReady: PropTypes.func,
		onCreateNestedStage: PropTypes.func,
		onMoveJobsToStage: PropTypes.func,
		onRemove: PropTypes.func,
		onCreateNewJob: PropTypes.func,
		onAddReportClick: PropTypes.func,
	};

	static defaultProps = {
		items: []
	};

	state = {
		selectedNodes: [],
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridApi.addEventListener('rowSelected', this.onRowSelected);
		this.gridApi.sizeColumnsToFit();

		if (this.props.onGridReady) {
			this.props.onGridReady(params);
		}
	}

	onRowDoubleClicked = params => {
		const type = params.node.data.itemType;
		const data = params.node.data;

		if (type === 'stage') {
			this.props.onEditStage(data);
		} else if (type === 'job') {
			this.props.onEditJob(data);
		}
	}

	onRowSelected = params => {
		const type = params.data.itemType;
		const node = params.node;

		this.gridApi.removeEventListener('rowSelected', this.onRowSelected);

		if (type === 'stage') {
			node.selectChildNodes(node.selected);
		} else if (type === 'job') {
			const parent = node.parent;
			if (parent) {
				const selectedLength = parent.childrenAfterGroup.filter(node => node.isSelected()).length;
				const childrenLength = parent.childrenAfterGroup.length;

				if (selectedLength === 0) {
					parent.selectThisNode(false);
				} else if (selectedLength < childrenLength) {
					parent.selectThisNode();
				} else {
					parent.selectThisNode(true);
				}
			}
		}

		this.gridApi.addEventListener('rowSelected', this.onRowSelected);
	}

	onSelectionChanged = (params) => {
		const selectedNodes = params.api.getSelectedNodes();
		this.setState({selectedNodes});
	}

	getOverStageNode = overNode => {
		if (overNode.data.itemType === 'stage') {
			return overNode;
		} else {
			return overNode.parent;
		}
	}

	getMoveContextSubmenu = (nodesToAction) => {
		const stages = this.props.stages;

		return stages.map(stage => {
			return {
				name: stage.title,
				action: () => this.props.onMoveJobsToStage(nodesToAction.filter(node => node.data.itemType === 'job'), stage.id),
			};
		});
	}

	getContextMenuItems = (params) => {
		const type = params.node.data.itemType;
		const isSelected = params.node.selected;
		const nodesToAction = isSelected ? this.gridApi.getSelectedNodes() : [params.node];

		const selectLeafNodes = node => {
			for (const leafNode of node.allLeafChildren) {
				if (leafNode.data.itemType === 'job') {
					leafNode.setSelected(true);
				}
			}
		};

		if (type === 'stage') {
			return [
				{
					name: 'Редактировать',
					action: () => this.props.onEditStage(params.node.data),
				},
				{
					name: 'Удалить',
					action: () => this.props.onRemove(nodesToAction),
				},
				{
					name: 'Добваить вложенный этап',
					action: () => this.props.onCreateNestedStage(params.node.data.id),
				},
				{
					name: 'Добавить работу',
					action: () => this.props.onCreateNewJob(params.node.data.id),
				},
				{
					name: 'Выбрать все',
					action: () => selectLeafNodes(params.node),
				},
			];
		} else if (type === 'job') {
			return [
				{
					name: 'Редактировать',
					action: () => this.props.onEditJob(params.node.data),
				},
				{
					name: 'Переместить в...',
					subMenu: this.getMoveContextSubmenu(nodesToAction),
				},
				{
					name: 'Удалить',
					action: () => this.props.onRemove(nodesToAction),
				}
			];
		}

		return null;
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		const getDataPath = item => item.dataPath;

		return (
			<CustomGrid
				groupSuppressAutoColumn={true}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items.isLoading ? null : this.props.items}
				groupDefaultExpanded={1}
				animateRows={true}
				rememberGroupStateWhenNewData={true}
				rowSelection={'multiple'}
				onSelectionChanged={this.onSelectionChanged}
				suppressRowClickSelection={true}
				onRowDoubleClicked={this.onRowDoubleClicked}
				treeData={true}
				getDataPath={getDataPath}
				onGridReady={this.onGridReady}
				getContextMenuItems={this.getContextMenuItems}
				suppressCellSelection={true}
			/>
		);
	}
}

export default Grid;

