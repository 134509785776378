import React from 'react';
import { Badge } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/ru';

import {
	AttachedFilesCellRenderer,
	EmptyCellRenderer
} from 'components/ui/grid/renderers';

moment.locale('ru');

export const getGridOptions = params => {

	const invoiceStatusRenderer = params => {
		const statuses = {
			payed: {
				value: 'Полностью оплачен',
				variant: 'success'
			},
			partlyPayed: {
				value: 'Частично оплачен',
				variant: 'warning'
			},
			notPayed: {
				value: 'Не оплачен',
				variant: 'danger'
			}
		};

		return <div>
			<Badge pill variant={statuses[params.value]?.variant}>{statuses[params.value]?.value}</Badge>
		</div>;
	};

	const statusRenderer = params => {
		const statuses = {
			done: {
				value: 'Выполнен',
				variant: 'success'
			},
			wait: {
				value: 'В ожидании',
				variant: 'secondary'
			},
		};

		return <div>
			<Badge pill variant={statuses[params.value].variant}>{statuses[params.value].value}</Badge>
		</div>;
	};

	const frameworkComponents = {
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		invoiceStatusCellRenderer: invoiceStatusRenderer,
		statusCellRenderer: statusRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 150,
			minWidth: 100,
			resizable: true,
			sortable: true,
			editable: false,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {
	const statusGetter = params => {
		const { invoice, orderItems } = params.data;

		if (!orderItems || !invoice.payments) {
			return null;
		}

		const payedCost = invoice.payments.length ? invoice.payments.reduce((acc, pip) => acc + Number(pip.amount), 0) : 0;

		const cost = orderItems.reduce((acc, item) => {
			const { amount, amountProposed, priceActual } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);

		if (payedCost === 0) {
			return 'notPayed';
		} else if (payedCost < cost) {
			return 'partlyPayed';
		}

		return 'payed';
	};

	const numberGetter = params => {
		const { done } = params.data;

		return done ? done.number : null;
	};

	const paymentStatusGetter = params => {
		const { done } = params.data;

		return done ? 'done' : 'wait';
	};

	const paymentDateGetter = params => {
		const { done, days } = params.data;

		return done ? done.date : days || null;
	};

	const paymentAmountGetter = params => {
		const { done, amount } = params.data;

		return done ? done.amount : amount || null;
	};

	const invoiceFileRendererParams = params => {
		const attachments = params.value || [];

		const itemsList = attachments.map(attach => {
			return {
				file: attach,
				labelVisible: false,
			};
		});


		return { itemsList };
	};

	const colDefs = [
		{
			headerName: 'Название проекта',
			field: 'project.title',
			width: 250,
		},
		{
			headerName: 'Номер счёта',
			field: 'invoice.number',
			width: 120,
		},
		{
			headerName: 'Дата счёта',
			field: 'invoice.date',
		},
		{
			headerName: 'Файл счёта',
			field: "invoice.attachments",
			cellRendererParams: invoiceFileRendererParams,
			width: 120,
		},
		{
			headerName: 'Поставщик',
			field: 'invoice.supplierTitle',
		},
		{
			headerName: 'Статус счёта',
			field: 'invoiceStatus',
			valueGetter: statusGetter,
		},
		{
			headerName: 'Номер ПП',
			field: 'number',
			valueGetter: numberGetter,
			width: 100,
		},
		{
			headerName: 'Дата платежа',
			field: 'date',
			valueGetter: paymentDateGetter,
		},
		{
			headerName: 'Сумма платежа',
			field: 'paymentAmount',
			valueGetter: paymentAmountGetter,
		},
		{
			headerName: 'Статус платежа',
			field: 'status',
			valueGetter: paymentStatusGetter,
		},
	];

	const renderersMap = {
		'invoice.attachments': { component: 'attachedFilesCellRenderer' },
		'invoiceStatus': { component: 'invoiceStatusCellRenderer' },
		'status': { component: 'statusCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		if (params.value === null || params.value === undefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
