import HistoryDataIterator from '../HistoryDataIterator';
import HistoryBuilder from './HistoryBuilder';


export default class DefaultBuilder extends HistoryBuilder
{
	build()
	{
		return new HistoryDataIterator(this.history);
	}
}
