import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

class ButtonToolbar extends React.PureComponent
{
	static propTypes = {
		btnList: PropTypes.arrayOf(PropTypes.object),
		style: PropTypes.object,
		align: PropTypes.string,
		btnMinWidth: PropTypes.number
	};

	static defaultProps = {
		btnList: [],
	};

	render() {
		const buttonStyle = this.props.buttonsStyle || {
			minWidth: '120px',
			margin: '4px'
		};

		return (
			<div
				className={["d-flex flex-row align-items-start", (this.props.className || '')].join(' ')}
				style={this.props.style}
			>
				{this.props.btnList.map((btn, index) => {
					return (
						<Button
							key={index}
							style={buttonStyle}
							onClick={btn.onClick}
							disabled={btn.disabled}
							{...btn.props}
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}
}

export default ButtonToolbar;
