import Pusher from 'pusher-js';
import EventEmitter from './EventEmitter';
import clientStorage from './clientStorage';
import uuidv4 from 'uuid/v4';

class NotificationService extends EventEmitter
{
	constructor(cluster, appKey)
	{
		super();

		this.pusher = new Pusher(appKey, {
			cluster: cluster,
			forceTLS: true,
		});

		//Pusher.logToConsole = true;

		this.subscribe();
	}

	subscribe()
	{
		this.pusher.subscribe('user-token-' + this.websocketToken()).bind('message', (data) => {
			this.emit('message', data);
		});
	}

	refreshWebsocketToken()
	{
		this.pusher.unsubscribe(this.websocketToken());
		this.removeAllListeners('message');

		clientStorage.deleteWebsocketToken();
		this.websocketToken();

		this.subscribe();
	}

	websocketToken()
	{
		let websocketToken = clientStorage.getWebsocketToken();

		if (!websocketToken) {
			websocketToken = uuidv4();

			clientStorage.setWebsocketToken(websocketToken);
		}

		return websocketToken;
	}
}

export default NotificationService;
