import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';


class FilterToolbar extends React.PureComponent
{

	static propTypes = {
		filters: PropTypes.arrayOf(PropTypes.shape({
			key: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(PropTypes.shape({
				value: PropTypes.string.isRequired,
				label: PropTypes.string.isRequired,
			})),
			placeholder: PropTypes.string,
			isMulti: PropTypes.bool,
		})),
		onChange: PropTypes.func,
	}

	static defaultProps = {
		filters: [],
	}

	state = {
		filters: {},
	}

	componentDidUpdate()
	{
		this.props.onChange(this.state.filters);
	}

	render()
	{
		const { filters } = this.props;

		return (
			<div className={classNames('d-flex', 'flex-row')}>
				{filters.map((f, index) => {

					const { key, ...props } = f;
					const onChange = options => {
						const filters = { ...this.state.filters };

						if (options && options.length) {
							filters[key] = options.map(o => o.value);
						} else {
							delete filters[key];
						}
						this.setState({ filters });
					};

					return (
						<div
							key={index}
							style={{ width: '300px' }}
							className="mx-1"
						>
							<Select
								onChange={onChange}
								{...props}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

export default FilterToolbar;
