import React from 'react';
import { NewModal as Modal } from 'components/ui/modals';
import PropTypes from 'prop-types';
import { ConsumableItemForm } from '.';


class ConsumableItemModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		locked: PropTypes.bool,
		consumableUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
	}

	render()
	{
		const {
			onConfirm,
			onCancel,
			visible,
			locked,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.consumaleItemFormProps.handleSubmit(),
					disabled: locked
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		const onFormReady = props => {
			this.consumaleItemFormProps = props;
		};

		return (
			<Modal
				btnList={btnList}
				size="md"
				title="Новая позиция"
				show={visible}
				onHide={() => onCancel()}
			>
				<ConsumableItemForm
					onFormReady={onFormReady}
					onSubmit={params => onConfirm(params)}
					onCancel={() => onCancel()}
					consumableUnits={this.props.consumableUnits}
				/>
			</Modal>
		);
	}
}

export default ConsumableItemModal;
