import React, { useState, useRef, useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Popover, Overlay } from 'react-bootstrap';
import { omit } from 'lodash';

import styles from './style.module.sass';


const SuggestInput = (props) => {
	const boxRef = useRef(null);
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	const { suggestRender, suggestVisible, formref, onSuggestHide, onHandleInput, ...otherProps } = props;

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	const suggest = (fieldProps) => (suggestVisible ? <Overlay
		target={boxRef.current}
		container={formref}
		show={suggestVisible}
		placement="bottom-start"
		rootClose
		onHide={() => onSuggestHide()}
	>
		{popoverPropps => {
			return (
				<Popover
					{...omit(popoverPropps, ['show'])}
					className={styles.SuggestInput__popover}
					arrowProps={{
						style: { visibility: 'hidden' }
					}}
				>
					<Popover.Content>
						{suggestRender(fieldProps)}
					</Popover.Content>
				</Popover>)
			;
		}}
	</Overlay> : null);

	return <>
		<Form.Control ref={boxRef} className={styles.SuggestInput}
			type="text"
			value={value || ''}
			onChange={e => {
				const value = e.target.value;
				helpers.setValue(value);
				onHandleInput(value);
			}}
			{...otherProps}
		/>
		{suggest(field)}
	</>;
};

SuggestInput.propTypes = {
	suggestVisible: PropTypes.bool,
	suggestRender: PropTypes.func,
	onSuggestHide: PropTypes.func,
	onHandleInput: PropTypes.func,
};

SuggestInput.defaultProps = {
	suggestRender: () => null,
	onSuggestHide: () => null,
	onHandleInput: () => null,
	suggestVisible: false,
};


export default SuggestInput;
