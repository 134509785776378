import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { isEmpty, keys, intersection } from 'lodash';

import api from 'core/api';

import { CustomGrid } from 'components/ui/grid';
import { getColumnDefs, getGridOptions } from './defs';

class Grid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		sourceSpecItemsMap: PropTypes.any.isRequired,
		onSelectionChanged: PropTypes.func,
		consSpecId: PropTypes.string.isRequired,
		isApproved: PropTypes.bool.isRequired,
		onGridReady: PropTypes.func,
		onUnmergeItem: PropTypes.func,
		mergingGroups: PropTypes.arrayOf(PropTypes.object),
		filters: PropTypes.object,
	};

	static defaultProps = {
		items: [],
		mergingGroups: [],
		filters: {},
	};

	createConsolidatedItem = (record) => {
		return this.props.dispatch(
			api.tbsConsolidatedSpecificationItems().create({
				consolidatedSpecificationId: this.props.consSpecId,
				title: record.title,
				partNumber: record.partNumber,
				manufacturerName: record.manufacturerName,
				consumableUnitId: record.consumableUnitId,
				sourceSpecificationItemIds: [record.id],
			})
		);
	}

	updateConsolidatedItem = (id, changes) => {
		return this.props.dispatch(
			api.tbsConsolidatedSpecificationItems().update({
				consolidatedSpecificationItemId: id,
				changes: changes
			}));
	}

	handleChanges = (record, changes) => {
		let p = null;

		if (record.isSource) {
			p = this.createConsolidatedItem({
				...record,
				...changes
			});
		} else {
			p = this.updateConsolidatedItem(record.id, changes);
		}

		return p.catch((err => {
			console.error(err);
			toast.error("Не удалось внести изменения", {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
			});
		}));
	};

	onFirstDataRendered = (params) => {
		params.api.sizeColumnsToFit();
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}
		const fieldName = params.colDef.field;
		const changes = {};
		const record = params.data;
		changes[fieldName] = params.newValue;
		this.handleChanges(record, changes);
	}

	onGridReady = (params) => {
		this.props.onGridReady(params);
		this.gridApi = params.api;
		const { onGridReady } = this.props;
		if (onGridReady) {
			onGridReady(params);
		}
	}

	getRowStyle = params => {
		const { groupColorsMap } = this.props;
		const group = params.data?.mergeGroup;

		if (params.data?.mergeGroup) {
			return { backgroundColor: groupColorsMap.get(group) };
		}

		return null;
	};

	isExternalFilterPresent = () => {
		const { filters } = this.props;

		return filters && !isEmpty(filters);
	}

	doesExternalFilterPass = node => {
		const { filters } = this.props;
		const { data } = node;

		const intersects = intersection(keys(data), keys(filters));

		if (!intersects.length) {
			return true;
		}

		for (const key of intersects) {
			if (!filters[key].includes(data[key])) {
				return false;
			}
		}

		return true;
	}

	render()
	{
		const {
			markedItems,
			isApproved,
		} = this.props;

		const columnDefs = getColumnDefs({
			...this.props,
			isApproved,
			onUnmergeClick: this.props.onUnmergeItem,
		});

		const gridOptions = getGridOptions();

		const isRowMaster = item => (item.sourceSpecificationItemIds?.length > 1);

		const getRowHeight = (params) => {
			if (params.node && params.node.detail) {
				const offset = 28 + 25;
				const allDetailRowHeight = params.data.sourceSpecificationItemIds.length * 25;

				return allDetailRowHeight + offset;
			} else {
				return 32;
			}
		};

		const getRowNodeId = item => item.id;

		return (
			<CustomGrid
				onGridReady={this.onGridReady}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				masterDetail={true}
				isRowMaster={isRowMaster}
				detailCellRenderer={'detailCellRenderer'}
				getRowHeight={getRowHeight}
				rowData={markedItems}
				onFirstDataRendered={this.onFirstDataRendered}
				onSelectionChanged={this.props.onSelectionChanged}
				rowSelection={'multiple'}
				stopEditingWhenGridLosesFocus={true}
				onCellValueChanged={this.onCellValueChanged}
				suppressRowClickSelection={true}
				getRowStyle={this.getRowStyle}
				deltaRowDataMode={true}
				getRowNodeId={getRowNodeId}
				isExternalFilterPresent={this.isExternalFilterPresent}
				doesExternalFilterPass={this.doesExternalFilterPass}
			/>
		);
	}
}

const mapToProps = (state, props) => {

	const { items, mergingGroups } = props;

	const markedItems = items.map(i => {
		for (const group of mergingGroups) {
			const { consolidatedSpecificationItemId, sourceSpecificationItemIds } = group;

			const hasSourceIds = i.sourceSpecificationItemIds?.some(si => sourceSpecificationItemIds?.includes(si.id));

			if (i.id === consolidatedSpecificationItemId || sourceSpecificationItemIds?.includes(i.id) || hasSourceIds) {
				return {
					mergeGroup: consolidatedSpecificationItemId,
					...i,
				};
			}
		}

		return i;
	});

	// !вынести куда-то в helpers
	const genColors = (begin, end, n = 0, alpha = 0.3) => {

		if (!n) {
			return [];
		}

		const r1 = begin.r; const g1 = begin.g; const b1 = begin.b;
		const r2 = end.r; const g2 = end.g; const b2 = end.b;

		const deltaR = r2 - r1;
		const deltaG = g2 - g1;
		const deltaB = b2 - b1;

		const colors = [];

		for (let i = 1; i <= n; ++i) {
			colors.push(`rgba(${r1 + deltaR * i / n}, ${g1 + deltaG * i / n}, ${b1 + deltaB * i / n}, ${alpha})`);
		}

		return colors;
	};

	const colors = genColors({r: 120, g: 120, b: 0}, {r: 120, g: 255, b: 120}, mergingGroups.length);

	const groupColorsMap = new Map();

	for (let i = 0;	 i < mergingGroups.length; ++i) {
		groupColorsMap.set(mergingGroups[i].consolidatedSpecificationItemId, colors[i]);
	}

	return {
		markedItems,
		groupColorsMap,
	};
};

export default connect(mapToProps)(Grid);

