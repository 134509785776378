import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { makeCancelable } from 'utils/promise';

import api from 'core/api';
import db from 'core/db';
import styles from './style.module.sass';

import List from './components/List';
import Categories from './components/Categories';
import Header from './components/Header';

const _debounced = debounce((callback) => {
	callback();
}, 500, false);

const GESNHandbook = (props) => {
	const [handbookOpened, setHandbookOpened] = useState(false);
	const [activeSection, setActiveSection] = useState(null);
	const [activeCategory, setActiveCategory] = useState(null);
	const [expanded, setExpanded] = useState(false);

	const [gesnItems, setGesnItems] = useState(null);
	const [inProgress, setInProgress] = useState(false);

	const request = useRef(null);

	const makeRequest = (body) => {
		if (request?.current) {
			request.current.cancel();
		}

		request.current = makeCancelable(props.dispatch(body));

		setInProgress(true);

		request.current.promise
			.then(res => {
				setGesnItems(res.items);
				setInProgress(false);
			})
			.catch(error => {
				if (!error.isCanceled) {
					setInProgress(false);
					console.log(error);
				}
			})
		;
	};

	useEffect(() => {
		return () => {
			if (request.current) {
				request.current.cancel();
			}
		};
	}, []);

	useEffect(() => {
		if (!handbookOpened) {
			setActiveSection(null);
			setActiveCategory(null);
		}
	}, [handbookOpened]);

	useEffect(() => {
		if (!activeCategory) {

			return;
		}

		_debounced(() => makeRequest(api.gesnDocuments().search({
			filter: {
				categoryId: activeCategory.id
			}
		})));
	}, [activeCategory]);

	useEffect(() => {
		if (!activeSection) {
			setActiveCategory(null);
		}
	}, [activeSection]);

	const { gesnSections, gesnCategories, paginationSize } = props;
	const sectionCategories = gesnCategories.filter(gc => gc.sectionId === activeSection?.id);

	return <div className={styles.GESNHandbook} style={props.style}>
		<Header
			activeSection={activeSection}
			inProgress={inProgress}
			activeCategory={activeCategory}
			handbookOpened={handbookOpened}
			setHandbookOpened={(isOpened) => setHandbookOpened(isOpened)}
			clearSection={() => setActiveSection(null)}

		/>
		{handbookOpened && !activeSection?.id ? <Categories
			items={gesnSections}
			paginationSize={paginationSize}
			onSelect={(section) => setActiveSection(section)}
		/> : null}
		{handbookOpened && activeSection?.id ? <Categories
			items={sectionCategories}
			paginationSize={paginationSize}
			onSelect={(category) => setActiveCategory(category)}
		/> : null}
		<List
			expanded={expanded}
			items={gesnItems || props.searchItems}
			onSelect={item => props.onSelect(item)}
		/>
		{gesnItems ? <div className={styles.GESNHandbook__footer}>
			{expanded ? <div
				className={styles.GESNHandbook__label}
				onClick={() => setExpanded(false)}
			>
				Скрыть
			</div> : null }
			{!expanded && gesnItems.length > 3 ? <div
				className={styles.GESNHandbook__label}
				onClick={() => setExpanded(true)}
			>
				Развернуть
			</div> : null }
		</div> : null}
	</div>;
};

GESNHandbook.propTypes = {
	paginationSize: PropTypes.number,
	searchItems: PropTypes.arrayOf(PropTypes.object),
	onFound: PropTypes.func,
	onSelect: PropTypes.func,
};

GESNHandbook.defaultProps = {
	paginationSize: 6,
};

const mapToProps = (state, props) => {
	const collects = {
		gesnSections: db.gesnSections.list(),
		gesnCategories: db.gesnCategories.list(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	return {
		...collects,
		isLoading,
	};
};

export default connect(mapToProps)(GESNHandbook);
