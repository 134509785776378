import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles/list-card.module.sass';

class ListCardItem extends React.PureComponent {
	static propTypes = {
		data: PropTypes.object.isRequired,
		actions: PropTypes.array,
		onClick: PropTypes.func,
		style: PropTypes.object,
	}

	items() {
		const list = [];

		for (const [key, value] of Object.entries(this.props.data)) {
			list.push(<div className={styles.dataItem} key={key}>{value}</div>);
		}

		return list;
	}

	actions() {
		return this.props?.actions ? this.props.actions.map((action, index) => (
			<div
				className={styles.actionItem}
				key={index}
				onClick={action.onClick}>
				<FontAwesomeIcon icon={action.icon} color={action.iconColor || "#7182AE"} />
			</div>
		)) : null;
	}

	render() {
		return (
			<div className={cn('d-flex', 'flex-row', styles.card, this.props.style || null)}>
				<div className={cn('d-flex', 'flex-grow-1', 'flex-row', 'justify-content-between', this.props.isDisabled ? styles.disabledItem : styles.items)} onClick={this.props.onClick}>{this.items()}</div>
				<div className={cn('d-flex', 'flex-row', 'justify-content-end', styles.actions)}>{this.actions()}</div>
			</div>
		);
	}
}

export default ListCardItem;
