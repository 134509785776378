import BudgetView from './components/Budget';
import SpecificatonView from './components/Specification';

import Requests from './components/Requests';

import Delivery from 'domains/supplies/components/Delivery';

import AmountApproval from './components/AmountApproval';
import InvoiceApproval from './components/InvoiceApproval';

import Estimate from './Estimate';
import Approval from './Approval';
import RequestDetails from './components/Requests/components/RequestDetails';

import Import from './components/Import';

export const estimateTabsRoutes = [
	{
		title: 'Сводная спецификация',
		path: '/projects/:projectId/supplies/estimate/specification',
		component: SpecificatonView,
	},
	{
		title: 'Смета',
		path: '/projects/:projectId/supplies/estimate/budget',
		component: BudgetView,
	}
];

export const approvalTabsRoutes = [
	{
		title: 'Превышение количества',
		path: '/projects/:projectId/supplies/approval/amount',
		component: AmountApproval,
	},
	{
		title: 'Новые счета',
		path: '/projects/:projectId/supplies/approval/invoices',
		component: InvoiceApproval,
	}
];

export const mainRoutes = [
	{
		path: "/supplies/estimate/import",
		exact: true,
		title: 'Импорт спецификаций',
		component: Import,
	},
	{
		title: 'Смета',
		path: '/supplies/estimate',
		component: Estimate
	},
	{
		path: "/supplies/requests",
		exact: true,
		component: Requests,
		title: 'Заявки'
	},
	{
		path: "/supplies/requests/:requestId",
		exact: true,
		component: RequestDetails
	},
	{
		title: 'Реестр заявок',
		path: '/supplies/delivery-tracking',
		component: Delivery
	},
	{
		title: 'Согласования',
		path: '/supplies/approval',
		component: Approval
	},
	{
		path: "/supplies/estimate/import",
		exact: true,
		title: 'Импорт спецификаций',
		component: Import,
	}
];
