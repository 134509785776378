import React from 'react';
import PropTypes from 'prop-types';
import {
	ComposedChart,
	Area, Bar,
	CartesianGrid,
	YAxis, XAxis,
	Tooltip,
	ResponsiveContainer
} from 'recharts';

import ChartTooltip from './Tooltip';


const CustomTooltip = ({ active, payload, label }) => {
	if (!active) {
		return null;
	}
	const data = payload && payload[0]?.payload;

	if (!data) {
		return null;
	}

	const { workers, perfomance, dates } = data;
	const labels = [
		{
			text: 'Количество сотрудников',
			value: workers,
			color: '#EED28A',
		},
		{
			text: 'Производительность',
			value: perfomance ? `${perfomance}%` : null,
			color: '#48B95A',
		},
		{
			text: 'Дата',
			value: dates,
		}
	];

	return <ChartTooltip labels={labels}/>;
};

class PerformanceChart extends React.PureComponent
{
	static propTypes = {
		dates: PropTypes.arrayOf(PropTypes.string),
		workers: PropTypes.arrayOf(PropTypes.any),
		perfomance: PropTypes.arrayOf(PropTypes.any),
	}

	static defaultProps = {
		dates: null,
		workers: [],
		perfomance: [],
	}

	render()
	{
		const { dates, workers, perfomance } = this.props;

		const workersMax = Math.max(10, Math.ceil(Math.max(...(workers || [])) * 2 / 10) * 10);
		const workersDomain = [0, workersMax];
		const workersTicks = new Array(11).fill().map((_, i) => workersMax / 10 * i);

		const perfomanceDomain = [0, Math.max(110, Math.min(Math.max(...(perfomance || [])), 200))] ;

		const data = [];

		if (dates) {
			for (let i = 0; i < dates.length; ++i) {
				data.push({
					dates: dates ? dates[i] : null,
					workers: workers ? workers[i] : null,
					perfomance: perfomance ? perfomance[i] : null,
				});
			}
		}

		return (
			<div>
				<ResponsiveContainer debounce={2} height={"95%"}>
					<ComposedChart data={data}>
						<CartesianGrid
							vertical={false}
							stroke="#ccc"
							strokeDasharray="5 5"
						/>
						<Bar
							yAxisId="left"
							dataKey="workers"
							barSize={20}
							fill="#EED28A"
						/>
						<Area
							yAxisId="right"
							dataKey="perfomance"
							stroke="#48B95A"
							strokeWidth={3}
							fillOpacity={0}
						/>
						<YAxis
							yAxisId="left"
							type="number"
							ticks={workersTicks}
							domain={workersDomain}
							allowDataOverflow={true}
							interval={0}
							hide
						/>
						<YAxis
							yAxisId="right"
							type="number"
							domain={perfomanceDomain}
							allowDataOverflow={false}
							interval={0}
							orientation="right"
							hide
						/>
						<XAxis
							dataKey="dates"
							hide
						/>
						{/* @TODO: Сделать нормальный динамический расчёт нормы производительности относительно планового кол-ва людей и объёма, чтобы он показывался в трудозатратах (но возможно этого и не нужно вообще)
						<ReferenceLine
							yAxisId="right"
							y={100}
							stroke="#48B95A"
							strokeDasharray="2 2"
							strokeWidth={2}
						>
							<Label
								value="НПД"
								position="insideBottomRight"
								fontSize={11}
							/>
						</ReferenceLine> */}
						<Tooltip content={<CustomTooltip />}/>
					</ComposedChart>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default PerformanceChart;
