import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faUpload} from '@fortawesome/free-solid-svg-icons';
import UploadList from './components/UploadList';
import { makeCancelable } from 'utils/promise';
import cn from 'classnames';
import uuidv4 from 'uuid/v4';

import style from './styles/uploader.module.sass';
import FileWrapper from './helpers/FileWrapper';

class Uploader extends React.Component
{
	state = {
		fileMap: {},
		dragging: false,
	}

	constructor(props)
	{
		super(props);
		this.inputRef = React.createRef();
		this.dropRef = React.createRef();
	}

	componentDidMount() {
		this.dragCounter = 0;
		const div = this.dropRef.current;

		if (!div) {
			return;
		}

		div.addEventListener('dragenter', this.handleDragIn);
		div.addEventListener('dragleave', this.handleDragOut);
		div.addEventListener('dragover', this.handleDrag);
		div.addEventListener('drop', this.handleDrop);
	}

	componentWillUnmount() {
		const div = this.dropRef.current;

		if (!div) {
			return;
		}

		div.removeEventListener('dragenter', this.handleDragIn);
		div.removeEventListener('dragleave', this.handleDragOut);
		div.removeEventListener('dragover', this.handleDrag);
		div.removeEventListener('drop', this.handleDrop);
	}

	handleDrag = (e) => {
		e.preventDefault();
		e.stopPropagation();
	}

	open = () => {
		this.inputRef.current.click();
	}

	handleDragIn = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({dragging: true});
		}
	}

	handleDragOut = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter--;
		if (this.dragCounter > 0) {
			return;
		}
		this.setState({dragging: false});
	}

	handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();

		const files = e.dataTransfer.files;

		if (files && files.length > 0) {
			for (const file of files) {
				this.request(file);
			}
			e.dataTransfer.clearData();
			this.dragCounter = 0;
		}

		this.setState({dragging: false});
	}

	remove = uid => {
		const fileMap = { ...this.state.fileMap };
		delete fileMap[uid];
		this.setState({ fileMap }, () => this.onChange());
	}

	onError = (fileWrapper, error) => {
		fileWrapper.request.cancel();
		fileWrapper.setStatus('error');
		fileWrapper.setError(error);
		this.updateFileMap(fileWrapper);
	}

	onSuccess = fileWrapper => {
		fileWrapper.setStatus('done');
		this.updateFileMap(fileWrapper);
	};

	onChange()
	{
		const files = Array.from(Object.values(this.state.fileMap));
		this.props.onFileListChanged(files);
	}

	updateFileMap = fileWrapper => {
		const fileMap = { ...this.state.fileMap };
		fileMap[fileWrapper.uid] = fileWrapper;
		this.setState({ fileMap }, () => this.onChange());
	}

	afterUpload = (fileWrapper, result) => {
		const { afterUpload } = this.props;

		if (afterUpload) {
			afterUpload(fileWrapper, result);
		}

		this.onSuccess(fileWrapper);
	}

	onAbort = fileWrapper => {
		if (fileWrapper.status === 'loading') {
			fileWrapper.request.cancel();
		}
		this.remove(fileWrapper.uid);
	}

	onUploadChange = e => {
		const files = e.target.files;

		for (const file of files) {
			this.request(file);
		}

		e.target.value = null;
	}

	request = file => {
		const { request } = this.props;

		if (!request) {
			return;
		}

		const canceledRequest = makeCancelable(request(file));

		const fw = new FileWrapper({
			file,
			request: canceledRequest,
			status: 'loading',
			abort: () => this.onAbort(fw),
			uid: uuidv4(),
		});

		const fileMap = { ...this.state.fileMap, [fw.uid]: fw };

		this.setState({ fileMap }, () => {
			canceledRequest.promise
				.then(result => this.afterUpload(fw, result))
				.catch(error => this.onError(fw, error))
			;
		});
	}


	renderDefaultComponent()
	{
		const { dragging } = this.state;

		return (
			<div
				ref={this.dropRef}
				className={dragging ? style.uploaderDragging : style.uploader}
				onClick={() => this.inputRef.current.click()}
			>
				<FontAwesomeIcon
					icon={faUpload}
					className={style.icon}
					size="3x"
				/>
				<div className={style.label}>
					{this.props.labelUploadPrompt}
				</div>
			</div>
		);
	}

	renderCustomComponent()
	{
		const Component = this.props.as;

		return (
			<Component
				{...this.props}
				ref={this.dropRef}
				onClick={() => this.inputRef.current.click()}
			>
				{this.props.children}
			</Component>
		);
	}

	render()
	{
		const files = Object.values(this.state.fileMap);
		const { multiple, accept, as, hidden } = this.props;

		const component = as ? this.renderCustomComponent() : this.renderDefaultComponent();

		const input = (
			<input
				className={cn('invisible')}
				type="file"
				ref={this.inputRef}
				onChange={this.onUploadChange}
				multiple={multiple}
				accept={accept}
			/>
		);

		if (hidden) {
			return input;
		}

		return (
			<div>
				{input}
				{component}
				<div className={style.underLabel}>
					{this.props.labelUploadNotes}
				</div>
				<UploadList items={files} />
			</div>
		);
	}
}

Uploader.propTypes = {
	labelHasProblems: PropTypes.string,
	labelUploadPrompt: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	labelUploadNotes: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	onFileListChanged: PropTypes.func,
	uploadProps: PropTypes.object,
	request: PropTypes.func,
	afterUpload: PropTypes.func,
	accept: PropTypes.string,
	multiple: PropTypes.bool,
	as: PropTypes.elementType,
	hidden: PropTypes.bool,
};

Uploader.defaultProps = {
	labelHasProblems: 'Если у вас есть проблемы с загрузкой файлов, можете написать нам в чат и приложить эти файлы',
	labelUploadPrompt: 'Нажмите или перетащите файлы в область для загрузки',
	labelUploadNotes: <>Можно загрузить один или несколько файлов.<br/>Принимаются только файлы .xlsx определенного формата. Образец можете скачать по ссылке выше.</>,
	uploadProps: {},
	onFileListChanged: () => {},
	multiple: false,
	hidden: false,
};

export default Uploader;
