import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import db from 'core/db';

import { Empty } from 'components/ui';
import Workspace from 'layouts/Default/components/Workspace';
import Grid from './components/Grid';

class Delivery extends React.PureComponent
{
	/**
	 * Сборка списка позиций заявок с полной информацией из заказов, счетов и сметы
	 */
	aggregateRequestItems = () => {
		const {
			purchaseOrderItems,
			purchaseRequestItems,
			consumableSheetItemsMap,
			purchaseInvoices,
			purchaseOrdersMap,
			purchaseRequestsMap,
			employees,
		} = this.props;

		// Группировка позиций заказов по заявкам
		const orderItemsByRequest = new Map();

		purchaseOrderItems.forEach(orderItem => {
			if (!purchaseOrdersMap.has(orderItem.purchaseOrderId)) {
				return;
			}

			const items = orderItemsByRequest.get(orderItem.purchaseRequestItemId) || [];
			items.push(orderItem);
			orderItemsByRequest.set(orderItem.purchaseRequestItemId, items);
		});

		return purchaseRequestItems.filter(requestItem => {
			// Фильтр по заявкам выбранного проекта
			return purchaseRequestsMap.has(requestItem.purchaseRequestId);
		}).map((requestItem) => {
			// Берём только первый элемент, потому что в текущей реализации поддерживается только одна позиция заказа на одну позицию заявки
			// Т.е. нельзя разбить количество заказываемого стафа из одной позиции заявки на два заказа.
			const orderItem = orderItemsByRequest.has(requestItem.id) && orderItemsByRequest.get(requestItem.id)[0];
			const order = purchaseOrdersMap.get(orderItem.purchaseOrderId);

			const invoice = purchaseInvoices.find(invoice => invoice.purchaseOrderId === orderItem.purchaseOrderId);
			const request = purchaseRequestsMap.get(requestItem.purchaseRequestId);
			request.employee = employees.find(employee => employee.userId === request.createdByUserId);

			let status = "Оформлена";
			if (requestItem.amount === '0.00') {
				status = "Отменена";
			} else if (!requestItem.isAmountApproved) {
				status = "На утверждении";
			} else if (request.assignedUserId) {
				status = "В работе";
			}

			if (invoice?.isWaitingForApprove) {
				status = "На согласовании";
			}

			if (!invoice?.isWaitingForApprove && orderItem.isApproved === true) {
				status = "В оплате";
			}

			if (order && order.isConfirmed) {
				status = "В доставке";
			}

			// TODO: непонятная хрень, по количеству доставленного и заказанного можно понять это состояние.
			if (orderItem.deliveryStatus === 'inTransit') {
				status = "Отгружено частично";
			} else if (orderItem.deliveryStatus === 'delivered') {
				status = "Отгружено полностью";
			}

			return {
				...requestItem,
				orderItem,
				budgetItem: consumableSheetItemsMap.get(requestItem.consumableSheetItemId),
				invoice,
				request,
				status
			};
		});
	}

	render()
	{
		const { consumableSheetId, isLoading } = this.props;

		if (isLoading) {
			return <Empty title="Загрузка заявок..." className={cn('w-100', 'h-100')} />;
		}

		const requestItems = this.aggregateRequestItems();

		if (!consumableSheetId || !requestItems) {
			return <Empty title="Все заявки обработаны, материалы доставлены." className={cn('w-100', 'h-100')} />;
		}

		return (
			<Workspace>
				<div className={cn('w-100', 'h-100', 'd-flex', 'flex-column')}>
					<Grid
						items={requestItems}
						currenciesMap={this.props.currenciesMap}
					/>
				</div>
			</Workspace>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consumableSheets = db.tbsConsumableSheets.list(projectFilter);
	const consumableSheetId = consumableSheets[0]?.id;

	const consumbaleSheetfilter = { filter: { consumableSheetId } };

	const collects = {
		purchaseOrders: db.tbsPurchaseOrders.listNotDeleted(projectFilter),
		purchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		purchaseRequests: db.tbsPurchaseRequests.listNotDeleted(projectFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.listNotDeleted(),
		consumableSheetItems: db.tbsConsumableSheetItems.listNotDeleted(consumbaleSheetfilter),
		currencies: db.currencies.list(),
		purchaseInvoicePayments: db.tbsPurchaseInvoicePayments.listNotDeleted(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted(),
		employees: db.employees.list()
	};

	const maps = {
		purchaseOrdersMap: collects.purchaseOrders.hashById(),
		purchaseRequestItemsMap: collects.purchaseRequestItems.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		currenciesMap: collects.currencies.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	return {
		...collects,
		...maps,
		consumableSheetId,
	};
};

export default connect(mapToProps)(Delivery);
