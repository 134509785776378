import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ChartLegend from './components/ChartLegend';
import { Container } from 'components/ui';
import styles from './style.module.sass';


class ChartLayout extends React.PureComponent
{
	static propTypes = {
		header: PropTypes.string,
		title: PropTypes.string,
		legend: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		})),
		renderHeaderWidget: PropTypes.func,
	}

	renderHeader()
	{
		const { header, title, renderHeaderWidget } = this.props;

		if (!header && !title && !renderHeaderWidget) {
			return null;
		}

		return (
			<div className={styles.Header}>
				<Container layout="row" between={!!renderHeaderWidget}>
					<Container layout="column">
						<div className={cn(styles.Header_label, styles.Header_label__bold)}>
							{header}
						</div>
						<div className={styles.Header_label}>
							{title}
						</div>
					</Container>
					{renderHeaderWidget ? (
						<Container layout="column">
							{renderHeaderWidget()}
						</Container>
					) : null}
				</Container>
			</div>
		);
	}

	renderLegend()
	{
		const { legend } = this.props;

		if (!legend?.length) {
			return null;
		}

		return <div className={styles.Legend}>
			{legend.map((l, i) => (<ChartLegend key={i} label={l.label} color={l.color} />))}
		</div>;
	}

	renderContent()
	{
		const { children } = this.props;

		return (
			<div className={styles.Content}>
				{children}
			</div>
		);
	}

	render()
	{
		const { style } = this.props;

		return (
			<div className={styles.ChartLayout} style={style}>
				{this.renderHeader()}
				{this.renderContent()}
				{this.renderLegend()}
			</div>
		);
	}
}

export default ChartLayout;
