import React from 'react';
import PropTypes from 'prop-types';
import { add, startOfWeek, endOfWeek, eachDayOfInterval, formatISO } from 'date-fns';
import { Day, DayFormat, WeekFormat, EmptyDay } from './types';
import { DayCell, EmptyDayCell, DayFormatCell, WeekFormatCell } from './components';

import styles from './style.module.sass';


class WeeklyHoursGrid extends React.PureComponent
{
	static propTypes = {
		baseColor: PropTypes.string,
		date: PropTypes.any,
		weekCount: PropTypes.number,
		data: PropTypes.object,
	}

	static defaultProps = {
		baseColor: '#FFA500',
		date: new Date(),
		weekCount: 5,
		data: {},
	}

	interpolate = (color, opacity) => {
		if (color.startsWith('#')) {
			return color + parseInt((color.length === 4 ? 15 : 255) * opacity, 10).toString(16);
		}
		if (color.startsWith('rgba')) {
			return color.replace(/[\d.]+\)/, a => (a * opacity).toFixed(2));
		}
		if (color.startsWith('rgb')) {
			return color.replace(')', opacity.toFixed(1));
		}

		return color;
	};

	generate(weekCount, date, data, baseColor)
	{
		const cells = [];
		const values = Array.from(Object.values(data));

		const min = Math.min(...values);
		const max = Math.max(...values);
		const MIN_OPACITY = 0.3;

		for (let i = 0; i < weekCount; ++i) {
			const week = i === 0 ? date : add(date, { weeks: i });

			const start = startOfWeek(week, {weekStartsOn: 1});
			const end = endOfWeek(week, {weekStartsOn: 1});
			const daysOfWeek = eachDayOfInterval({ start, end });

			for (const day of daysOfWeek) {
				const key = formatISO(day, { representation: 'date' });
				const value = data[key];

				if (value) {
					const opacity = (min === max) ? 1 : Math.round(((value - min) / (max - min) * 100)) / 100;
					const color = this.interpolate(baseColor, Math.max(opacity, MIN_OPACITY));
					cells.push(new Day(day, value, color));
				} else {
					cells.push(new EmptyDay());
				}
			}

			cells.push(new WeekFormat(start, end));
		}


		const weekDays = eachDayOfInterval({
			start: startOfWeek(date, {weekStartsOn: 1}),
			end: endOfWeek(date, {weekStartsOn: 1}),
		});

		for (const day of weekDays) {
			cells.push(new DayFormat(day));
		}

		return cells;
	}

	render()
	{
		const { weekCount, date, data, baseColor, ...props } = this.props;
		const cells = this.generate(weekCount, date, data, baseColor);

		return (
			<div {...props} className={styles.WeeklyHoursGrid}>
				{cells.map((cell, i) => {
					if (cell instanceof Day) {
						return <DayCell key={i} date={cell.date} value={cell.value} color={cell.color}/>;
					} else if (cell instanceof EmptyDay) {
						return <EmptyDayCell key={i}/>;
					} else if (cell instanceof DayFormat) {
						return <DayFormatCell key={i} date={cell.date}/>;
					} else if (cell instanceof WeekFormat) {
						return <WeekFormatCell key={i} dates={{ start: cell.start, end: cell.end }}/>;
					}

					return null;
				})}
			</div>
		);
	}
}

export default WeeklyHoursGrid;
