import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';
import cn from 'classnames';

import styles from './style.module.sass';


const CheckInput = props => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	const { className, ...otherPorps } = props;

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return <FormCheck
		onChange={e => helpers.setValue(e.target.checked)}
		checked={value || false}
		className={cn(styles.Checkbox, className)}
		{...otherPorps}
	/>;
};

CheckInput.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
};

export default CheckInput;
