import config from '../../config';

export const parseXlsx = async (file) => {
	const body = new FormData;
	body.append('file', file);

	const response = await fetch(
		config.services.xlsx.parser.url,
		{
			method: 'POST',
			body,
		},
	);

	const contentType = response.headers.get('content-type');

	if (!contentType || !contentType.includes('application/json')) {
		throw new Error('Expected content type is application/json, but ' + contentType + ' found');
	}

	return JSON.parse(await response.text()).result;
};
