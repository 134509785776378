import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthGuard from 'components/service/AuthGuard';
import { SettingsProvider } from 'core/local-settings';

import apiClient from 'core/api-client';
import activityIndicatorController from 'core/activity-indicator-controller';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/global/app.sass';
import 'styles/global/bootstrap-custom.sass';

import AppLayout from './layouts/Default';
import AuthLayout from './layouts/Authorization';
import InviteSwitch from 'domains/user/components/Invite/InviteSwitch';

import cn from 'classnames';
import { Loading } from 'components/ui';


class App extends React.PureComponent {
	state = {}

	constructor(props)
	{
		super(props);

		const token = localStorage.getItem('token');

		if (!token) {
			return;
		}

		apiClient.on('request', this.onRequestStart);
		apiClient.on('result', this.onRequestEnd);
		apiClient.on('error', this.onRequestEnd);

		this.props.dispatch({type: 'LOGIN_WITH_TOKEN', token: token});
	}

	componentDidMount() {
		activityIndicatorController.on('show', () => this.setState({ showActivityModal: true }));
		activityIndicatorController.on('hide', () => this.setState({ showActivityModal: false }));
	}

	onRequestStart = request => {
		if (!request.tags.background) {
			activityIndicatorController.push();
		}
	}

	onRequestEnd = (_, request) => {
		if (!request.tags.background) {
			activityIndicatorController.pop();
		}
	}

	renderActivityModal()
	{
		return (
			<div
				className={cn('h-100', 'w-100', 'fixed-top')}
				style={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
			>
				<Loading
					className={cn('h-100', 'w-100')}
					title="Загрузка..."
				/>
			</div>
		);
	}

	render() {

		const meId = this.props.me.id;
		const showActivityModal = this.state?.showActivityModal;
		const activityModal = this.renderActivityModal();

		const auth = <AuthLayout/>;
		const app = <InviteSwitch>
			<HashRouter>
				<AppLayout/>
			</HashRouter>
		</InviteSwitch>;

		const root = <Provider store={this.props.store}>
			<React.Fragment>
				<AuthGuard
					authComponent={auth}
					appComponent={app}
				/>
				<ToastContainer closeButton={false}/>
				{ showActivityModal ? activityModal : null }
			</React.Fragment>
		</Provider>;

		return meId ? <SettingsProvider meId={meId}>{root}</SettingsProvider> : root;
	}
}

App.propTypes = {
	store: PropTypes.object.isRequired,
};

export default connect((state, props) => {
	return {
		me: state.entities.me || {},
	};
})(App);
