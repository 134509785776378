import sendEmail from 'core/sendEmail';

const notifications = {
	'new-project': {
		description: 'Создан новый проект',
		subject: 'Создан новый проект',
		message: 'Создан новый проект',
		roles: ['Логист', 'Руководитель проекта', 'Директор']
	},
	'new-spec': {
		description: 'Загружена СС',
		subject: 'Загружена СС',
		message: 'Загружена СС',
		roles: ['Логист', 'Руководитель проекта']
	},
	'new-request': {
		description: 'Создана заявка',
		subject: 'Создана заявка',
		message: 'Создана заявка',
		roles: ['Логист']
	},
	// @todo добавить событие превышения в момент создания заявки
	'request-amount': {
		description: 'Превышение количества в заявке',
		subject: '',
		message: '',
		roles: ['Директор']
	},
	'request-in-progress': {
		description: 'Заявка взята в работу',
		subject: 'Заявка взята в работу',
		message: 'Заявка взята в работу',
		roles: ['Руководитель проекта']
	},
	// @todo
	'request-amount-changed': {
		description: 'Изменено количество в заявке',
		subject: 'Изменено количество в заявке',
		message: 'Изменено количество в заявке',
		roles: ['Логист']
	},
	'new-invoice': {
		description: 'Добавлен счёт по заявке',
		subject: 'Добавлен счёт по заявке',
		message: 'Добавлен счёт по заявке',
		roles: ['Руководитель проекта']
	},
	'invoice-approved': {
		description: 'Счёт согласован',
		subject: 'Счёт согласован',
		message: 'Счёт согласован',
		roles: ['Логист', 'Директор']
	},
	'invoice-declined': {
		description: 'Счёт отклонён',
		subject: 'Счёт отклонён',
		message: 'Счёт отклонён',
		roles: ['Логист']
	},
	'order-placed': {
		description: 'Заказ размещён',
		subject: 'Заказ размещён',
		message: 'Заказ размещён',
		roles: ['Логист', 'Руководитель проекта']
	},
};

const notify = (employees, event, message = null) => {
	const notification = notifications[event];
	employees.forEach((employee) => {
		if (employee.roles.find((value) => notification.roles.indexOf(value.title) >= 0) && employee.user.email && !employee.deactivationTime) {
			sendEmail(
				employee.user.email,
				notification.subject,
				message || notification.message
			);
		}
	});
};

export default notify;
