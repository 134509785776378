/* eslint-disable no-process-env */
export default {
	apiUrl: process.env.REACT_APP_API,
	aggridKey: "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-7_November_2020_[v2]_MTYwNDcwNzIwMDAwMA==e092019c061c6f309c36d9ef6026a1b6",
	pusher: {
		cluster: 'eu',
		appKey: '1986d3bb36a3532b1e7e'
	},
	tbs: {
		priceCurrency: {
			default: {
				code: "RUB",
				id: "1BAAFB81-13C0-4329-A131-B2C1ED7BA2A2",
				symbol: "₽",
				title: "Рубль",
			}
		}
	},
	services: {
		xlsx: {
			parser: {
				url: 'https://xlsx.brigadier.app/parse',
			}
		}
	}
};
