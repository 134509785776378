import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import moment from 'moment';
import WorkerDayForm from './components/WorkerDayForm';


class WorkerDayModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		workers: PropTypes.arrayOf(PropTypes.object),
		workerDay: PropTypes.object,
		projects: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		workers: [],
		projects: [],
	}

	state = {
		date: moment().toDate(),
		isAbsence: true,
		timeWorked: moment().startOf('day').toDate(),
		arriveTime: moment().startOf('day').toDate(),
	}

	onFormCreated = form => (this.form = form)

	render()
	{
		const {
			onConfirm,
			onCancel,
			visible,
			workers,
			workerDay,
			projects,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.form.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		return (
			<Modal
				btnList={btnList}
				size="md"
				title="Отчёт о присутствии"
				show={visible}
				onHide={() => onCancel()}
			>
				<WorkerDayForm
					onFormCreated={this.onFormCreated}
					workerDay={workerDay}
					onSubmit={changes => onConfirm(workerDay?.id, changes)}
					onCancel={() => onCancel()}
					workers={workers}
					projects={projects}
				/>
			</Modal>
		);
	}
}

export default WorkerDayModal;
