
import React from 'react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { Button } from 'react-bootstrap';

import { Empty } from 'components/ui';

import api from "core/api";
import db from "core/db";
import notify from 'utils/suppliesNotifications';
import { makeCancelable } from 'utils/promise';

import TopToolbar from './components/TopToolbar';
import Grid from './components/Grid';
import MergeDialog from './components/MergeDialog';
import Merger from './helpers/Merger';
import Aggregator from './helpers/Aggregator';
import styles from './styles.module.sass';


class SpecificatonView extends React.PureComponent
{
	state = {
		selectedNodes: [],
		mergeDialogOpen: false,
		approving: false,
		uploading: false,
		filterModalVisible: false,
		filters: {},
		mergingItems: [],
		mergingGroups: [],
		merging: false,
	}

	renderButtons = (buttons) =>
	{
		return (
			<div className={cn('d-flex', 'flex-row', 'align-items-center')}>
				{buttons.map((btn, index) => {
					return (
						<Button
							key={index}
							onClick={() => btn.onClick()}
							className="mr-1"
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}

	pageButtons = () => {
		if (this.props.consSpec?.isApproved) {
			return [];
		}

		const selectedCount = this.state.selectedNodes.length;

		const buttons = [
			{
				title: 'Утвердить',
				onClick: this.onApproveClick,
				disabled: this.state.approving,
			},
		];

		const btnMerge = {
			title: `Объеденить (${selectedCount})`,
			onClick: this.openMergeDialog,

		};

		if (selectedCount > 1) {
			buttons.push(btnMerge);
		}

		return buttons;
	}

	componentDidMount()
	{
		const { isLoading, consSpec } = this.props;

		if (!isLoading && consSpec) {
			this.checkMergeItems();
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
		const { isLoading, consSpec } = this.props;

		if (!consSpec) {
			return;
		}

		if (!prevProps.consSpec?.isApproved && consSpec.isApproved && this.gridApi) {
			this.gridApi.sizeColumnsToFit();
		}

		if (!isLoading) {
			this.checkMergeItems();
		}
	}

	componentWillUnmount()
	{
		this.cancelAutoMergeRequest();
	}

	cancelAutoMergeRequest()
	{
		if (this.autoMergeRequest) {
			this.autoMergeRequest.cancel();
			delete this.autoMergeRequest;
		}
	}

	onAutoMergeClicked = () => {
		const { mergingItems } = this.state;

		if (!mergingItems?.length) {
			return;
		}

		this.setState({ merging: true }, () => {

			this.cancelAutoMergeRequest();

			const autoMergeRequest = makeCancelable(this.props.dispatch(api.transaction().execute(mergingItems)));
			this.autoMergeRequest = autoMergeRequest;

			autoMergeRequest.promise
				.then(this.onRequestCallback)
				.catch(error => {
					console.log(error);
				})
				.finally(() => {
					this.cancelAutoMergeRequest();
					this.setState({ merging: false });
				})
			;

		});
	}

	checkMergeItems = () => {
		const { merging, uploading, approving } = this.state;
		const { consSpec, consSpecId, sourceSpecItems, consSpecItems } = this.props;

		if (merging || uploading || approving) {
			return;
		}

		if (!consSpec || consSpec.isApproved) {
			return;
		}

		const mergingItems = Merger.mergeAll(
			consSpecId,
			sourceSpecItems,
			consSpecItems,
		);

		const isMergesEqual = isEqual(
			mergingItems.map(mi => Merger.key(mi.props)).sort(),
			this.state.mergingItems.map(mi => Merger.key(mi.props)).sort()
		);

		if (isMergesEqual) {
			return;
		}

		const mergingGroups = mergingItems.map(mi => {
			const { consolidatedSpecificationItemId, sourceSpecificationItemIds, changes } = mi.props;

			const changesSourceSpecifications = changes?.sourceSpecificationItemIds || [];

			return {
				consolidatedSpecificationItemId,
				sourceSpecificationItemIds: [...(sourceSpecificationItemIds || []), ...changesSourceSpecifications],
			};
		});

		this.setState({ mergingItems, mergingGroups }, this.gridApi ? () => this.gridApi.redrawRows() : null);
	}

	unmerge = (item) => {
		const ids = item.sourceSpecificationItemIds;

		const transactionBody = ids.map(id => {
			return api.tbsSourceSpecificationItems().update({
				sourceSpecificationItemId: id,
				changes: {
					consolidatedSpecificationItemId: null,
				}
			});
		});

		this.setState({ merging: true }, () => {
			this.props.dispatch(api.transaction().execute(transactionBody))
				.finally(() => this.setState({ merging: false }));
		});
	}

	onRemoveSourceSpec = spec => {
		this.setState({ uploading: true });
		this.props.dispatch(api.tbsSourceSpecifications().update({
			sourceSpecificationId: spec.id,
			changes: {
				isDeleted: true
			}
		}))
			.then(() => this.props.dispatch(api.tbsConsolidatedSpecificationItems().list().withoutCache()))
			.then(() => this.props.dispatch(api.tbsSourceSpecificationItems().list().withoutCache()))
			.catch(err => console.error(err))
			.finally(() => this.setState({ uploading: false }, () => this.checkMergeItems()))
		;
	};

	onUploadSourceSpec = file => {
		this.setState({ uploading: true });

		this.props.dispatch(api.tbsSourceSpecifications().uploadFile(file))
			.then((result) => {
				return this.props.dispatch(api.tbsSourceSpecifications().create({
					consolidatedSpecificationId: this.props.consSpec?.id,
					attachmentId: result.attachment.id,
				}));
			})
			.then(() => this.props.dispatch(api.tbsSourceSpecificationItems().list().withoutCache()))
			.then(() => this.props.dispatch(api.tbsSourceSpecifications().list().withoutCache()))
			.catch((error) => {
				console.error(error);
				toast.error('Не удалось загрузить файл...', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => this.setState({ uploading: false }, () => this.checkMergeItems()))
		;
	}

	closeMergeDialog = () => {
		this.setState({mergeDialogOpen: false});
	}

	openMergeDialog = () => {
		this.setState({mergeDialogOpen: true});
	}

	onSelectionChanged = (params) => {
		const selectedNodes = params.api.getSelectedNodes();
		this.setState({ selectedNodes });
	}

	approveSpecification = () => {
		const sourceItems = this.props.sourceSpecItems.filter(item => item.consolidatedSpecificationItemId === null);

		this.setState({ approving: true });

		const constItemsCreation = sourceItems.map(item => api.tbsConsolidatedSpecificationItems().create({
			consolidatedSpecificationId: this.props.consSpecId,
			title: item.title,
			partNumber: item.partNumber,
			manufacturerName: item.manufacturerName,
			consumableUnitId: item.consumableUnitId,
			sourceSpecificationItemIds: [item.id],
		}));

		const consSheetCreation = api.tbsConsumableSheets().create({
			consolidatedSpecificationId: this.props.consSpecId
		});

		const transactionBody = [...constItemsCreation, consSheetCreation];

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => {
				db.tbsConsumableSheets.list({ refresh: true });
				db.tbsConsumableSheetItems.list({ refresh: true });
				db.tbsConsolidatedSpecifications.list({ refresh: true });
			})
			.then(() => {
				notify(this.props.employees, 'new-spec');
			})
			.catch(err => {
				console.error(err);
				toast.error('Ошибка утверждения сводной спецификации', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				this.setState({ approving: false });
			})
		;
	};

	onApproveClick = () => {
		const { items } = this.props;

		const hasUnfilled = items.some(i => !i.consumableUnitId);

		if (hasUnfilled) {
			toast.error('Необходимо заполнить все поля', {
				position: toast.POSITION.TOP_CENTER,
				hideProgressBar: true,
			});
		} else {
			this.approveSpecification();
		}
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onMergeSuccess = () => this.setState({selectedNodes: []});

	onFilterChange = filters => {
		this.setState({ filters }, this.gridApi ? () => this.gridApi.onFilterChanged() : null);
	}

	renderTopToolbar()
	{
		return (
			<TopToolbar
				sourceSpecs={this.props.sourceSpecs}
				consSpec={this.props.consSpec}
				onFilterChange={this.onFilterChange}
				mergingItems={this.state.mergingItems}
				onMergeClicked={() => this.onAutoMergeClicked()}
				onUploadSourceSpec={this.onUploadSourceSpec}
				onRemoveSourceSpec={this.onRemoveSourceSpec}
				items={this.props.items}
			/>
		);
	}

	render() {
		const { consSpec, isLoading } = this.props;
		const { filters, mergingGroups } = this.state;

		const allowedSourceSpecIds = filters.sourceSpecificationId || [];

		if (!consSpec || isLoading) {
			return (
				<Empty
					title="Нет сводной спецификации"
					className={cn('w-100', 'h-100')}
				/>
			);
		}

		const aggregator = new Aggregator({ ...this.props, allowedSourceSpecIds });
		const gridItems = aggregator.getGridItems();

		const { isApproved } = this.props.consSpec;
		const topToolbar = this.renderTopToolbar();
		const mergeItems = this.state.selectedNodes.map(node => node.data);

		return (
			<React.Fragment>
				{this.renderButtons(this.pageButtons())}
				<div className={cn('w-100', 'h-100', 'd-flex', 'flex-column', styles.tableContainer)}>
					{topToolbar}
					<Grid
						items={gridItems}
						consumableUnitsMap={this.props.consumableUnitsMap}
						consumableUnits={this.props.consumableUnits}
						onSelectionChanged={this.onSelectionChanged}
						sourceSpecItemsMap={this.props.sourceSpecItemsMap}
						consSpecId={this.props.consSpecId}
						onGridReady={this.onGridReady}
						isApproved={isApproved}
						onUnmergeItem={this.unmerge}
						mergingGroups={mergingGroups}
						filters={this.state.filters}
					/>
				</div>
				<MergeDialog
					items={[...mergeItems]}
					isOpen={this.state.mergeDialogOpen}
					onClose={this.closeMergeDialog}
					consumableUnitsMap={this.props.consumableUnitsMap}
					consumableUnits={this.props.consumableUnits}
					consSpecId={this.props.consSpecId}
					onSuccess={this.onMergeSuccess}
				/>
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consolidatedSpecifications = db.tbsConsolidatedSpecifications.list(projectFilter);
	const consolidatedSpecification = consolidatedSpecifications[0];
	const consolidatedSpecificationId = consolidatedSpecification?.id;

	const consSpecIdFilter = { filter: { consolidatedSpecificationId }};

	const sourceSpecItems = db.tbsSourceSpecificationItems.list(consSpecIdFilter);
	const consSpecItems = db.tbsConsolidatedSpecificationItems
		.list(consSpecIdFilter)
		.filter(csi => csi.sourceSpecificationItemIds.length)
	;
	const employees = db.employees.list();

	const sourceSpecs = db.tbsSourceSpecifications.list(consSpecIdFilter);
	const consumableUnits = db.consumableUnits.list();

	const sourceSpecItemsMap = sourceSpecItems.hashById();
	const sourceSpecsMap = sourceSpecs.hashById();
	const consumableUnitsMap = consumableUnits.hashById();

	const isLoading = props.isLoading
		|| sourceSpecItems.isLoading
		|| consSpecItems.isLoading
		|| sourceSpecs.isLoading
		|| consumableUnits.isLoading
		|| consolidatedSpecifications.isLoading
		|| consolidatedSpecifications.isLoading
	;

	if (isLoading) {
		return { isLoading };
	}

	const newProps = {
		sourceSpecsMap,
		sourceSpecs,
		consumableUnitsMap,
		consumableUnits,
		sourceSpecItems,
		consSpecItems,
		sourceSpecItemsMap,
		consSpec: consolidatedSpecification,
		consSpecId: consolidatedSpecificationId,
		employees
	};

	const aggregator = new Aggregator(newProps);
	const items = aggregator.getGridItems();

	return {
		...newProps,
		items,
	};
};

export default connect(mapToProps)(SpecificatonView);

