import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import db from 'core/db';
import { Empty } from 'components/ui';
// TODO: перенести куда следует
import InvoicesList from '../../components/Requests/components/InvoicesList';

class InvoiceApproval extends React.PureComponent {
	aggregateInvoicesList = () => {
		const {
			projectId,
			consumableSheetItemsMap,
			purchaseRequestItems,
			purchaseOrderItems,
			purchaseOrdersMap,
			purchaseInvoices
		} = this.props;

		// Собираем позиции заказа для выбранной заявки, которые не находятся в удалённых заказах (фильтр isDeleted возможно не нужен, но сервак не размечает позиции заказа как удаленные автоматически)
		const orderItems = purchaseRequestItems.flatMap((requestItem) => {
			// TODO: Вынести наружу и заменить на предварительное создание Map() чтобы вместо фильтра делать .get(purchaseRequestItemId)
			const items = purchaseOrderItems.filter(orderItem => orderItem.purchaseRequestItemId === requestItem.id);

			// Добавляем информацию о позиции заказа из сметы и соответствующую позицию заявки
			return items.map((item) => {
				return { ...item, consumableSheetItem: consumableSheetItemsMap.get(requestItem.consumableSheetItemId), requestItem };
			});
		}).filter(orderItem => {
			return purchaseOrdersMap.has(orderItem.purchaseOrderId) && !purchaseOrdersMap.get(orderItem.purchaseOrderId).isDeleted;
		});

		let orders = {};

		// Группировка позиций заказов по заказам (собираются заказы)
		orderItems.forEach((orderItem) => {
			if (!orders[orderItem.purchaseOrderId]) {
				orders[orderItem.purchaseOrderId] = {
					id: orderItem.purchaseOrderId,
					items: [],
					invoice: null
				};
			}

			orders[orderItem.purchaseOrderId].items.push(orderItem);
		});

		// Добавляются счета к заказам и фильтруются только по тем, где счета есть
		orders = Object.values(orders).map((order) => {
			order.invoice = purchaseInvoices.filter(i => i.purchaseOrderId === order.id)[0];

			return order;
		}).filter(o => o.invoice && purchaseOrdersMap.get(o.id).projectId === projectId);

		return orders;
	}

	render () {
		if (this.props.isLoading) {
			return <Empty
				className={cn('w-100', 'h-100')}
				title="Загружается список позиций заявки..."
			/>;
		}

		const list = this.aggregateInvoicesList();

		return (
			<InvoicesList
				orders={list}
				currenciesMap={this.props.currenciesMap}
				onRemoveItem={() => {}}
			/>
		);
	}
}

export default connect((state, props) => {
	const projectId = props.match.params.projectId;

	// Нужна для таблицы, чтобы взять оттуда курсы валют
	const consumableSheet = db.tbsConsumableSheets.list({ filter: { projectId }})[0];

	const consumableSheetFilter = { filter: { consumableSheetId: consumableSheet?.id } };

	// Нужно в первую очередь для проверки на isLoading
	const collects = {
		consumableSheetItems: db.tbsConsumableSheetItems.listNotDeleted(consumableSheetFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.listNotDeleted(),
		purchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		purchaseOrders: db.tbsPurchaseOrders.listNotDeleted(),
		currencies: db.currencies.list(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted(),
	};

	const maps = {
		purchaseOrdersMap: collects.purchaseOrders.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
		currenciesMap: collects.currencies.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	return {
		me: state.me,
		consumableSheet,
		projectId,
		...collects,
		...maps
	};
})(InvoiceApproval);
