import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import api from 'core/api';
import db from 'core/db';

import { Uploader, ButtonToolbar, Empty } from 'components/ui';

class UploadView extends React.Component
{
	static propTypes = {
		consSpecId: PropTypes.string.isRequired,
	}

	state = {
		fileList: [],
		loading: false,
	}

	createConsolidatedSpecification = () => {
		const transactionBody = this.state.fileList.map(file => {
			const createObj = {
				consolidatedSpecificationId: this.props.consSpecId,
				attachmentId: file.requestResult.attachment.id,
			};

			return api.tbsSourceSpecifications().create(createObj);
		});

		this.setState({loading: true});

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => {
				db.tbsConsolidatedSpecifications.list({refresh: true});
				db.tbsSourceSpecificationItems.list({refresh: true});
			})
			.then(() => {
				// this.props.onConsolidatedSpecFormed();
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	afterUpload = (fileWrapper, result) => {
		fileWrapper.requestResult = result;
	}

	onFileListChanged = (fileList) => {
		this.setState({fileList});
	}

	render()
	{
		const fileList = this.state.fileList;
		const loading = this.state.loading || fileList.some(f => f.status === 'error' || f.status === 'loading');
		const btnUploadDisabled = loading || !fileList.length;

		const uploadProps = {
			customRequest: this.uploadRequest,
			multiple: true,
			accept: '.xlsx',
		};

		const btnList = [
			{
				title: 'Загрузить',
				props: {
					disabled: btnUploadDisabled,
					onClick: () => this.createConsolidatedSpecification(),
				}
			}
		];

		const empty = fileList.length ? null : <Empty title="Загрузите файлы ИС"/>;
		const request = file => this.props.dispatch(api.tbsSourceSpecifications().uploadFile(file));

		return (
			<div className={"d-flex flex-column justify-content-between align-items-center flex-grow-1"}>
				<div className="w-75">
					<Uploader
						onFileListChanged={this.onFileListChanged}
						uploadProps={uploadProps}
						multiple={true}
						accept={'.xlsx'}
						request={request}
						afterUpload={this.afterUpload}
					/>
				</div>
				{empty}
				<ButtonToolbar
					className="w-100"
					btnList={btnList}
				/>
			</div>
		);
	}
}

export default connect()(UploadView);
