import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Route, Switch, withRouter, matchPath } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { makeLinkDynamic } from 'routes';
import { Nav } from 'react-bootstrap';

import ButtonsToolbar from './components/ButtonsToolbar';

import styles from './style.module.sass';


class TabsRouter extends React.PureComponent
{
	static propTypes = {
		routes: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		routes: [],
	}

	renderTabs()
	{
		const { routes, location } = this.props;
		const { pathname } = location;

		if (!routes.length) {
			return null;
		}

		const routesMatches = routes.map(r => matchPath(pathname, { path: r.path })).filter(m => m);
		const dymanics = Object.assign(...routesMatches.map(rm => rm.params).flat());

		return (
			<Nav variant="tabs" activeKey={pathname}>
				{routes.map((route, index) => {
					const link = makeLinkDynamic(route.path, dymanics);

					return (
						<Nav.Item key={index}>
							<Nav.Link eventKey={link}>
								<LinkContainer to={link}>
									<div>
										{route.title}
									</div>
								</LinkContainer>
							</Nav.Link>
						</Nav.Item>
					);
				})}
			</Nav>
		);
	}

	onInnerComponentUpdate = params => {
		this.buttons.update(params.buttons);
	}

	onInnerComponentMount = params => {
		this.buttons.update(params.buttons);
	}

	render()
	{
		const { routes } = this.props;
		const tabs = this.renderTabs();

		return (
			<div className={styles.wrapper}>
				<div className={cn('w-100', 'd-flex', 'flex-row', 'justify-content-between')}>
					<div className={cn('d-flex', 'flex-row', 'align-items-center')}>
						<ButtonsToolbar
							onMount={api => (this.buttons = api)}
						/>
					</div>
					{tabs}
				</div>
				<div className={cn("flex-grow-1", 'overflow-auto')}>
					<Switch>
						{routes.map((route, key) => {
							/* return (
								<Route
									path={route.path}
									exact={route.exact}
									key={key}
									render={props => {
										return <route.component
											{...props}
											onMount={this.onInnerComponentMount}
											onUpdate={this.onInnerComponentUpdate}
										/>;
									}}
								/>
							); */
							if (route.redirect) {
								return <Redirect
									from={route.path}
									to={route.to}
									key={key}
									exact={route.exact}
								/>;
							}

							return <Route
								path={route.path}
								key={key}
								exact={route.exact}
								//component={route.component}
								render={props => {
									return <route.component
										{...props}
										onMount={this.onInnerComponentMount}
										onUpdate={this.onInnerComponentUpdate}
									/>;
								}}
							/>;
						})}
					</Switch>
				</div>
			</div>
		);
	}
}

export default withRouter(TabsRouter);
