import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import { GridOverlay } from 'components/ui/grid';

import getMainMenuItems from './columnMenu';

import 'ag-grid-community/dist/styles/ag-grid.css';
import './styles/ag-custom.scss';

class CustomGrid extends React.PureComponent
{
	render ()
	{
		const {wrapperStyle, className, ...props} = this.props;

		const gridOptions = {
			loadingOverlayComponent: 'customOverlay',
			...(props.gridOptions || {}),
			frameworkComponents: {
				customOverlay: GridOverlay,
				...(props.gridOptions && props.gridOptions.frameworkComponents || {}),
			},
		};

		return (
			<div
				className={["ag-theme-balham-custom", className].join(' ')}
				style={{
					flex: 1,
					borderRadius: '5px',
					...(wrapperStyle || {}),
				}}
			>
				<AgGridReact
					headerHeight={42}
					rowHeight={38}
					getMainMenuItems={getMainMenuItems}
					{...props}
					gridOptions={gridOptions}
				/>
			</div>
		);
	}
}

export default CustomGrid;
