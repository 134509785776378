import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { Nav } from 'react-bootstrap';

import sendEmail from 'core/sendEmail';
import ProjectEditForm from 'domains/projects/components/ProjectEditForm';
import Grid from './components/Grid';
import { Empty } from 'components/ui';
import { Modal as ModalStatic, NewModal as Modal } from 'components/ui/modals';

import api from 'core/api';
import styles from './style.module.sass';


class List extends React.PureComponent
{
	static propTypes = {
		activeProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
		archivedProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
		employees: PropTypes.arrayOf(PropTypes.object).isRequired,
		goToNewProject: PropTypes.func.isRequired,
	}

	state = {
		editingProject: null,
		modalVisible: false,
		onlyArchivedVsisble: false,
	}

	showArchived = () => this.setState({ onlyArchivedVsisble: true })

	showActive = () => this.setState({ onlyArchivedVsisble: false })

	onTitleCellClicked = params => {
		const id = params?.data?.id;

		if (!id) {
			return;
		}

		this.props.history.push({
			pathname: `/projects/${id}`,
		});
	}

	onUnarchiveProjectClicked = params => {
		this.updateProject(params.id, { isArchived: false });
	}

	onArchiveProjectClicked = params => {
		ModalStatic.confirm({
			onConfirm: () => this.updateProject(params.id, { isArchived: true }),
			title: 'В архив',
			text: `Перенести "${params.title}" в архив?`,
		});
	}

	onEditProjectClicked = params => {
		this.setState({ editingProject: params }, () => {
			this.openModal();
		});
	}

	onCreateProjectClicked = () => {
		this.setState({ editingProject: null }, () => {
			this.openModal();
		});
	}

	closeModal= () => {
		this.setState({ modalVisible: false });
	}

	openModal = () => {
		this.setState({ modalVisible: true });
	}

	onFormCreated = formProps => {
		this.formProps = formProps;
	}

	onFormSubmit = formChanges => {
		if (this.state.editingProject) {
			this.updateProject(this.state.editingProject.id, formChanges);
		} else {
			this.createProject(formChanges);
		}
	}

	updateProject = (projectId, changes) => {
		this.props.dispatch(
			api.projects().update({
				projectId,
				changes,
			}))
			.then(() => {
				this.setState({ modalVisible: false, editingProject: null});
			})
			.catch(error => {
				toast.error("Не удалось обновить данные...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	createProject = projectParams => {
		this.props.dispatch(api.projects().create(projectParams))
			.then(() => {
				sendEmail(
					"zarincheg@gmail.com",
					"Создан новый проект",
					"Новый проект. Ссылка: <a href='https://http://localhost:3000/#/projects/367FB39D-872A-4EDA-A168-98DA2E6B76AF/supplies/estimate/budget'>Проект 123</a>");
				this.setState({ modalVisible: false, editingProject: null});
			})
			.catch(error => {
				toast.error("Не удалось создать проект...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	renderContent = () => {
		const onlyArchived = this.state.onlyArchivedVsisble;
		const { activeProjects, archivedProjects } = this.props;
		const items = onlyArchived ? archivedProjects : activeProjects;

		if (items.length === 0) {
			return (
				<div className="flex-grow-1 d-flex flex-column justify-content-center">
					<Empty title="Список проектов пуст."/>
				</div>
			);
		} else {
			return (
				<Grid
					className="flex-grow-1"
					items={items}
					onTitleCellClicked={this.onTitleCellClicked}
					onEditProjectClicked={this.onEditProjectClicked}
					onArchiveProjectClicked={this.onArchiveProjectClicked}
					onRowDoubleClicked={this.onEditProjectClicked}
					onUnarchiveProjectClicked={this.onUnarchiveProjectClicked}
				/>
			);
		}
	}

	render()
	{

		const modalBtnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.formProps?.handleSubmit(),
				},
			},
			{
				title: 'Отмена',
				props: {
					onClick: this.closeModal
				},
			}
		];

		const content = this.renderContent();
		const editingProject = this.state.editingProject;

		return (
			<React.Fragment>
				<div className={cn('d-flex', 'flex-row', 'justify-content-end', 'p-2')}>
					<Nav variant="pills" className={cn(styles.navSwitch)}>
						<Nav.Item onClick={ this.showActive }>
							<Nav.Link active={!this.state.onlyArchivedVsisble}>В работе</Nav.Link>
						</Nav.Item>
						<Nav.Item onClick={ this.showArchived }>
							<Nav.Link active={this.state.onlyArchivedVsisble}>Архив</Nav.Link>
						</Nav.Item>
					</Nav>
				</div>
				{content}

				<Modal
					title={ editingProject ? 'Редактирование проекта' : 'Новый проект'}
					show={this.state.modalVisible}
					onHide={this.closeModal}
					btnList={modalBtnList}
				>
					<ProjectEditForm
						onFormCreated={this.onFormCreated}
						project={this.state.editingProject}
						onSubmit={this.onFormSubmit}
						onCancel={this.closeModal}
						employees={this.props.employees.map(e => e.user)}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}

export default connect()(withRouter(List));
