import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { toast, Slide } from 'react-toastify';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

import api from 'core/api';
import db from 'core/db';
import Workspace from 'layouts/Default/components/Workspace';
import { Empty, Action } from 'components/ui';
import { Modal } from 'components/ui/modals';
import Grid from '../Grid';
import InvoicesList from '../InvoicesList';
import InvoiceModal from '../InvoiceModal';
import styles from './styles.module.sass';
import RequestExport from './helpers/RequestExport';

class RequestDetails extends React.PureComponent {
	state = {
		orderingItems: [],
		orderModalVisible: false,
		invoiceModalVisible: false,
		loading: false,
		activeModals: {
			edit: false,
			approval: false,
			editInvoice: false,
		},
		editingInvoice: null,
	}

	toggleModal = (name) => {
		const o = {};
		o[name] = !this.state.activeModals[name];
		const activeModals = Object.assign(this.state.activeModals, o);

		this.setState(activeModals);
	}

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	updateItem = (id, changes) => {
		this.props.dispatch(api.tbsPurchaseRequestItems().update({
			purchaseRequestItemId: id,
			changes,
		})).catch(error => {
			this.showError("Не удалось внести изменения");
			console.error(error);
		});
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.updateItem(params.node.data.id, changes);
	}

	removeInvoice = (order) => {
		const removeOrder = (order) => {
			this.props.dispatch(api.tbsPurchaseOrders().update({
				purchaseOrderId: order.id,
				changes: {
					isDeleted: true,
				}
			})).catch((err) => {
				this.showError("Не удалось удалить счёт");
				console.error(err);
			});
		};

		const removeInvoice = (invoice) => {
			this.props.dispatch(api.tbsPurchaseInvoices().update({
				purchaseInvoiceId: invoice.id,
				changes: {
					isDeleted: true,
				}
			})).catch((err) => {
				console.error(err);
			});
		};

		Modal.confirm({
			title: 'Удаление счёта',
			text: `Удалить счёт №"${order.invoice.number}" ?`,
			onConfirm: () => {
				removeOrder(order);
				removeInvoice(order.invoice);
			}
		});
	}

	rejectItem = requestItem => {
		// TODO: BRGD-425
		this.props.dispatch(api.tbsPurchaseRequestItems().update({
			purchaseRequestItemId: requestItem.id,
			changes: {
				isApproved: false
			}
		})).catch(err => {
			this.showError("Не удалось отменить позицию заявки.");
			console.error(err);
		});
	}

	aggregateRequest = () => {
		const {
			requestId,
			consumableSheetItemsMap,
			purchaseRequestItems,
			purchaseOrderItems,
			purchaseOrdersMap,
			purchaseRequestsMap,
			purchaseInvoices
		} = this.props;

		// Собираем позиции заказа для выбранной заявки, которые не находятся в удалённых заказах (фильтр isDeleted возможно не нужен, но сервак не размечает позиции заказа как удаленные автоматически)
		const orderItems = purchaseRequestItems.flatMap((requestItem) => {
			// TODO: Вынести наружу и заменить на предварительное создание Map() чтобы вместо фильтра делать .get(purchaseRequestItemId)
			const items = purchaseOrderItems.filter(orderItem => orderItem.purchaseRequestItemId === requestItem.id);

			// Добавляем информацию о позиции заказа из сметы и соответствующую позицию заявки
			return items.map((item) => {
				return {
					...item,
					consumableSheetItem: consumableSheetItemsMap.get(requestItem.consumableSheetItemId),
					requestItem,
				};
			});
		}).filter(orderItem => {
			return purchaseOrdersMap.has(orderItem.purchaseOrderId) && !purchaseOrdersMap.get(orderItem.purchaseOrderId).isDeleted;
		});

		let orders = {};
		let isRequestCompleted = false;

		// Группировка позиций заказов по заказам (собираются заказы)
		orderItems.forEach((orderItem) => {
			if (!orders[orderItem.purchaseOrderId]) {
				orders[orderItem.purchaseOrderId] = {
					id: orderItem.purchaseOrderId,
					items: [],
					invoice: null
				};
			}

			orders[orderItem.purchaseOrderId].items.push(orderItem);
		});

		// Добавляются счета к заказам и фильтруются только по тем, где счета есть
		orders = Object.values(orders).map((order) => {
			order.invoice = purchaseInvoices.filter(i => i.purchaseOrderId === order.id)[0];

			return order;
		}).filter(o => o.invoice);

		isRequestCompleted = orderItems.every(item => item.isApproved) && orders.length > 0 && orders.every(order => order.invoice && !order.invoice.isWaitingForApprove);

		// Сборка заявки
		return {
			...purchaseRequestsMap.get(requestId),
			// Добавляем информацию о позициях заявки и дополняем их информацией о позициях из сметы
			items: purchaseRequestItems.map(item => ({
				...item,
				consumableSheetItem: consumableSheetItemsMap.get(item.consumableSheetItemId),
				orderItems: orderItems.filter(orderItem => orderItem.purchaseRequestItemId === item.id)
			})), //.filter(item => item.isApproved), TODO: когда будет отмена нормально работать добавить
			orders,
			isRequestCompleted
		};
	}

	exportXLSX = (request) => {
		const xlsx = new RequestExport().createXLSX(request);
		xlsx.save(`Заявка №${request?.sn}.xlsx`);
	}


	render() {
		if (this.props.isLoading) {
			return <Empty
				className={cn('w-100', 'h-100')}
				title="Загружается список позиций заявки..."
			/>;
		}

		const request = this.aggregateRequest();

		return (
			<Workspace titleField="sn">
				<div className={cn('d-flex', 'flex-row', 'justify-content-between', styles.info)}>
					<div><span className={styles.title}>Ответственный</span>{request.assignedUser?.fullName || request.assignedUser?.phoneNumber || null}</div>
					<Action
						icon={faFileExcel}
						size="lg"
						style={{ color: "#48B95A" }}
						onClick={() => this.exportXLSX(request)}
						tooltip="Экспорт Excel"
					/>
				</div>
				<Grid
					items={request.items}
					onAddToOrder={this.onAddToOrder}
					consumableSheet={this.props.consumableSheet}
					onCellValueChanged={this.onCellValueChanged}
					onRejectItem={ this.rejectItem }
					isRequestCompleted={request.isRequestCompleted}
				/>
				<div style={{ flex: 1 }}>
					<InvoicesList
						orders={request.orders}
						currenciesMap={this.props.currenciesMap}
						onRemoveItem={this.removeInvoice}
						onEditItem={item => this.setState({ editingInvoice: item }, () => {
							this.toggleModal('editInvoice');
						})}
						employees={this.props.employees}
						isRequestCompleted={request.isRequestCompleted}
					/>
					<Button onClick={() => this.toggleModal('edit')} disabled={!request.assignedUser || request.isRequestCompleted}>Добавить</Button>
				</div>
				<InvoiceModal
					projectId={this.props.projectId}
					visible={this.state.activeModals.editInvoice}
					onClose={() => this.toggleModal('editInvoice')}
					currencies={this.props.currencies}
					currenciesMap={this.props.currenciesMap}
					requestItems={this.state.editingInvoice ? this.state.editingInvoice.items : []}
					employees={this.props.employees}
					initialValues={this.state.editingInvoice ? {
						...this.state.editingInvoice.invoice,
						requestItems: this.state.editingInvoice.items,
					} : {}}
					changesOnly={true}
					invoice={this.state.editingInvoice}
				/>
				<InvoiceModal
					projectId={this.props.projectId}
					visible={this.state.activeModals.edit}
					onClose={() => this.toggleModal('edit')}
					currencies={this.props.currencies}
					currenciesMap={this.props.currenciesMap}
					requestItems={request.items.filter(item => !item.orderItems.length)}
					employees={this.props.employees}
				/>
			</Workspace>
		);
	}
}

export default connect((state, props) => {
	const projectId = props.match.params.projectId;
	const requestId = props.match.params.requestId;

	// Нужна для таблицы, чтобы взять оттуда курсы валют
	const consumableSheet = db.tbsConsumableSheets.list({ filter: { projectId }})[0];

	const consumableSheetFilter = { filter: { consumableSheetId: consumableSheet?.id } };

	// Нужно в первую очередь для проверки на isLoading
	const collects = {
		consumableSheetItems: db.tbsConsumableSheetItems.listNotDeleted(consumableSheetFilter),
		purchaseRequests: db.tbsPurchaseRequests.listNotDeleted(consumableSheetFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.listNotDeleted({ filter: { purchaseRequestId: requestId }}),
		purchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		purchaseOrders: db.tbsPurchaseOrders.listNotDeleted(),
		currencies: db.currencies.list(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted(),
		employees: db.employees.list(),
	};

	const maps = {
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		purchaseOrdersMap: collects.purchaseOrders.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
		currenciesMap: collects.currencies.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	return {
		me: state.me,
		consumableSheet,
		projectId,
		requestId,
		...collects,
		...maps
	};
})(RequestDetails);
