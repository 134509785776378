import React from 'react';
import Loading from './Loading';


const withLoading = (Component) => {

	class LoadingComponent extends React.PureComponent
	{
		render()
		{
			const { isLoading, loadingTitle } = this.props;

			if (isLoading) {
				return (
					<div className="h-100 w-100 d-flex align-items-center justify-content-center">
						<Loading title={loadingTitle}/>
					</div>
				);
			}

			return <Component {...this.props}/>;
		}
	}

	return LoadingComponent;
};

export default withLoading;
