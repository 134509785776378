import {
	faSuitcase,
	faChartPie,
	faLayerGroup,
	faFileExcel,
	faStream,
	faTruck,
	faClipboardCheck
} from '@fortawesome/free-solid-svg-icons';

import { MenuItem, MenuItemSeparator } from '../types.js';


const projectMenu = [
	new MenuItem({
		label: 'Аналитика',
		routes: ['/projects/:projectId/report'],
		link: '/projects/:projectId/report',
		faIcon: faChartPie,
	}),
	new MenuItem({
		label: 'План',
		routes: ['/projects/:projectId/plan'],
		link: '/projects/:projectId/plan/scope',
		faIcon: faStream,
	}),
	new MenuItemSeparator(),
	new MenuItem({
		label: 'Смета',
		routes: ['/projects/:projectId/supplies/estimate'],
		link: '/projects/:projectId/supplies/estimate/budget',
		faIcon: faFileExcel,
	}),
	new MenuItem({
		label: 'Заявки',
		routes: ['/projects/:projectId/supplies/requests'],
		link: '/projects/:projectId/supplies/requests',
		faIcon: faLayerGroup,
	}),
	new MenuItem({
		label: 'Реестр заявок',
		routes: ['/projects/:projectId/supplies/delivery-tracking'],
		link: '/projects/:projectId/supplies/delivery-tracking',
		faIcon: faTruck,
	}),
	new MenuItem({
		label: 'Согласования',
		routes: ['/projects/:projectId/supplies/approval'],
		link: '/projects/:projectId/supplies/approval/amount',
		faIcon: faClipboardCheck,
	})
];

export const menu = [
	new MenuItem({
		label: "Проекты",
		routes: [
			'/projects',
			'/projects/:projectId',
			'/new-project',
		],
		link: '/projects',
		faIcon: faSuitcase,
		submenu: projectMenu,
	}),
	/* new MenuItem({
		label: "Сотрудники",
		routes: ['/employees'],
		link: '/employees',
		faIcon: faUsers,
	}),
	new MenuItem({
		label: "Компания",
		routes: ['/company'],
		link: '/company',
		faIcon: faBuilding,
	}),
	new MenuItem({
		label: "Личный кабинет",
		routes: ['/user'],
		link: '/user',
		faIcon: faUser,
	}), */
];
