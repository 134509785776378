import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';


class ColumnsControl extends React.PureComponent
{
	render() {
		const columns = this.props.columns.filter(col => col.colDef.field);
		const onChange = (e, col) => {
			this.props.setColumnVisible(col, e.target.checked);
			this.forceUpdate();
		};

		return (
			<Form.Group>
				{columns.map(col => {
					return (
						<Form.Check
							key={col.colId}
							type="checkbox"
							label={col.colDef.headerName}
							checked={col.visible}
							onChange={(e) => onChange(e, col)}
						/>
					);
				})}
			</Form.Group>
		);
	}
}

ColumnsControl.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	setColumnVisible: PropTypes.func.isRequired,
};

export default ColumnsControl;
