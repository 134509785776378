import React from 'react';
import PropTypes from 'prop-types';
import { CustomGrid } from 'components/ui/grid';
import { UnitsCellEditor } from 'components/ui/grid/editors';


class ParseGrid extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		invoiceTitles: PropTypes.arrayOf(PropTypes.string),
	};

	static defaultProps = {
		items: [],
		invoiceTitles: [],
	}

	onGridReady = params => {
		this.gridApi = params.api;
		this.props.onGridReady(params);
	}

	onFirstDataRendered = params => {
		params.api.sizeColumnsToFit();
	}

	render()
	{
		const { invoiceTitles } = this.props;

		const columnDefs = [
			{
				headerName: 'Позиции в смете',
				field: 'consumableSheetItem.title',
			},
			{
				headerName: 'Позиции в счёте',
				field: 'invoiceItemTitle',
				cellEditor: 'unitsCellEditor',
				cellEditorParams: {
					units: invoiceTitles.map(title => ({ title })),
					keyField: 'title',
					valueField: 'title'
				},
				editable: true,
			}
		];

		const frameworkComponents = {
			unitsCellEditor: UnitsCellEditor,
		};

		const gridOptions = {
			frameworkComponents: frameworkComponents,
		};

		const { items } = this.props;

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={items}
				suppressRowClickSelection={true}
				onGridReady={this.onGridReady}
				onFirstDataRendered={this.onFirstDataRendered}
				suppressCellSelection={true}
			/>
		);
	}
}

export default ParseGrid;
