import React from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';


class StatusCellRenderer extends React.PureComponent
{
	static propTypes = {
		variant: PropTypes.string
	}

	static defaultProps = {
		variant: 'secondary'
	}

	render() {
		const status = this.props.value;

		return (
			<div>
				<Badge pill variant={this.props.variant}>
					{status}
				</Badge>
			</div>
		);
	}
}

export default StatusCellRenderer;

