// import React from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import DatePicker, { registerLocale } from 'react-datepicker';

// import ru from "date-fns/locale/ru";
// import 'react-datepicker/dist/react-datepicker.css';
// import { Form } from 'react-bootstrap';

// registerLocale("ru", ru);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import ru from "date-fns/locale/ru";
import 'react-datepicker/dist/react-datepicker.css';

registerLocale("ru", ru);


const TimePicker = (props) => {
	const [time, setTime] = useState(null);

	useEffect(() => {
		setTime(props.intialTime);
	}, []);

	const onTimeChange = date => {
		props.onChange(date);
		if (!props.controlled) {
			setTime(date);
		}
	};

	const t = props.controlled ? props.value : time;

	return <ReactDatePicker
		{...props}
		selected={t}
		onChange={time => onTimeChange(time)}
		locale="ru"
		dateFormat="HH:mm"
		showTimeSelect
		showTimeSelectOnly
		timeFormat="HH:mm"
		timeIntervals={30}
		timeCaption="Время"
	/>;
};

TimePicker.propTypes = {
	onChange: PropTypes.func,
	intialTime: PropTypes.any,
	value: PropTypes.any,
	controlled: PropTypes.bool,
};

TimePicker.defaultProps = {
	onChange: () => null,
	controlled: false,
	value: null,
};

export default TimePicker;


// class TimePicker extends React.PureComponent
// {
// 	state = {
// 		date: null,
// 	}

// 	onChange = date => {
// 		const { onChange, formatter } = this.props;

// 		this.setState({ date }, () => {
// 			if (onChange) {
// 				onChange(formatter ? formatter(date) : date);
// 			}
// 		});
// 	}

// 	render() {
// 		const { initialValue, timeFormat, placeholder, timeIntervals, timeCaption, locale } = this.props;

// 		let intitalDate = null;

// 		if (initialValue) {
// 			const padNumber = number => number.toString().padStart(2, '0');
// 			const { minutes, hours, seconds } = initialValue;
// 			const date = moment().format('YYYY-MM-DD');
// 			const initialDateStr = `${date}-${padNumber(hours)}-${padNumber(minutes)}-${padNumber(seconds)}`;
// 			intitalDate = moment(initialDateStr, 'YYYY-MM-DD-HH-mm-SS').toDate();
// 		}

// 		return (
// 			<Form.Control
// 				as={DatePicker}
// 				selected={this.state.date || intitalDate}
// 				onChange={date => this.onChange(date)}
// dateFormat={timeFormat}
// 				placeholderText={placeholder}
// 				showTimeSelect
// 				showTimeSelectOnly
// 				timeIntervals={timeIntervals}
// 				timeCaption={timeCaption}
// 				locale={locale}
// 			/>
// 		);
// 	}
// }

// TimePicker.propTypes = {
// 	locale: PropTypes.string,
// 	timeFormat: PropTypes.string,
// 	placeholder: PropTypes.string,
// 	onChange: PropTypes.func,
// 	timeIntervals: PropTypes.number,
// 	timeCaption: PropTypes.string,
// 	initialValue: PropTypes.shape({
// 		hours: PropTypes.number.isRequired,
// 		minutes: PropTypes.number.isRequired,
// 		seconds: PropTypes.number.isRequired,
// 	}),
// 	formatter: PropTypes.func,
// };

// TimePicker.defaultProps = {
// 	locale: "ru",
// 	onChange: () => {},
// 	timeFormat: "HH:mm:ss",
// 	timeIntervals: 30,
// 	timeCaption: 'Время',
// 	formatter: date => moment(date).format('HH:mm:ss'),
// };

// export default TimePicker;
