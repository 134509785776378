import React from 'react';
import { connect } from 'react-redux';

import db from 'core/db';
import { withLoading } from 'components/ui';

import UploadView from './components/UploadView';
import EditView from './components/EditView';
import Formatter from './helpers/Formatter';


class NewProject extends React.PureComponent
{
	state = {
		currentIndex: 0,
		items: [],
	}

	onContinue = fileList => {
		const formatter = new Formatter({ fileList });
		const items = formatter.format();
		this.setState({ items, currentIndex: 1 });
	}

	goToProjects = () => {
		//this.timer = setTimeout(() => {
		this.props.history.push({
			pathname: '/projects',
		});
		//});
	}

	componentWillUnmount()
	{
		if (this.timer) {
			delete this.timer;
		}
	}

	render()
	{
		const indexMap = {
			0: <UploadView
				onContinue={this.onContinue}
				employees={this.props.employees}
				onCreateProject={this.goToProjects}
			/>,
			1: <EditView
				items={this.state.items}
				jobUnits={this.props.jobUnits}
				employees={this.props.employees}
				onConfirmImport={this.goToProjects}
			/>,
		};

		const { currentIndex } = this.state;

		return indexMap[currentIndex];
	}
}

const mapToProps = (state, props) => {
	const jobUnits = db.jobUnits.list();
	const employees = db.employees.list();
	const isLoading = jobUnits.isLoading || employees.isLoading;

	return {
		isLoading,
		jobUnits,
		employees,
	};
};

export default connect(mapToProps)(withLoading(NewProject));
