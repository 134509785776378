import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import uuidv4 from 'uuid/v4';
import { toast } from 'react-toastify';
import {
	startOfWeek, add, sub,
	parseISO, formatISO,
	differenceInCalendarDays,
	eachDayOfInterval,
	startOfDay, endOfDay,
	getUnixTime,
	isWithinInterval,
} from 'date-fns';

import db from 'core/db';
import api from 'core/api';

import { Modal as BasicModal } from 'components/ui/modals';
import { Empty, DateRangePicker, Select } from 'components/ui';
import Timeline, { TimelineHeaders, DateHeader, SidebarHeader } from 'react-calendar-timeline';

import WorkerModal from './components/WorkerModal';
import WorkerDayModal from './components/WorkerDayModal';
import Worker from './components/Worker';
import Workspace from 'layouts/Default/components/Workspace';

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-calendar-timeline/lib/Timeline.css';

import style from './style.module.sass';
import ReportGenerator from './helpers/ReportGenerator';
import ReportFormatter from './helpers/ReportFormatter';


class TimeSheet extends React.PureComponent
{
	constructor(params)
	{
		super(params);

		const now = new Date();
		const startDate = sub(startOfWeek(now), { weeks: 1 });
		const endDate = add(startDate, { weeks: 2 });

		this.state = {
			dateRange: [startDate, endDate],
			workersFilter: null,
			projectsFilter: null,
			positionFilter: null,
			workerModalVisible: false,
			timeModalVisible: false,
			workerDayEditing: null,
			workerEditing: null,
		};
	}

	createWorker = params => {
		this.props.dispatch(api.workers().create({
			workerId: uuidv4().toUpperCase(),
			companyId: this.props.companyId,
			...params,
		}))
			.then(() => this.closeWorkerModal())
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось создать сотрудника", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	updateWorker = (workerId, changes) => {
		this.props.dispatch(api.workers().update({
			workerId,
			changes,
		}))
			.then(() => this.closeWorkerModal())
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось обновить данные", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	createWorkerDay = params => {
		const projectId = params.projectId || this.props.workersMap.get(params.workerId).projectId;
		this.props.dispatch(api.workerDays().create({
			workerDayId: uuidv4().toUpperCase(),
			...params,
			projectId,
		}))
			.then(() => this.closeTimeModal())
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось создать отчёт о присутствии", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	updateWorkerDay = (workerDayId, changes) => {
		this.props.dispatch(api.workerDays().update({
			workerDayId,
			changes,
		}))
			.then(() => this.closeTimeModal())
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось обновить данные", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	removeWorker = worker => {
		this.props.dispatch(api.workers().update({
			workerId: worker.id,
			changes: {
				isDeleted: true,
			}
		}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось удалить сотрудника", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	onItemSelect = (id, e, time) => {
		const { workerDaysMap } = this.props;
		let workerDay = workerDaysMap.get(id);

		if (!workerDay) {
			const params = id.split(':'); //! shit parameters extraction
			const workerId = params[0];
			const projectId = this.props.workersMap.get(workerId).projectId;
			const date = params[1];
			workerDay = { workerId, date, isAbsence: true, projectId };
		}

		this.setState({ workerDayEditing: workerDay }, () => this.openTimeModal());
	}

	openWorkerModal = () => this.setState({ workerModalVisible: true })

	closeWorkerModal = () => this.setState({ workerModalVisible: false })

	openTimeModal = () => this.setState({ timeModalVisible: true })

	closeTimeModal = () => this.setState({ timeModalVisible: false })

	renderGroupHeader = props => {
		const { getRootProps } = props;

		return (
			<div {...getRootProps()}>
				<div className={cn("row", "no-gutters", "text-center", style.columns)}>
					<div className="col-5"/>
					<div className="col">
						Присутствие
					</div>
					<div className="col">
						Итого
					</div>
					<div className="col">
						В день
					</div>
				</div>
			</div>
		);
	}

	renderGroup = props => {
		const { group } = props;
		const { id, worker } = group;
		const { workersDayByWorkerIdMap, projectsMap } = this.props;
		const [endOfTimeline, startOfTimeline] = this.state.dateRange;
		const days = (workersDayByWorkerIdMap.get(id) || [])
			.filter(d => (d.date
				&& d.date <= formatISO(endOfTimeline, { representation: 'date' })
				&& d.date >= formatISO(startOfTimeline, { representation: 'date' }))

			);
		const totalDays = differenceInCalendarDays(startOfTimeline, endOfTimeline);

		const totalSeconds = days.reduce((acc, day) => {
			if (!day.timeWorked || day.isAbsence) {
				return acc;
			}

			const time = day.timeWorked.split(':');

			return acc + Number(time[0]) * 3600 + Number(time[1]) * 60 + Number(time[2]);
		}, 0);

		const workedHours = Number(totalSeconds / 3600).toFixed(1);
		const averageHours = Number(workedHours / totalDays).toFixed(1);

		const daysOnWork = days.filter(d => !d.isAbsence && d.timeWorked).length;

		return (
			<div className={cn('row', 'no-gutters', 'text-center')}>
				<div className={cn('col-5', 'text-left')}>
					<Worker
						className={cn('h-100', 'w-100')}
						worker={worker}
						projects={projectsMap}
						actions={[
							{
								icon: faTrash,
								hoverStyle: {
									color: 'red',
								},
								onClick: () => BasicModal.confirm({
									title: 'Удаление сотрудника',
									text: `Удалить ${worker.name}?`,
									onConfirm: () => this.removeWorker(worker),
								})
							},
							{
								icon: faEdit,
								onClick: () => this.setState({ workerEditing: worker }, () => this.openWorkerModal())
							}
						]}
					/>
				</div>
				<div className="col">
					{`${daysOnWork}/${totalDays} д`}
				</div>
				<div className="col">
					{`${workedHours} ч`}
				</div>
				<div className="col">
					{`${averageHours} ч`}
				</div>
			</div>
		);
	}

	getTitle = workerDay => {
		if (!workerDay) {
			return '';
		}

		if (this.state.projectsFilter && workerDay.projectId !== this.state.projectsFilter.value) {
			return '';
		}

		if (workerDay.isAbsence || !workerDay.timeWorked) {
			return '';
		}

		const wt = workerDay.timeWorked.split(':');
		const hoursWorked = Number(wt[0]) + Number(wt[1]) / 60;

		return `${Number(hoursWorked).toFixed(1)} ч.`;
	}

	getWorkerDay = (group, date) => {
		const { workerDays } = this.props;

		const day = workerDays.find(wd => wd.workerId === group.worker.id && wd.date === date);

		if (!this.state.projectsFilter) {
			return day;
		}

		return day && this.state.projectsFilter.value === day.projectId ? day : null;
	}

	generateItems = groups => {
		const [startOfTimeline, endOfTimeline] = this.state.dateRange;

		const items = [];

		if (!groups.length) {
			return items;
		}

		for (const g of groups) {
			for (const day of eachDayOfInterval({ start: startOfTimeline, end: endOfTimeline })) {
				const date = formatISO(day, { representation: 'date' });
				const workerDay = this.getWorkerDay(g, date);
				const title = this.getTitle(workerDay);
				const id = workerDay ? workerDay.id : `${g.worker.id}:${date}`;

				items.push({
					id,
					title,
					group: g.id,
					start_time: startOfDay(day),
					end_time: endOfDay(day),
					canMove: false,
					canResize: false,
					canChangeGroup: false,
					itemProps: {
						className: cn(
							{[style.item]: workerDay && !workerDay.isAbsence},
							{[style.itemAbsence]: workerDay?.isAbsence},
							{[style.itemEmpty]: !workerDay},
						),
					}
				});
			}
		}

		return items;
	}

	getGroups = () => {
		const { workers, workerDays } = this.props;
		const { workersFilter, positionFilter, projectsFilter, dateRange } = this.state;
		const [startOfTimeline, endOfTimeline] = dateRange;

		let groups = workers.map(w => ({
			id: w.id,
			title: w.name,
			stackItems: true,
			worker: w,
		}));

		if (projectsFilter) {
			const projectId = projectsFilter.value;

			groups = groups.filter(g => {
				const { worker } = g;
				const isWorkerBindedToProject = worker.projectId === projectId;
				const workerHasHours = workerDays.filter(wd => (
					wd.projectId === projectId &&
					wd.workerId === worker.id &&
					isWithinInterval(parseISO(wd.date), { start: startOfTimeline, end: endOfTimeline})
				)).length;

				return isWorkerBindedToProject || workerHasHours;
			});
		}

		if (workersFilter) {
			groups = groups.filter(g => workersFilter.value === g.id);
		}

		if (positionFilter) {
			if (positionFilter.value === null || positionFilter.value === '') {
				groups = groups.filter(g => !g.worker.position);
			} else {
				groups = groups.filter(g => positionFilter.value === g.worker.position);
			}
		}

		return groups;
	}

	exportXLSX = () => {
		const now = new Date();

		const { workers, workerDays, projectsMap } = this.props;
		const { projectsFilter } = this.state;

		const project = projectsMap.get(projectsFilter.value);

		const reportGenerator = new ReportGenerator(
			workers,
			workerDays,
		);

		const start = project.estimatedStartDate ? parseISO(project.estimatedStartDate) : now;
		const end = now;

		const report = reportGenerator.generate(project, start, end);
		const xlsx = ReportFormatter.createXLSX(project, report, projectsMap);

		xlsx.save("Табель.xlsx");
	}

	render()
	{
		const { workers, isLoading, projects } = this.props;
		const [startOfTimeline, endOfTimeline] = this.state.dateRange;

		const empty = <Empty className={cn('w-100', 'h-100')}/>;

		if (isLoading) {
			return empty;
		}

		const groups = this.getGroups().sort((a, b) => (a.title < b.title ? -1 : 1));
		const items = this.generateItems(groups);

		const {
			workerModalVisible,
			timeModalVisible,
			workerDayEditing,
			workerEditing,
		} = this.state;

		const projectSelectOptions = this.props.projects.map((value) => {
			return {
				value: value.id,
				id: value.id,
				label: value.title
			};
		});

		const actions = [
			{
				title: 'Добавить рабочего',
				onClick: () => this.setState({ workerEditing: null }, () => this.openWorkerModal())
			},
			{
				title: 'Записать время',
				onClick: () => this.setState({ workerDayEditing: null }, () => this.openTimeModal())
			},
			{
				title: 'Экспорт',
				onClick: this.exportXLSX,
				disabled: !this.state.projectsFilter
			}
		];

		const positions = Array.from(new Set([...workers.filter(w => w.position).map(w => w.position.trim())]));

		return (
			<Workspace actions={actions} hideTitle={true}>
				<WorkerModal
					worker={workerEditing}
					projects={projectSelectOptions}
					visible={workerModalVisible}
					onCancel={() => this.closeWorkerModal()}
					onConfirm={(workerId, changes) => (workerId ? this.updateWorker(workerId, changes) : this.createWorker(changes))}
				/>
				<WorkerDayModal
					visible={timeModalVisible}
					onConfirm={(workerDayId, changes) => (workerDayId ? this.updateWorkerDay(workerDayId, changes) : this.createWorkerDay(changes))}
					onCancel={() => this.closeTimeModal()}
					workers={workers}
					workerDay={workerDayEditing}
					projects={projects}
				/>
				<div className={style.wrapper}>
					<div className={cn('d-flex', 'flex-row', 'w-100', 'mb-2', 'pl-2')}>
						<div style={{ minWidth: '220px' }}>
							<Select
								options={workers.map(w => ({ value: w.id, label: w.name }))}
								value={this.state.workersFilter}
								onChange={workersFilter => this.setState({ workersFilter })}
								placeholder="Выберите сотрудника"
								isClearable
							/>
						</div>
						<div style={{ minWidth: '220px' }} className="ml-2">
							<Select
								options={projects.map(w => ({ value: w.id, label: w.title }))}
								value={this.state.projectsFilter}
								onChange={projectsFilter => this.setState({ projectsFilter })}
								placeholder="Выберите проект"
								isClearable
							/>
						</div>
						<div style={{ minWidth: '220px' }} className="ml-2">
							<Select
								options={[
									...positions.map(p => ({ value: p, label: p })),
									{ value: '', label: 'Без должности' }
								]}
								value={this.state.positionFilter}
								onChange={positionFilter => this.setState({ positionFilter })}
								placeholder="Выберите должность"
								isClearable
							/>
						</div>
						<DateRangePicker
							className="ml-2 text-align-center"
							onDatesChange={dateRange => this.setState({ dateRange })}
							dates={this.state.dateRange}
							controlled
						/>
					</div>
					{groups.length ? <Timeline
						// Похоже на баг react-calendar-timeline
						// Если масссив групп пустой то после не рендерит item'ы на самом таймлайне
						style={{ zIndex: 0 }}
						groups={groups}
						items={items}
						visibleTimeStart={getUnixTime(startOfTimeline) * 1000}
						visibleTimeEnd={getUnixTime(endOfTimeline) * 1000}
						canMove={false}
						canResize={false}
						stackItems
						lineHeight={50}
						itemHeightRatio={1}
						sidebarWidth={700}
						groupRenderer={props => this.renderGroup(props)}
						onItemSelect={(id, e, time) => this.onItemSelect(id, e, time)}
					>
						<TimelineHeaders>
							<SidebarHeader>
								{props => this.renderGroupHeader(props)}
							</SidebarHeader>
							<DateHeader
								unit="day"
								intervalRenderer={props => {
									const { getIntervalProps, intervalContext } = props;

									return (
										<div
											{...getIntervalProps()}
											className={cn('text-center', 'text-truncate')}
										>
											{intervalContext.interval.startTime.format('DD.MM')}
										</div>
									);
								}}
							/>
						</TimelineHeaders>
					</Timeline> : empty}
				</div>
			</Workspace>
		);
	}
}

const mapToProps = (state, props) => {
	const companyId = state.me.defaultEmployee.companyId;
	const filter = { companyId };

	const collects = {
		workers: db.workers.listNotDeleted({ filter }),
		workerDays: db.workerDays.list(),
		projects: db.projects.listNotArchived(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	const workersDayByWorkerIdMap = new Map();

	for (const wd of collects.workerDays) {
		const days = workersDayByWorkerIdMap.get(wd.workerId);

		if (days) {
			days.push(wd);
		} else {
			workersDayByWorkerIdMap.set(wd.workerId, [wd]);
		}
	}

	return {
		isLoading,
		companyId,
		workersDayByWorkerIdMap,
		workersMap: collects.workers.hashById(),
		workerDaysMap: collects.workerDays.hashById(),
		projectsMap: db.projects.list().hashById(),
		...collects,
	};
};

export default connect(mapToProps)(TimeSheet);
