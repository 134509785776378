
import Profile from './components/Profile';

const routes = [
	{
		path: '/user',
		title: 'Личный кабинет',
		exact: true,
		redirect: true,
		to: '/user/profile',
		children: [
			{
				path: "/profile",
				title: 'Профиль',
				component: Profile,
			},
		],
	},
];

export default routes;
