import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';


class User extends React.PureComponent
{
	static propTypes = {
		user: PropTypes.shape({
			fullName: PropTypes.string,
			phoneNumber: PropTypes.string,
			email: PropTypes.string,
		})
	}

	static defaultProps = {
		user: {},
	}

	render()
	{
		const { user, style, ...otherProps } = this.props;

		return <Badge
			variant="secondary"
			style={{
				fontWeight: '400',
				fontSize: '10px',
				padding: '2px 5px',
				...style
			}}
			{...otherProps}
		>
			{user.fullName || user.email || user.phoneNumber || 'noname'}
		</Badge>;
	}
}

export default User;
