import React from 'react';
import styles from './style.module.sass';
import cn from 'classnames';
import PropTypes from 'prop-types';


class Card extends React.Component
{
	static propTypes = {
		layout: PropTypes.oneOf(['row', 'column']),
		title: PropTypes.string,
		renderFooter: PropTypes.func,
	}

	static defaultProps = {
		layout: 'column',
	}

	renderHeader()
	{
		const { title } = this.props;

		if (!title) {
			return null;
		}

		return <div className={styles.Header}>
			<div className={styles.Header_label}>
				{title}
			</div>
		</div>;
	}

	renderContent()
	{
		const { layout, children } = this.props;

		const className = cn(
			styles.Content,
			{ [styles.Content__row]: layout === 'row' },
			{ [styles.Content__column]: layout === 'column' },
		);

		return <div className={className}>
			{children}
		</div>;
	}

	renderFooter()
	{
		const { renderFooter } = this.props;

		if (!renderFooter) {
			return null;
		}

		return <div className={styles.Footer}>
			{renderFooter()}
		</div>;
	}

	render()
	{
		const { style } = this.props;

		return (
			<div className={styles.Card} style={style}>
				{this.renderHeader()}
				{this.renderContent()}
				{this.renderFooter()}
			</div>
		);
	}
}

export default Card;
