import React from 'react';
import PropTypes from 'prop-types';
import FormikForm from 'components/ui/forms/formik/Form';


class WorkerDayForm extends React.PureComponent {

	static propTypes = {
		onFormCreated: PropTypes.func,
		onSubmit: PropTypes.func,
		workers: PropTypes.arrayOf(PropTypes.object),
		workerDay: PropTypes.object,
		projects: PropTypes.array,
	}

	static defaultProps = {
		workers: [],
		workerDay: {},
	}

	render() {
		const { worker, projects, onFormCreated, onSubmit } = this.props;
		const isEditing = !!worker?.id;

		const simplifiedSchema = [
			[{ id: 'name', type: 'text', label: 'Имя сотрудника' }],
			[{ id: 'position', type: 'text', label: 'Специализация или должность' }],
			[{ id: 'projectId', type: 'select', label: 'Проект' }],
		];

		const inputsProps = {
			name: { placeholder: "Введите имя работника" },
			position: { placeholder: "Введите должность работника" },
			projectId: {
				placeholder: "Изменить проект для работника",
				options: projects
			}
		};

		return <FormikForm
			simplifiedSchema={simplifiedSchema}
			inputsProps={inputsProps}
			onFormCreated={props => onFormCreated(props)}
			initialValues={worker}
			onSubmit={changes => onSubmit(changes)}
			changesOnly={isEditing}
		/>;
	}
}

export default WorkerDayForm;
