import React from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import style from './style.module.sass';


class CodeForm extends React.PureComponent
{
	static propTypes = {
		onConfirmCode: PropTypes.func,
		onSendCodeAgain: PropTypes.func,
		codeConfirmError: PropTypes.bool,
		isCodeTimeout: PropTypes.bool,
	}

	static defaultrops = {
		codeConfirmError: false,
	}

	constructor(props)
	{
		super(props);
		this.inputRef = React.createRef();
	}

	componentDidMount()
	{
		this.inputRef.current.focus();
	}

	onChanged = code => {
		const regExp = new RegExp('^\\d{4}$');

		if (regExp.test(code)) {
			this.props.onConfirmCode(code);
		}
	}

	render()
	{
		const { codeConfirmError, isCodeTimeout } = this.props;

		return (
			<Form className={cn('w-100', style.formCustom)}>
				<Form.Group>
					<Form.Label>
						Введите 4-х значный код
					</Form.Label>
					<Form.Control
						ref={this.inputRef}
						type="number"
						onChange={e => this.onChanged(e.target.value)}
						isInvalid={codeConfirmError}
					/>
					<Form.Control.Feedback type="invalid">
						Неверный код
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group>
					<Form.Label
						className={cn(
							{ [style.textLink]: !isCodeTimeout },
							{ [style.textLinkDisabled]: isCodeTimeout },
						)}
						onClick={() => {
							this.props.onSendCodeAgain();
							this.inputRef.current.focus();
						}}
					>
						Отправить ещё раз
					</Form.Label>
				</Form.Group>
			</Form>
		);
	}
}

export default CodeForm;


