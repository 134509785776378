import React from 'react';
import { Badge } from 'react-bootstrap';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	AttachedFilesCellRenderer,
	ActionsCellRenderer,
	EmptyCellRenderer
} from 'components/ui/grid/renderers';

import { numberFormatter } from 'components/ui/grid/formatters';
import ContextActionsCellRenderer from './components/ContextActionsCellRenderer';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

export const getGridOptions = params => {
	const { currenciesMap } = params;

	const dateRenderer = params => params.value && moment(params.value).format('L');

	const costRenderer = params => {
		const { currencyId } = params.data;

		if (!currencyId) {
			return null;
		}

		const currency = currenciesMap.get(currencyId);

		return `${params.valueFormatted} ${currency?.symbol}`;
	};

	const currencyRenderer = params => {
		const cur = currenciesMap.get(params.value);

		return `${cur.title} (${cur.symbol})`;
	};

	const statusRenderer = params => {
		const statuses = {
			payed: {
				value: 'Полностью оплачен',
				variant: 'success'
			},
			partlyPayed: {
				value: 'Частично оплачен',
				variant: 'warning'
			},
			notPayed: {
				value: 'Не оплачен',
				variant: 'danger'
			}
		};

		return <div>
			<Badge pill variant={statuses[params.value].variant}>{statuses[params.value].value}</Badge>
		</div>;
	};

	const isConfirmedRenderer = params => {
		const color = params.value ? '#48B95A' : '#DF3636';

		return <div style={{display: 'flex', marginTop: '14px', marginLeft: '25px'}}><FontAwesomeIcon icon={faCircle} size="sm" color={color} /></div>;
	};

	const frameworkComponents = {
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		contextActionsCellRenderer: ContextActionsCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
		statusCellRenderer: statusRenderer,
		isConfirmedRenderer,
		dateRenderer,
		costRenderer,
		currencyRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			width: 120,
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {
	const {
		onPayOrder,
		onCancelOrder,
		onAttachOrder,
		onRemoveAttachedFile,
		onShowInvoiceInfo,
	} = params;

	const invoiceFileRendererParams = params => {
		const attachments = params.value || [];

		const itemsList = attachments.map(attach => {
			return {
				file: attach,
				labelVisible: false,
				onRemoveClick: file => onRemoveAttachedFile(file, params.data),
			};
		});


		return { itemsList };
	};

	const costGetter = params => {
		const { orderItems } = params.data;

		return orderItems.reduce((acc, item) => {
			const { amountProposed, priceActual, amount } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);
	};

	const statusGetter = params => {
		const { orderItems, payments } = params.data;

		const payedCost = payments ? payments.reduce((acc, pip) => acc + Number(pip.amount), 0) : 0;

		const cost = orderItems.reduce((acc, item) => {
			const { amount, amountProposed, priceActual } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);

		if (payedCost === 0) {
			return 'notPayed';
		} else if (payedCost < cost) {
			return 'partlyPayed';
		}

		return 'payed';
	};

	const paymentsCostGetter = params => {
		return params.data.payments.reduce((acc, pip) => acc + Number(pip.amount), 0);
	};

	const costToPayGetter = params => {
		const cost = costGetter(params);
		const payed = paymentsCostGetter(params);

		const costToPay = cost - payed;

		return costToPay < 0 ? 0 : costToPay;
	};

	const paymentTermsTypeGetter = params => {
		const types = {
			full: 'Полная оплата',
			prepaid: 'Аванс',
			delay: 'Отсрочка'
		};

		return types[params.data.paymentTermsType];
	};

	const getActions = params =>
	{
		const actions = [
			{
				title: 'Платежи',
				onClick: () => onPayOrder(params.data),
			},
			{
				title: 'Разместить',
				onClick: () => onPayOrder(params.data),
			},
			{
				title: 'Соотвествие 1C счёту',
				onClick: () => onAttachOrder(params.data),
			},
			{
				title: 'Посмотреть счёт 1С',
				onClick: () => onShowInvoiceInfo(params.data),
			},
			{
				title: 'Отменить',
				onClick: () => onCancelOrder(params.data),
			}
		];

		return { actions };
	};

	const colDefs = [
		{
			headerName: 'Проект',
			field: 'project.title',
			minWidth: 200,
			width: 200
		},
		{
			headerName: 'Номер счёта',
			field: 'number',
			minWidth: 120,
			width: 120,
		},
		{
			headerName: 'Файл',
			field: 'attachments',
			cellRendererParams: invoiceFileRendererParams,
			minWidth: 80,
			width: 80,
		},
		{
			headerName: 'Дата счёта',
			field: 'date',
			minWidth: 120,
			width: 120,
		},
		{
			headerName: 'Поставщик',
			field: 'supplierTitle',
			minWidth: 200,
			width: 200
		},
		{
			headerName: 'Валюта',
			field: 'currencyId',
			minWidth: 100,
			width: 100,
		},
		{
			headerName: 'Сумма',
			field: 'cost',
			minWidth: 120,
			width: 120,
			valueGetter: costGetter,
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Оплачено',
			field: 'costPayed',
			minWidth: 120,
			width: 120,
			valueGetter: paymentsCostGetter,
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Остаток',
			field: 'costToPay',
			minWidth: 120,
			width: 120,
			valueGetter: costToPayGetter,
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Условия',
			field: 'paymentTermsType',
			valueGetter: paymentTermsTypeGetter,
			minWidth: 150,
			width: 150
		},
		{
			headerName: 'Статус',
			field: 'status',
			valueGetter: statusGetter,
			minWidth: 150,
			width: 150
		},
		{
			headerName: 'Размещён',
			field: 'order.isConfirmed',
			minWidth: 100,
			width: 100
		},
		{
			headerName: 'Действия',
			cellRenderer: 'contextActionsCellRenderer',
			cellRendererParams: getActions,
			width: 120,
			minWidth: 120,
		}
	];

	const renderersMap = {
		'date': { component: 'dateRenderer' },
		'attachments': { component: 'attachedFilesCellRenderer' },
		'currencyId': { component: 'currencyRenderer' },
		'cost': { component: 'costRenderer' },
		'costPayed': { component: 'costRenderer' },
		'costToPay': { component: 'costRenderer' },
		'status': { component: 'statusCellRenderer' },
		'order.isConfirmed': { component: 'isConfirmedRenderer' }
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
