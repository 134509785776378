import ProjectsList from './components/ListPage';
import NewProject from './components/NewProject';
import Projects from './components/Projects';

import { mainRoutes as jobsRoutes } from 'domains/jobs/routes';
//import tbsSourceSpecsRoutes from 'domains/tbs-source-specifications/routes';
import { mainRoutes as suppliesRoutes } from 'domains/supplies/routes';

export const mainRoutes = [
	{
		path: '/new-project',
		component: NewProject,
		title: 'Новый проект',
		exact: true,
	},
	{
		path: '/projects',
		component: Projects,
		title: 'Проекты',
		exact: true,
		children: {
			path: '/projects/:projectId',
			exact: true,
			redirect: true,
			to: '/projects/:projectId/report',
			children: [
				...jobsRoutes,
				...suppliesRoutes,
				//...tbsSourceSpecsRoutes,
			],
		},
	},
	{
		path: '/projects/:projectId',
		exact: true,
		redirect: true,
		to: '/projects/:projectId/report',
		children: [
			...jobsRoutes,
			...suppliesRoutes,
			//...tbsSourceSpecsRoutes,
		],
	},
];

export const tabsRoutes = [
	{
		path: '/projects',
		component: ProjectsList,
		title: 'Проекты',
		exact: true,
	},
];
