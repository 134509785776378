import React from 'react';
import db from 'core/db';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { ListGroup, Badge } from 'react-bootstrap';
import { format } from 'date-fns';
import { faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';

import api from 'core/api';
import notify from 'utils/suppliesNotifications';
import Workspace from 'layouts/Default/components/Workspace';
import { Empty, ListCardItem } from 'components/ui';
import { Modal } from 'components/ui/modals';

import RequestItemsAggregator from './helpers/RequestItemsAggregator';

class RequestsView extends React.PureComponent
{
	assignToMe = purchaseRequestId => {
		this.props.dispatch(api.tbsPurchaseRequests().assignToMe({
			purchaseRequestId,
		})).then(() => {
			notify(this.props.employees, 'request-in-progress');
		});
	}

	remove = params => {
		Modal.confirm({
			title: 'Удаление заявки',
			text: `Удалить заявку №"${params.sn}" ?`,
			onConfirm: () => {
				this.props.dispatch(api.tbsPurchaseRequests().update({
					purchaseRequestId: params.id,
					changes: {
						isDeleted: true,
					}
				}))
					.catch((err) => {
						toast.error("Не удалось удалить заявку", {
							position: toast.POSITION.TOP_CENTER,
							hideProgressBar: true,
						});
						console.error(err);
					})
				;
			},
		});
	}

	getStatus(request)
	{
		const { assignedUserId, purchaseRequestItems, orders, purchaseOrderItems } = request;

		if (purchaseRequestItems.some(i => !i.isAmountApproved)) {
			return <Badge variant="danger">{'Утверждение количества'}</Badge>;
		}

		const isRequestCompleted = orders.every(order => {
			return order.invoice && !order.invoice.isWaitingForApprove && order.items.every(item => item.isApproved);
		});

		if (purchaseOrderItems.length === purchaseRequestItems.length && isRequestCompleted) {
			return <Badge variant="success">{'Обработана'}</Badge>;
		}

		/* if (purchaseRequestItems.every(i => i.isApproved)) { // TODO: Тут надо смотреть состояние всех счетов isWaitingForApprove = true
			return <Badge variant="success">{'Обработана'}</Badge>;
		} */

		if (assignedUserId) {
			return <Badge variant="warning">{'В работе'}</Badge>;
		}

		return <Badge variant="info">{'Оформлена'}</Badge>;
	}

	getActions = request => {
		const actions = [
			{ icon: faTimes, iconColor: '#DF3636', onClick: () => this.remove(request) }
		];

		const isAmountApproved = !request.purchaseRequestItems.some(i => !i.isAmountApproved);

		if (!request.assignedUserId && isAmountApproved) {
			actions.unshift({ icon: faPlay, onClick: () => this.assignToMe(request.id) });
		}

		return actions;
	}

	render()
	{
		const { consumableSheet, completedRequests, isLoading } = this.props;

		if (isLoading || !consumableSheet || !consumableSheet.isApproved) {
			return <Empty
				className={cn('w-100', 'h-100')}
				title="Загружается список заявок..."
			/>;
		}

		if (!completedRequests.length) {
			return <Empty
				className={cn('w-100', 'h-100')}
				title="Нет заявок"
			/>;
		}

		const requests = this.props.completedRequests.sort((a, b) => (a.sn - b.sn));

		return (
			<Workspace>
				<ListGroup>
					{requests.map(request => {
						return (
							<ListGroup.Item
								as={ListCardItem}
								key={request.id}
								data={{
									number: request.sn,
									date: format(new Date(request.creationTime), 'dd-MM-yyyy HH:mm'),
									assignee: request.assignedUser?.fullName || request.assignedUser?.phoneNumber || null,
									status: this.getStatus(request),
								}}
								actions={this.getActions(request)}
								onClick={() => {
									this.props.history.push({
										pathname: `/projects/${this.props.projectId}/supplies/requests/${request.id}`,
									});
								}}
							/>
						);
					})}
				</ListGroup>
			</Workspace>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consumableSheets = db.tbsConsumableSheets.list(projectFilter);
	const consumableSheet = consumableSheets[0];
	const consumableSheetId = consumableSheet?.id;

	const consumableSheetFilter = { filter: { consumableSheetId } };

	const collects = {
		consumableSheetItems: db.tbsConsumableSheetItems.list(consumableSheetFilter),
		purchaseRequests: db.tbsPurchaseRequests.list(consumableSheetFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.list(),
		purchaseOrderItems: db.tbsPurchaseOrderItems.list(),
		purchaseOrders: db.tbsPurchaseOrders.list(),
		employees: db.employees.list(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted(),
	};

	const maps = {
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		purchaseOrdersMap: collects.purchaseOrders.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	const aggregator = new RequestItemsAggregator({...collects, ...maps});
	//const completedRequestItems = aggregator.completedRequestItems();
	const completedRequests = aggregator.completedRequests(); // TODO: что еще за completed?

	return {
		//completedRequestItems,
		completedRequests,
		me: state.me,
		consumableSheet,
		projectId,
		employees: collects.employees,
		...maps
	};
};

export default connect(mapToProps)(RequestsView);
