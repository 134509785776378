import { unitsFormatter, numberFormatter } from 'components/ui/grid/formatters';

import {
	retailPriceGetter,
	planPriceGetter,
	retailCostGetter,
	planCostGetter,
	customerPriceGetter,
	customerCostGetter,
	withCurrency,
} from '../../getters';

import {
	percentCellRenderer,
	defaultCurrencyCellRenderer,
	EmptyCellRenderer,
	SubtitleCellRenderer
} from 'components/ui/grid/renderers';


export const getGridOptions = params => {
	const priceRenderer = p => `${p.value}`.concat(' ', `${p.data?.priceCurrency?.symbol || ''}`);

	const frameworkComponents = {
		emptyCellRenderer: EmptyCellRenderer,
		priceCellRenderer: priceRenderer,
		percentCellRenderer: percentCellRenderer,
		defaultCurrencyCellRenderer: defaultCurrencyCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
	};

	const gridOptions = {
		frameworkComponents,
		defaultColDef: {
			resizable: true,
			editable: false,
			width: 100,
			suppressSizeToFit: true,
			cellStyle: {
				lineHeight: '32px',
				fontSize: '0.9em',
			},
		},
	};

	return gridOptions;
};

export const getColumnDefs = params => {
	const { consumableUnitsMap, consumableSheet } = params;
	const currencyRates = consumableSheet?.currencyRates || {};

	const titleRendererParams = params => {
		const data = params.data;

		let subtitle = data.partNumber || '';

		if (data.manufacturerName) {
			if (subtitle.length) {
				subtitle = subtitle.concat(' / ');
			}
			subtitle = subtitle.concat(data.manufacturerName);
		}

		return { subtitle };
	};

	const defs = [
		{
			headerName: 'Наименование',
			field: 'title',
			width: 400,
			cellRendererParams: titleRendererParams,
			cellStyle: { lineHeight: '16px' }
		},
		{
			headerName: 'Количество',
			field: 'amount',
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Ед. изи.',
			field: 'consumableUnitId',
			valueFormatter: params => unitsFormatter(params, consumableUnitsMap, 'suffix'),
		},
		{
			headerName: 'Розничная цена',
			field: 'retailPrice',
			valueGetter: params => retailPriceGetter(params.data),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановая цена',
			field: 'planPrice',
			valueGetter: params => planPriceGetter(params.data),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Розничная стоимость',
			field: 'retailCost',
			valueGetter: params => withCurrency(
				retailCostGetter(params.data),
				params.data.priceCurrencyId,
				currencyRates
			),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановая стоимость',
			field: 'planCost',
			valueGetter: params => withCurrency(
				planCostGetter(params.data),
				params.data.priceCurrencyId,
				currencyRates
			),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Цена для заказчика',
			field: 'customerPrice',
			valueGetter: params => customerPriceGetter(params.data),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Цена в договоре',
			field: 'planPriceOverride',
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Стоимость в договоре',
			field: 'customerCost',
			valueGetter: params => withCurrency(
				customerCostGetter(params.data),
				params.data.priceCurrencyId,
				currencyRates
			),
			valueFormatter: numberFormatter,
		},
	];

	const renderersMap = {
		'title': { component: 'subtitleCellRenderer' },
		'retailPrice': { component: 'priceCellRenderer' },
		'planPrice': { component: 'priceCellRenderer' },
		'retailCost': { component: 'defaultCurrencyCellRenderer' },
		'planCost': { component: 'defaultCurrencyCellRenderer' },
		'customerPrice': { component: 'priceCellRenderer' },
		'planPriceOverride': { component: 'priceCellRenderer' },
		'customerCost': { component: 'defaultCurrencyCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return defs.map(column => {
		if (column.field && !column.cellRenderer) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
