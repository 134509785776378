import moment from 'moment';
import 'moment/locale/ru';
import { faCheck, faBan, faComment } from '@fortawesome/free-solid-svg-icons';

import {
	EmptyCellRenderer,
	SubtitleCellRenderer,
	AttachedFilesCellRenderer,
	ActionsCellRenderer
} from 'components/ui/grid/renderers';

import { numberFormatter } from 'components/ui/grid/formatters';

import {
	NumberCellEditor,
	UnitsCellEditor,
	DateCellEditor
} from 'components/ui/grid/editors';

moment.locale('ru');

export const getGridOptions = params => {

	const { cellStyle, currenciesMap } = params;

	const priceRenderer = params => `${params.valueFormatted} ${params.data.consumableSheetItem.priceCurrency?.symbol || ''}`;
	const serialRenderer = params => `№ ${params.value}`;
	const amountRenderer = params => `${params.valueFormatted} ${params.data.consumableSheetItem.consumableUnit?.suffix || ''}`;

	const currencyRenderer = params => {
		const cur = currenciesMap.get(params.value);

		return `${cur.title} (${cur.symbol})`;
	};

	const dateRenderer = params => params.value && moment(params.value).format('L');

	const frameworkComponents = {
		unitsCellEditor: UnitsCellEditor,
		emptyCellRenderer: EmptyCellRenderer,
		numberCellEditor: NumberCellEditor,
		dateCellEditor: DateCellEditor,
		attachedFilesCellRenderer: AttachedFilesCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
		serialRenderer,
		priceRenderer,
		amountRenderer,
		dateRenderer,
		currencyRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
			cellStyle,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const { onApproveOrderItem, onRejectOrderItem } = params;

	const titleRendererParams = params => {
		const item = params.data.consumableSheetItem;

		return {
			subtitle: `${item.partNumber} | ${item.manufacturerName}`
		};
	};

	const getActions = (params) =>
	{
		return {
			actions: [
				{
					icon: faCheck,
					onClick: () => { onApproveOrderItem(params.data.id, params); },
					style: {color: params.node.data.isApproved === true ? '#48B95A' : '#999999' },
					size: 'lg'
				},
				{
					icon: faBan,
					onClick: () => { onRejectOrderItem(params.data.id, params); },
					style: {color: params.data.isApproved === false ? '#DF3636' : '#999999' },
					size: 'lg'
				},
				{
					icon: faComment,
					onClick: () => {
						params.api.startEditingCell({
							rowIndex: params.rowIndex,
							colKey: 'notes'
						});
					},
					hoverStyle: { color: '#7192E4' },
					size: 'lg'
				},
			]
		};
	};

	const colDefs = [
		{
			headerName: 'Наименование',
			field: 'consumableSheetItem.title',
			sort: 'asc',
			cellRendererParams: titleRendererParams,
			suppressSizeToFit: false,
			cellStyle: { lineHeight: 1.5 },
			minWidth: 400,
			pinned: 'left'
		},
		{
			headerName: 'Количество в заявке',
			field: 'requestItem.amount',
			valueFormatter: numberFormatter,
			minWidth: 170
		},
		{
			headerName: 'Количество',
			field: 'amountProposed',
			valueFormatter: numberFormatter,
			cellEditorParams: {inputProps: {step: '0.01'}},
			minWidth: 180
		},
		{
			headerName: 'Цена (план)',
			field: 'consumableSheetItem.planPrice',
			valueFormatter: numberFormatter,
			cellEditorParams: {inputProps: {step: '0.01'}},
			minWidth: 120
		},
		{
			headerName: 'Цена (факт)',
			field: 'priceActual',
			valueFormatter: numberFormatter,
			cellEditorParams: {inputProps: {step: '0.01'}},
			minWidth: 120
		},
		{
			headerName: 'Стоимость (план)',
			field: 'costActual',
			valueFormatter: numberFormatter,
			valueGetter: 'data.consumableSheetItem.planPrice * data.amount',
			minWidth: 150
		},
		{
			headerName: 'Стоимость (факт)',
			field: 'costActual',
			valueFormatter: numberFormatter,
			valueGetter: 'data.priceActual * data.amountProposed',
			minWidth: 150
		},
		{
			headerName: 'Срок поставки',
			field: 'deliveryDateActual',
			cellEditor: 'dateCellEditor',
			minWidth: 150
		},
		{
			minWidth: 300,
			sortable: false,
			headerName: 'Комментарий логиста',
			field: 'amountComment',
		},
		{
			minWidth: 120,
			headerName: 'Действия',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: getActions,
			sortable: false,
		},
		{
			minWidth: 300,
			sortable: false,
			headerName: 'Комментарий руководителя',
			field: 'notes',
			editable: true,
			cellEditor: 'agLargeTextCellEditor',
		}
	];

	const renderersMap = {
		'priceActual': { component: 'priceRenderer' },
		'costActual': { component: 'priceRenderer' },
		'consumableSheetItem.planPrice': { component: 'priceRenderer' },
		'amount': { component: 'amountRenderer' },
		'amountProposed': { component: 'amountRenderer' },
		'deliveryDateActual': { component: 'dateRenderer' }
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
