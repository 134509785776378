import React from 'react';
import PropTypes from 'prop-types';


class ToolPanel extends React.PureComponent
{
	state = {}

	componentWillMount()
	{
		document.addEventListener('mousedown', this.handleClick, false);
		document.addEventListener('keydown', this.handleClick, false);
	}

	componentWillUnmount()
	{
		document.removeEventListener('mousedown', this.handleClick, false);
		document.removeEventListener('keydown', this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.node.contains(e.target)) {
			return;
		}

		this.handleClickOutside();
	}

	handleKey = (e) => {
		if (e.key === "Escape") {
			this.clearSelection();
		}
	}

	handleClickOutside = () => {
		this.clearSelection();
	}

	clearSelection = () => {
		this.setState({...this.state, indexChecked: null});
	}

	render ()
	{
		const bgColor = '#f5f7f7';

		const itemStyle = {
			padding: '2px 12px 2px 12px',
			cursor: 'pointer',
			borderRight: '1px solid #BDC3C7',
			height: '100%',
		};

		const items = this.props.items || [];

		const bodyStyle = {
			backgroundColor: bgColor,
			minHeight: '50px',
			position: 'absolute',
			zIndex: 1000,
			width: '100%',
			marginTop: '28px',
			borderBottom: '1px solid #BDC3C7',
			padding: '15px',
			opacity: 0.98,
		};

		const index = this.state.indexChecked;

		if (index === null || index === undefined) {
			bodyStyle.display = 'none';
			itemStyle.borderBottom = '0px';
		}

		const onClick = (index, item) => {
			this.setState({
				...this.state,
				indexChecked: index,
				component: item.component,
			});
		};

		return (
			<div
				ref={(ref) => (this.node = ref)}
				style={{
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				<div
					style={{
						height: '28px',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						borderTop: '1px solid #BDC3C7',
						padding: '0px 4px 0px 4px',
					}}
				>
					{items.map((item, index) => {

						const style = {...itemStyle};
						if (index === this.state.indexChecked) {
							style.backgroundColor = bgColor;
							style.borderBottom = '0px';
						}

						return (
							<div
								key={index}
								style={style}
								onClick={() => onClick(index, item)}
							>
								{`#${item.title}`}
							</div>
						);
					})}
					<div
						style={{
							...itemStyle,
							flex: 1,
						}}
					/>
				</div>
				<div
					style={bodyStyle}
				>
					{this.state.component}
				</div>
			</div>
		);
	}
}

ToolPanel.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ToolPanel;
