import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import cn from 'classnames';

import PhotoGalleryImage from './components/PhotoGalleryImage';
import Carousel from './components/Carousel';

import style from './style.module.sass';


class PhotoGallery extends React.PureComponent
{
	static propTypes = {
		attachments: PropTypes.arrayOf(PropTypes.object),
		className: PropTypes.string,
		imgClassName: PropTypes.string,
		imgWidth: PropTypes.number,
		imgHeight: PropTypes.number,
		visibleCount: PropTypes.number,
		vertical: PropTypes.bool,
		renderImage: PropTypes.func,
		renderCaption: PropTypes.func,
	}

	static defaultProps = {
		attachments: [],
		visibleCount: 0,
		imgWidth: 200,
		imgHeight: 100,
		vertical: false,
	}

	state = {
		currentImageIndex: 0,
		modalVisible: false,
	}

	openModal = (event, index) => {
		this.setState({
			modalVisible: true,
			currentImageIndex: index,
		});
	}

	closeModal = () => {
		this.setState({
			modalVisible: false,
			currentImageIndex: 0,
		});
	}

	onSelect = (eventKey, direction) => {
		const index = this.state.currentImageIndex;
		const size = this.props.attachments.length;

		if (direction === 'next') {
			const newIndex = (index + 1) % size;
			this.setState({ currentImageIndex: newIndex });
		}

		if (direction === 'prev') {
			const prev = index - 1;
			const newIndex = prev < 0 ? (size - 1) : prev;
			this.setState({ currentImageIndex: newIndex });
		}
	}

	renderModal()
	{
		const { modalVisible, currentImageIndex } = this.state;
		const { attachments, renderCaption } = this.props;

		return (
			<Modal
				show={modalVisible}
				onHide={() => this.closeModal()}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Carousel
					attachments={attachments}
					currentImageIndex={currentImageIndex}
					onSelect={(eventKey, direction) => this.onSelect(eventKey, direction)}
					renderCaption={renderCaption}
				/>
			</Modal>
		);
	}

	renderShowMoreComponent()
	{
		const { attachments, visibleCount, imgWidth, imgHeight } = this.props;

		if (attachments.length <= visibleCount) {
			return null;
		}

		return (
			<div
				className={style.empty}
				style={{ width: imgWidth, height: imgHeight, minWidth: imgWidth }}
				onClick={e => this.openModal(e, visibleCount)}
			>
				{`Ещё ${attachments.length - visibleCount} фото`}
			</div>
		);
	}

	render()
	{
		const {
			attachments,
			visibleCount,
			imgClassName,
			className,
			imgWidth,
			imgHeight,
			vertical,
			renderImage,
		} = this.props;

		if (!attachments.length) {
			return null;
		}

		const visibleAttachments = attachments.slice(0, visibleCount);

		if (!visibleAttachments.length) {
			return null;
		}

		const showMoreComponent = this.renderShowMoreComponent();
		const modal = this.renderModal();

		return (
			<div className={cn(
				className,
				{ [style.vertical]: vertical },
				{ [style.horizontal]: !vertical },
			)}>
				{visibleAttachments.map((attachment, index) => (
					<PhotoGalleryImage
						key={index}
						width={imgWidth}
						height={imgHeight}
						className={imgClassName}
						onClick={(event, index) => this.openModal(event, index)}
						index={index}
						attachment={attachment}
						renderImage={renderImage}
					/>
				))}
				{showMoreComponent}
				{modal}
			</div>
		);
	}
}

export default PhotoGallery;
