import React from 'react';
import { connect } from 'react-redux';
import { withLoading, Page } from 'components/ui';

import db from 'core/db';

import List from './components/List';


class ListPage extends React.PureComponent
{

	goToNewProject()
	{
		this.timer = setTimeout(() => {
			this.props.history.push({
				pathname: '/new-project',
			});
		});
	}

	componentWillUnmount()
	{
		if (this.timer) {
			delete this.timer;
		}
	}

	componentDidMount()
	{
		const { isLoading, activeProjects } = this.props;

		if (!isLoading && !activeProjects.length) {
			this.goToNewProject();
		}
	}

	componentDidUpdate(prevProps, prevState)
	{
		const { isLoading, activeProjects } = this.props;

		if (!isLoading && !activeProjects.length) {
			this.goToNewProject();
		}
	}

	render()
	{
		const { activeProjects } = this.props;

		if (!activeProjects.length) {
			return null;
		}

		return (
			<Page>
				<List
					{...this.props}
					goToNewProject={() => this.goToNewProject()}
				/>
			</Page>
		);
	}
}

const mapToProps = (state, props) => {

	const activeProjects = db.projects.listNotArchived({
		filter: {
			companyId: state.company.id,
		}
	});

	const archivedProjects = db.projects.list({
		filter: {
			companyId: state.company.id,
			isArchived: true,
		}
	});

	const employees = db.employees.list();

	const isLoading = activeProjects.isLoading || archivedProjects.isLoading || employees.isLoading;
	const loadingTitle = 'Загружаем список проектов...';

	if (isLoading) {
		return { isLoading: true, loadingTitle };
	}

	return {
		activeProjects,
		archivedProjects,
		employees,
	};

};

export default connect(mapToProps)(withLoading(ListPage));
