import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createReduxMiddleware as createApi2ReduxMiddleware, QueryExecutor } from '@brigadier/js-api2';
import api from 'core/api';

import AppReducer from './reducers';
import apiClient from './api-client';


const queryExecutor = new QueryExecutor;

const tarifLimitsRefhresher = store => next => action => {
	const actions = [
		'PROJECTS_CREATE_RESPONSE',
		'PROJECTS_UPDATE_RESPONSE',
	];

	const result = next(action);

	if (actions.includes(action.type)) {
		store.dispatch(api.me().getSelf());
	}

	return result;
};

const store = createStore(
	(state, action) => {
		return AppReducer(
			queryExecutor.reducer(state, action),
			action
		);
	},
	applyMiddleware(
		tarifLimitsRefhresher,
		createApi2ReduxMiddleware(apiClient, queryExecutor), // эта миддлварь обязательно должна быть до thunk
		thunkMiddleware
	)
);

if (module.hot) {
	module.hot.accept('./reducers', () => {
		const nextRootReducer = require('./reducers');
		const finalReducer = {...nextRootReducer };
		store.replaceReducer(combineReducers(finalReducer));
	});
}

export default store;
