import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.sass';


const Label = (props) => {
	return (
		<div className={styles.Label}>
			{props.text}
		</div>
	);
};

Label.propTypes = {
	text: PropTypes.string,
};

export default Label;
