import NormalizerFactory from './normalizers/NormalizerFactory';
import PerfomanceBuilder from './builders/PerfomanceBuilder';
import PerfomancePercentBuilder from './builders/PerfomancePercentBuilder';
import DefaultBuilder from './builders/DefaultBuilder';

import RangeBuilder from './builders/RangeBuilder';

import { Options } from './types.js';


export default class HistoryManager
{
	constructor(type, data, startDate = new Date(), endDate = new Date())
	{
		this.type = type;
		this.data = data;

		startDate.setHours(0, 0, 0, 0);
		endDate.setHours(0, 0, 1, 0);

		this.options = new Options(startDate, endDate);
	}

	range()
	{
		return new RangeBuilder(this.options).build();
	}

	perfomancePercent(params)
	{
		const { type, data, options } = this;
		const normalizer = new NormalizerFactory(type, data, options).get();

		const builder = new PerfomancePercentBuilder(
			normalizer.normalize(),
			params
		);

		const iterator = builder.build();

		return iterator.sumGroupedByDate();
	}

	perfomance(params)
	{
		const { type, data, options } = this;
		const normalizer = new NormalizerFactory(type, data, options).get();

		const builder = new PerfomanceBuilder(
			normalizer.normalize(),
			params
		);

		const iterator = builder.build();

		return iterator.sumGroupedByDate();
	}

	remainPerfomance(params)
	{
		const perfomance = this.perfomance(params);
		const { totalPerformance } = params;

		if (Array.isArray(perfomance)) {
			return perfomance.map(p => totalPerformance - p);
		} else {
			return totalPerformance - perfomance;
		}
	}

	employees(params)
	{
		const { type, data, options } = this;
		const normalizer = new NormalizerFactory(type, data, options).get();

		const builder = new DefaultBuilder(
			normalizer.normalize(),
			params,
		);

		const iterator = builder.build();
		const employees = iterator.sumGroupedByDate();

		return employees;
	}

	progress(params)
	{
		const { type, data, options } = this;
		const normalizer = new NormalizerFactory(type, data, options).get();

		const builder = new DefaultBuilder(
			normalizer.normalize(),
			params,
		);

		const iterator = builder.build();

		return iterator.sumGroupedByDate();
	}
}
