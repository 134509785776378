import React from 'react';
import styles from './style.module.sass';
import PtopTypes from 'prop-types';


class ChartLegend extends React.PureComponent
{
	static propTypes = {
		label: PtopTypes.string,
		color: PtopTypes.string,
	}

	render()
	{
		const backgroundColor = this.props.color;

		return (
			<div className={styles.Legend}>
				<div
					className={styles.Legend_square}
					style={{ backgroundColor }}
				/>
				<div className={styles.Legend_label}>
					{this.props.label}
				</div>
			</div>
		);
	}
}

export default ChartLegend;
