import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Action } from 'components/ui';

import style from './style.module.sass';


class Worker extends React.PureComponent
{
	static propTypes = {
		worker: PropTypes.object.isRequired,
		projects: PropTypes.any,
		actions: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		actions: [],
	}

	state = {
		hovered: false
	}

	render()
	{
		const { worker, className, actions, projects } = this.props;
		const { hovered } = this.state;

		return (
			<div
				className={cn(style.worker, className)}
				onMouseEnter={() => this.setState({ hovered: true })}
				onMouseLeave={() => this.setState({ hovered: false })}
			>
				{actions.map((act, i) => {
					return (
						<Action
							{...act}
							key={i}
							style={{
								opacity: hovered ? 0.7 : 0.0,
								width: hovered ? 20 : 0,
								transition: 'width 300ms'
							}}
							className="mr-1"
						/>
					);
				})}
				<div className={style.title}>
					<span className={style.titleMain}>
						{worker.name}
					</span>
					<span className={style.titleSub}>
						{worker.position}
					</span>
					<span className={style.titleProject}>
						{worker.projectId ? projects.get(worker.projectId).title : null}
					</span>
				</div>
			</div>
		);
	}
}

export default Worker;
