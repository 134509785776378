import React from 'react';
import { Carousel as BootstrapCarousel, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';

import style from './style.module.sass';


class Carousel extends React.PureComponent
{

	static propTypes = {
		attachments: PropTypes.arrayOf(PropTypes.shape({
			url: PropTypes.string.isRequired,
		})).isRequired,
		currentImageIndex: PropTypes.number,
		onSelect: PropTypes.func,
		renderCaption: PropTypes.func,
	}

	static defaultProps = {
		items: [],
	}

	render()
	{
		const { attachments, currentImageIndex, onSelect, renderCaption } = this.props;

		return (
			<BootstrapCarousel
				activeIndex={currentImageIndex}
				slide={false}
				onSelect={(eventKey, direction) => onSelect(eventKey, direction)}
				interval={null}
			>
				{attachments.map((attachment, index) => {
					return (
						<BootstrapCarousel.Item key={index}>
							<div className={style.wrap}>
								<Image
									className={style.img}
									src={attachment.url}
									fluid
								/>
							</div>
							{ renderCaption && <BootstrapCarousel.Caption>
								{renderCaption(attachment)}
							</BootstrapCarousel.Caption>}
						</BootstrapCarousel.Item>
					);
				})}
			</BootstrapCarousel>
		);
	}
}
export default Carousel;
