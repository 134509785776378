import RolesSettings from './components/RolesSettings';
import Invoices from './components/Invoices';
import InvoicesView from './components/Invoices/components/InvoicesView';
import Payments from './components/Invoices/components/Payments';


export const mainRoutes = [
	{
		path: '/company',
		title: 'Компания',
		redirect: true,
		exact: true,
		to: '/company/roles',
		children: [
			{
				path: "/roles",
				title: 'Настройки ролей',
				component: RolesSettings,
			}, {
				path: "/invoices",
				title: 'Реестр счетов',
				component: Invoices,
			},
		]
	},
];

export const invoicesTabsRoutes = [
	{
		title: 'Счета',
		path: '/company/invoices',
		exact: true,
		component: InvoicesView,
	},
	{
		title: 'Оплаты',
		path: '/company/invoices/payments',
		exact: true,
		component: Payments,
	}
];
