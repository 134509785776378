import React, { useState } from 'react';
import {connect} from 'react-redux';
import api from 'core/api';
import db from 'core/db';
import uuidv4 from 'uuid/v4';
import { toast } from 'react-toastify';
import { compareAsc } from 'date-fns';

import { Container } from 'components/ui';

import Grid from './components/Grid';
import ReportModal from './components/ReportModal';
import JobEditModal from '../JobEditModal';
import StageEditModal from '../StageEditModal';

import Aggregator from './helpers/Aggregator';

import styles from './style.module.sass';


const reportRequest = (job, values, isFullProgress, props) => {
	const { periodicReportsMapByDate, projectId, periodicReportItems, periodicReports } = props;

	const periodicReport = periodicReportsMapByDate.get(values.date);

	let currentProgress = null;

	if (isFullProgress) {
		currentProgress = Number(values.currentProgress);
	} else {
		const latestReport = periodicReports
			? periodicReports.sort((a, b) => (a.date > b.date ? -1 : 1))[0]
			: null
		;
		const latestReportItem = latestReport
			? periodicReportItems.find(pri => pri.periodicReportId === latestReport.id && pri.jobId === job.id)
			: null
		;
		const latestValue = Number(latestReportItem?.currentProgress || 0);

		currentProgress = Number(values.currentProgress) + latestValue;
	}

	let request = null;

	if (periodicReport) {
		const reportItems = periodicReportItems.filter(pri => pri.periodicReportId === periodicReport.id);
		const periodicReportItem = reportItems.find(ri => ri.jobId === job.id);

		if (periodicReportItem) {
			request = api.periodicReportItems().update({
				periodicReportItemId: periodicReportItem.id,
				changes: {
					numberOfEmployedWorkers: values.numberOfEmployedWorkers,
					currentProgress: currentProgress,
				}
			});
		} else {
			request = api.periodicReportItems().create({
				periodicReportId: periodicReport.id,
				periodicReportItemId: uuidv4().toUpperCase(),
				jobId: job.id,
				numberOfEmployedWorkers: values.numberOfEmployedWorkers,
				currentProgress: currentProgress,
			});
		}

	} else {
		const periodicReportId = uuidv4().toUpperCase();

		const createReportRequest = api.periodicReports().create({
			periodicReportId,
			projectId,
			date: values.date,
		});

		const createReportItemRequest = api.periodicReportItems().create({
			periodicReportId,
			periodicReportItemId: uuidv4().toUpperCase(),
			jobId: job.id,
			numberOfEmployedWorkers: values.numberOfEmployedWorkers,
			currentProgress: currentProgress
		});

		request = api.transaction().execute([createReportRequest, createReportItemRequest]);
	}

	return request;
};

const transaction = (nodes, changes) => {
	const transactionBody = nodes.map(node => {
		const item = node.data;

		if (item.itemType === 'job') {
			return api.jobs().update({
				jobId: item.id,
				changes: changes,
			});
		} else if (item.itemType === 'stage') {
			return api.stages().update({
				stageId: item.id,
				changes: changes,
			});
		}

		return null;
	});

	return api.transaction().execute(transactionBody);
};

const moveJobsToStage = (nodes, stageId) => {
	return transaction(nodes, { stageId });
};

const remove = (nodes) => {
	return transaction(nodes, { isDeleted: true });
};

const Scope = (props) => {

	const [jobModalVisible, setJobModalVisisble] = useState(false);
	const [stageModalVisible, setStageModalVisible] = useState(false);
	const [reportModalVisible, setReportModalVisible] = useState(false);

	const [reportJob, setReportJob] = useState(null);

	const [editJob, setEditJob] = useState(null);
	const [jobInitialValues, setJobInitialValues] = useState(null);

	const [editStage, setEditStage] = useState(null);
	const [stageInitialValues, setStageInitialValues] = useState(null);

	return (
		<div className={styles.Scope}>
			<JobEditModal
				visible={jobModalVisible}
				onClose={() => setJobModalVisisble(false)}
				initialValues={{...(jobInitialValues || {}), workerDayHours: 8 }}
				changesOnly={!!editJob}
				jobId={editJob?.id || 'Новая работа'}
				title={editJob?.title}
			/>
			<StageEditModal
				visible={stageModalVisible}
				onClose={() => setStageModalVisible(false)}
				initialValues={stageInitialValues}
				changesOnly={!!editStage}
				stageId={editStage?.id}
				title={editStage?.title || 'Новый участок'}
			/>
			<ReportModal
				visible={reportModalVisible}
				reportItem={reportJob}
				onCancel={() => setReportModalVisible(false)}
				onConfirm={(job, values) => {
					const { isFullProgress, ...otherValues } = values;
					props.dispatch(reportRequest(job, otherValues, isFullProgress, props))
						.then(() => {
							setReportJob(null);
							setReportModalVisible(false);
						})
						.catch(err => {
							toast.error("Не удалось создать отчёт", {
								position: toast.POSITION.TOP_CENTER,
								hideProgressBar: true,
							});
							console.error(err);
						})
					;
				}}
			/>
			<Container height="100%" width="100%" bind>
				<Grid
					items={props.gridItems}
					stages={props.stages}
					stagesMap={props.stagesMap}
					onEditJob={editJob => {
						setEditJob(editJob);
						setJobInitialValues(editJob);
						setJobModalVisisble(true);
					}}
					onEditStage={editStage => {
						setEditStage(editStage);
						setStageInitialValues(editStage);
						setStageModalVisible(true);
					}}
					onCreateNestedStage={parentStageId => {
						setEditStage(null);
						setStageInitialValues({ parentStageId });
						setStageModalVisible(true);
					}}
					onCreateNewJob={stageId => {
						setEditJob(null);
						setJobInitialValues({ stageId });
						setJobModalVisisble(true);
					}}
					onMoveJobsToStage={(nodes, stageId) => {
						props.dispatch(moveJobsToStage(nodes, stageId))
							.catch(err => console.log(err));
					}}
					onRemove={nodes => {
						props.dispatch(remove(nodes))
							.catch(err => console.log(err));
					}}
					onAddReportClick={reportingJob => {
						setReportJob(reportingJob);
						setReportModalVisible(true);
					}}
				/>
			</Container>
		</div>
	);
};

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const filter = { filter: { projectId } };

	const collects = {
		jobs: db.jobs.listNotDeleted(filter).sort((a, b) => compareAsc(new Date(a.estimatedStartDate), new Date(b.estimatedStartDate))),
		stages: db.stages.listNotDeleted(filter),
		jobUnits: db.jobUnits.list(),
		users: db.users.list(),
		gesnSections: db.gesnSections.list(),
		gesnCategories: db.gesnCategories.list(),
		periodicReports: db.periodicReports.list(filter),
		periodicReportItems: db.periodicReportItems.list(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	const stagesMap = collects.stages.hashById();
	const periodicReportsMapByDate = new Map();

	for (const pr of collects.periodicReports) {
		periodicReportsMapByDate.set(pr.date, pr);
	}

	const aggregator = new Aggregator({ ...collects, stagesMap });
	const gridItems = aggregator.getGridItems();

	return {
		...collects,
		stagesMap,
		periodicReportsMapByDate,
		gridItems,
		projectId,
		me: state.me,
	};
};

export default connect(mapToProps)(Scope);

