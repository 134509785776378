import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';

class TabsRouter extends React.PureComponent
{
	static propTypes = {
		routes: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		routes: [],
	}

	render()
	{
		return (
			<Switch>
				{this.props.routes.map((route, key) => {
					if (route.redirect) {
						return <Redirect
							from={route.path}
							to={route.to}
							key={key}
							exact={route.exact}
						/>;
					}

					return <Route
						path={route.path}
						key={key}
						exact={route.exact}
						component={route.component}
					/>;
				})}
			</Switch>
		);
	}
}

export default withRouter(TabsRouter);
