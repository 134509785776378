import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { withFormik } from 'formik';
import { NumberInput } from 'components/ui/forms';
import config from 'config';

import { forIn, isEmpty } from 'lodash';

class CurrencyRatesForm extends React.PureComponent
{
	static propTypes = {
		onFormReady: PropTypes.func,
		onSubmit: PropTypes.func,
		onCancel: PropTypes.func,
		currencies: PropTypes.arrayOf(PropTypes.object),
		currenciesMap: PropTypes.any,
		ratesValues: PropTypes.object,
	}

	static defaultProps = {
		currencies: [],
		ratesValues: {},
	}

	componentDidMount()
	{
		this.props.onFormReady(this.props);
	}

	render()
	{
		const defaultCurrency = config.tbs.priceCurrency.default;

		return (
			<Form>
				{this.props.currencies
					.filter(curr => curr.id !== defaultCurrency.id)
					.map(curr => {
						return (
							<Form.Group as={Col} key={curr.id}>
								<Form.Label>{curr.title}</Form.Label>
								<InputGroup>
									<NumberInput
										hocInputProps={{ name: curr.id }}
									/>
									<InputGroup.Append>
										<InputGroup.Text>{defaultCurrency.symbol}</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							</Form.Group>
						);
					})}
			</Form>
		);
	}
}

const mapPropsToValues = props => {
	return {...props.ratesValues};
};

const handleSubmit = (values, { props, setSubmitting }) => {

	const intialValues = props.ratesValues;
	const changes = {};

	forIn(values, (value, key) => {
		if (intialValues[key] !== value) {
			changes[key] = value;
		}
	});

	if (isEmpty(changes)) {
		props.onCancel();
	} else {
		props.onSubmit({...intialValues, ...changes});
	}

	setSubmitting(false);
};

export default withFormik({ handleSubmit, mapPropsToValues })(CurrencyRatesForm);
