import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { Select } from 'components/ui';
import PropTypes from 'prop-types';


const SelectInput = (props) => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return <Select
		onChange={option => helpers.setValue(option.value)}
		value={props.options?.find(o => o.value === value)}
		controlled
		{...props}
	/>;
};

SelectInput.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object),
};


export default SelectInput;
