import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, CartesianGrid, YAxis, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

import ChartTooltip from './Tooltip';


const CustomTooltip = ({ active, payload, label }) => {

	if (!active) {
		return null;
	}
	const data = payload && payload[0]?.payload;

	if (!data) {
		return null;
	}

	const { plan, remain, dates } = data;

	const labels = [
		{
			text: 'Плановое количество ',
			value: plan ? `${plan} чел/ч` : null,
			color: '#D55B4A',
		},
		{
			text: 'Фактическое количество',
			value: remain ? `${remain} чел/ч` : null,
			color: '#829FEA',
		},
		{
			text: 'Дата',
			value: dates,
		}
	];

	return <ChartTooltip labels={labels}/>;

};
class JobsDynamicChart extends React.PureComponent
{
	static propTypes = {
		plan: PropTypes.arrayOf(PropTypes.any),
		dates: PropTypes.arrayOf(PropTypes.string),
		remain: PropTypes.arrayOf(PropTypes.any),
	}

	static defaultProps = {
		plan: [],
		remain: [],
		dates: null,
	}

	render()
	{
		const { plan, remain, dates } = this.props;


		const max = Math.max(Math.max(...[...(plan || []), ...(remain || [])]), 100);
		const ticks = new Array(16).fill().map((_, i) => (Math.ceil(i * max / 15 * 100) / 100));
		const domain = [0, max];

		const data = [];

		if (dates) {
			for (let i = 0; i < dates.length; ++i) {
				data.push({
					plan: plan ? plan[i] : null,
					remain: remain ? remain[i] : null,
					dates: dates[i],
				});
			}
		}

		return (
			<div>
				<ResponsiveContainer debounce={2} height={"95%"}>
					<LineChart data={data}>
						<CartesianGrid
							vertical={false}
							stroke="#ccc"
							strokeDasharray="5 5"
						/>
						<Line
							type="monotone"
							dataKey="plan"
							stroke="#D55B4A"
							strokeWidth={3}
							dot={false}
						/>
						<Line
							type="monotone"
							dataKey="remain"
							stroke="#829FEA"
							strokeWidth={3}
							dot={false}
						/>
						<YAxis
							hide
							ticks={ticks}
							domain={domain}
							allowDecimals={true}
							interval={0}
						/>
						<XAxis
							dataKey="dates"
							hide
						/>
						<Tooltip content={<CustomTooltip />}/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default JobsDynamicChart;

