import React from 'react';
import PropTypes from 'prop-types';

import JobProgressListItem from './components/JobProgressListItem';
import styles from './style.module.sass';


class JobProgressList extends React.PureComponent
{
	static propTypes = {
		jobs: PropTypes.arrayOf(PropTypes.object),
		visibleCount: PropTypes.number,
	}

	static defaultProps = {
		visibleCount: 5,
		jobs: [],
	}

	render()
	{
		const { jobs, visibleCount, ...props } = this.props;

		const visibleJobs = jobs.slice(0, visibleCount);
		const growIndex = 1 / visibleCount;

		return <div className={styles.JobProgressList} {...props}>
			{visibleJobs.map((job, i) => (
				<JobProgressListItem key={i} job={job} style={{ flexGrow: growIndex }}/>
			))}
		</div>;
	}
}

export default JobProgressList;
