import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumb } from 'react-bootstrap';
import { withRouter, matchPath } from 'react-router-dom';
import cn from 'classnames';

import { routesMap } from 'routes';
import db from 'core/db';
import { ButtonToolbar } from 'components/ui';
import styles from '../style.module.sass';

class Breadcrumbs extends React.PureComponent {
	render() {
		const locationDetails = [...this.props.locationDetails];
		const header = locationDetails.pop();

		return	(
			<div className={styles.breadcrumbs}>
				{!this.props.hideBreadcrumbs ?
					<Breadcrumb>
						{locationDetails.map((item, index) => {
							const title = item.title || item.entity?.title;

							if (!title) {
								return null;
							}

							return (
								<Breadcrumb.Item key={index} href={"/#" + item.url}>
									{title}
								</Breadcrumb.Item>
							);
						})}
					</Breadcrumb> : null }
				<div className={cn('d-flex', 'flex-row')}>
					{!this.props.hideTitle ? <span className={styles.title}>{header.title || (this.props.titleField && header.entity && header.entity[this.props.titleField])}</span> : null}
					<ButtonToolbar btnList={this.props.actions} buttonsStyle={{
						margin: '4px',
						borderRadius: "16px",
						paddingTop: "2px",
						paddingBottom: "2px",
						backgroundColor: "#ffbd2487",
						border: "none",
						color: "#2a355f"
					}} />
				</div>
			</div>
		);
	}
}

export default withRouter(connect((state, props) => {
	const locationDetails = [];
	let entity = null;

	/**
	 * TODO: Навеное это стоит вынести куда-то в другое место
	 */
	const entityMap = {
		"/projects/:projectId": {
			name: 'projects',
			idField: 'projectId'
		},
		"/:employeeId": {
			name: 'employees',
			idField: 'employeeId'
		},
		"/supplies/requests/:requestId": {
			name: 'tbsPurchaseRequests',
			idField: 'requestId'
		},
	};

	for (const mask in routesMap) {
		const match = matchPath(props.location.pathname, {
			path: mask
		});
		const e = entityMap[routesMap[mask].path] ? entityMap[routesMap[mask].path] : null;

		if (!match) {
			continue;
		}

		if (e) {
			entity = db[e.name].find(match.params[e.idField]);
		}

		locationDetails.push({...match, ...routesMap[mask], entity: entity});
	}

	return {
		locationDetails: locationDetails
	};
})(Breadcrumbs));
