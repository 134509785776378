import { HistoryGroupArray, HistoryGroup, HistoryValue } from "./types";

export default class HistoryDataIterator
{
	constructor(history)
	{
		if (!(history instanceof HistoryGroupArray)) {
			throw new Error('history must be instance of HistoryGroupArray');
		}

		this.history = history;
	}

	forEach(process) {
		const history = new HistoryGroupArray();

		for (const group of this.history) {
			const historyGroup = new HistoryGroup(group.jobId);

			for (const hValue of group) {
				historyGroup.push(new HistoryValue(hValue.date, process(hValue.value, group.jobId)));
			}

			history.push(historyGroup);
		}

		return new HistoryDataIterator(history);
	}

	eachDateValuesMap()
	{
		const res = new Map();

		for (const group of this.history) {
			for (const hValue of group) {
				const { date, value} = hValue;

				const array = res.get(date);

				if (array) {
					array.push(value);
				} else {
					res.set(date, [value]);
				}
			}
		}

		return res;
	}

	sumGroupedByDate()
	{
		return Array.from(this.eachDateValuesMap().values()).map(values => values.reduce((acc, value) => acc + Number(value), 0));
	}
}
