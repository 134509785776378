import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Col, InputGroup } from 'react-bootstrap';
import { withFormik } from 'formik';
import { isEmpty } from 'lodash';
import { getFormChanges } from 'core/helpers';
import { TextInput, SelectInput, DateRange } from 'components/ui/forms';


class ProjectEditForm extends React.PureComponent {

	static propTypes = {
		onFormCreated: PropTypes.func,
		onSubmit: PropTypes.func,
		onCancel: PropTypes.func,
		employees: PropTypes.arrayOf(PropTypes.object),
		// project: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
	}

	componentDidMount()
	{
		this.props.onFormCreated(this.props);
	}

	render() {
		return (
			<Form>
				<Form.Group>
					<Form.Label>Название проекта</Form.Label>
					<TextInput
						hocInputProps={{ name: "title" }}
						placeholder="Укажите короткое название"
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Адрес объекта</Form.Label>
					<TextInput
						hocInputProps={{ name: "address" }}
						placeholder="Можно указать координаты или населённый пункт"
					/>
				</Form.Group>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Label>Сроки проекта</Form.Label>
						<InputGroup className="flex-nowrap">
							<DateRange
								rangeFields={{
									start: 'estimatedStartDate',
									end: 'estimatedEndDate'
								}}
							/>
						</InputGroup>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Label>Руководитель проекта</Form.Label>
						<SelectInput
							hocInputProps={{ name: "assignedUserId" }}
							items={this.props.employees}
							optionFields={['fullName', 'phoneNumber']}
							emptyValue="Не назначен"
							placeholder="Выберите сотрудника"
						/>
					</Form.Group>
				</Form.Row>
			</Form>
		);
	}
}

const mapPropsToValues = (props) => {

	const project = props.project || {};

	return { ...project };
};

const handleSubmit = (values, { props, setSubmitting }) => {

	const intialValues = props.project || {};

	const changes = getFormChanges(values, intialValues);

	if (isEmpty(changes)) {
		props.onCancel();
	} else {
		props.onSubmit(changes);
	}

	setSubmitting(false);
};

const formik = withFormik({ mapPropsToValues, handleSubmit })(ProjectEditForm);

export default connect()(formik);
