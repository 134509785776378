import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Empty, Container } from 'components/ui';

import db from 'core/db';

import { Card } from '../layouts';
import JobProgressList from '../JobProgressList';


class JobsProgressCard extends React.PureComponent
{
	render()
	{
		const { jobs, ...props } = this.props;
		const actualJobs = jobs;

		return (
			<Card
				{...props}
				layout="column"
				title="Выполняемые виды работ"
			>
				<Container width="100%" height="100%" bind>
					{actualJobs?.length
						? <JobProgressList jobs={actualJobs}/>
						: <Empty title="Список пуст"/>
					}
				</Container>
			</Card>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };

	const jobs = db.jobs.listNotDeleted(projectIdFilter);

	return { jobs };
};

export default withRouter(connect(mapToProps)(JobsProgressCard));
