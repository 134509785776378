import React from 'react';

class Layout extends React.PureComponent
{
	render()
	{
		return (
			<div className="w-100 h-100 d-flex flex-row" style={{backgroundColor: '#fff'}}>
				<div className="h-100 col-3 border-right d-flex flex-column align-items-center p-0">
					{this.props?.children[0] || this.props?.children}
				</div>
				<div className="h-100 col-9 d-flex flex-column p-0">
					{this.props?.children[1]}
				</div>
			</div>
		);
	}
}

export default Layout;
