import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './style.module.sass';


class DayCell extends React.PureComponent
{
	static propTypes = {
		date: PropTypes.any,
		color: PropTypes.string,
		value: PropTypes.number,
	}

	render()
	{
		const { color, value } = this.props;

		return <div
			className={cn(styles.Cell, styles.Cell_data)}
			style={{ backgroundColor: color }}
		>
			{value}
		</div>;
	}
}

export default DayCell;
