import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { isEmpty, keys, intersection } from 'lodash';

import api from 'core/api';

import { CustomGrid } from 'components/ui/grid';

import { getColumnDefs, getGridOptions, getPinnedBottomRowData } from './defs';

class Grid extends React.Component
{
	static propTypes = {
		consumableSheet: PropTypes.object.isRequired,
		items: PropTypes.arrayOf(PropTypes.object),
		currenciesMap: PropTypes.any,
		consumableUnitsMap: PropTypes.any,
		consumableUnits: PropTypes.arrayOf(PropTypes.object),
		currencies: PropTypes.arrayOf(PropTypes.object),
		me: PropTypes.object,
		onSelectionChanged: PropTypes.func,
		onGridReady: PropTypes.func,
		filters: PropTypes.object,
		pricelistsMatchesMap: PropTypes.any,
		getMatchKey: PropTypes.func,
		getCurrencyRates: PropTypes.func,
		onChangeItemClick: PropTypes.func,
	};

	static defaultProps = {
		items: [],
		filters: {},
	};

	state = {
		selectedNodes: [],
		pinnedBottomRowData: null,
	}

	onSelectionChanged = (params) => {
		const filteredAndSelectedNodes = [];
		params.api.rowModel.forEachNodeAfterFilter(node => {
			if (node.selected) {
				filteredAndSelectedNodes.push(node);
			}
		});

		this.setState({selectedNodes: filteredAndSelectedNodes});
		this.props.onSelectionChanged(filteredAndSelectedNodes);
	}

	handleChanges = (node, changes, afterUpdate = null) => {
		const id = node.data.id;
		const round100 = x => Math.round(x * 100) / 100;

		if (changes.hasOwnProperty('retailDiscountPercents') && node.data.retailPrice) {
			changes['planPrice'] = round100(node.data.retailPrice - node.data.retailPrice * (node.data.retailDiscountPercents / 100));
		}

		if (changes.hasOwnProperty('retailPrice')) {
			changes['planPrice'] = node.data.retailDiscountPercents ? round100(node.data.retailPrice - node.data.retailPrice * (node.data.retailDiscountPercents / 100)) : node.data.retailPrice;
		}

		/* if (changes.hasOwnProperty('planPrice')) {
			changes['retailPrice'] = null;
		} */

		this.props.dispatch(
			api.tbsConsumableSheetItems().update({
				consumableSheetItemId: id,
				changes: changes
			}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось внести изменения", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => {
				if (afterUpdate) {
					afterUpdate();
				}
			})
		;
	};

	updateColumnDefs = () => {
		this.gridApi.setColumnDefs([]);
		this.gridApi.setColumnDefs(this.getDefs());
	}

	onCellValueChanged = (params) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.handleChanges(params.node, changes);
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.props.onGridReady(params, { updateColumnDefs: this.updateColumnDefs });
		this.updatePinnedRowData();
	}

	onCurrencyCellValuesChanged = params => {
		const { value, oldValue, field, node } = params;
		const { data } = node;

		if (value === oldValue) {
			return;
		}

		data[field] = value;
		node.setData(data);
		this.handleChanges(node, { [field]: value }, () => {
			this.gridApi.refreshCells({ rowNodes: [node], force: true });
		});
	}

	isExternalFilterPresent = () => {
		const { filters } = this.props;

		return filters && !isEmpty(filters);
	}

	doesExternalFilterPass = node => {
		const { filters } = this.props;
		const { data } = node;

		const intersects = intersection(keys(data), keys(filters));

		if (!intersects.length) {
			return true;
		}

		for (const key of intersects) {
			const value = data[key];

			if (Array.isArray(value)) {
				if (!intersection(value, filters[key]).length) {
					return false;
				}
			} else if (!filters[key].includes(data[key])) {
				return false;
			}
		}

		return true;
	}

	onMatchClicked = (node, match) => {
		const changes = {
			retailPrice: match.price,
		};

		this.handleChanges(node, changes);
	}

	getDefs()
	{
		return getColumnDefs({
			...this.props,
			onCurrencyCellValuesChanged: this.onCurrencyCellValuesChanged,
			onMatchClicked: this.onMatchClicked,
		});
	}

	getItemHistory = (node, data, callback) => {
		const { id } = data;

		this.props.dispatch(api.tbsConsumableSheetItems().history({
			filter: {
				consumableSheetItemId: id,
			},
			with: {
				'state.old': true,
			}
		}))
			.then(res => callback(res))
		;
	}

	updatePinnedRowData = () => {
		const pinnedBottomRowData = getPinnedBottomRowData(this.props, this.gridApi);
		this.setState({ pinnedBottomRowData });
	}

	render()
	{
		const columnDefs = this.getDefs();
		const gridOptions = getGridOptions(this.props);
		const { pinnedBottomRowData } = this.state;

		const getRowNodeId = params => params.id;
		const isRowMaster = data => data.replacementId;

		const detailCellRendererParams = {
			onReady: this.getItemHistory,
			...this.props,
		};

		const getRowHeight = (params) => {
			if (params.node && params.node.detail) {
				const offset = 28 + 32;

				return offset + 32;
			} else {
				return 32;
			}
		};

		const rowData = this.props.items;

		return (
			<CustomGrid
				masterDetail={true}
				isRowMaster={isRowMaster}
				detailCellRenderer={'detailCellRenderer'}
				detailCellRendererParams={detailCellRendererParams}
				getRowNodeId={getRowNodeId}
				deltaRowDataMode={true}
				onGridReady={this.onGridReady}
				onSelectionChanged={this.onSelectionChanged}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={rowData}
				onSelectionChanged={this.onSelectionChanged}
				rowSelection={'multiple'}
				stopEditingWhenGridLosesFocus={true}
				onCellValueChanged={this.onCellValueChanged}
				suppressRowClickSelection={true}
				isExternalFilterPresent={this.isExternalFilterPresent}
				doesExternalFilterPass={this.doesExternalFilterPass}
				getRowHeight={getRowHeight}
				pinnedBottomRowData={pinnedBottomRowData}
				onModelUpdated={() => this.updatePinnedRowData()}
			/>
		);
	}
}

export default connect()(Grid);

