import React from 'react';
import { Uploader } from 'components/ui';
import { connect } from 'react-redux';

import api from 'core/api';
import db from 'core/db';
import ColumnSpec from 'core/xlsx-parser/src/ColumnSpec.js';
import Parser from 'core/xlsx-parser/src/Parser.js';
import ParsedRow from 'core/xlsx-parser/src/ParsedRow.js';
import NotParsedRow from 'core/xlsx-parser/src/NotParsedRow.js';
import HeaderRow from 'core/xlsx-parser/src/HeaderRow.js';
import { parseXlsx } from 'core/services/xlsx-parser.js';

class ImportPage extends React.PureComponent
{
	columns = {
		title: new ColumnSpec(
			[/^Наименование$/],
			true,
		),
		partNumber: new ColumnSpec(
			[/^Артикул$/],
			true,
		),
		manufacturerName: new ColumnSpec(
			[/^Производитель$/],
			true,
		),
		consumableUnitDescr: new ColumnSpec(
			[/^Единица$/],
			true,
		),
		price: new ColumnSpec(
			[/^Цена$/],
			true,
		),
		vatPercents: new ColumnSpec(
			[/^НДС$/],
			false,
		),
	};

	initialState = {
		pricelist: null,
		diff: null,
		notParsed: null,
		inProgress: null,
	};

	state = this.initialState;

	mutate = (row) => {
		if (row instanceof NotParsedRow) {
			return row;
		}

		const unit = this.props.consumableUnitsMap[row.data.consumableUnitDescr.toLowerCase()];

		if (!unit) {
			return row;
		}

		return new ParsedRow(
			row.index,
			{
				...row.data,
				price: parseFloat(row.data.price).toFixed(2),
				consumableUnitId: unit.id,
				partNumber: row.data.partNumber.toString(),
				manufacturerName: row.data.manufacturerName.toString(),
				title: row.data.title.toString(),
			}
		);
	}

	parse = async (fileWrapper, result) => {
		const { file } = fileWrapper;
		const parser = new Parser(this.columns);
		const parsedSheet = parser.parse(result.body);

		const plainify = rowGroup => ([
			...rowGroup.rows,
			...rowGroup.childs.map(plainify).flat(),
		]);

		const rows = plainify(parsedSheet.worksheets[0].rootRowGroup)
			.filter(r => !(r instanceof HeaderRow))
			.map(this.mutate)
		;

		const notParsed = rows.filter(r => !r.data.consumableUnitId);
		const parsed = rows.filter(r => !!r.data.consumableUnitId);

		const pricelistTitle = file.name.replace(/\..*$/, '');

		const pricelist = this.props.consumablePricelists.find(l => l.title === pricelistTitle);

		const existsItems = pricelist
			? await this.props.dispatch(api.consumablePricelistItems().list({filter: {
				consumablePricelistId: pricelist.id,
			}}))
			: []
		;

		const diff = this.makeDiff(existsItems, parsed);

		this.setState({
			pricelist: pricelist || {
				title: pricelistTitle,
				supplierTitle: pricelistTitle,
			},
			notParsed,
			diff,
		});
	}

	makeDiff(existsItems, rows)
	{
		const key = item => JSON.stringify([
			item.title,
			item.manufacturerName,
			item.partNumber,
			item.consumableUnitId
		]);

		const changes = (oldItem, newItem) => {
			const changes = {};

			if (newItem.price !== oldItem.price) {
				changes.price = newItem.price;
			}

			const newVat = newItem.vatPercents || null;

			if (newVat !== oldItem.vatPercents) {
				changes.vatPercents = newVat;
			}

			return Object.keys(changes).length > 0 ? changes : null;
		};

		const existsItemsMap = existsItems.reduce((map, item) => {
			map[key(item)] = item;

			return map;
		}, {});

		const dels = { ...existsItemsMap };

		const appends = [];
		const updates = [];

		for (const { data: item } of rows) {
			const k = key(item);

			delete dels[k];

			const exists = existsItemsMap[k];

			if (exists) {
				const changed = changes(exists, item);

				if (!changed) {
					continue;
				}

				if (exists.isDeleted) {
					changed.isDeleted = false;
				}

				updates.push({
					old: exists,
					new: item,
					changes: changed,
				});

				continue;
			}

			appends.push(item);
		}

		const deletions = Object.values(dels).filter(e => !e.isDeleted);

		return {
			appends,
			updates,
			deletions,
		};
	}

	commit = async () => {
		let num = 0;

		let actions = [];

		const flush = async () => {
			if (!actions.length) {
				return;
			}

			const buf = actions;

			num += actions.length;

			this.setState({
				inProgress: num,
			});

			actions = [];

			await this.props.dispatch(api.transaction().execute(buf));
		};

		const send = async action => {
			actions.push(action.createWith({
				isAffectsSelf: false,
			}));

			if (actions.length >= 100) {
				await flush();
			}
		};

		let pricelist = this.state.pricelist;

		if (!pricelist.id) {
			pricelist = await this.props.dispatch(api.consumablePricelists().create({
				companyId: this.props.company.id,
				...pricelist,
			}));
		}

		for (const append of this.state.diff.appends) {
			await send(api.consumablePricelistItems().create({
				consumablePricelistId: pricelist.id,
				...append,
			}));
		}

		for (const update of this.state.diff.updates) {
			await send(api.consumablePricelistItems().update({
				consumablePricelistItemId: update.old.id,
				changes: update.changes,
			}));
		}

		for (const del of this.state.diff.deletions) {
			await send(api.consumablePricelistItems().update({
				consumablePricelistItemId: del.id,
				changes: {
					isDeleted: true,
				},
			}));
		}

		await flush();
		await this.props.dispatch(api.consumablePricelistItems().list().replaceCache());

		this.setState({
			inProgress: 'Done!',
		});
	}

	renderDiff()
	{
		const columns = Object.keys(this.columns);

		return <>
			<h2>Строки, которые не отпарсились</h2>
			<table>
				<tbody>
					{this.state.notParsed.map(({index, data}) => (
						<tr key={index}>
							<td>{JSON.stringify(data)}</td>
						</tr>
					))}
				</tbody>
			</table>

			<h2>Изменения</h2>
			<table>
				<thead>
					<tr>
						{columns.map(n => <th key={n}>{n}</th>)}
					</tr>
				</thead>

				<tbody>
					<tr>
						<th colSpan={columns.length}><h2>Новые ({this.state.diff.appends.length})</h2></th>
					</tr>
					{this.state.diff.appends.map((item, index) => (
						<tr key={index}>
							{columns.map(c => (
								<td key={c}>
									{item[c]}
								</td>
							))}
						</tr>
					))}

					<tr>
						<th colSpan={columns.length}><h2>Удалённые ({this.state.diff.deletions.length})</h2></th>
					</tr>

					{this.state.diff.deletions.map((item, index) => (
						<tr key={index}>
							{columns.map(c => (
								<td key={c}>
									{item[c]}
								</td>
							))}
						</tr>
					))}

					<tr>
						<th colSpan={columns.length}><h2>Изменённые ({this.state.diff.updates.length})</h2></th>
					</tr>

					{this.state.diff.updates.map((item, index) => (<tr key={index}>
						{columns.map(c => (
							<td key={c}>
								{item.new[c]}
							</td>
						))}
					</tr>))}
				</tbody>
			</table>
		</>;
	}

	render()
	{
		return <div>
			<Uploader
				onFileListChanged={fileList => this.setState({fileList})}
				multiple={true}
				accept={'.xlsx'}
				request={file => parseXlsx(file)}
				afterUpload={this.parse}
			/>

			{this.state.inProgress !== null && <>
				<h3>{this.state.inProgress}</h3>
			</>}

			{this.state.inProgress === null && this.state.diff && <>
				<button onClick={this.commit} value={'Submit'} >Submit</button>

				{this.renderDiff()}
			</>}
		</div>;
	}
}

ImportPage.propTypes = {};

ImportPage.defaultProps = {};

export default connect((state) => ({
	company: state.company,
	consumablePricelists: db.consumablePricelists.list(),
	consumableUnitsMap: db.consumableUnits.list().reduce((map, unit) => ({
		...map,
		[unit.suffix]: unit,
	}), {}),
}))(ImportPage);
