import React from 'react';
import PropTypes from 'prop-types';
import Form from 'components/ui/forms/formik/Form';
import { formatISO } from 'date-fns';
import { pickBy, identity } from 'lodash';

import styles from './styles/custom-bootstrap.module.sass';
import * as yup from 'yup';


const prepareSubmitValues = (values) => {
	const copy = { ...values };
	const { dates, ...other} = copy;

	return pickBy({
		estimatedStartDate: dates && dates[0] ? formatISO(dates[0], { representation: 'date' }) : null,
		estimatedEndDate: dates && dates[1] ? formatISO(dates[1], { representation: 'date' }) : null,
		...other,
	}, identity);
};

class ProjectEditForm extends React.PureComponent {

	static propTypes = {
		onFormCreated: PropTypes.func.isRequired,
		onSubmit: PropTypes.func,
		employees: PropTypes.arrayOf(PropTypes.object).isRequired,
	}

	render()
	{
		const { onSubmit, onFormCreated, employees } = this.props;


		const simplifiedSchema = [
			[
				{ id: 'title', type: 'text', label: 'Название проекта', sm: 6 },
				{ id: 'dates', type: 'daterange', label: 'Даты начала и завершения' },
			],
			[
				{ id: 'address', type: 'text', label: 'Адрес объекта' },
				{ id: 'assignedUserId', type: 'select', label: 'Руководитель проекта' },
			],
		];

		const inputsProps = {
			title: {
				placeholder: "Укажите короткое название",
			},
			address: {
				placeholder: "Можно указать координаты или населённый пункт",
			},
			dates: { placeholders: ['Выберите дату начала', 'Выберите дату завершения'] },
			assignedUserId: {
				placeholder: "Не назначен",
				options: employees.map(e => ({
					value: e.id,
					label: e.fullName || e.email || e.phoneNumber,
				})),
			},
		};

		const required = 'Необходимо заполнить';

		const validationSchema = yup.object().shape({
			title: yup.string().required(required),
			address: yup.string().required(required),
		});

		return <Form
			className={styles.formCustom}
			simplifiedSchema={simplifiedSchema}
			inputsProps={inputsProps}
			onFormCreated={onFormCreated}
			onSubmit={changes => onSubmit(prepareSubmitValues(changes))}
			validationSchema={validationSchema}
		/>;

	}
}

export default ProjectEditForm;
