import React from 'react';
import PropTypes from 'prop-types';
import Action from '../Action';


class ActionsToolbar extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object),
		className: PropTypes.string,
	}

	static defaultProps = {
		actions: []
	}

	renderSeparator = (index, size) => {
		if (size < 2 || (index + 1) === size) {
			return null;
		}

		return <span className="p-1"/>;
	}

	render()
	{
		const { actions } = this.props;
		const size = actions.length;

		return (
			<div className={this.props.className}>
				{actions.map((action, index) => {

					return (
						<span key={index}>
							<Action {...action}/>
							{this.renderSeparator(index, size)}
						</span>
					);
				})}
			</div>
		);
	}
}

export default ActionsToolbar;
