import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormikForm from 'components/ui/forms/formik/Form';
import { pickBy, isNil } from 'lodash';
import { formatISO9075 } from 'date-fns';
import { parseISO } from 'date-fns/esm';

const prepareSubmitValues = values => {
	const res = pickBy({
		...values,
		date: values.date ? formatISO9075(values.date, { representation: 'date' }) : null,
		arriveTime: values.arriveTime ? formatISO9075(values.arriveTime, { representation: 'time' }) : null,
		timeWorked: values.timeWorked ? formatISO9075(values.timeWorked, { representation: 'time' }) : null,
		isAbsence: isNil(values.isPresent) ? null : !values.isPresent,
	}, v => !isNil(v));

	delete res.isPresent;

	return res;
};

const prepareInitialValues = values => {
	const date = formatISO9075(new Date(), { representation: 'date' });

	return pickBy({
		...values,
		date: values.date ? parseISO(values.date) : null,
		arriveTime: values.arriveTime ? parseISO(`${date}T${values.arriveTime}`) : null,
		timeWorked: values.timeWorked ? parseISO(`${date}T${values.timeWorked}`) : null,
		isPresent: isNil(values.isAbsence) ? null : !values.isAbsence,
	}, v => !isNil(v));
};

const WorkerDayForm = (props) => {
	const [values, setValues] = useState(null);

	const simplifiedSchema = [
		[{ id: 'workerId', type: 'select', label: 'Имя сотрудника' }],
		[{ id: 'projectId', type: 'select', label: 'Проект' }],
		[
			{ id: 'date', type: 'date', label: 'Дата', sm: 5 },
			{ id: 'isPresent', type: 'check', label: ' ' }
		],
		[
			{ id: 'arriveTime', type: 'time', label: 'Время прихода', sm: 4 },
			{ id: 'timeWorked', type: 'time', label: 'Отработано', sm: 4 }
		],
	];

	const inputsProps = {
		workerId: {
			options: props.workers.map(w => ({ value: w.id, label: w.name })),
			placeholder: "Выберите сотрудника",
		},
		projectId: {
			options: props.projects.map(p => ({ value: p.id, label: p.title })),
			placeholder: "Выберите проект",
		},
		isPresent: {
			id: 'worker_day_form_is_present_check',
			label: 'Присутствовал',
		},
		arriveTime: { placeholder: "Выберите время", readOnly: !values?.isPresent},
		timeWorked: { placeholder: "Выберите время", readOnly: !values?.isPresent},
	};

	const { workerDay, onFormCreated, onSubmit } = props;
	const isEditing = !!workerDay?.id;

	return <FormikForm
		inputsProps={inputsProps}
		simplifiedSchema={simplifiedSchema}
		onFormCreated={props => onFormCreated(props)}
		initialValues={workerDay ? prepareInitialValues(workerDay) : { date: new Date() }}
		onSubmit={changes => onSubmit(prepareSubmitValues(changes))}
		changesOnly={isEditing}
		onChange={values => setValues(values)}
		onCancel={() => props.onCancel()}
	/>;
};

WorkerDayForm.propTypes = {
	onFormCreated: PropTypes.func,
	onSubmit: PropTypes.func,
	onCancel: PropTypes.func,
	workers: PropTypes.arrayOf(PropTypes.object),
	workerDay: PropTypes.object,
	projects: PropTypes.arrayOf(PropTypes.object),
};

WorkerDayForm.defaultProps = {
	workers: [],
	projects: [],
	workerDay: {},
};

export default WorkerDayForm;
