import React from 'react';
import { CustomGrid } from 'components/ui/grid';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { getColumnDefs, getGridOptions } from './defs';


class DetailCellRenderer extends React.PureComponent
{
	state = {
		item: null,
	}

	static propTypes = {
		onReady: PropTypes.func,
		consumableUnitsMap: PropTypes.any.isRequired,
		consumableSheet: PropTypes.object.isRequired,
	}

	componentDidMount()
	{
		const { data, onReady, node } = this.props;
		onReady(node, data, this.onHistoryRecieved);
	}

	onHistoryRecieved = result => {
		const keysToTrack = ['title', 'manufacturerName', 'parNumber'];
		const containsKeys = changes => Array.from(Object.keys(changes)).some(key => keysToTrack.includes(key));

		const resArr = Object.values(result)
			.filter(i => i && i.changes && containsKeys(i.changes))
			.sort((a, b) => (a.ts < b.ts ? -1 : 1))
		;

		if (!resArr.length) {
			return;
		}

		this.setState({ item: resArr[resArr.length - 1]?.state?.old });
	}

	onFirstDataRendered = params => {
		this.gridApi.sizeColumnsToFit();
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	render()
	{
		const { consumableSheet } = this.props;
		const { item } = this.state;
		const rowData = item ? [{...item, consumableSheet}] : null;

		const gridOptions = getGridOptions(this.props);
		const columnDefs = getColumnDefs(this.props);

		return (
			<div
				style={{ backgroundColor: '#EDF6FF' }}
				className={cn('d-flex', 'position-absolute', 'w-100', 'h-100', 'p-1')}
			>
				<CustomGrid
					gridOptions={gridOptions}
					columnDefs={columnDefs}
					rowData={rowData}
					onGridReady={this.onGridReady}
					onFirstDataRendered={this.onFirstDataRendered}
					rowHeight={32}
				/>
			</div>
		);
	}
}

export default DetailCellRenderer;

