import React from 'react';

import Workspace from 'layouts/Default/components/Workspace';
import { estimateTabsRoutes } from './routes';

class Estimate extends React.PureComponent {
	render() {
		const projectId = this.props.match.params.projectId;
		const actions = [{
			title: 'Импорт',
			onClick: () => {
				this.props.history.push({
					pathname: `/projects/${projectId}/supplies/estimate/import`,
				});
			},
		}];

		return (
			<Workspace routes={estimateTabsRoutes} actions={actions} />
		);
	}
}

export default Estimate;
