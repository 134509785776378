import { compareAsc } from 'date-fns';
import { UserCellRenderer, ActionsCellRenderer } from 'components/ui/grid/renderers';

import JobProgressCellRenderer from './components/JobProgressCellRenderer';
import JobDatesCellRenderer from './components/JobDatesCellRenderer';
import StageDatesCellRenderer from './components/StageDatesCellRenderer';

import { faListAlt } from '@fortawesome/free-solid-svg-icons';


export const getGridOptions = params => {

	const frameworkComponents = {
		jobProgressCellRenderer: JobProgressCellRenderer,
		jobDatesCellRenderer: JobDatesCellRenderer,
		stageDatesCellRenderer: StageDatesCellRenderer,
		userCellRenderer: UserCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
	};

	const defaultColDef = {
		minWidth: 40,
		resizable: true,
		sortable: true,
		editable: false,
		suppressSizeToFit: true,
	};

	const gridOptions = {
		frameworkComponents,
		defaultColDef,
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const { onAddReportClick } = params;

	const groupCellStyle = params => {
		const data = params.data;

		return data && data.itemType === 'stage' ? { fontWeight: '500' } : null;
	};

	const groupCellRendererParams = {
		suppressDoubleClickExpand: true,
		suppressSizeToFit: false,
		checkbox: params => params.data && params.data.itemType === 'job',
	};

	const groupColumn = {
		headerName: 'Наименование',
		field: 'title',
		suppressSizeToFit: false,
		showRowGroup: true,
		cellStyle: groupCellStyle,
		cellRenderer: "agGroupCellRenderer",
		cellRendererParams: groupCellRendererParams,
		//rowDrag: params => params.data && params.data.itemType === 'job',
	};

	const getActions = params => {
		return [
			{
				icon: faListAlt,
				onClick: params => onAddReportClick(params),
				tooltip: 'Добавить отчёт о работе',
			}
		];
	};

	const columnDefs = [
		{
			field: 'order',
			hide: true,
			comparator: (a, b) => {
				return a.localeCompare(b);
			},
		},
		groupColumn,
		{
			headerName: 'Готовность',
			cellRendererSelector: params => {
				if (params.data && params.data.itemType === 'job') {
					return { component: 'jobProgressCellRenderer' };
				}

				return null;
			},
		},
		{
			headerName: 'Сроки',
			cellRendererSelector: params => {
				if (!params.data) {
					return null;
				}

				if (params.data.itemType === 'job') {
					return { component: 'jobDatesCellRenderer' };
				} else if (params.data.itemType === 'stage') {
					return { component: 'stageDatesCellRenderer' };
				}

				return null;
			},
			field: 'estimatedStartDate',
			comparator: (a, b) => {
				return compareAsc(new Date(a), new Date(b));
			},
		},
		{
			headerName: 'Отвественный',
			field: 'assignedUser',
			cellRenderer: 'userCellRenderer',
		},
		{
			headerName: '#',
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: params => {
				if (params.data.itemType === 'stage') {
					return null;
				}

				const actions = getActions(params);

				return { actions, flash: true };
			},
			width: 50,
		}
	];

	return columnDefs;
};
