import { makeCancelable } from './promise';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

const useCancelableDebouncedApiCall = (apiMethod, props, delay) => {
	const [result, setResult] = useState(null);
	const [inProgress, setInProgress] = useState(false);

	const cancelablePromise = useRef(null);

	const _debounced = useRef(debounce((callback) => {
		callback();
	}, delay), [delay]);


	useEffect(() => {
		return () => {
			_debounced.current.cancel();
			if (cancelablePromise.current) {
				cancelablePromise.current.cancel();
			}
		};
	}, []);

	return [
		result,
		inProgress,
		() => _debounced.current(() => {
			if (cancelablePromise.current) {
				cancelablePromise.current.cancel();
			}

			cancelablePromise.current = makeCancelable(props.dispatch(apiMethod));

			setInProgress(true);

			cancelablePromise.current.promise
				.then((res) => {
					setResult(res);
					setInProgress(false);
				})
				.catch((error) => {
					if (!error.isCanceled) {
						console.log(error);
						setInProgress(false);
					}
				})
			;
		}),
		() => {
			_debounced.current.cancel();
			if (cancelablePromise.current) {
				cancelablePromise.current.cancel();
			}
		}
	];
};

export default useCancelableDebouncedApiCall;
