import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './style.module.sass';


class Page extends React.PureComponent
{
	static propTypes = {
		className: PropTypes.string,
		style: PropTypes.object,
	}

	render()
	{
		const { className, style } = this.props;

		return (
			<div className={cn(styles.wrapper, className)} style={style}>
				{this.props.children}
			</div>
		);
	}
}

export default Page;
