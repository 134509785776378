import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';


class Empty extends React.PureComponent
{
	static propTypes = {
		title: PropTypes.string,
	}

	static defaultProps = {
		title: 'Нет данных',
	}

	render()
	{
		const style = {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			opacity: 0.3,
			...this.props.style,
		};

		return (
			<div style={style} className={this.props.className}>
				{this.props.title}
				<FontAwesomeIcon
					style={{fontSize: '1.4em'}}
					icon={faArchive}
					fixedWidth
				/>
			</div>
		);
	}
}

export default Empty;
