
import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import api from "core/api";
import { Button } from 'react-bootstrap';
import { Empty } from 'components/ui';


class CreationView extends React.PureComponent
{
	state = {
		loading: false,
	}

	static propTypes = {
		projectId: PropTypes.string.isRequired,
	}

	create = () => {
		this.setState({loading: true});

		this.props.dispatch(api.tbsConsolidatedSpecifications().create({
			projectId: this.props.projectId,
		}))
			.then(() => {
				// message.success('Сводная спецификаци создана!');
			})
			.catch((err) => {
				console.error(err);
				// message.error('Ошибка оздания сводной спкцификации...');
			})
			.finally(() => {
				this.setState({loading: false});
			})
		;
	}

	render() {
		return (
			<div className="flex-grow-1 d-flex flex-column align-items-center justify-content-center">
				<Empty title="Нет сводных спецификаций..."/>
				<Button
					onClick={this.create}
					disabled={this.state.loading}
					className="mt-4"
				>
					Создать новую
				</Button>
			</div>
		);
	}
}

export default connect()(CreationView);

