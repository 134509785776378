class Aggregator
{
	constructor(params)
	{
		this.params = params;
		this._getPurchaseItemsMapByConsumableItemId();
	}

	_getSourceSpecasByConsumableItem = csi => {
		const { params } = this;
		const { sourceSpecItemsMap } = params;

		const sourceSpecificationItemIds = csi?.consolidatedSpecificationItem?.sourceSpecificationItemIds || [];
		const specIds = [];

		for (const ssiId of sourceSpecificationItemIds) {
			const ssi = sourceSpecItemsMap.get(ssiId);

			const { sourceSpecificationId } = ssi;
			specIds.push(sourceSpecificationId);
		}

		return specIds;
	}

	getGridItems()
	{
		const { _purchaseItemsMapByConsumableItemId, params } = this;
		const { consumableSheetItems, consumableSheet, usersMap } = params;

		return consumableSheetItems.map(item => {
			const purchaseItems = _purchaseItemsMapByConsumableItemId.get(item.id) || [];

			return {
				...item,
				orderedAmount: purchaseItems.reduce((acc, item) => {
					return acc + Number(item.amount);
				}, 0),
				requestAmount: 0,
				notes: null,
				consumableSheet,
				lockedByUser: usersMap.get(item.lockedByUserId),
				sourceSpecIds: this._getSourceSpecasByConsumableItem(item),
			};
		});
	}

	_getPurchaseItemsMapByConsumableItemId()
	{
		const { purchaseRequestItems } = this.params;

		const map = new Map();

		for (const item of purchaseRequestItems) {
			const id = item.consumableSheetItemId;

			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this._purchaseItemsMapByConsumableItemId = map;
	}
}

export default Aggregator;
