import { Options } from '../types';

export default class Normalizer
{
	constructor(data, options, params)
	{
		if (!data)
		{
			throw (new Error("data is required"));
		}

		if (!(options instanceof Options)) {
			throw new Error('options must be instance of Options');
		}

		this.options = options;
		this.data = data;
		this.params = params;
	}

	normalize() {}
}
