import Report from './components/Report';
import GanttControl from './components/GanttControl';
import Scope from './components/Scope';
import Plan from './components/Plan';
import ReportList from '../periodic-reports/components/ReportList';

export const tabsRoutes = [
	{
		path: "/projects/:projectId/plan/scope",
		component: Scope,
		title: 'Таблица',
	},
	{
		path: "/projects/:projectId/plan/gantt",
		component: GanttControl,
		title: 'Гантт',
	},
	{
		path: "/projects/:projectId/plan/periodic-reports",
		component: ReportList,
		title: 'Отчёты',
	},
];

export const mainRoutes = [
	{
		path: "/report",
		component: Report,
		title: 'Аналитика',
	},
	{
		path: "/plan",
		component: Plan,
		title: 'План',
	},
];
