/* eslint-disable no-bitwise */

const BASIC_COLORS = [
	0xFFA500, //orange
	0x808000, //olive
	0x800080, //purple
	0x800000, //maroon
	0xFFFF00, //yellow
	0x008000, //green
	0xFF00FF, //funchia
	0x00FF00, //lime
	0x00FFFF, //aqua
	0x008080, //teal
	0x0000FF, //blue
	0x000080, //navy
	0xFF0000, //red
	// 0x000000, //black
	// 0x808080, //gray
	// 0xC0C0C0, //silver
	// 0xFFFFFF, //white
];

const RGBAToHEX = (hex, alpha) => {
	const r = hex >> 16 & 0xFF;
	const g = hex >> 8 & 0xFF;
	const b = hex & 0xFF;
	const whiteBgCalc = 0xFF * (1 - alpha);

	const newR = Math.round(r * alpha + whiteBgCalc) << 16;
	const newG = Math.round(g * alpha + whiteBgCalc) << 8;
	const newB = Math.round(b * alpha + whiteBgCalc);

	return (newR | newG | newB);
};

class ColorsGenerator
{
	constructor(aplhaInit = 1.0, alphaStep = 0.1, basicColors = BASIC_COLORS)
	{
		this.alphaStep = alphaStep;
		this.aplhaInit = aplhaInit;
		this.basicColors = basicColors;
	}

	*iterator ()
	{
		const { alphaStep, basicColors, aplhaInit } = this;

		let alpha = aplhaInit;
		let i = 0;
		const bcl = basicColors.length;

		while (true) {
			const bci = i % bcl;

			if (i === (bcl - 1)) {
				const nextAlpha = alpha - alphaStep;
				alpha = (nextAlpha < 0.1 ? aplhaInit : nextAlpha);
				i = 0;
			} else {
				i++;
			}

			yield RGBAToHEX(basicColors[bci], alpha).toString(16).padStart(6, '0');
		}
	}
}

export default ColorsGenerator;
