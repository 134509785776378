import React, { useState } from 'react';

import { tabsRoutes } from '../routes';
import Workspace from 'layouts/Default/components/Workspace';
import JobEditModal from './JobEditModal';
import StageEditModal from './StageEditModal';
import { add } from 'date-fns';

const today = new Date();
const tomorrow = add(new Date(), { days: 1 });

const Plan = (props) => {
	const [jobModalVisible, setJobModalVisisble] = useState(false);
	const [stageModalVisible, setStageModalVisible] = useState(false);

	const actions = [{
		title: '+ Работа',
		onClick: () => setJobModalVisisble(true),
	}, {
		title: '+ Участок',
		onClick: () => setStageModalVisible(true),
	}];

	return (
		<React.Fragment>
			<JobEditModal
				visible={jobModalVisible}
				onClose={() => setJobModalVisisble(false)}
				title="Добавление работы"
				initialValues={{
					dates: [today, tomorrow],
					numberOfEmployedWorkers: 1,
					workerDayHours: 8,
				}}
			/>
			<StageEditModal
				visible={stageModalVisible}
				onClose={() => setStageModalVisible(false)}
				title="Добавление участка"
			/>
			<Workspace
				routes={tabsRoutes}
				actions={actions}
			/>
		</React.Fragment>
	);
};

export default Plan;
