export class Day {
	constructor(date, value, color) {
		this.date = date;
		this.value = value;
		this.color = color;
	}
}

export class DayFormat {
	constructor(date) {
		this.date = date;
	}
}

export class WeekFormat {
	constructor(start, end) {
		this.start = start;
		this.end = end;
	}
}

export class EmptyDay {}
