import { add, differenceInDays } from 'date-fns';

const getLaborCostsPerUnit = ({dates, totalProgress, numberOfEmployedWorkers, workerDayHours}) => {
	if (!dates || !totalProgress || !numberOfEmployedWorkers || !workerDayHours) {
		return null;
	}

	const [start, end] = dates;

	if (!start || !end) {
		return null;
	}

	const daysCount = differenceInDays(end, start) + 1;

	return daysCount * Number(workerDayHours) * Number(numberOfEmployedWorkers) / Number(totalProgress);
};

const getNumberOfEmployeedWorkers = ({laborCostsPerUnit, totalProgress, workerDayHours, dates}) => {

	if (!dates || !totalProgress || !laborCostsPerUnit || !workerDayHours) {
		return null;
	}

	const [start, end] = dates;

	if (!start || !end) {
		return null;
	}


	const daysCount = differenceInDays(end, start) + 1;

	return Math.ceil(Number(laborCostsPerUnit) * Number(totalProgress) / (daysCount * workerDayHours));
};

const getEndDate = ({ laborCostsPerUnit, totalProgress, workerDayHours, numberOfEmployedWorkers, startDate }) => {
	if (!numberOfEmployedWorkers || !totalProgress || !laborCostsPerUnit || !workerDayHours || !startDate) {
		return null;
	}

	const dayCount = Math.ceil(laborCostsPerUnit * totalProgress / (workerDayHours * numberOfEmployedWorkers));

	const endDate = add(startDate, { days: dayCount });

	return endDate;
};

const getTotalLaborCosts = ({ laborCostsPerUnit, totalProgress }) => {
	if (!laborCostsPerUnit || !totalProgress) {
		return null;
	}

	return Number(laborCostsPerUnit) * Number(totalProgress);
};

const getDailyProgress = ({ totalProgress, dates }) => {
	if (!totalProgress || !dates) {
		return null;
	}

	const [start, end] = dates;

	if (!start || !end) {
		return null;
	}

	const daysCount = differenceInDays(end, start) + 1;

	return Number(totalProgress) / Number(daysCount);
};

const getDaysDifference = ({ endDate, calculated }) => {
	if (!endDate || !calculated?.endDate) {
		return null;
	}

	return differenceInDays(calculated.endDate, endDate);
};


const getEmployeesDiffrence = ({numberOfEmployedWorkers, calculated }) => {
	if (!numberOfEmployedWorkers || !calculated?.numberOfEmployedWorkers) {
		return null;
	}

	return Number(numberOfEmployedWorkers) - Number(calculated?.numberOfEmployedWorkers);
};

export function reducer(state, action) {
	switch (action.type) {
		case 'laborCostsPerUnit':
			return {
				...state,
				laborCostsPerUnit: getLaborCostsPerUnit(action.values),
			};
		case 'numberOfEmployedWorkers':
			return {
				...state,
				numberOfEmployedWorkers: getNumberOfEmployeedWorkers(action.values),
			};
		case 'endDate':
			return ({
				...state,
				endDate: getEndDate(action.values)
			});
		case 'totalLaborCosts':
			return {
				...state,
				totalLaborCosts: getTotalLaborCosts(action.values),
			};
		case 'dailyProgress':
			return ({
				...state,
				dailyProgress: getDailyProgress(action.values),
			});
		case 'daysDifference':
			return ({
				...state,
				daysDifference: getDaysDifference(action.values),
			});
		case 'employeesDifference': {
			return ({
				...state,
				employeesDifference: getEmployeesDiffrence(action.values),
			});
		}
		default:
			throw new Error(`Action dosesn't exist!`);
	}
}
