import React from 'react';
import { toast } from 'react-toastify';
import { pick, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';
import cn from 'classnames';
import { Button } from 'react-bootstrap';

import db from 'core/db';
import api from 'core/api';
import notify from 'utils/suppliesNotifications';
import config from 'config';

import { Empty } from 'components/ui';

import {
	PricelistsToolbar,
	Grid,
	CurrencyRatesModal,
	RequestModal,
	ConsumableItemModal,
	ChangingItemModal,
	TopToolbar,
} from './components';

import Aggregator from './helpers/Aggregator';
import styles from './styles.module.sass';


class BudgetView extends React.PureComponent
{
	state = {
		selectedNodes: [],
		loading: false,
		requestModalVisible: false,
		ratesModalVisible: false,
		consumableItemModalVisible: false,
		consumableItemModalLocked: false,
		requestModalLocked: false,
		filters: {},
		pricelistsMatchesMap: null,
		changingItemNode: null,
		changingModalVisible: false,
	}

	renderButtons = (buttons) =>
	{
		return (
			<div className={cn('d-flex', 'flex-row', 'align-items-center')}>
				{buttons.map((btn, index) => {
					return (
						<Button
							key={index}
							onClick={() => btn.onClick()}
							className="mr-1"
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}

	pageButtons = () => {
		const { consumableSheet } = this.props;

		if (!consumableSheet) {
			return [];
		}

		const { isApproved } = consumableSheet;

		return isApproved ? this.orderButtons() : this.sheetButtons();
	}

	orderButtons = () => {
		const nodes = this.state.selectedNodes;

		const buttons = [
			{
				title: 'Добавить позицию',
				onClick: () => this.openConsumableItemModal(),
			},
		];

		if (nodes.length) {
			buttons.push({
				title: 'Новая заявка'.concat(' ', `(${nodes.length})`),
				disabled: !nodes.length,
				onClick: () => this.openRequestModal(),
			});
		}

		return buttons;
	}

	sheetButtons = () => {
		const buttons = [
			{
				title: 'Добавить позицию',
				onClick: () => this.openConsumableItemModal(),

			},
			{
				title: 'Утвердить',
				onClick: () => this.approveSheet(),
			},
		];

		const { me } = this.props;
		const { selectedNodes } = this.state;
		const selectedRows = selectedNodes.map(node => node.data);

		const selectedReleased = selectedRows.filter(row => !row.lockedByUserId);
		const selectedLockedByUser = selectedRows.filter(row => row.lockedByUserId === me.id);

		const btnLockTitle = 'Взять в работу' + (selectedReleased.length ? ` (${selectedReleased.length})` : '');
		const btnReleaseTitle = 'Убрать из работы' + (selectedLockedByUser.length ? ` (${selectedLockedByUser.length})` : '');

		if (selectedReleased.length) {
			buttons.push({
				title: btnLockTitle,
				onClick: () => this.lockSelected(selectedReleased),
			});
		}

		if (selectedLockedByUser.length) {
			buttons.push({
				title: btnReleaseTitle,
				onClick: () => this.releaseSelected(selectedLockedByUser),
			});
		}

		return buttons;
	}

	onGridReady = (params, componentApi) => {
		this.gridApi = params.api;
		this.gridComponentApi = componentApi;
	}

	clearSelection = () => {
		this.gridApi.redrawRows({
			rowNodes: this.state.selectedNodes,
		});
		this.gridApi.deselectAll();
	}

	lockSelected = rows => {
		if (!rows.length) {
			return;
		}

		const transactionBody = rows.map(row => api.tbsConsumableSheetItems().lock({
			consumableSheetItemId: row.id
		}));

		this.setState({ loading: true });
		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(this.clearSelection)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось взять в работу", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => this.setState({ loading: false }))
		;
	}

	releaseSelected = rows => {
		if (!rows.length) {
			return;
		}

		const transactionBody = rows.map(row => api.tbsConsumableSheetItems().unlock({
			consumableSheetItemId: row.id
		}));

		this.setState({ loading: true });
		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(this.clearSelection)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось убрать из работы", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => this.setState({ loading: false }))
		;
	}

	approveSheet = () => {
		this.setState({ loading: true });
		this.props.dispatch(api.tbsConsumableSheets()
			.approve({consumableSheetId: this.props.consumableSheetId}))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось утвердить спецификацию", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(() => this.setState({ loading: false }))
		;
	}

	updateCurrencyRates = currencyRates => {
		this.props.dispatch(api.tbsConsumableSheets().update({
			consumableSheetId: this.props.consumableSheetId,
			changes: { currencyRates }
		}))
			.then(this.closeCurrencyRatesModal)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось именить значения валют", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	changeConsumableItem = changes => {
		const { changingItemNode } = this.state;

		const replaceItemRequest = api.tbsConsumableSheetItems().update({
			consumableSheetItemId: changingItemNode.data.id,
			changes: {
				...changes,
				replacementId: uuidv4().toUpperCase(),
			}
		});

		this.props.dispatch(replaceItemRequest)
			.then(() => {
				//! ag-grid баг, свойство master должно быть динамическое
				//! убрать когда исправят баг
				//! https://github.com/ag-grid/ag-grid-enterprise/issues/221
				changingItemNode.master = true;
				this.gridApi.redrawRows({ rowNodes: [changingItemNode] });
			})
			.then(() => this.setState({ changingItemNode: null }, () => this.closeChangingModal()))
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось заменить позицию", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
		;
	}

	createConsumableItem = item => {
		this.lockConsumableItemModal();
		this.props.dispatch(api.tbsConsumableSheetItems().create({
			consumableSheetId: this.props.consumableSheetId,
			...item,
		}))
			.then(this.closeConsumableItemModal)
			.catch((err) => {
				console.error(err);
				toast.error("Не удалось создать новую позицию...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(this.unlockConsumableItemModal)
		;
	}

	createRequest = transactionBody => {
		if (!transactionBody) {
			return;
		}

		this.lockRequestModal();

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(this.closeRequestModal)
			.then(this.clearSelection)
			.then(() => {
				notify(this.props.employees, 'new-request');
				toast.success('Новая заявка создана!', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.catch((err) => {
				console.error(err);
				toast.error('Не удалось создать заявку', {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
			})
			.finally(this.unlockRequestModal)
		;
	}

	onConfirmPricelist = (values, afterConfirm) => {
		const data = [];

		this.gridApi.forEachNodeAfterFilterAndSort(node => {
			data.push(node.data);
		});

		const { consumablePricelistsMap } = this.props;
		const queries = data.filter(d => d.partNumber).map(d => pick(d, ['partNumber']));

		const transactionBody = values.map(v => api.consumablePricelistItems().matchAll({
			filter: { consumablePricelistId: v.value },
			queries,
		}));

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(results => {
				const pricelistsMatchesMap = new Map();

				for (const { matches } of results) {
					for (const matchArray of matches) {
						for (const m of matchArray) {
							const key = this.getMatchKey(m);
							const mapValue = pricelistsMatchesMap.get(key);
							if (mapValue) {
								pricelistsMatchesMap.set(key, [...matchArray, ...mapValue]);
							} else {
								pricelistsMatchesMap.set(key, matchArray);
							}
						}
					}
				}

				pricelistsMatchesMap.forEach((value, key) => {
					const matches = uniqBy(value, 'consumablePricelistId').map(m => {
						return {
							...m,
							consumablePricelist: consumablePricelistsMap.get(m.consumablePricelistId),
						};
					});
					pricelistsMatchesMap.set(key, matches);
				});

				this.setState({ pricelistsMatchesMap }, () => this.updatePrices());
			})
			.catch(e => console.log('err', e))
			.finally(() => afterConfirm())
		;
	}

	updatePrices = () => {
		const transactionBody = [];
		const { pricelistsMatchesMap } = this.state;
		const { me } = this.props;
		const priceCurrencyId = config.tbs.priceCurrency.default.id;

		this.gridApi.forEachNode(node => {
			const { data } = node;
			const { id } = data;
			const key = this.getMatchKey(data);
			const pricesLists = pricelistsMatchesMap.get(key);

			const isAssignedToMe = data.lockedByUserId === me.id;

			if (pricesLists?.length && isAssignedToMe) {
				const { price } = pricesLists[0];

				transactionBody.push(api.tbsConsumableSheetItems().update({
					consumableSheetItemId: id,
					changes: {
						retailPrice: price,
						planPrice: data.planPrice,
						priceCurrencyId,
					}
				}));
			}
		});

		if (!transactionBody.length) {
			this.gridComponentApi.updateColumnDefs();

			return;
		}

		this.props.dispatch(api.transaction().execute(transactionBody))
			.then(() => this.gridComponentApi.updateColumnDefs())
			.catch(err => console.error(err))
		;
	}

	openChangingModal = () => this.setState({ changingModalVisible: true })

	closeChangingModal = () => this.setState({ changingModalVisible: false })

	openRequestModal = () => this.setState({requestModalVisible: true})

	closeRequestModal = () => this.setState({requestModalVisible: false})

	openConsumableItemModal = () => this.setState({ consumableItemModalVisible: true })

	closeConsumableItemModal = () => this.setState({ consumableItemModalVisible: false })

	openCurrencyRatesModal = () => this.setState({ ratesModalVisible: true })

	closeCurrencyRatesModal = () => this.setState({ ratesModalVisible: false })

	lockConsumableItemModal = () => this.setState({ consumableItemModalLocked: true })

	unlockConsumableItemModal = () => this.setState({ consumableItemModalLocked: false })

	lockRequestModal = () => this.setState({ requestModalLocked: true })

	unlockRequestModal = () => this.setState({ requestModalLocked: false })

	onFilterChange = filters => this.setState({ filters }, () => this.gridApi?.onFilterChanged())

	onChangeItemClick = changingItemNode => this.setState({ changingItemNode }, () => this.openChangingModal())

	getMatchKey = m => m.partNumber;

	getCurrencyRates = () => {
		const { consumableSheet } = this.props;

		return consumableSheet?.currencyRates || {};
	}

	render()
	{
		const { consumableSheet, consumablePricelists, isLoading } = this.props;
		const { changingItemNode } = this.state;

		if (!consumableSheet || isLoading) {
			return <Empty title="Нет сметы" className={cn('w-100', 'h-100')}/>;
		}

		const { isApproved } = consumableSheet;

		const topToolBar = <TopToolbar
			consumableSheet={this.props.consumableSheet}
			currenciesMap={this.props.currenciesMap}
			onRatesEditClick={this.openCurrencyRatesModal}
			items={this.props.gridItems}
			onFilterChange={this.onFilterChange}
			sourceSpecs={this.props.sourceSpecs}
		/>;

		const priceListsToolbar = !isApproved && <PricelistsToolbar
			consumablePricelists={consumablePricelists}
			onConfirmPricelist={this.onConfirmPricelist}
		/>;

		const requestModal = <RequestModal
			items={[...this.state.selectedNodes.map(node => node.data)]}
			visible={this.state.requestModalVisible}
			locked={this.state.requestModalLocked}
			onCancel={() => this.closeRequestModal()}
			onConfirm={params => this.createRequest(params)}
			currenciesMap={this.props.currenciesMap}
			purchaseRequestItems={this.props.purchaseRequestItems}
			consumableSheetId={this.props.consumableSheetId}
			purchaseRequestsMap={this.props.purchaseRequestsMap}
			consumableSheetItems={this.props.consumableSheetItems}
		/>;

		const ratesModal = <CurrencyRatesModal
			onCancel={() => this.closeCurrencyRatesModal()}
			onConfirm={rates => this.updateCurrencyRates(rates)}
			visible={this.state.ratesModalVisible}
			currencyRates={this.props.consumableSheet?.currencyRates}
			currencies={this.props.currencies}
			currenciesMap={this.props.currenciesMap}
		/>;

		const consumableItemModal = <ConsumableItemModal
			onCancel={() => this.closeConsumableItemModal()}
			onConfirm={item => this.createConsumableItem(item)}
			visible={this.state.consumableItemModalVisible}
			locked={this.state.consumableItemModalLocked}
			consumableUnits={this.props.consumableUnits}
		/>;

		const changingModal = changingItemNode && <ChangingItemModal
			onCancel={() => this.closeChangingModal()}
			onConfirm={item => this.changeConsumableItem(item)}
			visible={this.state.changingModalVisible}
			consumableUnits={this.props.consumableUnits}
			item={pick(changingItemNode.data, [
				'title',
				'partNumber',
				'manufacturerName',
			])}
		/>;

		return (
			<React.Fragment>
				{this.renderButtons(this.pageButtons())}
				<div className={cn('w-100', 'h-100', 'd-flex', 'flex-column', styles.tableContainer)}>
					{topToolBar}
					{priceListsToolbar}
					<Grid
						consumableSheet={this.props.consumableSheet}
						items={this.props.gridItems}
						currencies={this.props.currencies}
						currenciesMap={this.props.currenciesMap}
						consumableUnits={this.props.consumableUnits}
						consumableUnitsMap={this.props.consumableUnitsMap}
						onSelectionChanged={selectedNodes => this.setState({ selectedNodes })}
						onGridReady={this.onGridReady}
						me={this.props.me}
						filters={this.state.filters}
						pricelistsMatchesMap={this.state.pricelistsMatchesMap}
						getMatchKey={this.getMatchKey}
						getCurrencyRates={this.getCurrencyRates}
						onChangeItemClick={this.onChangeItemClick}
					/>
				</div>
				{requestModal}
				{ratesModal}
				{consumableItemModal}
				{changingModal}
			</React.Fragment>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectFilter = { filter: { projectId }};

	const consumableSheets = db.tbsConsumableSheets.list(projectFilter);
	const consumableSheet = consumableSheets[0];
	const consumableSheetId = consumableSheet?.id;

	const consolidatedSpecifications = db.tbsConsolidatedSpecifications.list(projectFilter);
	const consolidatedSpecification = consolidatedSpecifications[0];
	const consolidatedSpecificationId = consolidatedSpecification?.id;

	const consumableSheetFilter = { filter: { consumableSheetId } };
	const consSpecIdFilter = { filter: { consolidatedSpecificationId } };

	const collects = {
		consumableSheets,
		consolidatedSpecifications,
		consumableSheetItems: db.tbsConsumableSheetItems.list(consumableSheetFilter),
		purchaseRequests: db.tbsPurchaseRequests.list(consumableSheetFilter),
		sourceSpecs: db.tbsSourceSpecifications.list(consSpecIdFilter),
		sourceSpecItems: db.tbsSourceSpecificationItems.list(consSpecIdFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.list(),
		currencies: db.currencies.list(),
		users: db.users.list(),
		consumableUnits: db.consumableUnits.list(),
		consumablePricelists: db.consumablePricelists.list(),
		employees: db.employees.list(),
	};

	const maps = {
		currenciesMap: collects.currencies.hashById(),
		consumableUnitsMap: collects.consumableUnits.hashById(),
		usersMap: collects.users.hashById(),
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		sourceSpecItemsMap: collects.sourceSpecItems.hashById(),
		consumablePricelistsMap: collects.consumablePricelists.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading: true };
	}

	const aggregator = new Aggregator({...collects, ...maps});

	return {
		consumableSheet,
		consumableSheetId,
		me: state.entities.me,
		gridItems: aggregator.getGridItems(),
		...collects,
		...maps,
	};
};

export default connect(mapToProps)(BudgetView);
