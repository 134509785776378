import ImportPage from './components/ImportPage';

const routes = [
	{
		path: '/consumable-pricelists',
		title: 'Прайслисты',
		exact: true,
		redirect: true,
		to: '/consumable-pricelists/import',
		children: [
			{
				path: '/import',
				title: 'Импорт',
				component: ImportPage,
			},
		],
	},
];

export default routes;
