import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

class Document {
	constructor(xlsx)
	{
		this.xlsx = xlsx;
	}

	save(filename)
	{
		this.xlsx.writeBuffer({ base64: true })
			.then((data) => {
				const file = new File([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
				saveAs(file, filename);
			})
		;
	}
}

class RequestExport {
	createXLSX(request)
	{
		const items = request?.items || [];

		const rows = items.map(item => {
			const { consumableSheetItem } = item;

			return [
				consumableSheetItem?.title,
				consumableSheetItem?.partNumber,
				consumableSheetItem?.manufacturerName,
				item.amount
			];
		});

		const now = new Date();
		const workbook = new ExcelJS.Workbook();
		workbook.created = now;
		workbook.properties.date1904 = true;
		workbook.calcProperties.fullCalcOnLoad = true;

		const worksheet = workbook.addWorksheet(`Заявка №${request?.sn}`);

		worksheet.addRow(['Наименование', 'Артикул', 'Производитель', 'Количество']);
		worksheet.addRows(rows);

		worksheet.findRow(1).font = { bold: true };

		worksheet.columns = [
			{ width: 40 },
			{ width: 16 },
			{ width: 16 },
			{ width: 16 },
		];

		return new Document(workbook.xlsx);
	}
}

export default RequestExport;
