import React from 'react';
import ReactDOM from 'react-dom';
import InputModal from './InputModal';
import ConfirmModal from './ConfirmModal';
import SelectModal from './SelectModal';


class BasicModal
{
	static input(props) {
		const rootModal = document.getElementById('rootModal');

		const hide = () => {
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const confirm = (text) => {
			props.onConfirm(text);
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const modal = <InputModal
			title={props.title}
			placeholder={props.placeholder}
			text={props.text}
			onReject={hide}
			onConfirm={confirm}
		/>;

		ReactDOM.render(
			modal,
			rootModal
		);
	}

	static confirm(props) {
		const rootModal = document.getElementById('rootModal');

		const hide = () => {
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const confirm = () => {
			props.onConfirm();
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const modal = <ConfirmModal
			title={props.title}
			text={props.text}
			onReject={hide}
			onConfirm={confirm}
		/>;

		return ReactDOM.render(
			modal,
			rootModal
		);
	}

	static select(props) {
		const rootModal = document.getElementById('rootModal');

		const hide = () => {
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const confirm = value => {
			props.onConfirm(value);
			setTimeout(() => {
				ReactDOM.unmountComponentAtNode(rootModal);
			}, 300);
		};

		const modal = <SelectModal
			title={props.title}
			values={props.values}
			labels={props.labels}
			inputTitle={props.inputTitle}
			onReject={hide}
			onConfirm={confirm}
		/>;

		return ReactDOM.render(
			modal,
			rootModal
		);
	}
}

export default BasicModal;
