import {
	eachMonthOfInterval,
	eachDayOfInterval,
	startOfMonth,
	endOfMonth,
	formatISO,
	isWithinInterval,
	parseISO,
} from 'date-fns';


class ReportGenerator
{
	constructor(workers, workerDays)
	{
		this.workers = workers;
		this.workerDays = workerDays;

		const workerDaysByWorkerId = new Map();

		for (const wd of workerDays) {
			const mapOfDays = workerDaysByWorkerId.get(wd.workerId);

			if (mapOfDays) {
				mapOfDays.set(wd.date, wd);
			} else {
				const mapOfDays = new Map();
				mapOfDays.set(wd.date, wd);
				workerDaysByWorkerId.set(wd.workerId, mapOfDays);
			}
		}

		this.workerDaysByWorkerId = workerDaysByWorkerId;
	}

	_formatWorkedDaysToHours = workerDay => {
		if (workerDay.isAbsence || !workerDay.timeWorked) {
			return 0;
		}

		const timeWorked = workerDay.timeWorked.split(':');

		const hours = Number(timeWorked[0]);
		const minutes = Number(timeWorked[1]);
		const seconds = Number(timeWorked[2]);

		return Number(hours + minutes / 60 + seconds / 3600).toFixed(1);
	}

	// eslint-disable-next-line consistent-return
	generate(project, startDate, endDate)
	{
		try {
			const report = [];
			const { workers, workerDaysByWorkerId } = this;
			const projectWorkers = workers.filter(w => w.projectId === project.id);

			if (!projectWorkers.length) {
				return report;
			}

			const months = eachMonthOfInterval({ start: startDate, end: endDate });

			for (const month of months) {
				const monthReport = { month };

				const days = eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) });
				monthReport.days = days;

				const workersReports = [];

				for (const worker of workers) {
					const workerDaysMap = workerDaysByWorkerId.get(worker.id) || new Map();

					const arrayOfWorkerDays = [];

					for (const day of days) {
						const iso = formatISO(day, { representation: 'date' });
						const workerDay = workerDaysMap.get(iso);

						arrayOfWorkerDays.push(workerDay ? {
							...workerDay,
							workedHours: this._formatWorkedDaysToHours(workerDay),
						} : null);
					}

					const totalNumberOfHours = arrayOfWorkerDays.reduce((acc, day) => acc + Number(day?.workedHours || 0), 0);

					const otherProjectsDays = Array.from(workerDaysMap.values())
						.filter(wd => wd.projectId !== project.id && isWithinInterval(parseISO(wd.date), {
							start: startOfMonth(month),
							end: endOfMonth(month),
						}));

					const otherProjectsNumberOfHours = otherProjectsDays.reduce((acc, day) => acc + Number(this._formatWorkedDaysToHours(day)), 0);

					if (totalNumberOfHours <= 0 || totalNumberOfHours === otherProjectsNumberOfHours) {
						continue;
					}

					workersReports.push({
						worker,
						arrayOfWorkerDays,
						totalNumberOfHours,
						otherProjectsNumberOfHours,
					});
				}

				if (!workersReports.length) {
					continue;
				}

				monthReport.workersReports = workersReports;
				report.push(monthReport);
			}

			return report;
		} catch (e) {
			console.log(e);
		}
	}
}

export default ReportGenerator;
