import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import styles from './style.module.sass';


class WeekFormatCell extends React.PureComponent
{
	static propTypes = {
		dates: PropTypes.shape({
			start: PropTypes.any,
			end: PropTypes.any,
		})
	}

	render()
	{
		const { dates } = this.props;
		const { start, end } = dates;

		return <div className={styles.Cell}>
			{`${format(start, 'dd.MM')} - ${format(end, 'dd.MM')}`}
		</div>;
	}
}

export default WeekFormatCell;
