import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isNil } from 'lodash';

import styles from './style.module.sass';


class LabeledValue extends React.PureComponent
{
	static propTypes = {
		title: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.any,
		color: PropTypes.string,
		centered: PropTypes.bool,
		suffix: PropTypes.string,
	}

	static defaultProps = {
		suffix: '',
	}

	render()
	{
		const { title, label, value, color, centered, suffix } = this.props;

		return (
			<div className={cn(
				styles.LabeledValue,
				{ [styles.LabeledValue__centered]: centered},
			)}>
				{title ? <div className={styles.LabeledValue_title}>{title}</div> : null}
				{label ? <div className={styles.LabeledValue_label}>{label}</div> : null}
				<div
					className={styles.LabeledValue_value}
					style={{ color }}
				>
					{isNil(value) ? '-' : `${value}${suffix}`}
				</div>
			</div>
		);
	}
}

export default LabeledValue;
