import React from 'react';
import PropTypes from 'prop-types';
import { Form, Col } from 'react-bootstrap';
import { withFormik } from 'formik';

import { NumberInput, TextInput, SelectInput } from 'components/ui/forms';

class ConsumableItemForm extends React.PureComponent
{
	static propTypes = {
		onFormReady: PropTypes.func,
		onSubmit: PropTypes.func,
		onCancel: PropTypes.func,
		consumableUnits: PropTypes.arrayOf(PropTypes.object),
		initialItem: PropTypes.object,
	}

	static defaultProps = {
		consumableUnits: [],
		initialItem: {},
	}

	componentDidMount()
	{
		this.props.onFormReady(this.props);
	}

	render()
	{
		return (
			<Form>
				<Form.Group>
					<Form.Label>Наименование</Form.Label>
					<TextInput hocInputProps={{ name: "title" }} />
				</Form.Group>
				<Form.Group>
					<Form.Label>Тип</Form.Label>
					<TextInput hocInputProps={{ name: "partNumber" }} />
				</Form.Group>
				<Form.Group>
					<Form.Label>Производитель</Form.Label>
					<TextInput hocInputProps={{ name: "manufacturerName" }} />
				</Form.Group>
				<Form.Row>
					<Col>
						<Form.Group>
							<Form.Label>Количество</Form.Label>
							<NumberInput hocInputProps={{ name: "amount" }} />
						</Form.Group>
					</Col>
					<Col>
						<Form.Group>
							<Form.Label>Единицы измерения</Form.Label>
							<SelectInput
								hocInputProps={{ name: "consumableUnitId" }}
								items={this.props.consumableUnits}
								optionFields={['suffix']}
							/>
						</Form.Group>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

const mapPropsToValues = props => {
	return {...props.initialItem};
};

const handleSubmit = (values, { props, setSubmitting }) => {

	props.onSubmit(values);

	setSubmitting(false);
};

export default withFormik({ handleSubmit, mapPropsToValues })(ConsumableItemForm);
