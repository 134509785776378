import React from 'react';
import PropTypes from 'prop-types';

import { CustomGrid } from 'components/ui/grid';
import { getColumnDefs, getGridOptions } from './defs';

class Grid extends React.PureComponent {
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
	}

	render()
	{
		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		return (
			<CustomGrid
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				suppressRowClickSelection={true}
				suppressCellSelection={true}
			/>
		);
	}
}

export default Grid;
