import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import BootstrapForm from './components/bootstrap/Form';
import { isEqual as isDatesEqual } from 'date-fns';
import { forIn, isArray, isObject, pickBy, isNil, isEqual, isEmpty, isDate } from 'lodash';

const isElementsEqual = (first, second) => {
	if (isDate(first)) {
		return isDatesEqual(first, second);
	}

	return first === second;
};

const getArrayDiff = (arr, initialArr = []) => {
	if (!arr) {
		return null;
	}

	const diff = [];

	for (let i = 0; i < initialArr.length; ++i) {
		const element = arr[i];
		const initialElement = initialArr[i];
		const elementDiff = {};

		for (const key of Object.keys(initialElement)) {
			if (!isElementsEqual(initialElement[key], element[key])) {
				elementDiff[key] = element[key];
			}
		}

		diff.push(isEmpty(elementDiff) ? null : elementDiff);
	}

	const isDiffEmpty = !diff.filter(e => !isNil(e)).length;

	return isDiffEmpty ? null : diff;
};

export const formChanges = (values, initialValues = {}) => {
	const definedValues = pickBy(values, v => !isNil(v));

	if (isEmpty(definedValues)) {
		return null;
	}

	const changes = {};

	forIn(definedValues, (value, key) => {
		if (isArray(value)) {
			const initialArr = initialValues[key];
			const arr = value;
			const diff = getArrayDiff(arr, initialArr);

			if (diff) {
				changes[key] = diff;
			}
		} else if (isObject(value)) {
			const initial = initialValues[key] || {};
			if (!isEqual(value, initial)) {
				changes[key] = value;
			}
		} else if (value !== initialValues[key]) {
			changes[key] = value;
		}
	});

	return isEmpty(changes) ? null : changes;
};


const Form = (props) => {
	const {
		initialValues,
		validationSchema,
		validateOnChange,
		changesOnly,
		onCancel,
		onSubmit,
		children,
		...formProps
	} = props;

	const handleSubmit = (values, { setSubmitting }) => {
		if (changesOnly) {
			const changes = formChanges(values, initialValues);

			if (changes) {
				onSubmit(changes);
			} else {
				onCancel();
			}
		} else {
			onSubmit(values);
		}

		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={{...(initialValues || {})}}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			validateOnChange={validateOnChange}
		>
			<BootstrapForm {...formProps}>
				{children}
			</BootstrapForm>
		</Formik>
	);
};

Form.propTypes = {
	initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
	changesOnly: PropTypes.bool,
	validationSchema: PropTypes.object,
	validateOnChange: PropTypes.bool,
};

Form.defaultProps = {
	changesOnly: false,
	validateOnChange: false,
};

export default Form;
