import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';


export default class Loading extends React.PureComponent {

	static propTypes = {
		title: PropTypes.string,
		className: PropTypes.string,
		style: PropTypes.object,
	}

	static defaultProps = {
		title: null,
	}

	render() {
		const { className, style, title } = this.props;

		const icon = <FontAwesomeIcon
			className={cn('mr-2')}
			size="lg"
			fixedWidth
			spin={true}
			icon={faSpinner}
		/>;

		return (
			<div
				className={cn(
					'd-flex',
					'flex-row',
					'align-items-center',
					'justify-content-center',
					'text-muted',
					className
				)}
				style={style}
			>
				{icon}
				{title}
			</div>
		);
	}
}
