import React from 'react';
import Workspace from 'layouts/Default/components/Workspace';
import ProjectsList from './ListPage';


class Projects extends React.PureComponent {
	render() {
		const actions = [{
			title: 'Создать проект',
			onClick: () => {
				this.props.history.push({
					pathname: '/new-project',
				});
			},
		}];

		return (
			<Workspace actions={actions} hideTitle={true}>
				<ProjectsList />
			</Workspace>
		);
	}
}

export default Projects;
