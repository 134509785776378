import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import cn from 'classnames';


class CurrencyCellEditor extends React.PureComponent
{
	static propTypes = {
		selectField: PropTypes.string.isRequired,
		onSelectValueChange: PropTypes.func.isRequired,
		options: PropTypes.arrayOf(PropTypes.object).isRequired,
		defaultValue: PropTypes.any,
	}

	static defaultProps = {
		options: [],
	}

	state = {}

	constructor(props)
	{
		super(props);
		this.inputRef = React.createRef();
		this.selectRef = React.createRef();
	}


	componentDidMount()
	{
		this.timer = setTimeout(() => {

			const { selectField, defaultValue } = this.props;

			const selectValue = this.props.data[selectField] || defaultValue;
			this.selectRef.current.value = selectValue;

			const ch = this.props.charPress;
			const re = /^\d$/;
			const isDigit = re.test(ch);

			this.inputRef.current.focus();

			if (ch && isDigit) {
				this.inputRef.current.value = ch;
				this.setState({ value: ch, selectValue});
			} else {
				this.inputRef.current.value = this.props.value;
				this.inputRef.current.select();
				this.setState({value: this.props.value, selectValue});
			}
		});
	}

	componentWillUnmount()
	{
		if (this.inputRef.current.checkValidity()) {
			const { selectValue } = this.state;
			const { node, selectField, onSelectValueChange } = this.props;
			const value = selectValue || this.selectRef.current.value;

			if (selectValue) {
				onSelectValueChange({
					oldValue: node.data[selectField],
					field: selectField,
					value,
					node
				});
			}
		}

		delete this.timer;
	}

	getValue() {
		return this.state.value;
	}

	isPopup()
	{
		return false;
	}

	isCancelBeforeStart()
	{
		return false;
	}

	isCancelAfterEnd()
	{
		return !this.inputRef.current.checkValidity();
	}

	render()
	{
		const onChange = (e) => {
			const value = e.target.value;
			this.setState({ value });
		};

		const onSelectChange = (e) => {
			const selectValue = e.target.value;
			this.setState({ selectValue });
		};

		const { options } = this.props;

		return (
			<div className={cn('d-flex', 'flex-row', 'h-100', 'w-100', 'position-absolute')}>
				<input
					style={{ flex: '0.9 1 auto' }}
					className={style.cellNumberInput}
					ref={this.inputRef}
					type="number"
					step="0.01"
					onChange={onChange}
					min="0"
				/>
				<select
					style={{
						flex: '0.1 1 auto',
						minWidth: '30px',
						WebkitAppearance: 'none',
						border: 'none',
						textAlignLast: 'center'
					}}
					ref={this.selectRef}
					onChange={onSelectChange}
				>
					{options.map(opt =>
						<option key={opt.id} value={opt.id}>
							{opt.symbol}
						</option>
					)}
				</select>
			</div>
		);
	}
}

export default CurrencyCellEditor;
