import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.sass';
import { Pagination } from 'react-bootstrap';


const getPagination = (array, paginationSize) => {
	const length = array?.length;

	if (!length) {
		return null;
	}

	const size = length > paginationSize ? Math.ceil(length / paginationSize) : 0;

	if (!size) {
		return null;
	}

	return Array.from(Array(size).keys());
};

const getPaginationIntervals = (index, size, array) => {
	const start = index * size;
	const end = Math.min((index + 1) * size, array.length);

	return [start, end];
};


const Categories = (props) => {
	const [paginationNumber, setPaginationNumber] = useState(0);

	const { items, paginationSize } = props;

	const pagination = getPagination(items, paginationSize);
	const [paginationStart, paginationEnd] = getPaginationIntervals(paginationNumber, paginationSize, items);

	return (
		<div className={styles.Categories}>
			<div className={styles.Categories__list}>
				{items
					.slice(paginationStart, paginationEnd)
					.map((section, i) => (
						<div key={i}>
							<span onClick={() => props.onSelect(section)}>
								{section.title}
							</span>
						</div>
					))}
			</div>
			{pagination ? <Pagination size="sm" className={styles.Categories__pagination}>
				{pagination.map((_, i) => (
					<Pagination.Item
						key={i}
						active={i === paginationNumber}
						onClick={() => setPaginationNumber(i)}
					>
						{i + 1}
					</Pagination.Item>
				))}
			</Pagination> : null}
		</div>
	);
};

Categories.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	onSelect: PropTypes.func,
	paginationSize: PropTypes.number,
};

export default Categories;
