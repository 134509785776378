import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faQuestionCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


class Alert extends React.PureComponent
{
	static propTypes = {
		type: PropTypes.oneOf(['info', 'question', 'danger']),
	}

	static defaultProps = {
		type: 'info',
	}

	render()
	{
		const iconsMap = {
			'info': faInfoCircle,
			'question': faQuestionCircle,
			'danger': faTimesCircle,
		};

		const { type, children } = this.props;

		const isDanger = type === 'danger';

		return (
			<div
				className={cn("bg-light", "d-flex", 'justify-content-center', "align-items-center")}
				style={{ minHeight: '150px'}}
			>
				<FontAwesomeIcon
					className={cn("mr-3", {'text-danger': isDanger})}
					style={{ opacity: isDanger ? 0.5 : 0.1 }}
					icon={iconsMap[type]}
					size="4x"
					fixedWidth
				/>
				<span style={{ fontSize: '1.2rem' }}>
					{children}
				</span>
			</div>
		);
	}
}

export default Alert;
