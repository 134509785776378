import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import api from 'core/api';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import StageEditForm from './components/StageEditForm';

const createStage = (values, props) => {
	if (props.stageId) {
		props.dispatch(api.stages().update({
			stageId: props.stageId,
			changes: values,
		}))
			.then(() => props.onClose())
			.catch(err => console.log(err))
		;
	} else {
		props.dispatch(api.stages().create({
			projectId: props.projectId,
			...values,
		}))
			.then(() => props.onClose())
			.catch(err => console.log(err))
		;
	}
};

const StageEditModal = (props) => {
	const form = useRef(null);

	const btnList = [{
		title: 'Ок',
		props: {
			onClick: () => (form ? form.current.handleSubmit() : {}),
		}
	},
	{
		title: 'Отмена',
		props: {
			onClick: () => props.onClose()
		}
	}];

	return (
		<Modal
			show={props.visible}
			onHide={() => props.onClose()}
			title={props.title}
			btnList={btnList}
		>
			<StageEditForm
				onFormCreated={f => (form.current = f)}
				onSubmit={values => createStage(values, props)}
				initialValues={props.initialValues}
				changesOnly={props.changesOnly}
				onCancel={() => props.onClose()}
			/>
		</Modal>
	);
};

StageEditModal.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string,
	match: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
	stageId: PropTypes.string,
	changesOnly: PropTypes.bool,
};

StageEditModal.defaultProps = {
	visible: false,
	initialValues: {},
};

const mapToProps = (state, props) => {
	const projectId = props.match?.params?.projectId;

	return { projectId };
};

export default withRouter(connect(mapToProps)(StageEditModal));
