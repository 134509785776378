import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import formikField from './formikField';
import Select from './Select';

class SelectInput extends React.PureComponent {

	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		optionFields: PropTypes.arrayOf(PropTypes.string),
		idField: PropTypes.string,
		placeholder: PropTypes.string,
	}

	static defaultProps = {
		optionFields: ['title'],
		idField: 'id',
		placeholder: '-',
		items: [],
	}

	render()
	{
		const {
			forwardedRef,
			inputProps,
			formikParams,
			...innerProps
		} = this.props;

		const { field, form: { values } } = formikParams;
		const { value, ...otherInputProps } = inputProps;
		const { optionFields, idField, ...otherInnerProps } = innerProps;

		const initialValue = values[field.name] || '';

		return (
			<Form.Control
				as={Select}
				ref={forwardedRef}
				id={field.name}
				value={value || initialValue}
				displayFields={optionFields}
				keyField={idField}
				{...otherInputProps}
				{...otherInnerProps}
			/>
		);
	}
}

export default formikField(SelectInput);
