import React from 'react';

import Workspace from 'layouts/Default/components/Workspace';
import { approvalTabsRoutes } from './routes';

class Estimate extends React.PureComponent {
	render() {
		return (
			<Workspace routes={approvalTabsRoutes} />
		);
	}
}

export default Estimate;
