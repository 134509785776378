import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form as BootstrapForm, Row, Col, InputGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';

import TextInput from './inputs/TextInput';
import DateInput from './inputs/DateInput';
import SelectInput from './inputs/SelectInput';
import TimeInput from './inputs/TimeInput';
import CheckInput from './inputs/CheckInput';
import DateRangeInput from './inputs/DateRangeInput';
import SuggestInput from './inputs/SuggestInput';
import FileInput from './inputs/FileInput';
import RadioGroupInput from './inputs/RadioGroupInput';
import Label from './inputs/Label';

const INPUTS_MAP = {
	'text': TextInput,
	'select': SelectInput,
	'time': TimeInput,
	'date': DateInput,
	'check': CheckInput,
	'daterange': DateRangeInput,
	'suggest': SuggestInput,
	'file': FileInput,
	'radiogroup': RadioGroupInput,
	'label': Label,
	'empty': null,
};

const layoutsMap = {
	'row': Row,
	'col': Col,
};
/**
 *
 * @param {string} groupId - Group unique id
 * @param {string} fieldName - Group field name
 * @param {number} index - Group row index
 * @return {string} Group input unique id
 */
const generateGroupInputId = (groupId, fieldName, index) => `${groupId}[${index}].${fieldName}`;

/**
	 * Render a single input
	 * @param {any} Input - React Component to render
	 * @param {string} id - Input unique id
	 * @param {string} label - Input label
	 * @param {object} inputProps - Input internal properties
	 * @param {object} inputComponents - Inpit additional components
	 * @param {string} error - Field input error
	 * @param {object} formRef - Form ref
	 * @returns {any} Retrun React Component
	 */
const renderComponent = (Input, id, label, inputProps, components, error, formRef) => {
	if (!Input) {
		return null;
	}

	const append = components?.append;
	const prepend = components?.prepend;

	return (
		<>
			{label ? <BootstrapForm.Label htmlFor={id}>
				{label}
			</BootstrapForm.Label> : null}
			<InputGroup style={{ height: '100%' }}>
				{prepend ? <InputGroup.Prepend>
					<Prepend.Text>
						{prepend}
					</Prepend.Text> : prepend.component
				</InputGroup.Prepend> : null}
				<Input name={id} formref={formRef?.current} {...inputProps}/>
				{append ? <InputGroup.Append>
					<InputGroup.Text>
						{append}
					</InputGroup.Text>
				</InputGroup.Append> : null}
			</InputGroup>
			{error ? <BootstrapForm.Control.Feedback
				className="d-block"
				type="invalid"
			>
				{error}
			</BootstrapForm.Control.Feedback> : null}
		</>
	);
};

/**
	 * Collect all group inputs props and components
	 * @param {string} groupId - Group id
	 * @param {object} groupFieldIds - Array of group field ids
	 * @param {{ items: array, inputsProps: func, components: func }} groupProps - Group props constains group items and callbacks with inputs props and components
	 * @param {object} formik context
	 * @returns {{ inputsProps, components }} Returns group inputs props and components
	 */
const collectGroup = (groupId, groupProps, context) => {
	if (!groupProps.items) {
		return {};
	}

	const inputsProps = {};
	const components = {};

	for (let i = 0; i < groupProps.items.length; ++i) {
		const item = groupProps.items[i];

		const currentValue = context?.values[groupId] ? context.values[groupId][i] : null;

		const rowInputsProps = groupProps.inputsProps && groupProps.inputsProps(item, i, currentValue) || {};
		for (const key of Object.keys(rowInputsProps)) {
			const id = generateGroupInputId(groupId, key, i);
			inputsProps[id] = rowInputsProps[key];
		}

		const rowComponents = groupProps.components && groupProps.components(item, i, currentValue) || {};
		for (const key of Object.keys(rowComponents)) {
			const id = generateGroupInputId(groupId, key, i);
			components[id] = rowComponents[key];
		}
	}

	return { inputsProps, components };
};

/**
	 * Render a group of inputs
	 * @param {string} groupId - Group id
	 * @param {object} groupFields - Array of group fields
	 * @param {object} groupsProps
	 * @param {string} error
	 * @param {object} formik context
	 */
const renderGroup = (groupId, groupFields, groupProps, error, context) => {
	if (!groupProps) {
		return null;
	}

	const { inputsProps, components } = collectGroup(groupId, groupProps, context);

	return (
		<BootstrapForm.Group>
			<Row>
				{groupFields.map(field => {
					// eslint-disable-next-line no-unused-vars
					const { id, label, type, ...other } = field;

					return label ? <Col key={id} {...other}>
						<BootstrapForm.Label>
							{label}
						</BootstrapForm.Label>
					</Col> : null;
				})}
			</Row>
			{groupProps.items.map((_, idx) => {
				const currentValue = context?.values[groupId] ? context.values[groupId][idx] : null;
				const rowClassName = groupProps.rowClassName && groupProps.rowClassName(currentValue, idx);
				const rowStyle = groupProps.rowStyle && groupProps.rowStyle(currentValue, idx);

				return (
					<Row key={idx} className={rowClassName} style={rowStyle}>
						{groupFields.map(field => {
						// eslint-disable-next-line no-unused-vars
							const { id, label, type, ...other } = field;
							const componentId = generateGroupInputId(groupId, id, idx);

							return <Col key={componentId} {...other}>
								{renderComponent(
									INPUTS_MAP[type],
									componentId,
									null,
									inputsProps[componentId],
									components[componentId],
									null,
									null,
								)}
							</Col>;
						})}
					</Row>
				);
			})}
			{error ? <BootstrapForm.Control.Feedback
				className="d-block"
				type="invalid"
			>
				{error}
			</BootstrapForm.Control.Feedback> : null}
		</BootstrapForm.Group>
	);
};

const Form = (props) => {
	const context = useFormikContext();

	const {
		onFormCreated,
		children,
		schema,
		className,
		onChange,
		simplifiedSchema,
	} = props;

	const formRef = useRef(null);

	useEffect(() => {
		onFormCreated(context);
	}, []);

	useEffect(() => {
		onChange(context.values);
	}, [context.values]);

	/**@todo remove old generator functions */
	// *************************************************************************
	const wrapInGroup = (component, group) => {
		if (!group) {
			return component;
		}

		const { append, prepend, props } = group;

		const getComponent = type => {
			if (type.text) {
				return <InputGroup.Text>
					{type.text}
				</InputGroup.Text>;
			}
			if (type.component) {
				return type.component;
			}

			return null;
		};

		return (
			<InputGroup {...props}>
				{prepend ? <InputGroup.Prepend>
					{getComponent(prepend)}
				</InputGroup.Prepend> : null}
				{component}
				{append ? <InputGroup.Append>
					{getComponent(append)}
				</InputGroup.Append> : null}
			</InputGroup>
		);
	};

	const wrapInFormGroup = (component, form, id) => {
		if (!form?.group) {
			return component;
		}

		const error = context.errors[id];

		return (
			<BootstrapForm.Group>
				{form?.label
					? <BootstrapForm.Label htmlFor={id}>
						{form.label}
					</BootstrapForm.Label>
					: null
				}
				{component}
				{ error
					?
					<BootstrapForm.Control.Feedback
						className="d-block"
						type="invalid"
					>
						{error}
					</BootstrapForm.Control.Feedback>
					: null}
			</BootstrapForm.Group>
		);
	};

	const renderInput = input => {
		if (!input) {
			return null;
		}

		const { type, id, props, group } = input;

		if (!type) {
			return null;
		}

		const Input = INPUTS_MAP[type];
		const component = <Input name={id} formref={formRef.current} {...props}/>;

		return wrapInGroup(component, group);
	};

	const resolveSchema = schema => {
		return (
			<>
				{schema.map((element, i) => {
					const { layout, input, children } = element;

					if (!layout) {
						return null;
					}

					const Layout = layoutsMap[layout.type];

					const component = (
						<Layout key={i} {...(layout.props || {})}>
							{wrapInFormGroup(renderInput(input), layout.form, input?.id)}
							{children ? resolveSchema(children) : null}
						</Layout>
					);

					return component;
				})}
			</>
		);
	};
	// *************************************************************************

	const resolveSimlifiedSchema = (simplifiedSchema) => {
		if (!simplifiedSchema?.length) {
			return null;
		}

		return simplifiedSchema.map((row, i) => (
			<Col key={i}>
				<Row>{row.map((cols, i) => {
					const { id, type, label, group, ...otherProps } = cols;
					const error = context?.errors ? context.errors[id] : null;

					return (
						<Col key={i + id} {...otherProps}>
							{group
								? renderGroup(
									id,
									group,
									props.groupsProps[id],
									error,
									context,
								)
								: <BootstrapForm.Group>
									{renderComponent(
										INPUTS_MAP[type],
										id,
										label,
										props.inputsProps[id],
										props.components[id],
										error,
										formRef,
									)}
								</BootstrapForm.Group>
							}
						</Col>
					);
				})}
				</Row>
			</Col>
		));
	};

	return (
		<BootstrapForm
			ref={formRef}
			className={className}
			noValidate
		>
			{children || resolveSimlifiedSchema(simplifiedSchema) || resolveSchema(schema)}
		</BootstrapForm>
	);
};

Form.propTypes = {
	simplifiedSchema: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				type: PropTypes.oneOf(Array.from(Object.keys(INPUTS_MAP))),
				label: PropTypes.string,
				group: PropTypes.arrayOf(PropTypes.object),
			})
		)
	),
	inputsProps: PropTypes.object,
	components: PropTypes.object,
	groupsProps: PropTypes.object,
	onFormCreated: PropTypes.func,
	className: PropTypes.string,
	/**@todo remove old schema propperty */
	schema: PropTypes.arrayOf(PropTypes.shape({
		layout: PropTypes.shape({
			type: PropTypes.oneOf(Array.from(Object.keys(layoutsMap))).isRequired,
			props: PropTypes.object,
			form: PropTypes.shape({
				group: PropTypes.bool,
				label: PropTypes.string,
			}),
		}).isRequired,
		input: PropTypes.shape({
			id: PropTypes.string.isRequired,
			type: PropTypes.oneOf(Array.from(Object.keys(INPUTS_MAP))).isRequired,
			props: PropTypes.object,
			group: PropTypes.shape({
				prepend: PropTypes.shape({
					text: PropTypes.string,
				}),
				append: PropTypes.shape({
					text: PropTypes.string,
				}),
				props: PropTypes.object,
			})
		}),
		children: PropTypes.arrayOf(PropTypes.object) // nested schema
	})),
};

Form.defaultProps = {
	inputsProps: {},
	groupsProps: {},
	components: {},
	onChange: () => null,
	onFormCreated: () => null,
};

export default Form;
