import React from 'react';
import PropTypes from 'prop-types';

class UserAvatar extends React.Component {
	colors = [
		'#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#1976D2', '#00838F',
		'#00796B', '#388E3C', '#558B2F', '#827717', '#E65100', '#E64A19', '#6D4C41',
		'#FF3D00'
	];

	getColor(string)
	{
		let sum = 0;

		for (let i = 0; i < string.length; i++) {
			sum += string.charCodeAt(i);
		}

		return this.colors[sum % this.colors.length];
	}

	getTwoLetters(user)
	{
		if (user.fullName) {
			return user.fullName[0] + user.fullName.substr(user.fullName.indexOf(' ') + 1, 1);
		}

		return user.phoneNumber?.substr(-2);
	}

	render()
	{
		const user = this.props.user;

		const letters = this.getTwoLetters(user);

		return (
			<div
				style={{
					width: this.props.size,
					height: this.props.size,
					borderRadius: Math.floor(this.props.size / 2),
					backgroundColor: this.getColor(user.fullName || user.phoneNumber || user.email),

					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					...(this.props.style || {})
				}}
				title={this.props.user.fullName}
			>
				<span
					style={{
						fontSize: Math.round(this.props.size / 2),
						color: 'white',
						fontWeight: 500,
					}}
				>
					{letters}
				</span>
			</div>
		);
	}
}

UserAvatar.propTypes = {
	user: PropTypes.object.isRequired,
	size: PropTypes.number.isRequired,
	style: PropTypes.object,
};

export default UserAvatar;
