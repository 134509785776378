import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.sass';

class RadialProgressBar extends React.PureComponent
{
	static propTypes = {
		percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		color: PropTypes.string,
		bgColor: PropTypes.string,
		radius: PropTypes.number,
		strokeWidth: PropTypes.number,
	}

	static defaultProps = {
		percent: 0,
		color: '#5CC87F',
		bgColor: '#C4C4C4',
		strokeWidth: 3
	}

	getPath(centerX, centerY, radius, sweepAngle, startAngle = 0, rotationAngle = 0)
	{
		const cos = Math.cos;
		const sin = Math.sin;
		const pi = Math.PI;

		const matrixTimes = (([[a, b], [c, d]], [x, y]) => [a * x + b * y, c * x + d * y]);
		const rotateMatrix = ((x) => [[cos(x), -sin(x)], [sin(x), cos(x)]]);
		const addVec = (([a1, a2], [b1, b2]) => [a1 + b1, a2 + b2]);

		const swa = sweepAngle % (2 * pi);
		const sta = startAngle;
		const r = radius;
		const cx = centerX;
		const cy = centerY;
		const rta = rotationAngle;

		const rotMatrix = rotateMatrix(rta);

		const [sX, sY] = (addVec(matrixTimes(rotMatrix, [r * cos(sta), r * sin(sta)]), [cx, cy]));
		const [eX, eY] = (addVec(matrixTimes(rotMatrix, [r * cos(sta + swa), r * sin(sta + swa)]), [cx, cy]));
		const fA = (swa > pi) ? 1 : 0;
		const fS = (swa > 0) ? 1 : 0;

		return `M ${sX} ${sY} A ${r} ${r} ${rta / (2 * pi) * 360} ${fA} ${fS} ${eX} ${eY}`;
	}

	render()
	{
		const { percent, color, radius, strokeWidth, bgColor } = this.props;

		//wrapper parameters
		const height = radius * 2 + strokeWidth;
		const width = radius * 2 + strokeWidth;

		//circle center coordinates
		const cx = radius + strokeWidth / 2;
		const cy = cx;

		//fill stroke angle
		const sweepDegrees = 360 * percent / 100;
		const sweepRadians = sweepDegrees * Math.PI / 180;

		return (
			<div className={styles.RadialProgressBar} style={{ height, width }}>
				<svg height="100%" width="100%">
					<circle
						cx={cx}
						cy={cy}
						r={radius}
						fill="none"
						stroke={percent < 100 ? bgColor : color}
						strokeWidth={strokeWidth}
					/>
					{ percent < 100 ? <path
						d={this.getPath(cx, cy, radius, sweepRadians, 270 * Math.PI / 180)}
						fill="none"
						stroke={color}
						strokeWidth={strokeWidth}
					/> : null }
					<text
						className={styles.RadialProgressBar_text}
						x="50%"
						y="50%"
						dominantBaseline="central"
						textAnchor="middle"
					>
						{`${percent}%`}
					</text>
				</svg>
			</div>
		);
	}
}

export default RadialProgressBar;
