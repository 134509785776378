import React from 'react';
import PropTypes from 'prop-types';
import { FileDownloadToolbar } from 'components/ui';


class AttachedFilesCellRenderer extends React.PureComponent
{

	static propTypes = {
		itemsList: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		itemsList: [],
	}

	render()
	{
		return (
			<div>
				<FileDownloadToolbar
					iconsList={this.props.itemsList}
				/>
			</div>
		);
	}
}

export default AttachedFilesCellRenderer;
