import {
	NumberCellEditor,
	UnitsCellEditor,
} from 'components/ui/grid/editors';

import {
	RequiredCellRenderer,
	EmptyCellRenderer,
	UserCellRenderer,
	percentCellRenderer,
	defaultCurrencyCellRenderer,
	daysCellRenderer,
} from 'components/ui/grid/renderers';

import { unitsFormatter, numberFormatter } from 'components/ui/grid/formatters';

import AutocompletePriceCellRenderer from './components/AutocompletePriceCellRenderer';
import TitleCellRenderer from './components/TitleCellRenderer';
import DetailCellRenderer from './components/DetailCellRenderer';
import CurrencyCellEditor from './components/CurrencyCellEditor';

import config from 'config';

import {
	retailPriceGetter,
	planPriceGetter,
	retailCostGetter,
	planCostGetter,
	customerPriceGetter,
	customerCostGetter,
	marginValueGetter,
	withCurrency,
	remainAmountGetter,
} from './getters';


const defaultCellStyle = { lineHeight: '32px' };

export const getPinnedBottomRowData = (props, gridApi) => {

	if (!gridApi) {
		return null;
	}

	const items = [];

	gridApi.forEachNodeAfterFilter(node => {
		items.push(node.data);
	});

	const { getCurrencyRates } = props;
	const defaultPriceCurrency = config.tbs.priceCurrency.default;

	const sumFields = [
		'retailPrice',
		'planPrice',
		'retailCost',
		'planCost',
		'customerPrice',
		'planPriceOverride',
		'customerCost',
	];

	const getters = {
		'retailPrice': retailPriceGetter,
		'planPrice': planPriceGetter,
		'retailCost': retailCostGetter,
		'planCost': planCostGetter,
		'customerPrice': customerPriceGetter,
		'customerCost': customerCostGetter,
	};

	const data = [items.reduce((acc, data) => {
		for (const field of sumFields) {

			const getter = getters[field];
			const value = getter ? getter(data) : data[field];

			if (!value) {
				continue;
			}

			const currenciedValue = withCurrency(
				value,
				data.priceCurrencyId,
				getCurrencyRates,
			);

			const res = acc[field] ? Number(acc[field]) + Number(currenciedValue) : Number(currenciedValue);
			acc[field] = res;
		}

		return acc;
	}, { title: ' Итого: ', priceCurrency: defaultPriceCurrency, priceCurrencyId: defaultPriceCurrency.id })];

	return data;
};

export const getGridOptions = params => {
	const {
		me,
		consumableSheet,
	} = params;

	const { isApproved } = consumableSheet;

	const priceRenderer = params => `${params.valueFormatted || params.value} ${params.data?.priceCurrency?.symbol || ''}`;

	const frameworkComponents = {
		numberCellEditor: NumberCellEditor,
		unitsCellEditor: UnitsCellEditor,
		currencyCellEditor: CurrencyCellEditor,
		requiredCellRenderer: RequiredCellRenderer,
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		priceCellRenderer: priceRenderer,
		percentCellRenderer: percentCellRenderer,
		defaultCurrencyCellRenderer: defaultCurrencyCellRenderer,
		daysCellRenderer: daysCellRenderer,
		autocompletePriceCellRenderer: AutocompletePriceCellRenderer,
		titleCellRenderer: TitleCellRenderer,
		detailCellRenderer: DetailCellRenderer,
	};

	const isEditable = params => isApproved || (params.data.lockedByUserId && params.data.lockedByUserId === me.id);

	const getRowStyle = params => {
		if (isEditable(params)) {
			return null;
		}

		return { color: 'rgba(0,0,0,0.5)' };
	};

	const gridOptions = {
		getRowStyle: getRowStyle,
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			resizable: true,
			sortable: true,
			cellStyle: defaultCellStyle,
		}
	};

	return gridOptions;
};

export const requiredFields = [];

export const getColumnDefs = params => {
	const {
		currencies,
		onCurrencyCellValuesChanged,
		consumableUnits,
		consumableUnitsMap,
		me,
		consumableSheet,
		pricelistsMatchesMap,
		currenciesMap,
		onMatchClicked,
		getMatchKey,
		onChangeItemClick,
		getCurrencyRates,
	} = params;

	const { isApproved } = consumableSheet;

	const isEditable = params => isApproved || (params.data.lockedByUserId && params.data.lockedByUserId === me.id);

	const margeCellStyle = (params) => {
		if (Number(params.value) === 0) {
			return defaultCellStyle;
		}

		return { ...defaultCellStyle, color: params.value > 0 ? 'green' : 'red' };
	};

	const remainCellStyle = (params) => {
		if (Number(params.value) === 0) {
			return defaultCellStyle;
		}

		return { ...defaultCellStyle, color: params.value >= 0 ? '#000' : 'red' };
	};

	const titleRendererParams = params => {
		const data = params.data;

		let subtitle = data.partNumber || '';

		if (data.manufacturerName) {
			if (subtitle.length) {
				subtitle = subtitle.concat(' / ');
			}
			subtitle = subtitle.concat(data.manufacturerName);
		}

		return { subtitle, onChangeItemClick };
	};

	const priceEditorParams = {
		options: currencies,
		selectField: 'priceCurrencyId',
		onSelectValueChange: onCurrencyCellValuesChanged,
		defaultValue: config.tbs.priceCurrency.default.id,
	};

	const defs = [
		{
			pinned: 'left',
			resizable: false,
			width: 30,
			field: 'title',
			cellRenderer: "agGroupCellRenderer",
		},
		{
			checkboxSelection: true,
			pinned: 'left',
			resizable: false,
			width: 40,
			headerCheckboxSelection: true,
		},
		{
			headerName: 'Наименование',
			field: 'title',
			minWidth: 400,
			width: 400,
			sort: 'asc',
			pinned: 'left',
			cellRendererParams: titleRendererParams,
			cellStyle: { lineHeight: 1.05 }
		},
		{
			headerName: 'Ответственный',
			field: 'lockedByUser',
			minWidth: 120,
			width: 120,
			hide: isApproved,
		},
		{
			headerName: 'Количество',
			minWidth: 110,
			width: 110,
			field: 'amount',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: '0.01' } },
		},
		{
			headerName: 'Заказано',
			minWidth: 100,
			width: 100,
			field: 'orderedAmount',
			valueFormatter: numberFormatter,
			hide: !isApproved,
		},
		{
			headerName: 'Остаток',
			minWidth: 100,
			width: 100,
			field: 'remainAmount',
			valueFormatter: numberFormatter,
			valueGetter: params => remainAmountGetter(params.data),
			cellStyle: remainCellStyle,
			hide: !isApproved,
		},
		{
			headerName: 'Единица',
			field: 'consumableUnitId',
			minWidth: 100,
			width: 100,
			editable: isEditable,
			cellEditor: 'unitsCellEditor',
			cellEditorParams: { units: consumableUnits, valueField: 'suffix'},
			valueFormatter: params => unitsFormatter(params, consumableUnitsMap, 'suffix'),
		},
		{
			headerName: 'Розничная цена',
			minWidth: 140,
			width: 140,
			field: 'retailPrice',
			editable: isEditable,
			valueGetter: params => retailPriceGetter(params.data),
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: priceEditorParams,
			cellRendererParams: params => {
				if (!pricelistsMatchesMap) {
					return { currenciesMap };
				}

				const matches = pricelistsMatchesMap.get(getMatchKey(params.data));

				return { matches, currenciesMap, onMatchClicked };
			},
		},
		{
			headerName: 'Плановая цена',
			minWidth: 140,
			width: 140,
			field: 'planPrice',
			editable: isEditable,
			valueGetter: params => planPriceGetter(params.data),
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: priceEditorParams
		},
		{
			headerName: 'Скидка от розницы',
			headerTooltip: 'Скидка на закупку. Учитывается в плановой себестоимости.',
			minWidth: 160,
			width: 160,
			field: 'retailDiscountPercents',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", max: "100" } },
		},
		{
			headerName: 'Розничная стоимость',
			minWidth: 170,
			width: 170,
			field: 'retailCost',
			valueGetter: params => {

				const { node } = params;

				if (node.rowPinned) {
					return params.data?.retailCost;
				}

				return withCurrency(
					retailCostGetter(params.data),
					params.data.priceCurrencyId,
					getCurrencyRates
				);
			},
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановая стоимость',
			minWidth: 170,
			width: 170,
			field: 'planCost',
			valueGetter: params => {

				const { node } = params;

				if (node.rowPinned) {
					return params.data?.planCost;
				}

				return withCurrency(
					planCostGetter(params.data),
					params.data.priceCurrencyId,
					getCurrencyRates
				);
			},
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Плановый срок поставки',
			minWidth: 190,
			width: 190,
			field: 'planDeliveryDurationDays',
			editable: isEditable,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "0", step: "1" } },
		},
		{
			headerName: 'Поставщик',
			minWidth: 200,
			width: 250,
			field: 'supplierTitle',
			editable: isEditable,
		},
		{
			headerName: 'Примечание',
			minWidth: 120,
			width: 120,
			field: 'notes',
			editable: isEditable,
			cellEditor: 'agLargeTextCellEditor',
		},
		{
			headerName: 'Скидка / Наценка (%)',
			headerTooltip: 'Уменьшение розницы / Увеличение входа (- / +). Применяется к цене для заказчика.',
			minWidth: 170,
			width: 170,
			field: 'priceCorrectionPercents',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'numberCellEditor',
			cellEditorParams: { inputProps: { min: "-100", step: "0.01" } },
		},
		{
			headerName: 'Цена для заказчика',
			minWidth: 160,
			width: 160,
			field: 'customerPrice',
			valueGetter: params => customerPriceGetter(params.data),
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Цена в договоре',
			minWidth: 160,
			width: 160,
			field: 'planPriceOverride',
			editable: isEditable,
			valueFormatter: numberFormatter,
			cellEditor: 'currencyCellEditor',
			cellEditorParams: priceEditorParams,
		},
		{
			headerName: 'Стоимость в договоре',
			minWidth: 180,
			width: 180,
			field: 'customerCost',
			valueGetter: params => {

				const { node } = params;

				if (node.rowPinned) {
					return params.data?.customerCost;
				}

				return withCurrency(
					customerCostGetter(params.data),
					params.data.priceCurrencyId,
					getCurrencyRates
				);
			},
			valueFormatter: numberFormatter,
		},
		{
			headerName: 'Маржа',
			minWidth: 80,
			width: 100,
			field: 'marge',
			valueGetter: params => marginValueGetter(params.data),
			valueFormatter: numberFormatter,
			cellStyle: margeCellStyle,
		},
	];

	const renderersMap = {
		'lockedByUser': { component: 'userCellRenderer' },
		'retailPrice': { component: 'autocompletePriceCellRenderer' },
		'planPrice': { component: 'priceCellRenderer' },
		'retailDiscountPercents': { component: 'percentCellRenderer' },
		'retailCost': { component: 'defaultCurrencyCellRenderer' },
		'planCost': { component: 'defaultCurrencyCellRenderer' },
		'priceCorrectionPercents': { component: 'percentCellRenderer' },
		'customerPrice': { component: 'priceCellRenderer' },
		'planPriceOverride': { component: 'priceCellRenderer' },
		'customerCost': { component: 'defaultCurrencyCellRenderer' },
		'marge': { component: 'percentCellRenderer' },
		'planDeliveryDurationDays': { component: 'daysCellRenderer' },
		'title': { component: 'titleCellRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;
		const isRequiredColumn = requiredFields.includes(column.field);

		if (isValueUndefined) {
			if (isRequiredColumn && isEditable(params)) {
				return {
					component: 'requiredCellRenderer',
					params: { requiredTitle: '-' },
				};
			} else {
				return { component: 'emptyCellRenderer'};
			}
		}

		return renderersMap[column.field] || null;
	};

	return defs.map(column => {
		if (column.field && !column.cellRenderer) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
