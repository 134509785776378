import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'components/ui/forms/formik/Form';
import styles from './style.module.sass';
import * as yup from 'yup';
import { parseISO } from 'date-fns';

const prepareInitialValues = values => {
	const result = {...values};

	if (values.date) {
		result.date = parseISO(values.date);
	}

	if (values.requestItems) {
		const items = [...values.requestItems];
		for (let i = 0; i < items.length; ++i) {

			if (items[i].deliveryDateActual) {
				items[i] = {
					...items[i],
					deliveryDateActual: parseISO(items[i].deliveryDateActual),
					isChecked: true,
				};
			}
		}

		result.requestItems = items;
	}

	if (values.paymentSchedule && values.paymentSchedule.length) {
		const scheduleItems = values.paymentSchedule.map((item, index) => {
			return {
				'amount': item.amount,
				'days': item.days,
			};
		});

		result.paymentSchedule = scheduleItems;
	}

	return result;
};


const InvoiceEditForm = (props) => {
	const [values, setValues] = useState(null);
	const { currencies, currenciesMap, requestItems, initialValues, changesOnly, ...otherProps } = props;

	const schema = [
		[
			{
				id: 'requestItems',
				group: [
					{ id: 'isChecked', type: 'check', label: 'Наименование', sm: 4 },
					{ id: 'priceActual', type: 'text', label: 'Цена (за еденицу)' },
					{ id: 'amountProposed', type: 'text', label: 'Количество в счёте' },
					{ id: 'deliveryDateActual', type: 'date', label: 'Дата поставки' },
					{ id: 'amountComment', type: 'text', label: 'Комментарий' },
				]
			}
		],
		[
			{ id: 'supplierTitle', type: 'text', label: 'Поставщик' },
			{ id: 'number', type: 'text', label: 'Номер счёта' },
			{ id: 'date', type: 'date', label: 'Дата счёта' },
			{ id: 'currencyId', type: 'select', label: 'Валюта' },
			{ id: 'file', type: 'file', label: 'Файл счёта' },
		],
		[
			{ id: 'paymentTermsType', type: 'radiogroup', label: 'Способ оплаты' }
		],
	];

	if (values?.paymentTermsType === 'partPayment' || values?.paymentTermsType === 'delayedPayment') {
		schema.push([
			{
				id: 'paymentSchedule',
				group: [
					{ id: 'title', type: 'label', sm: 2 },
					{ id: 'amount', type: 'text', sm: 2 },
					{ id: 'days', type: 'text', sm: 2 }
				]
			}
		]);
	}

	const groupsProps = {
		'requestItems': {
			items: requestItems,
			inputsProps: (item, idx, value) => {
				const isChecked = value?.isChecked;

				const labelProps = {
					'isChecked': {
						id: 'ri_checkbox_' + idx,
						label: item.consumableSheetItem?.title,
						className: styles.checkbox,
					},
				};

				const otherProps = {
					'priceActual': {
						placeholder: 'Введите цену',
					},
					'amountProposed': {
						placeholder: 'Введите количество'
					},
					'deliveryDateActual': {
						placeholder: 'Выберите дату'
					},
					'amountComment': {
						placeholder: 'Комментарий к позиции'
					},
				};

				const readOnlyProps = {
					'priceActual': {
						readOnly: true,
					},
					'amountProposed': {
						readOnly: true,
					},
					'deliveryDateActual': {
						readOnly: true,
					},
					'amountComment': {
						readOnly: true,
					},
				};

				if (isChecked) {
					return { ...labelProps, ...otherProps };
				} else {
					return { ...labelProps, ...readOnlyProps };
				}
			},
			components: (item, _, value) => {
				const priceCurrencySymbol = item.consumableSheetItem?.priceCurrency?.symbol;
				const unitSuffux = item.consumableSheetItem?.consumableUnit?.suffix;
				const isChecked = value?.isChecked;

				if (isChecked) {
					return {
						'priceActual': {
							append: priceCurrencySymbol ? priceCurrencySymbol : null,
						},
						'amountProposed': {
							append: unitSuffux ? unitSuffux : null,
						}
					};
				}

				return null;
			},
			rowStyle: (value) => {
				const isChecked = value?.isChecked;

				return isChecked ? null : { opacity: 0.7 };
			}
		},
		'paymentSchedule': {
			items: ['Первый платёж', 'Второй платёж', 'Третий платёж'],
			inputsProps: (item) => ({
				'title': { text: item },
				'amount': {
					placeholder: "Сумма",
				},
				'days': { placeholder: "Отсрочка" },
			}),
			components: () => ({
				'amount': {
					append: values?.currencyId ? currenciesMap.get(values.currencyId)?.symbol : null,
				},
			}),
		}
	};

	const inputsProps = {
		'supplierTitle': {
			placeholder: 'Введите название',
		},
		'number': {
			placeholder: 'Введите номер счёта',
		},
		'date': {
			placeholder: 'Выберите дату',
		},
		'currencyId': {
			options: currencies.map(c => ({ value: c.id, label: c.symbol })),
			placeholder: 'Выберите валюту',
		},
		'paymentTermsType': {
			options: [
				{ label: "Полная оплата", value: 'fullPayment', id: 'invoice_form_payment_full_radio' },
				{ label: "Аванc", value: 'partPayment', id: 'invoice_form_payment_part_radio' },
				{ label: "Отсрочка", value: 'delayedPayment', id: 'invoice_form_payment_delayed_radio' },
			]
		},
	};

	const validationSchema = yup.object().shape({
		supplierTitle: yup.string().required('Заполните название поставщика'),
		number: yup.string().required('Заполните номер счёта'),
		date: yup.date().required('Выберите дату счёта'),
		currencyId: yup.string().required('Выберите валюту'),
		file: changesOnly ? yup.string() : yup.string().required('Выберите файл счёта'),
		requestItems: yup.array().test('valid', 'Выберите позиции заявки', arr => (arr.length && arr.some(i => i?.isChecked))),
		paymentTermsType: yup.string().required('Выберите тип оплаты'),
	});

	let invoiceTotal = 0;
	if (values && values.requestItems.length > 0) {
		invoiceTotal = values.requestItems.reduce((acc, cur) => {
			return Number(cur.priceActual) * Number(cur.amountProposed) + acc;
		}, 0);
	}

	return (
		<React.Fragment>
			<div>Сумма счёта: <b>{invoiceTotal || 0}</b> руб.</div><br/>
			<Form
				{...otherProps}
				simplifiedSchema={schema}
				inputsProps={inputsProps}
				groupsProps={groupsProps}
				onChange={(values) => setValues(values)}
				validationSchema={validationSchema}
				changesOnly={changesOnly}
				initialValues={{
					supplierTitle: '',
					number: '',
					date: '',
					currencyId: '',
					requestItems: [],
					paymentTermsType: 'fullPayment',
					...prepareInitialValues(initialValues),
				}}
			/>
		</React.Fragment>
	);
};

InvoiceEditForm.propTypes = {
	currencies: PropTypes.arrayOf(PropTypes.object),
	requestItems: PropTypes.arrayOf(PropTypes.object),
	currenciesMap: PropTypes.any,
};

export default InvoiceEditForm;
