
import { isWithinInterval, eachDayOfInterval, parseISO, formatISO } from 'date-fns';
const DAILY_HOURS_NORM = 10;

const hourseNumber = (hoursStr) => {
	if (!hoursStr) {
		return 0;
	}

	const time = hoursStr.split(":");

	return Math.round((Number(time[0]) + Number(time[1] / 60)) * 10) / 10;
};

const median = (array) => {
	const length = array?.length;

	if (!length) {
		return 0;
	}

	if (length === 1) {
		return array[0];
	}

	const isEven = (length % 2) === 0;

	const sorted = array.sort((a, b) => a - b);
	const middle = Math.floor(length / 2);

	if (isEven) {
		return (sorted[middle] + sorted[middle + 1]) / 2;
	}
	else {
		return sorted[middle];
	}
};

export const hours = (workerDays) => workerDays.filter(wd => !wd.isAbsence).reduce((hours, wd) => {
	const { timeWorked, date } = wd;

	const hoursWorked = 	hourseNumber(timeWorked);

	if (hours[date]) {
		hours[date] = hours[date] + hoursWorked;
	} else {
		hours[date] = hoursWorked;
	}

	return hours;
}, {});

export const loadAveragePercent = (workerDays, workers, interval) => {
	if (!workerDays || !workers?.length || !interval) {
		return 0;
	}

	const days = eachDayOfInterval(interval);
	const plannedTotalHours = workers.length * DAILY_HOURS_NORM * days.length;

	const hoursArray = [];
	const hrs = hours(workerDays);

	for (const day of eachDayOfInterval(interval)) {
		const iso = formatISO(day, { representation: 'date' });
		hoursArray.push(hrs[iso] ? hrs[iso] : 0);
	}

	const totalHours = hoursArray.reduce((acc, h) => acc + h, 0);

	return Math.round(totalHours / plannedTotalHours * 100 * 10) / 10;
};

export const loadPercentMedian = (workerDays, workers, interval) => {
	if (!workerDays || !workers || !interval) {
		return 0;
	}

	const plannedDailyHours = workers.length * DAILY_HOURS_NORM;

	if (!plannedDailyHours) {
		return 0;
	}

	const hoursArray = [];
	const hrs = hours(workerDays);

	for (const day of eachDayOfInterval(interval)) {
		const iso = formatISO(day, { representation: 'date' });
		hoursArray.push(hrs[iso] ? hrs[iso] : 0);
	}

	return Math.round(median(hoursArray) / plannedDailyHours * 100 * 10) / 10;
};

export const averageHoursPerDay = (interval, workerDays) => {
	if (!interval || !workerDays) {
		return null;
	}

	const daysCount = eachDayOfInterval(interval).length;

	if (!daysCount) {
		return 0;
	}

	const totalHours = workerDays
		.filter(wd => isWithinInterval(parseISO(wd.date), interval))
		.filter(wd => !wd.isAbsence)
		.reduce((acc, wd) => acc + 	hourseNumber(wd.timeWorked), 0)
		;

	return Math.round(totalHours / daysCount * 10) / 10;
};

export const averageAbsencePerWorker = (interval, workerDays) => {
	const days = workerDays.filter(wd => isWithinInterval(parseISO(wd.date), interval));

	const workersIds = new Set();

	for (const day of days) {
		workersIds.add(day.workerId);
	}

	const workersCount = workersIds.size;

	if (!workersCount) {
		return 0;
	}

	const totalAbsenceses = days.filter(wd => wd.isAbsence).length;

	return Math.round(totalAbsenceses / workersCount * 10) / 10;
};
