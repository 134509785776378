import React from 'react';
import PropTypes from 'prop-types';
import { LineChart, Line, CartesianGrid, YAxis, XAxis, Tooltip, ResponsiveContainer } from 'recharts';


import ChartTooltip from './Tooltip';


const CustomTooltip = ({ active, payload, label }) => {
	if (!active) {
		return null;
	}

	const data = payload && payload[0]?.payload;

	if (!data) {
		return null;
	}

	const { plan, real, dates } = data;

	const labels = [
		{
			text: 'Плановое значение',
			value: plan ? `${plan}%` : null,
			color: '#7182AE',
		},
		{
			text: 'Фактическое значение',
			value: real ? `${real}%` : null,
			color: '#FFC530',
		},
		{
			text: 'Дата',
			value: dates,
		}
	];

	return <ChartTooltip labels={labels}/>;
};

class ProjectProgressChart extends React.PureComponent
{
	static propTypes = {
		plan: PropTypes.arrayOf(PropTypes.any),
		real: PropTypes.arrayOf(PropTypes.any),
		dates: PropTypes.arrayOf(PropTypes.string),
	}

	static defaultProps = {
		plan: [],
		real: [],
		dates: null
	}

	render()
	{
		const { plan, real, dates } = this.props;

		const data = [];
		const ticks = new Array(11).fill().map((_, i) => (i * 10));

		if (dates) {
			for (let i = 0; i < dates.length; ++i) {
				data.push({
					plan: plan ? plan[i] : null,
					real: real ? real[i] : null,
					dates: dates[i],
				});
			}
		}

		return (
			<div>
				<ResponsiveContainer debounce={2} height={"95%"}>
					<LineChart data={data}>
						<CartesianGrid
							vertical={false}
							stroke="#ccc"
							strokeDasharray="5 5"
						/>
						<Line
							type="monotone"
							dataKey="plan"
							stroke="#7182AE"
							strokeWidth={3}
							dot={false}
						/>
						<Line
							type="monotone"
							dataKey="real"
							stroke="#FFC530"
							strokeWidth={3}
							dot={false}
						/>
						<YAxis
							ticks={ticks}
							interval={0}
							// domain={[0, 105]}
							hide
						/>
						<XAxis
							dateKey="dates"
							hide
						/>
						<Tooltip content={<CustomTooltip />}/>
					</LineChart>
				</ResponsiveContainer>
			</div>
		);
	}
}

export default ProjectProgressChart;
