class RequestItemsAggregator
{
	constructor(params)
	{
		this.params = params;
		this._getItemsMapByConsumableItemId();
		this._getItemsMapByPurchaseRequestId();
		this._getPurchaseOrderItemsMapByRequestItemId();
	}

	_getRequestItemStatus = requestItem => {
		const {
			purchaseOrderItems,
			isCanceled,
			isAmountApproved,
		} = requestItem;

		const hasOrders = purchaseOrderItems.length;
		const hasUnapprovedOrders = hasOrders && purchaseOrderItems.some(item => !item.isApproved);

		if (isCanceled) {
			return 'Отменена';
		}

		if (!isAmountApproved) {
			return 'Утверждение количества';
		}

		if (hasUnapprovedOrders) {
			return 'Требуется утверждение';
		}

		if (hasOrders && !hasUnapprovedOrders) {
			return 'Ожидание поставки';
		}

		return 'Оформлена';
	};

	_getItemsMapByConsumableItemId = () => {
		const { purchaseRequestItems } = this.params;
		const map = new Map();

		for (const item of purchaseRequestItems) {
			const id = item.consumableSheetItemId;
			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this.itemsMapByConsumableItemId = map;
	};

	_getItemsMapByPurchaseRequestId = () => {
		const { purchaseRequestItems } = this.params;
		const map = new Map();

		for (const item of purchaseRequestItems) {

			const id = item.purchaseRequestId;
			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(item);
			} else {
				map.set(id, [item]);
			}
		}

		this.itemsMapByPurchaseRequestId = map;
	};

	_getPurchaseOrderItemsMapByRequestItemId = () => {
		const { purchaseOrderItems, purchaseOrdersMap, purchaseInvoices } = this.params;
		const map = new Map();

		for (const item of purchaseOrderItems) {
			const id = item.purchaseRequestItemId;

			if (!purchaseOrdersMap.has(item.purchaseOrderId)) {
				continue; // TODO: это какой-то говнохак, такого вообще не должно быть, надо избавиться от этого агрегатора и сделать как везде.
			}

			const invoices = purchaseInvoices.filter(invoice => invoice.purchaseOrderId === item.purchaseOrderId);
			const patchedItem = {
				...item,
				purchaseOrder: {
					...purchaseOrdersMap.get(item.purchaseOrderId),
					invoice: invoices.length > 0 ? invoices[0] : null
				},
			};


			const mapItems = map.get(id);

			if (mapItems) {
				mapItems.push(patchedItem);
			} else {
				map.set(id, [patchedItem]);
			}
		}

		this.purchaseOrderItemsMapByRequestItemId = map;
	};

	completedRequestItems = () => {
		const {
			purchaseRequestItems,
			consumableSheetItemsMap,
			purchaseRequestsMap,
		} = this.params;

		const {
			itemsMapByConsumableItemId,
			itemsMapByPurchaseRequestId,
			purchaseOrderItemsMapByRequestItemId,
		} = this;

		return purchaseRequestItems
			.filter(item => purchaseRequestsMap.get(item.purchaseRequestId))
			.map(item => {

				const ordererdItems = itemsMapByConsumableItemId.get(item.consumableSheetItemId) || [];

				const orderedAmount = ordererdItems
					.filter(i => !i.isCanceled)
					.reduce((acc, item) => {
						return acc + Number(item.amount);
					}, 0)
				;
				const purchaseRequest = purchaseRequestsMap.get(item.purchaseRequestId) || {};
				const purchaseRequestItems = itemsMapByPurchaseRequestId.get(purchaseRequest.id) || [];
				const purchaseOrderItems = purchaseOrderItemsMapByRequestItemId.get(item.id) || [];
				const consumableSheetItem = consumableSheetItemsMap.get(item.consumableSheetItemId) || {};

				const result = {
					...item,
					purchaseRequest,
					purchaseRequestItems,
					purchaseOrderItems,
					orderedAmount,
					consumableSheetItem,
				};

				return {
					...result,
					status: this._getRequestItemStatus(result),
				};
			})
		;
	};

	completedRequests = () => {
		const {
			purchaseRequests,
			consumableSheetItemsMap,
			purchaseInvoices
		} = this.params;

		const {
			itemsMapByPurchaseRequestId,
			itemsMapByConsumableItemId,
			purchaseOrderItemsMapByRequestItemId,
		} = this;

		return purchaseRequests.map(pr => {

			const purchaseRequestItems = (itemsMapByPurchaseRequestId.get(pr.id) || [])
				.map(pri => {

					const ordererdItems = itemsMapByConsumableItemId.get(pri.consumableSheetItemId) || [];
					const purchaseOrderItems = purchaseOrderItemsMapByRequestItemId.get(pri.id) || [];
					const consumableSheetItem = consumableSheetItemsMap.get(pri.consumableSheetItemId) || {};
					const orderedAmount = ordererdItems
						.filter(i => !i.isCanceled)
						.reduce((acc, item) => {
							return acc + Number(item.amount);
						}, 0)
					;

					return {
						...pri,
						purchaseOrderItems,
						consumableSheetItemsMap,
						consumableSheetItem,
						orderedAmount,
					};
				})
			;

			const purchaseOrderItems = purchaseRequestItems.reduce((acc, i) => {
				return acc.concat(i.purchaseOrderItems);
			}, []);

			// Группировка позиций заказов по заказам (собираются заказы)
			let orders = {};
			purchaseOrderItems.forEach((orderItem) => {
				if (!orders[orderItem.purchaseOrderId]) {
					orders[orderItem.purchaseOrderId] = {
						id: orderItem.purchaseOrderId,
						items: [],
						invoice: null
					};
				}

				orders[orderItem.purchaseOrderId].items.push(orderItem);
			});

			// Добавляются счета к заказам и фильтруются только по тем, где счета есть
			orders = Object.values(orders).map((order) => {
				order.invoice = purchaseInvoices.filter(i => i.purchaseOrderId === order.id)[0];

				return order;
			}).filter(o => o.invoice);

			return {
				...pr,
				purchaseRequestItems,
				purchaseOrderItems,
				orders
			};
		});
	};
}

export default RequestItemsAggregator;
