class FileWrapper
{
	constructor({ file, request, abort, status, uid })
	{
		this.file = file;
		this.request = request;
		this.abort = abort;
		this.status = status;
		this.uid = uid;
	}

	setWarning(warning)
	{
		this.warning = warning;
	}

	setStatus(status) {
		this.status = status;
	}

	//@ one of ['loading', 'error', 'done']
	setError(error)
	{
		this.error = error;
	}

	errorText()
	{
		return this.error?.message;
	}
}

export default FileWrapper;
