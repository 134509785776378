import React from 'react';
import {connect} from 'react-redux';
import db from 'core/db';
import api from 'core/api';
import { toast } from 'react-toastify';
import uuidv4 from 'uuid/v4';

import Workspace from 'layouts/Default/components/Workspace';
import { ButtonToolbar } from 'components/ui';
import Grid from './components/Grid';
import RoleEditForm from './components/RoleEditForm';
import pageStyle from 'styles/page.module.sass';
import { Modal } from 'components/ui/modals';


class RolesSettings extends React.PureComponent
{
	state = {
		editRoleFormVisible: false,
		editingRole: {},
		editingRoleEntityTypes: [],
	}

	hideModal = () => {
		this.setState({editRoleFormVisible: false});
	}

	onEditRole = editingRole => {
		const sourceTypesMap = this.props.entityTypesMap;
		const typesMap = new Map();

		for (const key of sourceTypesMap.keys()) {
			typesMap.set(key, {...sourceTypesMap.get(key)});
		}

		for (const permission of editingRole.permissions) {
			const type = typesMap.get(permission.entityTypeId);
			if (type.permissions) {
				type.permissions.push(permission.permission);
			} else {
				type.permissions = [permission.permission];
			}
		}

		const editingRoleEntityTypes = Array.from(typesMap.values());

		this.setState({
			editingRole,
			editingRoleEntityTypes,
			editRoleFormVisible: true,
		});
	}

	onRemoveRole = role => {
		this.props.dispatch(api.roles().update({
			roleId: role.id,
			changes: {
				isDeleted: true,
			}
		}))
			.catch((err) => {
				toast.error("Не удалось удалить роль", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
			.finally(() => {
				this.hideModal();
			})
		;
	}

	onRemoveRoleModal = (role) => {
		Modal.confirm({
			title: 'Удаление роли',
			text: `Удалить роль '${role.title}'?`,
			onConfirm: () => this.onRemoveRole(role),
		});
	}

	onAddRole = (title) => {
		this.props.dispatch(api.roles().create({
			roleId: uuidv4().toUpperCase(),
			title: title,
			permissions: [],
		}))
			.catch((err) => {
				toast.error("Не удалось создать новую роль", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
		;
	}

	onAddRoleModal = () => {
		Modal.input({
			title: 'Добавление роли',
			placeholder: 'Имя новой роли',
			onConfirm: this.onAddRole,
		});
	}

	onUpdateRole = (role, changes) => {
		this.props.dispatch(api.roles().update({
			roleId: role.id,
			changes,
		}))
			.catch((err) => {
				toast.error("Не удалось внести изменения...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(err);
			})
			.finally(() => {
				this.hideModal();
			})
		;
	}

	render()
	{
		const actions = [
			{
				title: 'Добавить роль',
				onClick: this.onAddRoleModal,
			}
		];

		return (
			<Workspace actions={actions} hideTitle={true} hideBreadcrumbs={true}>
				<Grid
					items={this.props.items}
					onEditRole={this.onEditRole}
					onRemoveRole={this.onRemoveRoleModal}
				/>
				<RoleEditForm
					editingItem={this.state.editingRole}
					modalProps={{show: this.state.editRoleFormVisible}}
					formProps={{entityTypes: this.state.editingRoleEntityTypes}}
					title={'Редактирование роли'}
					onClose={this.hideModal}
					onUpdate={this.onUpdateRole}
				/>
			</Workspace>
		);
	}
}

const mapStateToProps = (state, props) => {
	const defaultRoleAssignementId = '107E8C34-546D-4606-A223-F11D43A369FE';

	const roles = db.roles.list().map(role => {
		const assignements = role.assignements || [];
		const hasDefaultAssignement = assignements.map(a => a.id).includes(defaultRoleAssignementId);

		return {
			...role,
			usersCount: 0,
			isDefaultRole: hasDefaultAssignement,
			isServiceRole: assignements.length > 0,
		};
	});

	const employees = db.employees.list();
	const rolesMap = roles.hashById();
	const entityTypes = db.entityTypes.list();

	for (const emp of employees) {
		const empRoles = emp.roles;
		for (const r of empRoles) {
			const item = rolesMap.get(r.id);
			if (item) {
				item.usersCount = item.usersCount + 1;
			}
		}
	}

	return {
		items: Array.from(rolesMap.values()).sort((a, b) => (b.isServiceRole - a.isServiceRole)),
		entityTypes: entityTypes,
		entityTypesMap: entityTypes.hashById(),
	};
};

export default connect(mapStateToProps)(RolesSettings);
