import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast, Slide } from 'react-toastify';

import api from 'core/api';
import db from 'core/db';
import notify from 'utils/suppliesNotifications';
import { NewModal } from 'components/ui/modals';
import Grid from './Grid';
import styles from './styles.module.sass';

class ApprovalModal extends React.PureComponent {
	static propTypes = {
		visible: PropTypes.bool,
		onConfirm: PropTypes.func,
		onCancel: PropTypes.func,
		order: PropTypes.object,
		invoiceItems: PropTypes.arrayOf(PropTypes.object),
	}

	costCalc = purchaseOrderItems => {
		return purchaseOrderItems.reduce((acc, item) => {
			const { amount, amountProposed, priceActual } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);
	};

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	updatePurchaseOrderItem = (id, changes) => {
		this.props.dispatch(
			api.tbsPurchaseOrderItems().update({
				purchaseOrderItemId: id,
				changes: changes
			}))
			.catch((err) => {
				console.error(err);
				this.showError("Не удалось внести изменения");
			})
		;
	};

	onCellValueChanged = (params, updateCallback) => {
		if (params.oldValue === params.newValue) {
			return;
		}

		const fieldName = params.colDef.field;
		const changes = {};
		changes[fieldName] = params.newValue;

		this.updatePurchaseOrderItem(params.node.data.id, changes);
	}

	approveOrderItem = (id, row) => {
		const transactionOps = [];

		if (row.data.isApproved) {
			// Снимаем аппрув
			transactionOps.push(api.tbsPurchaseOrderItems().update({
				purchaseOrderItemId: id,
				changes: {
					isApproved: null
				}
			}));

			// Возвращаем исходное количество в заявке на место.
			transactionOps.push(api.tbsPurchaseRequestItems().update({
				purchaseRequestItemId: row.data.purchaseRequestItemId,
				changes: {
					amount: row.data.amount
				}
			}));

		} else {
			// нужен алерт, потому что это исходное значение количества в заяявке не сохранится и только руками обратно менять.
			transactionOps.push(api.tbsPurchaseRequestItems().update({
				purchaseRequestItemId: row.data.purchaseRequestItemId,
				changes: {
					amount: row.data.amountProposed
				}
			}));

			transactionOps.push(api.tbsPurchaseOrderItems().approve({
				purchaseOrderItemId: id,
			}));
		}

		this.props.dispatch(api.transaction().execute(transactionOps))
			.then(() => {
				row.api.refreshCells({
					rowNodes: [row.node],
					force: true
				});
			})
			.catch(error => {
				this.showError('Не удалось утвердить позицию счёта');
				console.error(error);
			});
	}

	rejectOrderItem = (id, row) => {
		const isCancel = row.data.isApproved === false;
		this.props.dispatch(api.tbsPurchaseOrderItems().update({
			purchaseOrderItemId: id,
			changes: {
				isApproved: isCancel ? null : false
			}
		}))
			.then(() => {
				row.api.refreshCells({
					rowNodes: [row.node],
					force: true
				});

				if (!isCancel) {
					notify(this.props.employees, 'invoice-declined');
				}
			})
			.catch(error => {
				this.showError('Не удалось отклонить позицию счёта');
				console.error(error);
			});
	}

	completeInvoiceApprovement = () => {
		this.props.dispatch(api.tbsPurchaseInvoices().removeFromApprove({
			purchaseInvoiceId: this.props.order.invoice?.id,
		})).then(() => {
			notify(this.props.employees, 'invoice-approved');
			this.props.onCancel();
		});
	}

	render () {
		const { isVisible, order, onCancel } = this.props;

		if (!order || this.props.isLoading) {
			return null;
		}

		console.log('ORDER', order);
		const isCompleteBtnDisabled = !order.invoice.isWaitingForApprove || order.items.some(item => !item.isApproved || !item.requestItem.isAmountApproved || Number(item.amountProposed) > Number(item.amount));
		const btnList = [
			{
				title: 'Отправить на оплату',
				props: {
					onClick: this.completeInvoiceApprovement,
					disabled: isCompleteBtnDisabled
				}
			},
			{
				title: 'Закрыть',
				props: {
					onClick: () => onCancel(),
				}
			}
		];

		return (
			<NewModal
				title="Согласование счёта"
				size="xl"
				show={isVisible}
				onHide={() => onCancel()}
				btnList={btnList}
			>
				<div className={styles.sectionHeader}>
					<div className={styles.title}>Данные счёта</div>
					<div className={styles.description}>Информация о поставщике и условиях оплаты</div>
				</div>
				<div className={styles.invoiceData}>
					<div>
					Счёт №{order.invoice.number} от {order.invoice.date}<br/>
						{order.invoice.supplierTitle}
					</div>
					<div>Сумма счёта: {this.costCalc(order.items)} {this.props.currenciesMap.get(order.invoice.currencyId).symbol}</div>
					<div>{order.invoice.paymentTermsNotes}</div>
				</div>

				<div className={styles.sectionHeader}>
					<div className={styles.title}>Состав счёта</div>
					<div className={styles.description}>Позиции из заявки, дополненные информацией из счёта</div>
				</div>
				<div style={{display: 'flex', minHeight: '350px'}}>
					<Grid
						items={order.items}
						currenciesMap={this.props.currenciesMap}
						onCellValueChanged={this.onCellValueChanged}
						onApproveOrderItem={this.approveOrderItem}
						onRejectOrderItem={this.rejectOrderItem}
						deltaRowDataMode={true}
					/>
				</div>
			</NewModal>
		);
	}
}

export default connect((state, props) => {
	// чтобы отображение переключения статусов работало, но вообще это какое-то говно, так нельзя делать
	const items = db.tbsPurchaseOrderItems.listNotDeleted().hashById();
	const collects = {
		employees: db.employees.list(),
	};
	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	if (props.order) {
		const monkey = props.order.items.map((item) => {
			item.isApproved = items.get(item.id).isApproved;

			return {
				...item
			};
		});

		return {
			...monkey,
			employees: collects.employees
		};
	}

	return props;
})(ApprovalModal);
