import JobUnitMatcher from './JobUnitMatcher';

class Formatter
{
	constructor(params)
	{
		this.params = params;
	}

	format()
	{
		const excelDateToJsDate = date => date && new Date((date - (25567 + 2)) * 86400 * 1000);

		return this.params.fileList
			.map(file => file.parsedRows)
			.reduce((res, rows) => {
				return res.concat(rows);
			}, [])
			.filter(row => !!row)
			.map(row => {
				const { estimatedStartDate, estimatedEndDate, unitTypeTitle } = row.data;

				const data = {
					...row.data,
					index: row.index,
					unitId: new JobUnitMatcher().match(unitTypeTitle),
					estimatedStartDate: excelDateToJsDate(estimatedStartDate),
					estimatedEndDate: excelDateToJsDate(estimatedEndDate),
				};

				delete data.unitTypeTitle;

				return data;
			});
	}
}

export default Formatter;
