import { formatISO, isAfter, eachDayOfInterval } from 'date-fns';
import { HistoryGroupArray, HistoryGroup, HistoryValue } from '../types';
import Normalizer from './Normalizer';

export default class LastNormalizer extends Normalizer
{
	normalize()
	{
		const { data, options, params = {} } = this;
		const { limitDate, defaultValue = 0 } = params;
		const { startDate, endDate } = options;

		const normalizedGroupArray = new HistoryGroupArray();

		for (const jobId of Object.keys(data)) {
			const group = data[jobId];

			const normalizedGroup = new HistoryGroup(jobId);

			let latestValue = defaultValue;
			const dates = Object.keys(group);
			const closestDate = dates.slice(-1);

			for (const day of eachDayOfInterval({ start: startDate, end: endDate })) {
				const date = formatISO(day, { representation: 'date' });

				if (limitDate && isAfter(day, limitDate)) {
					normalizedGroup.push(new HistoryValue(date, defaultValue));
					continue;
				}

				const value = group[date];

				if (value) {
					latestValue = value;
				} else if (closestDate && date > closestDate) {
					latestValue = group[closestDate];
				}

				normalizedGroup.push(new HistoryValue(date, latestValue));
			}

			normalizedGroupArray.push(normalizedGroup);
		}

		return normalizedGroupArray;
	}
}
