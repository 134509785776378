import moment from 'moment';
import 'moment/locale/ru';

import {
	EmptyCellRenderer,
	UserCellRenderer,
	SubtitleCellRenderer,
	StatusCellRenderer,
} from 'components/ui/grid/renderers';

import { numberFormatter } from 'components/ui/grid/formatters';

moment.locale('ru');

export const getGridOptions = params => {

	const { cellStyle } = params;

	const serialRenderer = params => `№ ${params.value}`;
	const amountRenderer = params => `${params.valueFormatted} ${params.data.budgetItem.consumableUnit.suffix}`;
	const statusRenderer = params => {
		const v = params.value;

		if (v === 'inTransit') {
			return 'Отгружен частично';
		} else if (v === 'delivered') {
			return 'Отгружен полностью';
		}

		return '';
	};

	const dateRenderer = params => params.value && moment(params.value).format('L');

	const frameworkComponents = {
		emptyCellRenderer: EmptyCellRenderer,
		userCellRenderer: UserCellRenderer,
		subtitleCellRenderer: SubtitleCellRenderer,
		statusCellRenderer: StatusCellRenderer,
		serialRenderer,
		statusRenderer,
		amountRenderer,
		dateRenderer,
	};

	const gridOptions = {
		frameworkComponents: frameworkComponents,
		defaultColDef: {
			minWidth: 40,
			resizable: true,
			sortable: true,
			editable: false,
			cellStyle,
		}
	};

	return gridOptions;
};

export const getColumnDefs = params => {


	const titleRendererParams = params => {
		const item = params.data.budgetItem;

		return {
			subtitle: `${item.partNumber} / ${item.manufacturerName}`
		};
	};

	const colDefs = [
		{
			headerName: 'Наименование',
			field: 'budgetItem.title',
			sort: 'asc',
			pinned: 'left',
			cellRendererParams: titleRendererParams,
			width: 400,
			cellStyle: { lineHeight: 1.2, paddingTop: '4px' }
		},
		{
			headerName: 'Количество',
			field: 'orderItem.amount',
			valueFormatter: numberFormatter,
			valueGetter: (params) => {
				if (params.data.orderItem) {
					return params.data.orderItem.amount || params.data.amount;
				}

				return params.data.amount;
			},
			minWidth: 120,
			width: 120
		},
		{
			headerName: 'Cрок поставки',
			field: 'orderItem.deliveryDateActual',
			width: 140
		},
		{
			headerName: 'Статус',
			field: 'status',
			width: 100
		},
		{
			headerName: 'Номер счёта',
			width: 130,
			field: 'invoice.number',
		},
		{
			headerName: 'Дата счёта',
			width: 150,
			field: 'invoice.date',
		},
		{
			headerName: 'Заявка',
			width: 100,
			field: 'request.sn',
		},
		{
			headerName: 'Поставщик',
			width: 300,
			field: 'invoice.supplierTitle'
		},
		{
			headerName: 'Создатель заявки',
			width: 150,
			field: 'request.employee.user',
		},
		{
			headerName: 'Ответственный',
			width: 150,
			field: 'request.assignedUser',
		},
	];

	const renderersMap = {
		'budgetItem.title': { component: 'subtitleCellRenderer' },
		'request.assignedUser': { component: 'userCellRenderer' },
		'request.employee.user': { component: 'userCellRenderer' },
		'request.sn': { component: 'serialRenderer' },
		'orderItem.deliveryStatus': { component: 'statusRenderer' },
		'orderItem.amount': { component: 'amountRenderer' },
		'orderItem.deliveryDateActual': { component: 'dateRenderer' },
		'invoice.date': { component: 'dateRenderer' },
	};

	const getRendererSelector = (params, column) => {
		const isValueUndefined = params.value === null || params.value === undefined;

		if (isValueUndefined) {
			return { component: 'emptyCellRenderer'};
		}

		return renderersMap[column.field] || null;
	};

	return colDefs.map(column => {
		if (column.field) {
			column.cellRendererSelector = params => getRendererSelector(params, column);
		}

		return column;
	});
};
