import React from 'react';
import PropTypes from 'prop-types';
import api from 'core/api';
import { toast, Slide } from 'react-toastify';
import cn from 'classnames';
import moment from 'moment';
import { connect } from 'react-redux';

import { ButtonToolbar, Page } from 'components/ui';
import ProjectEditForm from './components/ProjectEditForm';
import Grid from './components/Grid';
import { validationFields } from './components/Grid/defs';
import { isValid } from './helpers/validators';


class EditView extends React.PureComponent
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		onConfirmImport: PropTypes.func,
	};

	static defaultProps = {
		items: []
	};

	state = {
		selectedNodes: [],
		btnImportDisabled: false,
		project: {},
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
	}

	onRemoveNodes = (nodes) => {
		this.gridApi.updateRowData({ remove: nodes.map(node => node.data) });
	}

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	confirmImport = () => {
		if (this.hasUnfilledReqiuredCells()) {
			this.showError("Необходимо заполнить все обязательные поля!");

			return;
		}

		const model = this.gridApi.getModel();
		const nodes = model.rootNode.allLeafChildren;
		const jobList = nodes.map(node => {
			const data = node.data;
			delete data.index;

			return data;
		});

		const projectRequest = api.projects().create({...this.state.project});
		const projectId = projectRequest.props.projectId;

		const stages = [];
		const stageRequests = [];
		let stageId;

		// TODO: это нужно оставить для негруппированных работ в корне
		//const title = 'Новый этап';
		//const stageRequest = api.stages().create({ projectId, title });
		//const stageId = stageRequest.props.stageId;

		const jobRequests = jobList.map(job => {
			job.title = job.title.replace(/\n/g, '');

			let stage;
			let stageTitle;
			let stageRequest;
			let parentStage;
			let parentStageRequest;

			if (job.subStageTitle) {
				stage = stages.find(s => s.title === job.subStageTitle);
				stageTitle = job.subStageTitle;
			} else if (!job.subStageTitle && job.stageTitle) {
				stage = stages.find(s => s.title === job.stageTitle);
				stageTitle = job.stageTitle;
			} else if (!job.subStageTitle && !job.stageTitle) {
				stage = stages.find(s => s.title === 'Этап 1');
				stageTitle = 'Этап 1';
			}

			if (!stage) {
				if (job.subStageTitle) {
					parentStage = stages.find(s => s.title === job.stageTitle);

					if (!parentStage) {
						parentStageRequest = api.stages().create({ projectId, title: job.stageTitle });
						parentStage = {
							id: parentStageRequest.props.stageId,
							title: job.stageTitle,
							projectId
						};
						stages.push(parentStage);
						stageRequests.push(parentStageRequest);
					}

					stageRequest = api.stages().create({ projectId, title: job.subStageTitle, parentStageId: parentStage.id });
				} else {
					stageRequest = api.stages().create({ projectId, title: stageTitle });
				}

				stage = {
					id: stageRequest.props.stageId,
					title: stageTitle,
					projectId
				};
				stages.push(stage);
				stageRequests.push(stageRequest);
			}

			stageId = stage.id;

			return api.jobs().create({
				stageId,
				projectId,
				...job,
				estimatedStartDate: moment(job.estimatedStartDate).format("YYYY-MM-DD"),
				estimatedEndDate: moment(job.estimatedEndDate).format("YYYY-MM-DD"),
			});
		});

		const jobIDs = jobRequests.map(request => request.props.jobId);
		const stageIDs = stageRequests.map(request => request.props.stageId);

		const transactionBody = [
			projectRequest,
			...stageRequests,
			...jobRequests,
			api.stages().saveOrder({ orderedIds: stageIDs }),
			api.jobs().saveOrder({ orderedIds: jobIDs }),
		];

		this.setState({ btnImportDisabled: true }, () => {
			this.gridApi.showLoadingOverlay();
			this.props.dispatch(api.transaction().execute(transactionBody))
				.then(() => this.props.onConfirmImport())
				.catch((err) => {
					console.error(err);
					this.showError("Не удалось создать проект. " + err.name + ': ' + err.message);
				})
				.finally(() => {
					this.gridApi.hideOverlay();
					this.setState({ btnImportDisabled: false });
				})
			;
		});
	}

	hasUnfilledReqiuredCells = () => {
		if (!this.gridApi) {
			return true;
		}

		const model = this.gridApi.getModel();
		const nodes = model.rootNode.allLeafChildren;

		for (const node of nodes) {
			const data = node.data;
			for (const key of Array.from(Object.keys(data))) {
				if (validationFields.includes(key) && !isValid(data[key], key)) {

					return true;
				}
			}
		}

		return false;
	}

	onFormSubmit = project => {
		this.setState({ project }, () => {
			this.confirmImport();
		});
	}

	render()
	{
		return (
			<Page className="p-0">
				<div
					className="p-3"
					style={{backgroundColor: 'white'}}
				>
					<ProjectEditForm
						onSubmit={values => this.onFormSubmit(values)}
						onFormCreated={form => (this.projectEditForm = form)}
						employees={this.props.employees.map(e => e.user)}
					/>
				</div>
				<div className={cn('flex-grow-1', 'd-flex', 'm-2')}>
					<Grid
						{...this.props}
						onGridReady={this.onGridReady}
						onCreateNewStage={this.onCreateNewStage}
						onMoveToStage={this.onMoveToStage}
						onRemoveNodes={this.onRemoveNodes}
					/>
				</div>
				<ButtonToolbar
					className={cn('mb-2', 'mr-2')}
					btnList={[
						{
							title: 'Сохранить',
							props: {
								onClick: () => this.projectEditForm?.handleSubmit(),
								disabled: this.state.btnImportDisabled,
							}
						}
					]}
				/>
			</Page>
		);
	}
}

export default connect()(EditView);

