import React from 'react';
import PropTypes from 'prop-types';
import ActionsToolbar from 'components/ui/toolbars/ActionsToolbar';


class ActionsCellRenderer extends React.PureComponent
{
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.object),
		title: PropTypes.string,
	}

	static defaultProps = {
		actions: []
	}

	renderWithTitle()
	{
		const { data, actions } = this.props;

		const actionsWithData = actions.map(act => {
			return {
				...act,
				onClick: act.onClick && (() => act.onClick(data)),
			};
		});

		return (
			<div className={"d-flex flex-row align-items-center w-100"}>
				<ActionsToolbar actions={actionsWithData}/>
				<div className="ml-1">
					{this.props.title}
				</div>
			</div>
		);
	}

	renderActions()
	{
		const { data, actions } = this.props;

		const actionsWithData = actions.map(act => {
			return {
				...act,
				onClick: act.onClick && (() => act.onClick(data)),
			};
		});

		return (
			<div className={"d-flex flex-row justify-content-center align-items-center w-100"}>
				<ActionsToolbar actions={actionsWithData}/>
			</div>
		);
	}

	render()
	{
		const { title } = this.props;

		return title ? this.renderWithTitle() : this.renderActions();
	}
}

export default ActionsCellRenderer;
