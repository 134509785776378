import React from 'react';
import PropTypes from 'prop-types';

import style from 'components/ui/grid/styles/custom-cell.module.sass';

class SubtitleCellRenderer extends React.PureComponent
{

	static propTypes = {
		subtitle: PropTypes.string,
		valueFormatter: PropTypes.func,
	}

	static defaultProps = {
		subtitle: '',
	}

	render() {
		let value = this.props.valueFormatted || this.props.value;

		if (this.props.valueFormatter) {
			value = this.props.valueFormatter(value);
		}

		if (!this.props.subtitle.length) {
			return (
				<div
					className={style.wrapper}
				>
					{this.props.value}
				</div>
			);
		}

		return (
			<div className={[style.wrapper, "flex-column align-items-start position-relative px-0"].join(' ')}>
				<div
					className="d-flex align-items-center w-100"
					style={{height: '65%'}}
				>
					<span className="d-inline-block text-truncate">
						{value}
					</span>
				</div>
				<div
					className="d-flex align-items-center w-100 font-weight-bold"
					style={{fontSize: '0.75em', height: '35%'}}
				>
					<span className="d-inline-block text-truncate">
						{this.props.subtitle}
					</span>
				</div>
			</div>
		);
	}
}

export default SubtitleCellRenderer;

