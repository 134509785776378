import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import { DatePicker } from 'components/ui';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';


const DateInput = (props) => {
	const { placeholder, ...otherProps } = props;
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return <DatePicker
		onChange={date => helpers.setValue(date)}
		value={value}
		customInput={<Form.Control/>}
		placeholderText={placeholder}
		controlled
		{...otherProps}
	/>;
};

DateInput.propTypes = {
	name: PropTypes.string.isRequired,
	format: PropTypes.func,
};


export default DateInput;
