import { mainRoutes as projectRoutes } from './domains/projects/routes';
import employeesRoutes from './domains/employees/routes';
import { mainRoutes as companyRoutes } from './domains/company/routes';
import userRoutes from './domains/user/routes';
import timesheetRoutes from './domains/workers/routes';
import consumablePricelistsRoutes from './domains/consumable-pricelists/routes';

export const getRoutes = (navigation) => {
	let r = [];

	const getChildrenPaths = (item, parentPath = '') => {
		const currentPath = parentPath + item.path;
		let res = [{...item, path: currentPath, parent: parentPath === '' ? null : parentPath}];

		if (item.children && item.children.length) {
			for (const child of item.children) {
				res = res.concat(getChildrenPaths(child, currentPath));
			}
		}

		return res;
	};

	for (const item of navigation) {
		r = r.concat(getChildrenPaths(item));
	}

	return r;
};

export const getRoutesMap = (navigation) => {
	let r = {};

	const getChildrenPaths = (item, parentPath = '') => {

		const currentPath = parentPath + item.path;
		let res = {};
		res[currentPath] = {...item, parent: parentPath === '' ? null : parentPath };

		if (item.children && item.children.length) {
			for (const child of item.children) {
				res = Object.assign(res, getChildrenPaths(child, currentPath));
			}
		}

		return res;
	};

	for (const item of navigation) {
		r = Object.assign(r, getChildrenPaths(item));
	}

	return r;
};

export const makeLinkDynamic = (link, dynamics = {}) => {
	const splitted = link.split('/');
	for (let i = 0; i < splitted.length; ++i) {
		const item = splitted[i];
		const dynamic = dynamics[item.substr(1)];
		if (item.startsWith(':') && dynamic) {
			splitted[i] = dynamic;
		}
	}

	return splitted.join('/');
};

export const navigation = [
	{
		path: '/',
		to: '/projects',
		exact: true,
		redirect: true,
	},
	...projectRoutes,
	...employeesRoutes,
	...companyRoutes,
	...userRoutes,
	...timesheetRoutes,
	...consumablePricelistsRoutes,
];

export const routes = getRoutes(navigation);
export const routesMap = getRoutesMap(navigation);
