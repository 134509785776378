import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import InvoiceInfoGrid from './components/InvoiceInfoGrid';
import cn from 'classnames';


class InvoiceInfoModal extends React.PureComponent
{
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		invoice: PropTypes.object,
		consumableSheetItemsMap: PropTypes.any.isRequired,
		purchaseRequestItemsMap: PropTypes.any.isRequired,
	}

	static defaultProps = {
		visible: false,
	}

	render()
	{
		const { invoice, purchaseRequestItemsMap, consumableSheetItemsMap } = this.props;

		if (!invoice) {
			return null;
		}

		const { orderItems } = invoice;

		const items = orderItems
			.map(poi => {
				const pri = purchaseRequestItemsMap.get(poi.purchaseRequestItemId);
				const csi = consumableSheetItemsMap.get(pri.consumableSheetItemId);

				return {
					...poi,
					consumableSheetItem: csi,
				};
			})
		;

		const height = items.length * 35 + 60;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.props.onClose()
				}
			},
		];

		return (
			<Modal
				title={"Счёт 1С"}
				show={this.props.visible}
				btnList={btnList}
				onHide={() => this.props.onClose()}
			>
				<div
					className={cn('w-100', 'd-flex')}
					style={{ height }}
				>
					<InvoiceInfoGrid
						items={items}
					/>
				</div>
			</Modal>
		);
	}
}

export default InvoiceInfoModal;
