import HistoryDataIterator from '../HistoryDataIterator';
import HistoryBuilder from './HistoryBuilder';


export default class PerfomanceBuilder extends HistoryBuilder
{
	build()
	{
		const { history, params } = this;
		const { jobsMap } = params;

		if (!jobsMap) {
			throw (new Error('jobsMap is Required!'));
		}

		return new HistoryDataIterator(history).forEach((value, jobId) => {
			const job = jobsMap.get(jobId);

			if (!job) {
				return 0;
			}

			const { productionRatePerShift } = job;

			if (!productionRatePerShift) {
				return 0;
			}

			return value * productionRatePerShift;
		});

	}
}
