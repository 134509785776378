import React from 'react';
import { NewModal as Modal } from 'components/ui/modals';
import PropTypes from 'prop-types';
import CurrencyRatesForm from './components/CurrencyRatesForm';


class CurrencyRatesModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		currencyRates: PropTypes.object.isRequired,
		currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
		currenciesMap: PropTypes.any.isRequired,
	}

	render() {
		const {
			onConfirm,
			onCancel,
			visible,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.currencyRatesFormProps.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		const onFormReady = props => {
			this.currencyRatesFormProps = props;
		};

		return (
			<Modal
				btnList={btnList}
				size="md"
				title="Курсы валют"
				show={visible}
				onHide={() => onCancel()}
			>
				<CurrencyRatesForm
					onFormReady={onFormReady}
					onSubmit={rates => onConfirm(rates)}
					onCancel={() => onCancel()}
					currenciesMap={this.props.currenciesMap}
					currencies={this.props.currencies || []}
					ratesValues={this.props.currencyRates || {}}
				/>
			</Modal>
		);
	}
}

export default CurrencyRatesModal;
