import React from 'react';
import { connect } from 'react-redux';
import { Navbar, Button, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeartbeat, faBell, faUser, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { makeLinkDynamic } from 'routes';
import { withRouter, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { MenuItem, MenuItemSeparator } from '../types';
import menu from './menu.js';
import styles from './style.module.sass';
import userIcon from 'layouts/Default/assets/img/icons/worker-001.png';
import logoSrc from '../../assets/img/logo-square.png';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';

class MainMenu extends React.Component {

	onLogoutPress = () => {
		this.props.dispatch({type: 'LOGOUT'});
	}

	renderMenuItem = (item, withSubmenu = false, dymanics = {}) => {
		const { link, routes, label } = item;
		const { collapsed, location } = this.props;
		const { pathname } = location;

		const isActive = routes.some(r => !!matchPath(pathname, { path: r, strict: false }));

		const itemLabel = !collapsed && <div>
			{label}
		</div>;
		const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
			<div
				ref={ref}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}
				className={cn(
					{[styles.link]: !collapsed},
					{[styles.linkActive]: isActive},
					"d-flex",
					"flex-row",
					"align-items-center"
				)}>
				{children}
				<FontAwesomeIcon icon={faChevronDown} fixedWidth size="xs" className={cn("ml-1", styles.chevron)} color="#afafaf" />
			</div>
		));

		if (withSubmenu) {
			return (
				<Dropdown key={item.key}>
					<Dropdown.Toggle
						as={CustomToggle}
					>{itemLabel}</Dropdown.Toggle>
					<DropdownMenu className={cn([styles.submenu])}>
						{item.submenu.map((subItem, index) => (
							<LinkContainer to={makeLinkDynamic(subItem.link, dymanics)} exact={true} key={index}>
								<Dropdown.Item>
									{subItem.label}
								</Dropdown.Item>
							</LinkContainer>
						))}
					</DropdownMenu>
				</Dropdown>
			);
		}

		return (
			<React.Fragment key={item.key}>
				<LinkContainer to={makeLinkDynamic(link, dymanics)} exact={true}>
					<div className={cn(
						{[styles.link]: !collapsed},
						{[styles.linkActive]: isActive}
					)}>
						{itemLabel}
					</div>
				</LinkContainer>
			</React.Fragment>
		);
	}

	renderMainMenu = items => {
		return (
			<nav className={styles.mainMenu}>
				{items.map(item => {
					if (item instanceof MenuItem) {
						if (item.submenu?.length) {
							return this.renderMenuItem(item, true);
						}

						return this.renderMenuItem(item);
					} else if (item instanceof MenuItemSeparator) {
						return this.renderSeparartor(item);
					}

					return null;
				})}
			</nav>
		);
	}

	render() {
		const icons = [
			<FontAwesomeIcon icon={faHeartbeat} fixedWidth size="lg" />,
			<FontAwesomeIcon icon={faBell} fixedWidth size="lg" />,
		];

		const logoImg = <img src={logoSrc} className={styles.logoImg} />;
		const mainMenu = this.renderMainMenu(menu);
		const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
			<div
				className={styles.user}
				ref={ref}
				onClick={(e) => {
					e.preventDefault();
					onClick(e);
				}}>
				<span className={styles.name}>{children}</span>
				<div className={styles.icon}>
					<img src={userIcon}></img>
				</div>
			</div>
		));

		return (
			<Navbar expand="lg" className={styles.statusBar}>
				<div className={cn('d-flex')}>
					<LinkContainer
						to={'/'}
						exact={true}
						style={{cursor: 'pointer'}}
					>
						<div className={styles.logoWrapper}>{logoImg}</div>
					</LinkContainer>

					{mainMenu}
				</div>
				<div className={cn('d-flex')}>
					<div className={styles.controls}>
						<div className={styles.services}>
							{icons.map((icon, index) => (
								<div
									key={index}
									onClick={() => this.props.onActivityDrawerOpen(index)}
								>
									{icon}
								</div>
							))}
						</div>
					</div>

					<Dropdown drop="down" alignRight>
						<Dropdown.Toggle
							id="user-menu"
							as={CustomToggle}
							userName={this.props.me.fullName}
						>{this.props.me.fullName}</Dropdown.Toggle>

						<Dropdown.Menu className={styles.userMenu}>

							<Dropdown.Header className={styles.header}>
								<div className={styles.title}>
									<div className={styles.position}>{this.props.me.position || 'Сотрудник'}</div>
									<div className={styles.companyTitle}>{this.props.me.defaultEmployee.company.title}</div>
								</div>
							</Dropdown.Header>

							<LinkContainer to="/user/profile">
								<Dropdown.Item className={styles.item} eventKey="1">
									<FontAwesomeIcon icon={faUser} fixedWidth size="lg" />
									<span>Профиль</span>
								</Dropdown.Item>
							</LinkContainer>

							<Button
								onClick={this.onLogoutPress}
								className={styles.logoutButton}
							>
							Выход
							</Button>

						</Dropdown.Menu>
					</Dropdown>
				</div>
			</Navbar>
		);
	}
}

MainMenu.propTypes = {
	onActivityDrawerOpen: PropTypes.func.isRequired,
};

export default connect((state) => {
	return {
		me: state.me
	};
})(withRouter(MainMenu));
