import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import formikField from './formikField';

import { DATE_FORMAT } from 'core/helpers/constants';
import ru from "date-fns/locale/ru";
import 'react-datepicker/dist/react-datepicker.css';

registerLocale("ru", ru);


const DateInput = props => {

	const {
		forwardedRef,
		formikParams,
		inputProps,
		...innerProps
	} = props;

	const getDateValue = value => {
		if (!value) {
			return null;
		}

		if (value === '') {
			return value;
		}

		return moment(value).toDate();
	};

	const { field, form: { values } } = formikParams;
	const { value, onChange, ...otherInputProps } = inputProps;
	const { format, placeholder, formatter, ...otherInnerProps } = innerProps;

	const initialValue = getDateValue(values[field.name] || '');

	return (
		<Form.Control
			ref={forwardedRef}
			id={field.name}
			selected={getDateValue(value) || initialValue}
			onChange={value => onChange(formatter(value))}
			as={DatePicker}
			dateFormat={format}
			placeholderText={placeholder}
			autoComplete="off"
			{...otherInputProps}
			{...otherInnerProps}
		/>
	);
};

DateInput.propTypes = {
	locale: PropTypes.string,
	format: PropTypes.string,
	formatter: PropTypes.func,
};

DateInput.defaultProps = {
	locale: "ru",
	format: "dd.MM.yyyy",
	formatter: date => moment(date).format(DATE_FORMAT),
};

export default formikField(DateInput);
