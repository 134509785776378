
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap';


class Action extends React.PureComponent
{
	static propTypes = {
		icon: PropTypes.object.isRequired,
		popover: PropTypes.shape({
			title: PropTypes.string,
			content: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.node,
				PropTypes.element
			]),
		}),
		tooltip: PropTypes.string,
		onClick: PropTypes.func,
		className: PropTypes.string,
		style: PropTypes.object,
		hoverStyle: PropTypes.object,
		size: PropTypes.string,
		disabled: PropTypes.bool,
	}

	static defaultProps = {
		style: {},
		hoverStyle: {},
		size: '1x',
		disabled: false,
		onClick: () => null,
	}

	state = {
		hovered: false
	}

	onMouseEnterHandler = () => {
		this.setState({ hovered: true });
	}

	onMouseLeaveHandler = () => {
		this.setState({ hovered: false });
	}

	renderWithOverlay = (icon, overlay) => {

		const { tooltipTrigger } = this.props;

		const popperConfig = {
			modifiers: [
				{
					name: 'preventOverflow',
					enabled: false
				}, {
					name: 'hide',
					enabled: false
				}
			]
		};

		return (
			<OverlayTrigger
				overlay={overlay}
				trigger={tooltipTrigger || ["hover", "focus"]}
				popperConfig={popperConfig}
			>
				{icon}
			</OverlayTrigger>
		);
	}

	renderPopoverAction = icon => {
		const { popover } = this.props;

		const popoverComponent = (
			<Popover>
				<Popover.Title as="h4">
					{popover.title}
				</Popover.Title>
				<Popover.Content>
					{popover.content}
				</Popover.Content>
			</Popover>
		);

		return this.renderWithOverlay(icon, popoverComponent);
	}

	renderTooltipAction = icon => {
		const { tooltip } = this.props;

		const tooltipComponent = <Tooltip>
			{tooltip}
		</Tooltip>;

		return this.renderWithOverlay(icon, tooltipComponent);
	}

	render()
	{
		const { icon, onClick, className, tooltip, popover, size, disabled } = this.props;

		const style = {
			color: 'rgba(0, 0, 0, 0.6)',
			cursor: 'pointer',
			...(this.props.style)
		};

		const hoverStyle = {
			color: 'rgba(0, 0, 0, 0.7)',
			cursor: 'pointer',
			...(this.props.hoverStyle)
		};

		const hovered = this.state.hovered;
		const currentStyle = !hovered || disabled ? style : hoverStyle;

		const faIcon = <FontAwesomeIcon
			className={className}
			onMouseEnter={this.onMouseEnterHandler}
			onMouseLeave={this.onMouseLeaveHandler}
			icon={icon}
			fixedWidth
			style={currentStyle}
			onClick={disabled ? () => null : () => onClick()}
			size={size}
		/>;

		if (tooltip) {
			return this.renderTooltipAction(faIcon);
		} else if (popover) {
			return this.renderPopoverAction(faIcon);
		}

		return faIcon;
	}
}

export default Action;
