import React from 'react';
import { DateRangePicker, Container } from 'components/ui';
import { Card } from '../../layouts';
import PropTypes from 'prop-types';
import { sub } from 'date-fns';

class FiltersCard extends React.PureComponent
{
	static propTypes = {
		onDatesChanged: PropTypes.func,
	}

	render()
	{
		const now = new Date();

		return (
			<Card layout="row" {...this.props}>
				<Container layout="row" width="100%" between bind>
					<DateRangePicker
						initialDates={[sub(now, { days: 14 }), now]}
						onDatesChange={dates => this.props.onDatesChanged(dates)}
					/>
				</Container>
			</Card>
		);
	}
}

export default FiltersCard;
