import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import WorkerForm from './components/WorkerForm';

class WorkerModal extends React.PureComponent
{
	static propTypes = {
		onCancel: PropTypes.func,
		onConfirm: PropTypes.func,
		visible: PropTypes.bool.isRequired,
		worker: PropTypes.object,
	}

	render()
	{
		const {
			onConfirm,
			onCancel,
			visible,
			worker,
			projects,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.form.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onCancel(),
				}
			},
		];

		return (
			<Modal
				btnList={btnList}
				size="md"
				title={worker ? 'Редактирование' : 'Новый сотрудник'}
				show={visible}
				onHide={() => onCancel()}
			>
				<WorkerForm
					onFormCreated={form => (this.form = form)}
					onSubmit={changes => onConfirm(worker?.id, changes)}
					worker={worker}
					projects={projects}
				/>
			</Modal>
		);
	}
}

export default WorkerModal;
