import React from 'react';
import cn from 'classnames';
import { Form, Button } from 'react-bootstrap';
import { withFormik } from 'formik';
import { TextInput } from 'components/ui/forms';
import * as EmailValidator from 'email-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';

import style from './style.module.sass';


class LoginForm extends React.PureComponent
{
	state = {
		checked: true,
	}

	static propTypes = {
		onReady: PropTypes.func,
		onSubmit: PropTypes.func,
		isLoading: PropTypes.bool,
	}

	static defaultProps = {
		isLoading: false,
	}

	componentDidMount()
	{
		this.inputRef.focus();
		const { onReady } = this.props;

		if (!onReady) {
			return;
		}

		onReady(this);
	}

	getLogin = value => {
		if (!value) {
			return null;
		}

		const emailIsValid = EmailValidator.validate(value);
		const phoneNumber = parsePhoneNumberFromString(value, 'RU');
		const phoneIsValid = phoneNumber?.isValid();

		if (emailIsValid) {
			return { email: value };
		}

		if (phoneIsValid) {
			const { countryCallingCode, nationalNumber } = phoneNumber;

			return { phoneNumber: `${countryCallingCode}${nationalNumber}` };
		}

		return null;
	}

	validateLogin = value => {
		const login = this.getLogin(value);

		if (!login) {
			return 'Некорректный email или номер телефона';
		}

		return null;
	}

	onCheckChange = checked => {
		this.setState({ checked }, () => {
			if (checked) {
				this.inputRef.focus();
			}
		});
	}

	render()
	{
		const { errors, className } = this.props;

		return (
			<Form className={cn('w-100', style.formCustom, className)} onSubmit={this.props.handleSubmit}>
				<Form.Group>
					<Form.Label>E-mail или номер телефона</Form.Label>
					<TextInput
						hocInputProps={{
							name: "login",
							validate: v => this.validateLogin(v),
							innerRef: ref => (this.inputRef = ref),
						}}
					/>
					<Form.Control.Feedback type="invalid">
						{errors?.login}
					</Form.Control.Feedback>
				</Form.Group>

				<Button
					className={cn('w-100', 'py-2', 'my-3')}
					onClick={() => this.props.handleSubmit()}
					disabled={!this.state.checked || this.props.isLoading}
					style={{
						backgroundColor: '#f2bb2b',
						border: 'none',
					}}
				>
					Далее
				</Button>

				<Form.Group>
					<Form.Check
						id="confirm_ckecbox"
						type="checkbox"
						label="Даю согласие на обработку персональных данных"
						className={style.customCheck}
						defaultChecked={this.state.checked}
						onChange={e => this.onCheckChange(e.target.checked)}
					/>
				</Form.Group>
			</Form>
		);
	}
}

const handleSubmit = (values, { props, setSubmitting }) => {
	props.onSubmit(values?.login);
	setSubmitting(false);
};

export default withFormik({
	handleSubmit,
	validateOnChange: false,
	validateOnBlur: false
})(LoginForm);


