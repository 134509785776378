import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isInteger } from 'lodash';

import styles from './style.module.sass';


class Container extends React.PureComponent
{
	static propTypes = {
		layout: PropTypes.oneOf(['row', 'column']).isRequired,
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		schema: PropTypes.arrayOf(PropTypes.number),
		defaultGrowIndex: PropTypes.number,
		between: PropTypes.bool,
		spacing: PropTypes.number,
		bind: PropTypes.bool,
	}

	static defaultProps = {
		bind: false,
		between: false,
		schema: [],
		defaultGrowIndex: 1,
		spacing: 10,
		layout: 'column',
	}

	render()
	{
		const { layout, width, height, schema, defaultGrowIndex, between, spacing, bind, padding } = this.props;

		const style = {
			...this.props.style,
			width,
			height,
			padding,
		};

		const className = cn(
			styles.Container,
			{[styles.Container__row]: layout === 'row'},
			{[styles.Container__column]: layout === 'column'},
			{[styles.Container__between]: between},
			{[styles.Container__bind_height]: bind && (layout === 'row')}
		);

		const grow = schema.reduce((acc, g) => acc + g, 0);
		const childrenCount = React.Children.count(this.props.children);

		const children = React.Children.map(this.props.children, (child, i) => (child ? React.cloneElement(child, {
			...(child.props || {}),
			style: {
				...(child.props?.style || {}),
				flexGrow: isInteger(schema[i]) ? schema[i] : defaultGrowIndex,
				flexBasis: grow && isInteger(schema[i]) ? Math.round(schema[i] / grow * 100 * 100) / 100 : 0,
				marginRight: layout === 'row' && i !== (childrenCount - 1) ? spacing : 0,
				marginBottom: layout === 'column' && i !== (childrenCount - 1) ? spacing : 0,
			}
		}) : null));

		return (
			<div className={className} style={style}>
				{children}
			</div>
		);
	}
}

export default Container;
