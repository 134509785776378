import { formatISO, eachDayOfInterval } from 'date-fns';
import { HistoryGroupArray, HistoryGroup, HistoryValue } from '../types';
import Normalizer from './Normalizer';

export default class DeltaNormalizer extends Normalizer
{
	normalize()
	{
		const { data, options, params = {} } = this;
		const { startDate, endDate } = options;
		const { defaultValue = 0 } = params;

		const normalizedGroupAraray = new HistoryGroupArray();

		for (const jobId of Object.keys(data)) {
			const group = data[jobId];
			const normalizedGroup = new HistoryGroup(jobId);

			for (const day of eachDayOfInterval({ start: startDate, end: endDate})) {
				const date = formatISO(day, { representation: 'date' });
				const value = group[date];
				normalizedGroup.push(new HistoryValue(date, value ? value : defaultValue));
			}

			normalizedGroupAraray.push(normalizedGroup);
		}

		return normalizedGroupAraray;
	}
}
