import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { ButtonToolbar } from 'components/ui';


export default class ConfirmModal extends React.Component
{
	static propTypes = {
		onConfirm: PropTypes.func,
		onReject: PropTypes.func,
		title: PropTypes.string,
		text: PropTypes.string,
	}

	state = {
		show: true,
	}

	onHide = () => {
		this.setState({show: false});
		this.props.onReject();
	}

	onConfirm = () => {
		this.setState({show: false});
		this.props.onConfirm();
	}

	render = () => {
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.onConfirm(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => this.onHide(),
					variant: 'secondary',
				}
			}
		];

		return (
			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				show={this.state.show}
				onHide={this.onHide}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{this.props.title}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{this.props.text}
				</Modal.Body>
				<Modal.Footer>
					<ButtonToolbar btnList={btnList}/>
				</Modal.Footer>
			</Modal>
		);
	}
}
