import React from 'react';
import { connect } from 'react-redux';

import cn from 'classnames';
import AuthPage from 'domains/authorization';

import logo from 'assets/img/logo.png';
import style from './styles/style.module.sass';

class AuthLayout extends React.PureComponent {
	render () {
		return (
			<div className={cn(
				'w-100',
				'vh-100',
				'd-flex',
				'flex-column',
				'align-items-center',
			)}>
				<div style={{height: '30%'}}>
					<img className={cn('m-4')} src={logo} style={{width: 230}} />
				</div>

				<AuthPage />

				<div className={cn(
					'w-100',
					'd-flex',
					'flex-row',
					'justify-content-center',
					'align-items-center',
					'p-4',
				)}>
					<span className={cn(style.textLinkInfo, 'mx-2')}>
						<a href="https://brigadier.app/product" target="_blank">О продукте</a>
					</span>
					<span className={cn(style.textLinkInfo, 'mx-2')}>
						<a href="https://brigadier.app/privacy" target="_blank">Политика конфиденциальности</a>
					</span>
					<span className={cn(style.textLinkInfo, 'mx-2')}>
						<a href="https://brigadier.app/privacy" target="_blank">Пользовательское соглашение</a>
					</span>
				</div>
			</div>
		);
	}
}

export default connect()(AuthLayout);
