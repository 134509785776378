import React, { useState, useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { formatISO, format, isValid, parseISO } from 'date-fns';
import { debounce, pickBy, identity } from 'lodash';

import { faList, faCircleNotch, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Action } from 'components/ui';
import FormikForm from 'components/ui/forms/formik/Form';
import db from 'core/db';
import api from 'core/api';
import useApiCall from 'utils/useApiCall';

import { reducer as calculatorReducer } from './helpers/calculator';
import GESNHandbook from './components/GESNHandbook';
import Calculations from './components/Calculations';

const _debounced = debounce((callback) => {
	callback();
}, 300, false);

const prepareInitialValues = values => {
	const { estimatedStartDate, estimatedEndDate, maxProgress, productionRatePerShift } = values;

	return {
		dates: [
			estimatedStartDate ? parseISO(estimatedStartDate) : null,
			estimatedEndDate ? parseISO(estimatedEndDate) : null,
		],
		totalProgress: maxProgress,
		laborCostsPerUnit: productionRatePerShift,
		...values
	};
};

const prepareSubmitValues = (values, calculated) => {
	const copy = { ...values };
	delete copy.workerDayHours;
	const { dates, totalProgress, laborCostsPerUnit, ...other} = copy;

	return pickBy({
		estimatedStartDate: dates && dates[0] ? formatISO(dates[0], { representation: 'date' }) : null,
		estimatedEndDate: dates && dates[1] ? formatISO(dates[1], { representation: 'date' }) : null,
		maxProgress: totalProgress,
		productionRatePerShift: laborCostsPerUnit || calculated.laborCostsPerUnit,
		...other,
	}, identity);
};

const JobEditForm = (props) => {
	const form = useRef(null);
	const [gesnDoc, setGesnDoc] = useState(null);
	const [suggestVisible, setSuggestVisible] = useState(false);
	const [values, setValues] = useState({});
	const [searchItems, setSearchItems] = useState(null);
	const [titleInput, setTitleInput] = useState(null);

	const [calculated, dispatchCalculated] = useReducer(calculatorReducer, {});

	const [searchResult, searchInProgress, callSearch, cancelSearch] = useApiCall(
		api.gesnDocuments().search({ query: titleInput }),
		props,
		500,
	);

	const [gesnFullDoc, fetchDocInProgress, fetchFullDoc] = useApiCall(
		api.gesnDocuments().getFull({ documentId: gesnDoc?.id }),
		props,
		500,
	);

	useEffect(() => {
		if (!searchResult) {
			return;
		}

		setSearchItems(searchResult.items);
	}, [searchResult]);

	useEffect(() => {
		dispatchCalculated({ type: 'laborCostsPerUnit', values: values });
	}, [
		values.dates,
		values.totalProgress,
		values.numberOfEmployedWorkers,
		values.workerDayHours,
	]);

	useEffect(() => {
		dispatchCalculated({ type: 'numberOfEmployedWorkers', values: values });

		dispatchCalculated({ type: 'endDate',
			values: {
				...values,
				startDate: values.dates && values.dates[0],
			}
		});
	}, [
		values.totalProgress,
		values.laborCostsPerUnit,
		values.dates,
		values.numberOfEmployedWorkers,
		values.workerDayHours,
	]);

	useEffect(() => {
		const unidId = gesnFullDoc?.body?.unitDesc?.unit?.id;
		const laborCosts = gesnFullDoc?.body?.laborCosts;
		const laborCostsPerUnit = laborCosts && laborCosts[0];

		if (unidId) {
			form.current.setFieldValue('unitId', unidId);
		}

		if (laborCostsPerUnit?.amount) {
			form.current.setFieldValue('laborCostsPerUnit', laborCostsPerUnit.amount);
		}
	}, [gesnFullDoc]);

	useEffect(() => {
		if (!gesnDoc?.id) {
			return;
		}

		setSuggestVisible(false);
		fetchFullDoc();
	}, [gesnDoc]);

	useEffect(() => {
		if (!titleInput || titleInput.length < 3) {
			cancelSearch();
			setSearchItems(null);

			return;
		}

		callSearch();
	}, [titleInput]);

	useEffect(() => {
		setSuggestVisible(!!searchItems?.length);
	}, [searchItems]);

	useEffect(() => {
		dispatchCalculated({ type: 'dailyProgress',
			values: {
				dates: values.dates,
				totalProgress: values.totalProgress,
			}
		});
	}, [values.dates, values.totalProgress]);

	useEffect(() => {
		dispatchCalculated({ type: 'totalLaborCosts',
			values: {
				totalProgress: values.totalProgress,
				laborCostsPerUnit: values.laborCostsPerUnit || calculated.laborCostsPerUnit,
			}
		});
	}, [values.totalProgress, values.laborCostsPerUnit, calculated.laborCostsPerUnit]);

	useEffect(() => {
		dispatchCalculated({ type: 'daysDifference',
			values: {
				endDate: values.dates ? values.dates[1] : null,
				calculated: calculated,
			}}
		);
	}, [values.dates, calculated.endDate]);

	useEffect(() => {
		dispatchCalculated({ type: 'employeesDifference',
			values: {
				numberOfEmployedWorkers: values.numberOfEmployedWorkers,
				calculated: calculated,
			}}
		);
	}, [values.numberOfEmployedWorkers, calculated.numberOfEmployedWorkers]);

	const inputsProps = {
		'title': {
			placeholder: "Название вида работ",
			suggestRender: () => <GESNHandbook
				searchItems={searchItems}
				onSelect={item => {
					setGesnDoc(item);
					setTimeout(() => {
						form.current.setFieldValue('title', item.title);
					});
				}}
			/>,
			suggestVisible: suggestVisible,
			onSuggestHide: () => setSuggestVisible(false),
			onHandleInput: (value) => setTitleInput(value),
		},
		'stageId': {
			placeholder: 'Весь проект',
			options: props.stages.map(s => ({ value: s.id, label: s.title })),
		},
		'dates': { placeholders: ['Выберите дату начала', 'Выберите дату завершения'] },
		'totalProgress': { placeholder: 'Плановый объём работ'},
		'unitId': {
			placeholder: 'Выберите из списка',
			options: props.jobUnits.map(ju => ({ value: ju.id, label: ju.suffix })),
		},
		'numberOfEmployedWorkers': { placeholder: 'Плановое количество рабочих' },
		'workerDayHours': { placeholder: 'Продолжительность рабочего дня в часах' },
		'laborCostsPerUnit': {
			placeholder: 'В человеко-часах на единицу объёма',
		},
	};

	const formComponents = {
		'title': {
			append: searchInProgress || fetchDocInProgress
				? <FontAwesomeIcon icon={faCircleNotch} size="sm" spin />
				: <Action
					icon={faList}
					onClick={() => setSuggestVisible(true)}
					tooltip="Открыть справочник ГЭСН"
				/>,
		},
		'dates': {
			append: calculated.daysDifference ? <Action
				icon={faExclamationTriangle}
				style={{ color: 'rgba(255, 189, 36, 0.53)' }}
				tooltip={`Предполагаемая дата завершения - ${isValid(calculated.endDate) ? format(calculated.endDate, "dd.MM.yyyy") : ''}`}
				onClick={() => form.current.setFieldValue('dates', [values.dates[0], calculated.endDate])}
			/> : null,
		},
		'numberOfEmployedWorkers': {
			append: calculated.employeesDifference ? <Action
				icon={faExclamationTriangle}
				style={{ color: 'rgba(255, 189, 36, 0.53)' }}
				tooltip={`Предполагаемое количество стордуников ${calculated.numberOfEmployedWorkers}`}
				onClick={() => form.current.setFieldValue('numberOfEmployedWorkers', calculated.numberOfEmployedWorkers)}
			/> : null,
		}
	};

	const simplifiedSchema = [
		[
			{ id: 'title', type: 'suggest', label: 'Наименование' }
		],
		[
			{ id: 'stageId', type: 'select', label: 'Участок или этап', sm: 6 },
			{ id: 'dates', type: 'daterange', label: 'Даты начала и завершения' },
		],
		[
			{ id: 'totalProgress', type: 'text', label: 'Объём' },
			{ id: 'unitId', type: 'select', label: 'Единица измерения' },
			{ id: 'numberOfEmployedWorkers', type: 'text', label: 'Количество людей' },
		],
		[
			{ id: 'workerDayHours', type: 'text', label: 'Рабочий день', sm: 5 },
			{ id: 'laborCostsPerUnit', type: 'text', label: 'Трудозатраты', sm: 5 },
		]
	];

	return <Container width="100%">
		<FormikForm
			simplifiedSchema={simplifiedSchema}
			inputsProps={inputsProps}
			components={formComponents}
			onFormCreated={f => {
				form.current = f;
				props.onFormCreated(f);
			}}
			onSubmit={changes => props.onSubmit(prepareSubmitValues(changes, calculated))}
			onChange={values => _debounced(() => setValues(values))}
			initialValues={prepareInitialValues(props.initialValues)}
			changesOnly={props.changesOnly}
			onCancel={() => props.onClose()}
		/>
		<Calculations
			laborCostsPerUnit={values.laborCostsPerUnit || calculated.laborCostsPerUnit}
			totalLaborCosts={calculated.totalLaborCosts}
			totalProgress={values.totalProgress || null}
			dailyProgress={calculated.dailyProgress}
			jobUnits={values.unitId ? props.jobUnitsMap.get(values.unitId) : {}}
		/>
	</Container>;
};

JobEditForm.propTypes = {
	onFormCreated: PropTypes.func,
	onSubmit: PropTypes.func,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object,
	changesOnly: PropTypes.bool,
	onCancel: PropTypes.func,
};

JobEditForm.defaultProps = {
	initialValues: {},
	changesOnly: false,
};

const mapToProps = (state, props) => {
	const projectId = props.match?.params?.projectId;
	const filter = { filter: { projectId } };

	const stages = db.stages.list(filter);
	const jobUnits = db.jobUnits.list();
	const jobUnitsMap = jobUnits.hashById();

	return {
		stages,
		jobUnits,
		jobUnitsMap,
	};
};

export default withRouter(connect(mapToProps)(JobEditForm));
