import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ru from "date-fns/locale/ru";
import 'react-datepicker/dist/react-datepicker.css';
import styles from './style.module.sass';
import { Form } from 'react-bootstrap';

registerLocale("ru", ru);


const DateRangePicker = (props) => {
	const { controlled, dates, onDatesChange } = props;

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const [intialStartDate, initialEndDate] = props.initialDates;

	const sDate = controlled ? dates && dates[0] : startDate;
	const eDate = controlled ? dates && dates[1] : endDate;

	if (sDate) {
		sDate.setHours(0, 0, 0, 0);
	}

	if (eDate) {
		eDate.setHours(0, 0, 1, 0);
	}

	const onStartDateChanged = date => {
		if (!controlled) {
			setStartDate(date);
		}
		onDatesChange([date, eDate]);
	};

	const onEndDateChanged = date => {
		if (!controlled) {
			setEndDate(date);
		}
		onDatesChange([sDate, date]);
	};

	useEffect(() => {
		setStartDate(intialStartDate);
		setEndDate(initialEndDate);
	}, []);

	return (
		<div className={cn(styles.DateRangePicker, props.className)}>
			<DatePicker
				placeholderText={props.placeholders[0]}
				customInput={props.customInput}
				selected={sDate}
				onChange={date => onStartDateChanged(date)}
				selectsStart
				startDate={sDate}
				endDate={eDate}
				locale={"ru"}
				dateFormat={"dd.MM.yyyy"}
			/>
			<DatePicker
				placeholderText={props.placeholders[1]}
				customInput={props.customInput}
				selected={eDate}
				onChange={date => onEndDateChanged(date)}
				selectsEnd
				startDate={sDate}
				endDate={eDate}
				minDate={sDate}
				locale={"ru"}
				dateFormat={"dd.MM.yyyy"}
			/>
		</div>
	);
};

DateRangePicker.propTypes = {
	onDatesChange: PropTypes.func,
	initialDates: PropTypes.arrayOf(PropTypes.any),
	customInput: PropTypes.any,
	placeholders: PropTypes.arrayOf(PropTypes.string),
	controlled: PropTypes.bool,
	dates: PropTypes.any,
};

DateRangePicker.defaultProps = {
	initialDates: [null, null],
	placeholders: [null, null],
	controlled: false,
	dates: null,
	customInput: <Form.Control/>,
};

export default DateRangePicker;
