import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';


class ValidationCellRenderer extends React.PureComponent
{
	static propTypes = {
		validationTitle: PropTypes.string,
		isValid: PropTypes.bool,
	}

	static defaultProps = {
		validationTitle: 'Ошибка ввода',
		isValid: true,
	}

	render()
	{
		const { isValid } = this.props;

		const icon = <FontAwesomeIcon
			fixedWidth
			icon={faExclamationCircle}
			style={{color: 'rgb(225, 50, 0)', marginRight: '5px'}}
		/>;

		const value = this.props.valueFormatted || this.props.value;

		const tooltip = <Tooltip>
			{this.props.validationTitle}
		</Tooltip>;


		const popperConfig = {
			modifiers: [
				{
					name: 'preventOverflow',
					enabled: false
				}, {
					name: 'hide',
					enabled: false
				}
			]
		};

		const overlay = <OverlayTrigger overlay={tooltip} trigger={["hover", "focus"]} popperConfig={popperConfig}>
			<div className={"text-truncate"}>
				{icon}
				{value}
			</div>
		</OverlayTrigger>;

		const reguralText =
			<div className={"text-truncate"}>
				{value}
			</div>
		;

		const component = isValid ? reguralText : overlay;

		return component;
	}
}

export default ValidationCellRenderer;
