import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Form } from 'react-bootstrap';

import './style.module.sass';

const styles = {
	control: () => ({
		borderWidth: 0,
		padding: 0,
		height: '100%',
		lineHeight: 2,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	}),
	valueContainer: () => ({
		height: '100%',
		padding: 0,
	}),
	placeholder: () => ({
		fontSize: '0.8rem'
	}),
	singleValue: (provided, state) => ({
		...provided,
		paddingRight: '30px',
	}),
	dropdownIndicator: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	clearIndicator: (provided, state) => ({
		...provided,
		padding: 0,
	}),
	indicatorsContainer: (provided, state) => ({
		...provided,
		zIndex: 1,
	}),
};

const Select = (props) => {
	return <Form.Control
		as={ReactSelect}
		components={{
			Input: () => null,
			IndicatorSeparator: () => null,
		}}
		styles={styles}
		isSearchable={false}
		{...props}
	/>;
};

Select.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		value: PropTypes.any.isRequired,
		label: PropTypes.string.isRequired,
	}))
};

export default Select;
