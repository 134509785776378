import React from 'react';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ButtonsToolbar extends React.PureComponent
{
	state = {
		buttons: [],
	}

	static propTypes = {
		onMount: PropTypes.func,
	}

	componentDidMount()
	{
		this.props.onMount({ update: this.update });
	}

	update = buttons => {
		this.setState({ buttons });
	}

	render()
	{
		const { buttons } = this.state;

		return (
			<div className={cn('d-flex', 'flex-row', 'align-items-center')}>
				{buttons.map((btn, index) => {
					return (
						<Button
							key={index}
							onClick={() => btn.onClick()}
							className="mr-1"
						>
							{btn.title}
						</Button>
					);
				})}
			</div>
		);
	}
}

export default ButtonsToolbar;
