import config from '../config';
import deviceId from './deviceId';

import { Client as ApiClient, HttpTransport, RetryableFetcher } from '@brigadier/js-api2';

const fetcher = new RetryableFetcher(
	(...args) => fetch(...args),
	1,
	2,
	(error, retry) => {
		setTimeout(retry, 5000);
	}
);

const apiHttpTransport = new HttpTransport(config.apiUrl, fetcher.fetch.bind(fetcher));

const apiClient = new ApiClient(
	apiHttpTransport,
	deviceId,
);

export default apiClient;
