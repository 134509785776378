import React from 'react';
import PropTypes from 'prop-types';
import ActionsToolbar from 'components/ui/toolbars/ActionsToolbar';
import config from 'config';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';


class CurrencyRatesToolbar extends React.PureComponent
{
	static propTypes = {
		rates: PropTypes.object,
		onEditClick: PropTypes.func,
		currenciesMap: PropTypes.any,
	}

	static defaultProps = {
		rates: {},
	}

	render()
	{
		const { rates, currenciesMap } = this.props;
		const ratesKeys = Object.keys(this.props.rates);
		const defaultCurrencySymbol = config.tbs.priceCurrency.default.symbol;

		return (
			<div className="d-flex flex-row align-items-center mx-2">
				{isEmpty(rates) ? <div className="mr-2">Курсы валют</div> : null}
				<ActionsToolbar
					actions={[
						{
							icon: faEdit,
							tooltip: 'Редактировать',
							onClick: this.props.onEditClick,
						}
					]}
				/>
				{ratesKeys.map(key => {
					const rate = currenciesMap.get(key);
					const value = rates[key];

					return (
						<div key={key} className="mx-2">
							<span>
								<span className="font-weight-bold">
									{`${rate.symbol}`}
								</span>
								{` - ${Number(value).toFixed(2)} ${defaultCurrencySymbol}`}
							</span>
						</div>
					);
				})}
			</div>
		);
	}
}

export default CurrencyRatesToolbar;
