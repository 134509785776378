import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import api from 'core/api';
import notify from 'utils/suppliesNotifications';

import { NewModal as Modal } from 'components/ui/modals';
import { Page, Uploader, Alert } from 'components/ui';
import HorizontalLine from './HorizontalLine';
import ProjectEditForm from 'domains/projects/components/ProjectEditForm';

import JobSheetParser from '../helpers/JobSheetParser/JobSheetParser';
import ParsedRow from 'core/xlsx-parser/src/ParsedRow';

import sheetExmple from 'assets/docs/sheet_example.xlsx';


class UploadView extends React.PureComponent
{
	static propTypes = {
		onContinue: PropTypes.func,
		onCreateProject: PropTypes.func,
		employees: PropTypes.arrayOf(PropTypes.object),
	}

	state = {
		fileList: [],
		modalVisible: false,
	}

	renderBtnCreatePlan()
	{
		const btnText = <>Составить план<br/>вручную</>;

		return (
			<Button
				className={cn('mb-5', 'px-5')}
				style={{ fontSize: '1.1rem' }}
				onClick={() => this.openModal()}
			>
				{btnText}
			</Button>
		);
	}

	renderBtnContinue()
	{
		const fl = this.state.fileList;
		const uploadError = fl.some(f => (f.status === 'error' || f.status === 'loading'));

		return (
			<Button
				className={cn('my-3', 'px-5')}
				disabled={uploadError}
				onClick={() => this.props.onContinue(this.state.fileList)}
			>
				Продолжить
			</Button>
		);
	}

	renderProjectModal()
	{
		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.formProps?.handleSubmit(),
				},
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => this.closeModal(),
				},
			}
		];

		return (
			<Modal
				title="Новый проект"
				show={this.state.modalVisible}
				onHide={this.closeModal}
				btnList={btnList}
			>
				<ProjectEditForm
					onFormCreated={formProps => (this.formProps = formProps)}
					onSubmit={this.createProject}
					onCancel={this.closeModal}
					employees={this.props.employees.map(e => e.user)}
				/>
			</Modal>
		);

	}

	closeModal= () => this.setState({ modalVisible: false })

	openModal = () => this.setState({ modalVisible: true })

	parse = (fileWrapper, result) => {
		const parser = new JobSheetParser();
		const parsedSheet = parser.parse(result.body);
		const worksheet = parsedSheet.worksheets && parsedSheet.worksheets[0];

		const combineRows = (rowGroup, level = 0, parentTitle = null) => {
			let combinedRows = [];

			if (rowGroup.childs.length > 0) {
				const l = level + 1;
				const parent = rowGroup.title;
				rowGroup.childs.forEach((rowGroup) => {
					combinedRows = combinedRows.concat(combineRows(rowGroup, l, parent));
				});
			}

			const parsedRows = rowGroup.rows
				.filter(row => row instanceof ParsedRow)
				.map((row) => {
					row.data.stageTitle = level === 1 ? rowGroup.title : parentTitle;
					row.data.subStageTitle = level === 2 ? rowGroup.title : null;

					return row;
				});

			return combinedRows.concat(parsedRows);
		};

		if (worksheet) {
			fileWrapper.worksheet = worksheet;
			const parsedRows = combineRows(worksheet.rootRowGroup);
			if (parsedRows.length) {
				fileWrapper.parsedRows = parsedRows;
			} else {
				fileWrapper.setWarning('Не удалось распознать строки таблицы');
			}
		} else {
			fileWrapper.setWarning('Не удалось распознать таблицу');
		}
	}

	createProject = projectProps => {
		this.props.dispatch(api.projects().create(projectProps))
			.then(() => {
				notify(this.props.employees, "new-project", "Название: " + projectProps.title);
				this.closeModal();
			})
			.catch(error => {
				toast.error("Не удалось создать проект...", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
			.finally(() => this.props.onCreateProject())
		;
	}

	render()
	{
		const info = <>Создайте план проекта чтобы начать работать в системе. План может <br/>
						быть создан вручную или с помощью загрузки сметы работ из Excel.</>;

		const labelUploadPrompt = <>Нажмите или перетащите <b>файл со сметой</b> работ в область для загрузки.<br/>
									Можно загрузить один или несколько файлов</>;

		const labelUploadNotes = <>Поддерживаемый формат Excel-таблицы: <b><u><a href={sheetExmple}>скачать образец</a></u></b></>;


		const fl = this.state.fileList;
		const hasUpload = fl.length;

		const contBtn = this.renderBtnContinue();
		const planBtn = this.renderBtnCreatePlan();

		const separator = (
			<div className={cn('d-flex', 'flex-row', 'justify-content-center')}>
				<HorizontalLine
					text="или"
					className={cn('w-75', 'my-4')}
				/>
			</div>
		);

		const projectModal = this.renderProjectModal();

		return (
			<Page className={cn('p-0', 'overflow-auto')} style={{ backgroundColor: 'white' }}>
				<Alert>
					{info}
				</Alert>
				<div className={cn('flex-grow-1', 'd-flex', 'align-items-center', 'justify-content-center')}>
					<div className={cn('w-50', 'm-2')}>
						<Uploader
							labelUploadPrompt={labelUploadPrompt}
							labelUploadNotes={labelUploadNotes}
							onFileListChanged={fileList => this.setState({fileList})}
							request={file => this.props.dispatch(api.parsers().parseXlsx(file))}
							multiple={true}
							accept=".xlsx"
							afterUpload={this.parse}
						/>
					</div>
				</div>
				{!hasUpload && separator}
				<div className={cn('d-flex', 'flex-row', 'justify-content-center')}>
					{hasUpload ? contBtn : planBtn}
				</div>
				{projectModal}
			</Page>
		);
	}
}

export default connect()(UploadView);
