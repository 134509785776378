import React, { useState, useEffect, useRef } from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import styles from "./style.module.sass";

const FileInput = (props) => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);
	const input = useRef(null);
	// const { file, onFileChange } = props;

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	return (
		<>
			<input
				ref={input}
				id="fileInput"
				type="file"
				style={{ visibility: 'hidden', width: 0, height: 0 }}
				onChange={() => helpers.setValue(input.current.files[0])}
			/>
			<Form.Control
				onClick={() => input.current.click()}
				type="text"
				className={styles.Control}
				value={value?.name}
				placeholder="Выберите файл..."
				readOnly
				htmlFor="fileInput"
				{...props}
			/>
		</>
	);
};

export default FileInput;
