import React, { useState } from 'react';
import FormikForm from 'components/ui/forms/formik/Form';
import { formatISO } from 'date-fns';

const prepareSubmotValues = values => {
	return {
		...values,
		date: formatISO(values.date, { representation: 'date' }),
	};
};

const ReportForm = (props) => {
	const [values, setValues] = useState(null);

	const { reportItem, ...otherProps } = props;

	const suffix = reportItem?.unit?.suffix || '';
	const maxProgress = reportItem?.maxProgress;


	const simplifiedSchema = [
		[{ id: 'title', type: 'text', label: 'Наименование' }],
		[
			{ id: 'numberOfEmployedWorkers', type: 'text', label: 'Количество сотрудников' },
			{ id: 'currentProgress', type: 'text', label: 'Объём работы' },
			{ id: 'isFullProgress', type: 'check', label: ' ' },
		],
		[{ id: 'date', type: 'date', label: 'Дата выполнения работ' }],
	];

	const inputsProps = {
		title: { placeholder: "Наименование работы", readOnly: true},
		numberOfEmployedWorkers: { placeholder: "Введите кол-во сотрудников" },
		currentProgress: { placeholder: 'Введите объём работ' },
		date: { placeholder: 'Выберите дату' },
		isFullProgress: { id: "report_form_full_progress_checkbox", label: 'Полный объём' }
	};

	const formComponents = {
		currentProgress: {
			append: values?.isFullProgress ? `/ ${maxProgress}(${suffix})` : ` ${suffix}`,
		}
	};

	return <FormikForm
		simplifiedSchema={simplifiedSchema}
		inputsProps={inputsProps}
		onChange={values => setValues(values)}
		components={formComponents}
		initialValues={{
			date: new Date(),
			numberOfEmployedWorkers: 1,
			title: reportItem?.title,
		}}
		{...otherProps}
		onSubmit={values => props.onSubmit(prepareSubmotValues(values))}
	/>;
};

export default ReportForm;
