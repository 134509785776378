import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import { CustomGrid } from 'components/ui/grid';
import { getColumnDefs, getGridOptions } from './defs';


class Grid extends React.Component
{
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.object),
		consumableUnitsMap: PropTypes.any,
	};

	static defaultProps = {
		items: [],
	};

	state = {
		selectedNodes: [],
	}

	onGridReady = (params) => {
		this.gridApi = params.api;
		this.gridApi.sizeColumnsToFit();
	}

	render()
	{

		const columnDefs = getColumnDefs(this.props);
		const gridOptions = getGridOptions(this.props);

		return (
			<CustomGrid
				onGridReady={this.onGridReady}
				onSelectionChanged={this.onSelectionChanged}
				columnDefs={columnDefs}
				gridOptions={gridOptions}
				rowData={this.props.items}
				stopEditingWhenGridLosesFocus={true}
				suppressRowClickSelection={true}
			/>
		);
	}
}

export default connect()(Grid);
