import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './style.module.sass';

const List = (props) => {
	const { items, expanded } = props;

	if (!items?.length) {
		return null;
	}

	return (
		<div
			className={cn(
				styles.List,
				{[styles.List_expanded]: expanded }
			)}
		>
			{items.slice(0, expanded ? items.length : 3).map(item => (
				<div
					key={item.id}
					className={styles.List__item}
					onClick={() => props.onSelect(item)}
				>
					<div>
						<span className={styles.List__item__name}>
							{item.name}
						</span>
						<span>
							{item.title}
						</span>
					</div>
				</div>
			))}
		</div>
	);
};

List.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object),
	expanded: PropTypes.bool,
	onSelect: PropTypes.func,
};

export default List;
