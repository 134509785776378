import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import db from 'core/db';
import { Empty } from 'components/ui';
import Grid from './components/Grid';
import styles from './styles.module.sass';

class Payments extends React.PureComponent {
	aggregatePayments = () => {
		const {
			tbsPurchaseInvoices,
			tbsPurchaseInvoicePayments,
			tbsPurchaseOrderItems,
			projectsMap,
			tbsPurchaseOrdersMap
		} = this.props;

		// Добавляем в счета выполненные платежи, чтобы потом для каждой позиции платежа высчитывать статус оплаты счета.
		const invoies = tbsPurchaseInvoices.map(invoice => {
			return {
				...invoice,
				payments: tbsPurchaseInvoicePayments.filter(payment => payment.purchaseInvoiceId === invoice.id)
			};
		});

		const payments = invoies.flatMap(invoice => {
			const payments = [];
			const order = tbsPurchaseOrdersMap.get(invoice.purchaseOrderId);

			// Это для статуса оплаты счёта, подсчитываются стоимости каждой позиции.
			const orderItems = tbsPurchaseOrderItems.filter(item => item.purchaseOrderId === order.id);
			const project = projectsMap.get(order.projectId);

			if (invoice.paymentSchedule.length) {
				invoice.paymentSchedule.forEach((item, index) => {
					payments.push({
						invoice,
						orderItems,
						project,
						...item,
						done: invoice.payments.length && invoice.payments[index]
					});
				});
			} else if (invoice.payments.length) {
				invoice.payments.forEach((item) => {
					payments.push({
						invoice,
						orderItems,
						project,
						...{
							amount: item.amount,
							days: 0
						},
						done: item
					});
				});
			}

			return payments;
		});

		return payments;
	}

	render () {
		if (this.props.isLoading) {
			return <Empty className="w-100 h-100" title="Загрузка списка платежей..."/>;
		}

		const payments = this.aggregatePayments();

		return (
			<div className={cn('w-100', 'h-100', 'd-flex', 'flex-column', styles.tableContainer)}>
				<Grid items={payments} />
			</div>
		);
	}
}

const mapStateToProps = (state, props) => {

	const collects = {
		tbsPurchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted({
			filter: {
				isWaitingForApprove: false
			}
		}),
		tbsPurchaseInvoicePayments: db.tbsPurchaseInvoicePayments.list(),
		tbsPurchaseOrders: db.tbsPurchaseOrders.listNotDeleted(),
		tbsPurchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		projects: db.projects.list(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	const maps = {
		projectsMap: collects.projects.hashById(),
		tbsPurchaseOrdersMap: collects.tbsPurchaseOrders.hashById(),
	};

	return {
		...collects,
		...maps,
	};
};

export default connect(mapStateToProps)(Payments);
