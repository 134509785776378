import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

import style from '../styles/cell-style.module.sass';


class JobProgressCellRenderer extends React.PureComponent
{
	renderProgressBar(isDone)
	{
		const { data } = this.props;
		const { currentProgress, maxProgress } = data;

		if (!maxProgress) {
			return null;
		}

		if (isDone) {
			return null;
		}

		const persents = Math.floor(Number(currentProgress) * 100 / Number(maxProgress));

		return (
			<ProgressBar
				variant="success"
				className="w-100"
				style={{ height: '4px' }}
				now={persents}
			/>
		);
	}

	renderProgressTitle()
	{
		const { data } = this.props;
		const { unit, currentProgress, maxProgress } = data;

		if (!maxProgress) {
			return null;
		}

		const suffix = unit?.suffix || '';

		return (
			<span>
				{`${Number(currentProgress).toFixed(1)} / ${Number(maxProgress).toFixed(1)} `}
				<span style={{ fontSize: '0.7rem' }}>
					{suffix}
				</span>
			</span>
		);
	}

	renderDoneIcon(isDone)
	{
		if (!isDone) {
			return null;
		}

		return (
			<FontAwesomeIcon
				fixedWidth
				icon={faCheck}
				style={{ color: 'green', marginRight: '5px' }}
			/>
		);
	}

	render()
	{
		const { data } = this.props;
		const { currentProgress, maxProgress } = data;
		const isDone = Number(currentProgress) === Number(maxProgress);

		const progressTitle = this.renderProgressTitle();
		const progressBar = this.renderProgressBar(isDone);
		const icon = this.renderDoneIcon(isDone);

		return (
			<div
				className={isDone ? style.fakeCellWrapperLeft : style.fakeCellWrapperCenter}
				style={{ flexDirection: 'column' }}
			>
				<span>
					{icon}
					{progressTitle}
				</span>
				{progressBar}
			</div>
		);
	}
}

export default JobProgressCellRenderer;


