import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
import cn from 'classnames';

import { makeLinkDynamic } from 'routes';
import TabsRouter from './components/TabsRouter';
import Breadcrumbs from './components/Breadcrumbs';

import styles from './style.module.sass';

/**
 * Служит для контекстной навигации. В минимальном варианте показывает только хлебные крошки.
 * Весь рабочий интерфейс рендерится внутри этого компонента.
 * - Можно добавить табы (если передаются табы, то обязательно передать и роуты для этих табов, используется компонент ui/TabsRouter)
 * - Можно добавить кнопки действий
 */
class Worksapce extends React.PureComponent {
	renderTabs()
	{
		const { routes, location } = this.props;
		const { pathname } = location;

		if (!routes?.length) {
			return null;
		}

		// Надо разобраться зачем это надо
		const routesMatches = routes.map(r => matchPath(pathname, { path: r.path })).filter(m => m);
		const dymanics = Object.assign(...routesMatches.map(rm => rm.params).flat());

		return (
			<Nav variant="tabs" activeKey={pathname}>
				{routes.map((route, index) => {
					const link = makeLinkDynamic(route.path, dymanics);

					return (
						<Nav.Item key={index}>
							<Nav.Link eventKey={link}>
								<LinkContainer to={link}>
									<div>{route.title}</div>
								</LinkContainer>
							</Nav.Link>
						</Nav.Item>
					);
				})}
			</Nav>
		);
	}

	render () {
		const tabs = this.renderTabs();
		const routes = this.props.routes;

		return (
			<div className={cn(styles.workspace, this.props.className)}>
				<div className={styles.navigation}>
					<Breadcrumbs
						actions={this.props.actions}
						hideTitle={this.props.hideTitle}
						titleField={this.props.titleField}
						hideBreadcrumbs={this.props.hideBreadcrumbs}
					/>
					{tabs}
				</div>
				<div className={styles.content}>
					{routes ? <TabsRouter routes={routes}/> : this.props.children || null}
				</div>
			</div>
		);
	}
}

Worksapce.propTypes = {
	actions: PropTypes.array,
	routes: PropTypes.array,
	hideTitle: PropTypes.bool,
	titleField: PropTypes.string,
	hideBreadcrumbs: PropTypes.bool,
	headerColor: PropTypes.string,
	className: PropTypes.any,
};

export default withRouter(Worksapce);
