import { MenuItem } from '../types.js';

export default [
	new MenuItem({
		label: "Проекты",
		routes: [
			'/projects',
			'/projects/:projectId',
			'/new-project',
		],
		link: '/projects',
	}),
	new MenuItem({
		label: "Табель",
		routes: ['/timesheet'],
		link: '/timesheet',
	}),
	new MenuItem({
		label: "Компания",
		routes: ['/company'],
		link: '/company',
		submenu: [
			new MenuItem({
				label: "Сотрудники",
				routes: ['/company/employees'],
				link: '/company/employees',
			}),
			new MenuItem({
				label: "Роли",
				routes: ['/company/roles'],
				link: '/company/roles',
			}),
			new MenuItem({
				label: "Реестр счетов",
				routes: ['/invoices'],
				link: '/company/invoices',
			})
		]
	}),
];
