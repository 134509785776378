import React from 'react';
import { Form } from 'react-bootstrap';
import formikField from './formikField';


class TextInput extends React.PureComponent
{
	render()
	{
		const {
			forwardedRef,
			formikParams,
			inputProps,
			id,
			...innerProps
		} = this.props;

		const { field, form: { values } } = formikParams;
		const { value, onChange,	 ...otherInputProps } = inputProps;
		const initialValue = values[field.name] || '';

		return (
			<Form.Control
				ref={forwardedRef}
				value={value || initialValue}
				onChange={e => onChange(e.target.value)}
				id={id || field.name}
				{...otherInputProps}
				{...innerProps}
			>
			</Form.Control>
		);
	}
}

export default formikField(TextInput);
