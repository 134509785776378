import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ProgressBar } from 'react-bootstrap';
import { isAfter, parseISO, isValid, format } from 'date-fns';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { faThumbsUp, faFire } from '@fortawesome/free-solid-svg-icons';

import api from 'core/api';
import db from 'core/db';
import pq from 'utils/ProjectQualifier';
import { Action, Container } from 'components/ui';

import HistoryManager from '../../../helpers/history/HistoryManager.js';
import { ChartLayout, Card } from '../../layouts';
import { ProjectProgressChart } from '../../charts';

import styles from './style.module.sass';


class ProjectProgressCard extends React.PureComponent
{
	static propTypes = {
		startDate: PropTypes.any,
		endDate: PropTypes.any,
	}

	state = {
		data: null,
	}

	renderIcon = (endDate, estimatedEndDate) => {
		if (!isValid(endDate) || !isValid(estimatedEndDate)) {
			return null;
		}

		const isAft = isAfter(estimatedEndDate, endDate);

		return (
			<Action
				icon={isAft ? faFire : faThumbsUp}
				className={cn(
					styles.Card_icon,
					{ [styles.Card_icon__suck]: isAft},
					{ [styles.Card_icon__ok]: !isAft},
				)}
			/>
		);
	}

	renderDateLabel(date)
	{
		if (!date) {
			return 'Нет данных';
		}

		if (!isValid(date)) {
			return (
				<span className={styles.Card_label}>
					Не указана
				</span>
			);
		}

		return (
			<span className={styles.Card_label}>
				{format(date, "P")}
			</span>
		);
	}

	getHistoryProgress = () => {
		const { data } = this.state;
		const { jobs, jobsMap, startDate, endDate } = this.props;

		if (!data) {
			return {};
		}

		const totalPerformance = pq.totalPerformance(jobs);

		const historyManager = new HistoryManager('last', data, startDate, endDate);
		const perfomance = historyManager.perfomancePercent({ jobsMap, totalPerformance });
		const dates = historyManager.range();

		return {
			progressRangePercent: perfomance.map(p => Number(p).toFixed(1)),
			dates: dates.map(d => format(d, 'dd.MM')),
		};
	};

	componentDidMount()
	{
		const { projectId } = this.props;

		if (!projectId) {
			return;
		}

		this.props.dispatch(api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: projectId,
				isDeleted: false,
			}
		}))
			.then(res => this.setState({ data: res.groups }))
			.catch(err => console.log(err))
		;
	}

	render()
	{
		const { project, jobs, startDate, endDate } = this.props;

		//Рассчитаная дата окончания проекта
		const estimatedEndDate = pq.estimatedEndDate(project, jobs);

		//Дата окнчания проекта по плану
		const projectEndDate = project?.estimatedEndDate ? parseISO(project.estimatedEndDate) : null;

		//Прогресс на сегодня
		const todayProgressPercents = pq.currentProgressPercents(project, jobs);

		//Рассчитаный планируемый прогресс на сегодня
		const todayPlannedProgressPercents = pq.plannedPerformancePercentOnDate(project, jobs);

		//Рассчитаный планируемы прогресс на интервале по датам
		const plannedProgressRangePercent = (pq.plannedPerformancePercentWithinInterval(project, jobs, startDate, endDate) || []).map(p => Number(p).toFixed(1));

		//Фактический прогресс рассчитанный из истории
		const { progressRangePercent, dates } = this.getHistoryProgress();

		return (
			<Card layout="row" {...this.props}>
				<Container
					layout="column"
					schema={[0, 1]}
					width="100%"
					height="100%"
					spacing={34}
				>
					<Container layout="row" between>
						<Container layout="column">
							<Container layout="row">
								<span className={cn(styles.Card_label, styles.Card_label_header)}>
									Общая готовность работ
								</span>
							</Container>
							<Container layout="row">
								<span className={styles.Card_label}>
									План
								</span>
								<ProgressBar
									className={cn(styles.Card_progress, styles.Card_progress_plan)}
									srOnly
									now={Number(todayPlannedProgressPercents || 0).toFixed(1)}
								/>
								<span className={styles.Card_label}>
									{`${Number(todayPlannedProgressPercents || 0).toFixed(1)}%`}
								</span>
							</Container>
							<Container layout="row">
								<span className={styles.Card_label}>
									Факт
								</span>
								<ProgressBar
									className={cn(styles.Card_progress, styles.Card_progress_real)}
									srOnly
									now={Number(todayProgressPercents || 0).toFixed(1)}
								/>
								<span className={styles.Card_label}>
									{`${Number(todayProgressPercents || 0).toFixed(1)}%`}
								</span>
							</Container>
						</Container>
						<div/>
						<Container layout="column">
							<Container layout="row" schema={[1, 0]}>
								<span className={cn(styles.Card_label, styles.Card_label_header)}>
									Сроки проекта
								</span>
								{this.renderIcon(projectEndDate, estimatedEndDate)}
							</Container>
							<Container layout="row">
								<span className={styles.Card_label}>
									План
								</span>
								<span className={styles.Card_label}>
									{this.renderDateLabel(projectEndDate)}
								</span>
							</Container>
							<Container layout="row">
								<span className={styles.Card_label}>
									Прогноз
								</span>
								<span className={styles.Card_label}>
									{this.renderDateLabel(estimatedEndDate)}
								</span>
							</Container>
						</Container>
					</Container>
					<ChartLayout
						header="Готовность проекта, план-факт"
						title="График соответствия фактически выполняемых объёмов работ плановомым показателям на каждый день."
						legend={[
							{ label: 'Плановая доля готовности', color: '#7182AE' },
							{ label: 'Фактическая доля готовности', color: '#FFC530' },
						]}
					>
						<ProjectProgressChart
							real={progressRangePercent}
							plan={plannedProgressRangePercent}
							dates={dates}
						/>
					</ChartLayout>
				</Container>
			</Card>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };

	const collects = {
		jobs: db.jobs.listNotDeleted(projectIdFilter),
		projects: db.projects.list({ filter: { id: projectId } }),
	};

	const maps = {
		jobsMap: collects.jobs.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	const project = collects.projects[0];

	return {
		project,
		projectId,
		isLoading,
		...collects,
		...maps,
	};
};

export default withRouter(connect(mapToProps)(ProjectProgressCard));
