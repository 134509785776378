import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Form, Table, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import cn from 'classnames';

import db from 'core/db';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

import { DATE_FORMAT } from 'core/helpers/constants';
import 'react-datepicker/dist/react-datepicker.css';
import './style.module.sass';


class PaymentModal extends React.PureComponent
{
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		onConfirm: PropTypes.func.isRequired,
	}

	static defaultProps = {
		visible: false,
	}

	state = {}

	renderPayments = () => {
		const { invoice } = this.props;

		if (!invoice.payments.length) {
			return null;
		}

		return (
			<Table borderless hover size="sm" className={cn("p-0", "mb-5")}>
				<thead>
					<tr>
						<th>Дата платежа</th>
						<th>Номер платежного поручения</th>
						<th>Сумма платежа</th>
					</tr>
				</thead>
				<tbody>
					{invoice.payments.sort((a, b) => (a.date < b.date ? -1 : 1)).map((pp, i) => (
						<tr key={i}>
							<td>{pp.date && moment(pp.date).format('LL')}</td>
							<td>{pp.number}</td>
							<td>{`${Number(pp.amount).toFixed(2)} ${this.props.currencies.get(invoice.currencyId).symbol}`}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	}

	render()
	{
		const { invoice } = this.props;

		const btnList = [
			{
				title: 'Добавить',
				props: {
					onClick: () => this.props.onConfirm(invoice,
						{...this.state, date: moment(this.state.date).format(DATE_FORMAT) }),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => this.props.onClose(),
					variant: 'secondary',
				}
			}
		];

		return (
			<Modal
				title={`Платежи по счёту ${invoice?.number}`}
				show={this.props.visible}
				onHide={this.props.onClose}
				btnList={btnList}
			>
				{this.renderPayments()}
				<Form onChange={e => this.setState({ [e.target.id]: e.target.value })}>
					<Row>
						<Form.Group as={Col}>
							<Form.Label>
								Дата
							</Form.Label>
							<Form.Control
								className="d-block"
								onChange={date => this.setState({ date })}
								selected={this.state.date}
								as={DatePicker}
								placeholderText="Выберите дату платежа"
								locale="ru"
								dateFormat="dd.MM.yyyy"
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Номер платёжного поручения</Form.Label>
							<Form.Control
								id="number"
								placeholder="Введите номер"
							/>
						</Form.Group>
						<Form.Group as={Col}>
							<Form.Label>Сумма</Form.Label>
							<Form.Control
								id="amount"
								placeholder={`0.00 ${this.props.currencies.get(invoice.currencyId).symbol}`}
							/>
						</Form.Group>
					</Row>
				</Form>
			</Modal>
		);
	}
}

export default connect((state, props) => {
	return {
		currencies: db.currencies.list().hashById()
	};
})(PaymentModal);
