import React from 'react';
import PropTypes from 'prop-types';
import { NewModal as Modal } from 'components/ui/modals';
import ParseGrid from './components/ParseGrid';
import cn from 'classnames';
import { connect } from 'react-redux';
import db from 'core/db';


class ParseInvoiceModal extends React.PureComponent
{
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onClose: PropTypes.func.isRequired,
		consumableSheetItemsMap: PropTypes.any.isRequired,
		purchaseRequestItemsMap: PropTypes.any.isRequired,
		invoice: PropTypes.object,
		onGridReady: PropTypes.func,
	}

	static defaultProps = {
		visible: false,
	}

	render()
	{
		const {
			invoice,
			purchaseRequestItemsMap,
			consumableSheetItemsMap,
			purchase1cInvoiceItems
		} = this.props;

		const { orderItems } = invoice;

		const items = orderItems
			.map(poi => {
				const pri = purchaseRequestItemsMap.get(poi.purchaseRequestItemId);
				const csi = consumableSheetItemsMap.get(pri.consumableSheetItemId);

				return {
					...poi,
					consumableSheetItem: csi,
					invoiceItemTitle: purchase1cInvoiceItems.map(i => i.title).find(title => (title === csi?.title)),
				};
			})
		;

		const height = items.length * 35 + 60;

		const btnList = [
			{
				title: 'Сохранить',
				props: {
					onClick: () => this.props.onConfirm(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => this.props.onClose(),
					variant: 'secondary',
				}
			}
		];

		const sorted = items.sort((a, b) => (a.invoiceTitle && !b.invoiceTitle ? -1 : 1));

		return (
			<Modal
				title={"Сопоставление позиций в смете и в счёте"}
				show={this.props.visible}
				btnList={btnList}
				onHide={() => this.props.onClose()}
			>
				<div
					className={cn('w-100', 'd-flex')}
					style={{ height }}
				>
					<ParseGrid
						items={[...sorted]}
						invoiceTitles={purchase1cInvoiceItems.map(i => i.title)}
						onGridReady={params => this.props.onGridReady(params)}
					/>
				</div>
			</Modal>
		);
	}
}

const mapToProps = (state, props) => {
	const purchase1cInvoices = db.tbsPurchase1cInvoices.list({
		filter: {
			purchaseInvoiceId: props.invoice.id
		}
	});

	const purchase1cInvoice = purchase1cInvoices[0];

	const purchase1cInvoiceItems = db.tbsPurchase1cInvoiceItems.list({
		filter: {
			purchase1cInvoiceId: purchase1cInvoice?.id,
		}
	});

	return {
		purchase1cInvoiceItems
	};
};

export default connect(mapToProps)(ParseInvoiceModal);
