import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';

import 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker';
import 'dhtmlx-gantt/codebase/locale/locale_ru';
import { renderToString } from 'react-dom/server';
import { IoIosRadioButtonOff, IoIosArrowDropdownCircle } from 'react-icons/io';
import Balance from './helpers/Balance';
import Price from './Price';
import { IoIosFlame as IconFire } from 'react-icons/io';
import TaskInfo from './helpers/TaskInfo';
import Estimator from './helpers/Estimator';
import TasksGraph from './helpers/TasksGraph';
import {Button} from 'react-bootstrap';
import ColumnsResizer from './ColumnsResizer';

import './custom.css';


const minColumnWidth = 68;
const intialColumnWidth = 340;

class Diagramm extends React.PureComponent
{
	ganttContainer = null;
	ganttListeners = {};
	ganttMarkers = [];
	balance = null;

	state = {
		resizerMoving: false,
	}

	iconCompleted = <IoIosArrowDropdownCircle
		size={'1.1em'}
		color={'#56C367'}
	/>;

	iconIncompleted = <IoIosRadioButtonOff
		size={'1.1em'}
		color={'#56C36700'}
	/>;

	iconExpirationWarning = <IconFire
		size={'1.1em'}
		color={'#F1BA2C'}
	/>;

	iconExpired = <IconFire
		size={'1.1em'}
		color={'#E95252'}
	/>;

	static contextTypes = {
		router: PropTypes.object
	};

	reinitUnit()
	{
		const units = {
			day: {
				scale_unit: 'day',
				date_scale: '%d',
				min_column_width: 20,
				subscales: [
					{
						unit: 'month',
						step: 1,
						date: '%M',
					},
				],

			},
			week: {
				scale_unit: 'week',
				date_scale: '%d',
				min_column_width: 20,
				subscales: [
					{
						unit: 'month',
						step: 1,
						date: '%M',
					},
				],
			},
			month: {
				scale_unit: 'month',
				date_scale: '%M',
				min_column_width: 40,
				subscales: [
					{
						unit: 'year',
						step: 1,
						date: '%Y',
					},
				],
			},
		};

		for (const option in units[this.props.unit]) {
			gantt.config[option] = units[this.props.unit][option];
		}
	}

	/**
	 * @param task
	 * @return {TaskInfo}
	 */
	taskInfo(task)
	{
		return TaskInfo.calculate(task);
	}

	reinitGantt()
	{
		const now = new Date();

		this.reinitUnit();

		for (const id in this.ganttListeners) {
			gantt.detachEvent(this.ganttListeners[id]);
		}

		for (const id of this.ganttMarkers) {
			gantt.deleteMarker(id);
		}

		this.ganttListeners = {};

		gantt.config.fit_tasks = true;
		gantt.config.drag_links = true;
		gantt.config.drag_progress = false;
		gantt.config.row_height = 32;
		gantt.config.scale_height = 54;
		gantt.config.grid_width = intialColumnWidth;

		gantt.templates.grid_folder = (item) => {
			return null;
		};

		gantt.templates.grid_file = (item) => {
			return null;
		};

		gantt.templates.task_text = function(start, end, task) {
			if (task.task) {
				return renderToString(
					task.task.balanceDelta
						? <Price withPlus={true} value={task.task && task.task.balanceDelta} />
						: <span>{task.text}</span>
				);
			}

			return task.text;
		};

		gantt.templates.task_class = (start, end, task) => {
			if (task.completed) {
				return 'brGantt_task_completed';
			}

			if (task.woDates) {
				return 'brGantt_task_woDates';
			}

			const info = this.taskInfo(task);

			if (info.expired) {
				return 'brGantt_task_expired';
			}

			if (info.hasExpirationWarning) {
				return 'brGantt_task_expirationWarning';
			}

			return (
				'brGantt_task_' + (task.taskType || 'hide')
			);
		};

		gantt.date.balance_start = date => {
			return date;
		};

		gantt.date.add_balance = (date, inc) => {
			return gantt.date.add(date, inc, "day");
		};

		// gantt.config.scale_height = 100;
		gantt.config.grid_resize = true;
		gantt.templates.rightside_text = (start, end, task) => {
			if (task.taskType !== 'job' && task.taskType !== 'stage') {
				return null;
			}

			const timeOverflow = TaskInfo.timeOverflow(new Date, task);

			if (timeOverflow <= 0) {
				return null;
			}

			if (task.woDates) {
				return null;
			}

			return renderToString(
				<div
					style={{
						height: '100%',
						width: (timeOverflow * 100).toFixed(3) + '%',
						backgroundColor: '#ff000020',
						zIndex: 100,
					}}
				/>
			);
		};

		gantt.config.date_grid = '%d.%m.%Y';
		gantt.config.min_column_width = minColumnWidth;
		gantt.config.columns = [
			{
				name: 'text',
				label: 'Этапы',
				tree: true,
				template: (task) => {
					let icon = this.iconIncompleted;
					const info = this.taskInfo(task);

					if (task.completed) {
						icon = this.iconCompleted;
					} else if (info.expired) {
						icon = this.iconExpired;
					} else if (info.hasExpirationWarning) {
						icon = this.iconExpirationWarning;
					}

					return renderToString(
						<div>
							<span
								style={{marginRight: 4}}
							>
								{icon}
								<Button
									variant="link"
									size="sm"
									id="btn_task"
									className="ganttBtn"
									style={{
										color: 'black',
										fontWeight: task.taskType === 'stage' ? 'bold' : 'normal',
										padding: 0,
									}}
								>
									{task.text}
								</Button>
							</span>

						</div>
					);
				}
			},
			// {
			// 	name: 'add',
			// 	width: 32,
			// }
		];

		this.ganttListeners.onAfterTaskUpdate = gantt.attachEvent('onAfterTaskUpdate', (id, item) => {
			this.props.onTaskUpdate(item);

			this.reestimate();

			gantt.render();
		});

		this.ganttListeners.onBeforeLightbox = gantt.attachEvent('onBeforeLightbox', id => {
			const newTask = gantt.getTask(id);
			const parent = gantt.getTask(newTask.parent);

			gantt.deleteTask(id);

			if (parent.onAddPress) {
				parent.onAddPress();
			}

			return false;
		});


		gantt.templates.grid_row_class = (start, end, task) => {
			if (task.$level > 0) {
				return 'brGantt_nestedTask';
			}

			return 'brGantt_rootTask';
		};

		gantt.templates.task_row_class = (start, end, task) => {
			if (task.$level > 0) {
				return 'brGantt_nestedTask';
			}

			return 'brGantt_rootTask';
		};

		gantt.config.show_errors = false;

		this.ganttListeners.onTaskClick = gantt.attachEvent('onTaskClick', (id, e) => {
			if (e.target.id === "btn_task") {
				const location = '/projects';
				this.props.history.push(location);

				return false;
			}

			return true;

		});

		this.ganttListeners.onTaskDblClick = gantt.attachEvent('onTaskDblClick', (id, d, d2) => {
			if (!id) {
				return true;
			}

			const task = gantt.getTask(id);

			if (this.props.onEditPress) {
				this.props.onEditPress(task);
			}

			return false;
		});

		this.ganttListeners.onLinkValidation = gantt.attachEvent('onLinkValidation', (link) => {
			if (link.type !== gantt.config.links.finish_to_start) {
				return false;
			}

			return this.props.canCreateLink(link);
		});

		this.ganttListeners.onAfterLinkAdd = gantt.attachEvent('onAfterLinkAdd', (id, item) => {
			const newId = uuidv4();
			gantt.changeLinkId(id, newId);

			if (this.props.onLinkCreate) {
				this.props.onLinkCreate(item.id, item.source, item.target);
			}

			this.reestimate();

			gantt.render();
		});

		this.ganttListeners.onAfterLinkDelete = gantt.attachEvent('onAfterLinkDelete', (id, item) => {
			if (this.props.onLinkDelete) {
				this.props.onLinkDelete(id);
			}

			this.reestimate();

			gantt.render();
		});

		this.balance = new Balance();
		for (const d of this.props.data) {
			if (!d.task || !d.task.balanceDelta) {
				continue;
			}

			this.balance.push(d.start_date, d.task.balanceDelta);
		}

		gantt.parse({data: this.props.data, links: this.props.links});

		gantt.eachTask(task => {
			task.$open = true;
		});

		this.ganttMarkers.push(gantt.addMarker({
			start_date: now,
			css: 'today',
		}));

		this.reestimate();

		gantt.render();
	}

	componentDidMount()
	{
		gantt.init(this.ganttContainer);
		gantt.clearAll();

		this.reinitGantt();
		this.reinitUnit();

		gantt.render();
	}

	componentDidUpdate()
	{
		if (!this.props.data) {
			return;
		}

		for (const task of this.props.data) {
			const currentTask = gantt.getTask(task.id);

			if (currentTask.progress === task.progress) {
				continue;
			}

			currentTask.progress = task.progress;
			currentTask.completed = task.completed;
		}

		this.reestimate();
		this.reinitUnit();

		gantt.render();
	}

	reestimate()
	{
		const tasks = gantt.getTaskByTime();
		const links = gantt.getLinks();

		(new Estimator(new Date, new TasksGraph(tasks, links))).reestimateInplace();
	}

	render()
	{
		return (
			<React.Fragment>
				<div
					className={'brGantt_container'}
					style={{
						width: '100%',
						minHeight: '100%',
						overscrollBehavior: 'none',
						pointerEvents: this.state.resizerMoving ? 'none' : 'inherit',
					}}
					ref={(input) => { this.ganttContainer = input; }}
				>
				</div>
				<ColumnsResizer
					onResize={(width) => {
						gantt.config.grid_width = width;
						gantt.render();
					}}
					onMovingChanged={(resizerMoving) => {
						this.setState({resizerMoving});
					}}
					initialWidth={intialColumnWidth}
					minWidth={minColumnWidth}
				/>
			</React.Fragment>
		);
	}
}

Diagramm.defaultProps = {
	unit: 'day',
};

Diagramm.propTypes = {
	data: PropTypes.array.isRequired,
	links: PropTypes.array.isRequired,
	onTaskUpdate: PropTypes.func.isRequired,
	onLinkCreate: PropTypes.func,
	onLinkDelete: PropTypes.func,
	canCreateLink: PropTypes.func.isRequired,
	unit: PropTypes.string,
	location: PropTypes.object.isRequired,
};

export default Diagramm;
