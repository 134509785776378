import React from 'react';
import ReactDOM from 'react-dom';
import { Field, connect } from 'formik';
import { Popover, Overlay } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import style from './input.module.sass';


const formikField = (Component) => {
	class InputComponent extends React.PureComponent
	{
		static propTypes = {
			hocInputProps: PropTypes.shape({
				name: PropTypes.string.isRequired,
				onReady: PropTypes.func,
				shouldShowSuggestion: PropTypes.func,
				suggestionRenderer: PropTypes.func,
			}),
		}

		static defaultProps = {
			hocInputProps: {
				shouldShowSuggestion: () => true
			}
		}

		state = {
			suggestionVisible: false,
			value: undefined,
			highlighted: false,
			touched: false,
			parentNode: null,
		}

		constructor(props) {
			super(props);
			this.inputRef = React.createRef();
		}

		componentDidMount()
		{
			const { hocInputProps } = this.props;
			const { onReady, name, innerRef } = hocInputProps;

			if (onReady) {
				onReady(this, name);
			}

			if (innerRef) {
				innerRef(this.inputRef.current);
			}

			const parentNode = ReactDOM.findDOMNode(this).parentNode;

			this.setState({ parentNode });
		}

		componentWillUnmount()
		{
			delete this.higlightTimer;
		}

		openSuggestion = () => this.setState({ suggestionVisible: true })

		closeSuggestion = () => this.setState({ suggestionVisible: false })

		highlight = () => {
			this.setState({ highlighted: true }, () => {
				this.higlightTimer = setTimeout(() => {
					this.setState({ highlighted: false });
				}, 300);
			});
		}

		setValue = (value, callback) => {
			const { hocInputProps, formik } = this.props;
			const { name } = hocInputProps;

			if (value === this.state.value) {
				return;
			}

			formik.setFieldValue(name, value);
			this.setState({ value }, callback ? () => callback() : null);
			this.highlight();
		}

		setTouched = (touched = true) => {
			const { hocInputProps } = this.props;
			const { name } = hocInputProps;

			const { formik } = this.props;

			if (touched !== this.state.touched) {
				formik.setFieldTouched(name, touched);
			}
		}

		renderSuggestion()
		{
			const { hocInputProps } = this.props;
			const { suggestionRenderer } = hocInputProps;

			return (
				<Overlay
					target={this.inputRef.current}
					container={this.state.parentNode}
					show={!!this.state.suggestionVisible}
					placement="bottom"
					rootClose
					onHide={() => this.closeSuggestion()}
				>
					{props => {
						return (
							<Popover
								{...omit(props, ['show'])}
								className={style.popoverCustom}
								arrowProps={{
									style: { visibility: 'hidden' }
								}}
							>
								<Popover.Content>
									{suggestionRenderer()}
								</Popover.Content>
							</Popover>)
						;
					}}
				</Overlay>
			);
		}

		onChange = value => {
			const { hocInputProps } = this.props;
			const { shouldShowSuggestion } = hocInputProps;

			if (shouldShowSuggestion && shouldShowSuggestion(value)) {
				this.openSuggestion();
			} else {
				this.closeSuggestion();
			}

			this.setValue(value);
			this.setTouched(value !== '');
		};

		renderField = params => {
			const { hocInputProps, formik, ...innerProps } = this.props;
			const { name, suggestionRenderer } = hocInputProps;

			const { touched, errors } = formik;
			const { value, highlighted } = this.state;

			const className = highlighted ? style.fieldHighlighted : style.field;
			const isInvalid = touched[name] && !!errors[name];

			const inputProps = {
				className,
				value,
				isInvalid,
				onChange: this.onChange,
			};

			return (
				<React.Fragment>
					<Component
						forwardedRef={this.inputRef} //Ссылка для рендера Overlay
						inputProps={inputProps} //Свойства и методы hoc инпута
						formikParams={params} //Свойства и методы formik
						{...innerProps} //Остальные свойства уникальные для внутреннего компонента
					/>
					{suggestionRenderer && this.renderSuggestion()}
				</React.Fragment>
			);
		}

		render()
		{
			const { hocInputProps } = this.props;

			return (
				<Field {...hocInputProps}>
					{this.renderField}
				</Field>
			);
		}
	}

	return connect(InputComponent);
};

export default formikField;
