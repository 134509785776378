import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { FormCheck } from 'react-bootstrap';

import styles from './style.module.sass';


const RadioInputGroup = props => {
	const [field, meta, helpers] = useField(props);
	const [value, setValue] = useState(props.value);

	useEffect(() => {
		setValue(field.value);
	}, [field.value]);

	const { options, ...otherProps } = props;

	return (
		<div
			className={styles.RadioInputGroup}
			{...otherProps}
		>
			{options.map(opt => (
				<FormCheck
					key={opt.value}
					className={styles.RadioInputGroup_radio}
					onChange={e => {
						if (e.target.checked) {
							helpers.setValue(opt.value);
						}
					}}
					checked={opt.value === value}
					type="radio"
					label={opt.label}
					id={opt.id}
				/>
			))}
		</div>
	);
};

RadioInputGroup.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		label: PropTypes.string,
		value: PropTypes.any,
	}))
};

RadioInputGroup.defaultProps = {
	options: [],
};


export default RadioInputGroup;
