import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Action } from 'components/ui';
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import style from 'components/ui/grid/styles/custom-cell.module.sass';


class TitleCellRenderer extends React.PureComponent
{
	static propTypes = {
		onChangeItemClick: PropTypes.func,
		subtitle: PropTypes.string,
	}

	state = {
		hovered: false,
	}

	onMouseEnterHandler = () => {
		this.setState({ hovered: true });
	}

	onMouseLeaveHandler = () => {
		this.setState({ hovered: false });
	}

	render()
	{
		const { hovered } = this.state;
		const { onChangeItemClick, value, subtitle, node } = this.props;

		const action = (
			<div
				style={{
					top: 0,
					right: 0,
					position: 'absolute',
				}}
				className={cn('h-100', 'mx-1', 'd-flex', 'align-items-center')}
			>
				<Action
					icon={faExchangeAlt}
					onClick={() => onChangeItemClick(node)}
					style={{
						opacity: hovered ? 1.0 : 0.0,
						transition: 'opacity 350ms',
					}}
				/>
			</div>
		);

		return (
			<div
				onMouseEnter={this.onMouseEnterHandler}
				onMouseLeave={this.onMouseLeaveHandler}
				className={cn(
					style.wrapper,
					'flex-column',
					'align-items-start',
				)}
				style={{
					paddingRight: hovered ? 20 : 0,
					transition: 'padding 150ms'
				}}
			>
				<div
					className={cn(
						'd-flex',
						'align-items-center',
						'w-100',
						{'h-100': !subtitle},
					)}
					style={subtitle ? { height: '65%' } : {}}
				>
					<span className="d-inline-block text-truncate">
						{value}
					</span>
				</div>
				{ subtitle && <div
					className="d-flex align-items-center w-100 font-weight-bold"
					style={{fontSize: '0.75em', height: '35%'}}
				>
					<span className="d-inline-block text-truncate">
						{subtitle}
					</span>
				</div>}
				{action}
			</div>
		);
	}
}

export default TitleCellRenderer;
