import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.sass';


const Tooltip = props => {
	return (
		<div className={styles.Tooltip}>
			{props.labels.map((l, i) => {
				const { text, value, color } = l;

				return <div key={i} className={styles.Tooltip_label}>
					{color
						? <div style={{ backgroundColor: color }} className={styles.Tooltip_label_color}/>
						: null
					}
					<div className={styles.Tooltip_label_text}>{text}</div>
					<div className={styles.Tooltip_label_value}>{value}</div>
				</div>;
			})}
		</div>
	);
};

Tooltip.propTypes = {
	labels: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		color: PropTypes.string,
	}))
};

Tooltip.defaultProps = {
	labels: [],
};

export default Tooltip;
