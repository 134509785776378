import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import cn from 'classnames';


class AutocompletePriceCellRenderer extends React.PureComponent
{
	static propTypes = {
		matches: PropTypes.arrayOf(PropTypes.object),
		currenciesMap: PropTypes.any,
		onMatchClicked: PropTypes.func,
	}

	static defaultProps = {
		matches: [],
	}

	render()
	{
		const { value, valueFormatted, matches, currenciesMap, data, onMatchClicked, node } = this.props;
		const currency = currenciesMap?.get(data?.priceCurrencyId);

		const badge = (
			<div
				className="h-100"
				style={{
					top: 0,
					right: 0,
					position: 'absolute',
				}}
			>
				<Button
					variant="warning"
					className={cn("mr-2", "p-1")}
					style={{ lineHeight: 0.5 }}
				>
					{matches.length}
				</Button>
			</div>
		);

		const popover = (
			<Popover>
				<Popover.Title className={cn('px-2', 'py-1')}>
					Доступные прайс-листы
				</Popover.Title>
				<Popover.Content className={cn('px-0', 'py-1')}>
					<ListGroup>
						{matches.map((m, i) => {
							const { consumablePricelist, price } = m;
							const { title } = consumablePricelist;

							return (
								<ListGroup.Item
									key={i}
									action
									className={cn("px-2", "py-0", 'border-0', 'd-flex', 'justify-content-between')}
									onClick={() => onMatchClicked(node, m)}
								>
									<span>
										{title}
									</span>
									<span className={cn("font-weight-bold", 'text-dark')}>
										{`${price}${currency?.symbol || ''}`}
									</span>
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</Popover.Content>
			</Popover>
		);

		const popperConfig = {
			modifiers: [
				{
					name: 'preventOverflow',
					enabled: false
				}, {
					name: 'hide',
					enabled: false
				}
			]
		};

		const overlayTrigger = (
			matches.length ? <OverlayTrigger
				overlay={popover}
				trigger={["hover", "focus"]}
				popperConfig={popperConfig}
			>
				{badge}
			</OverlayTrigger> : null
		);

		return (
			<div className="text-truncate">
				{`${valueFormatted || value} ${currency?.symbol || ''}`}
				{overlayTrigger}
			</div>
		);
	}
}

export default AutocompletePriceCellRenderer;
