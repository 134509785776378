import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import api from 'core/api';
import db from 'core/db';
import pq from 'utils/ProjectQualifier';

import { Container } from 'components/ui';
import { ChartLayout, Card } from '../layouts';
import { JobsDynamicChart } from '../charts';
import LabeledValue from '../LabeledValue';

import HistoryManager from '../../helpers/history/HistoryManager.js';


class JobsDynamicCard extends React.PureComponent
{
	static propTypes = {
		startDate: PropTypes.any,
		endDate: PropTypes.any
	}

	state = {
		data: null,
	}

	componentDidMount()
	{
		const { projectId } = this.props;

		if (!projectId) {
			return;
		}

		this.props.dispatch(api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'last',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: projectId,
				isDeleted: false,
			}
		}))
			.then(res => this.setState({ data: res.groups }))
			.catch(err => console.log(err))
		;
	}

	getHistoryPerformance()
	{
		const { jobs, jobsMap, startDate, endDate } = this.props;
		const { data } = this.state;

		if (!data) {
			return {};
		}

		const totalPerformance = pq.totalPerformance(jobs);

		const hm = new HistoryManager('last', data, startDate, endDate);

		const perfomance = hm.remainPerfomance({ jobsMap, totalPerformance });

		const dates = hm.range();

		return {
			jobsRemainPerfomance: perfomance.map(p => Number(p).toFixed(0)),
			dates: dates.map(d => format(d, 'dd.MM')),
		};
	}

	todayJobsRemainPerfomance()
	{
		const { data } = this.state;

		if (!data) {
			return null;
		}

		const { jobsMap, jobs } = this.props;
		const totalPerformance = pq.totalPerformance(jobs);

		const hm = new HistoryManager('last', data);

		const perfomance = hm.remainPerfomance({ jobsMap, totalPerformance });

		return perfomance ? perfomance[0] : null;
	}

	plannedRemainPerfomance()
	{
		const { project, jobs, startDate, endDate } = this.props;

		const totalPerformance = pq.totalPerformance(jobs);

		if (!totalPerformance) {
			return null;
		}

		const planedJobsPerfomance = pq.plannedPerformanceWithinInterval(project, jobs, startDate, endDate);

		const plannedRemainPerfomance = planedJobsPerfomance
			? planedJobsPerfomance.map(p => Number(totalPerformance - p).toFixed(0))
			: null
		;

		return plannedRemainPerfomance;
	}

	todayPerfomanceGapPercent()
	{
		const { project, jobs} = this.props;

		const todayJobsRemainPerfomance = this.todayJobsRemainPerfomance();

		const totalPerformance = pq.totalPerformance(jobs);
		const todayPlannedPerf = pq.plannedProgressOnDate(project, jobs);
		const todayRemainPerf = totalPerformance - todayJobsRemainPerfomance;
		const todayPerfomanceGap = todayPlannedPerf - todayRemainPerf;

		let todayPerfomanceGapPercent = 0;

		if (todayPerfomanceGap && todayPerfomanceGap > 0) {
			todayPerfomanceGapPercent = Number(todayPerfomanceGap / totalPerformance * 100).toFixed(1);
		}

		return todayPerfomanceGapPercent;
	}

	render()
	{
		// Остаток чел/ч по факту
		const { jobsRemainPerfomance, dates } = this.getHistoryPerformance();

		//Остаток чел/ч по плану
		const plannedRemainPerfomance = this.plannedRemainPerfomance();

		// Процент отставания на сегодня
		const todayPerfomanceGapPercent = this.todayPerfomanceGapPercent();

		return (
			<Card layout="column" {...this.props}>
				<Container layout="column" height="100%" width="100%">
					<ChartLayout
						header="Динамика выполнения работ, график сгорания"
						title="График сгорания планового объёма работ в трудозатратах.
							Разрыв в линиях показыает отставание и опережение.
							Чем ниже уровень графика, тем лучше."
						legend={[
							{ label: 'Плановый объём работ', color: '#D45B4A' },
							{ label: 'Оставшийся объём работ', color: '#7182AE' },
						]}
						renderHeaderWidget={() => {
							if (todayPerfomanceGapPercent === undefined) {
								return null;
							}

							return <LabeledValue
								centered
								title="Отставание"
								value={`${todayPerfomanceGapPercent}%`}
								color="#D45B4A"
							/>;
						}}
					>
						<JobsDynamicChart
							remain={jobsRemainPerfomance}
							plan={plannedRemainPerfomance}
							dates={dates}
						/>
					</ChartLayout>
				</Container>
			</Card>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };

	const collects = {
		jobs: db.jobs.listNotDeleted(projectIdFilter),
		projects: db.projects.list({ filter: { id: projectId } }),
	};

	const maps = {
		jobsMap: collects.jobs.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	const project = collects.projects[0];

	return {
		project,
		projectId,
		isLoading,
		...collects,
		...maps,
	};
};

export default withRouter(connect(mapToProps)(JobsDynamicCard));
