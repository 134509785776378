import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ListGroup, Badge } from 'react-bootstrap';
import { faFileExcel, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ListCardItem } from 'components/ui';
import ApprovalModal from '../ApprovalModal';
import styles from './styles.module.sass';

class InvoicesList extends React.PureComponent {
	static propTypes = {
		orders: PropTypes.array,
		currencies: PropTypes.object,
		onRemoveItem: PropTypes.func,
		onSelectItem: PropTypes.func,
		onEditItem: PropTypes.func,
	}

	state = {
		activeModals: {
			approval: {
				isVisible: false
			}
		},
		selectedOrder: null
	}

	selectedOrder = null

	toggleModal = (name, params = null) => {
		const o = {};
		o[name] = {
			isVisible: !this.state.activeModals[name].isVisible
		};
		const activeModals = Object.assign(this.state.activeModals, o);

		this.selectedOrder = params;

		this.setState({activeModals, selectedOrder: params });
	}

	costCalc = purchaseOrderItems => {
		return purchaseOrderItems.reduce((acc, item) => {
			const { amount, amountProposed, priceActual } = item;
			const am = amountProposed || amount;

			if (!am || !priceActual) {
				return acc;
			}

			return acc + am * priceActual;
		}, 0);
	};

	renderAttachments = (attachments) => {
		const list = attachments.map((value, index) => {
			return (
				<a href={value.url} target="_blank" key={index} title={value.originalName}>
					<FontAwesomeIcon icon={faFileExcel} size="sm" color="#48B95A" />
				</a>
			);
		});

		return (
			<React.Fragment>{list}</React.Fragment>
		);
	}

	// FIXME PLEASE: Если isApproved станет nullable, то можно будет использовать только это поле для статуса согласования счёта.
	// А isWaitingForApprove использовать для одобрения и размещения счёта директором.
	getStatus(order)
	{
		const { invoice, items } = order;

		if (items.some(i => i.isApproved === false)) {
			return <Badge variant="danger">{'Позиция отменена'}</Badge>;
		}

		if (invoice.isWaitingForApprove) {
			return <Badge variant="warning">{'На согласовании'}</Badge>;
		}

		return <Badge variant="success">{'Одобрен'}</Badge>;
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.listContainer}>
					<ListGroup className={styles.list}>
						<ListGroup.Item
							as={ListCardItem}
							data={{
								date: 'Дата счёта',
								number: 'Номер счёта',
								supplier: 'Поставщик',
								cost: 'Сумма счёта',
								file: 'Файл счёта',
								status: 'Статус'
							}}
							style={styles.listHeaderItem}
						/>
						{this.props.orders.map((value, index) => {
							const currency = value.items.length ? value.items[0].consumableSheetItem.priceCurrency : null;

							return (
								<ListGroup.Item
									as={ListCardItem}
									key={index}
									data={{
										date: value.invoice.date,
										number: value.invoice.number,
										supplier: value.invoice.supplierTitle,
										// @todo Условия заменить на нормальный набор параметров
										cost: String(this.costCalc(value.items)) + ' ' + (currency ? currency.symbol : '₽'),
										file: value.invoice.attachments && this.renderAttachments(value.invoice.attachments),
										status: this.getStatus(value)
									}}
									onClick={() => {
										if (this.props.isRequestCompleted) {
											return false;
										}

										this.toggleModal('approval', value);

										return true;
									}}
									actions={this.props.isRequestCompleted ? [] : [
										{ icon: faEdit, onClick: () => this.props.onEditItem(value) },
										{ icon: faTimes, iconColor: "#F41A1A", onClick: () => this.props.onRemoveItem(value) },
									]}
									isDisabled={this.props.isRequestCompleted}
								/>
							);
						})}
					</ListGroup>
				</div>

				<ApprovalModal
					isVisible={this.state.activeModals.approval.isVisible}
					onCancel={() => this.toggleModal('approval')}
					order={this.selectedOrder}
					currenciesMap={this.props.currenciesMap}
				/>
			</React.Fragment>
		);
	}
}

export default connect()(InvoicesList);
