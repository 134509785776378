import React from 'react';
import PropTypes from 'prop-types';
import { NewModal } from 'components/ui/modals';
import InvoiceEditForm from './components/InvoiceEditForm';
import { connect } from 'react-redux';
import api from 'core/api';
import notify from 'utils/suppliesNotifications';
import { formatISO } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { toast, Slide } from 'react-toastify';

class InvoiceModal extends React.PureComponent
{
	static propTypes = {
		visible: PropTypes.bool,
		onClose: PropTypes.func,
		requestItems: PropTypes.arrayOf(PropTypes.object),
		currencies: PropTypes.arrayOf(PropTypes.object),
		currenciesMap: PropTypes.any,
		changesOnly: PropTypes.bool,
		intialValues: PropTypes.object,
		invoice: PropTypes.any,
	}

	static defaultProps = {
		changesOnly: false,
		initialValues: {},
	}

	showError = (message) => {
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
		});
	}

	/**
 	*
 	* @param {{supplierTitle, number, date, currencyId, file, requestItems, paymentTermsType, paymentSchedule }} values - Form data
 	* @param {array} purchaseRequestItems - Purchase request items
 	* @param {string} projectId
 	*/
	createInvoice = (values, purchaseRequestItems, projectId) => {
		const {
			supplierTitle,
			number,
			date,
			currencyId,
			file,
			requestItems,
			paymentTermsType,
			paymentSchedule
		} = values;

		const invoiceTotal = requestItems.reduce((acc, curr) => {
			return acc + Number(curr.priceActual) * Number(curr.amountProposed);
		}, 0);
		const scheduleTotal = paymentSchedule ? paymentSchedule.reduce((acc, curr) => acc + Number(curr.amount), 0) : 0;

		if (paymentTermsType !== "fullPayment" && invoiceTotal !== scheduleTotal) {
			this.showError('Сумма запланированных платежей не совпадает с суммой счёта!');

			return null;
		}

		const preparedRequestItems = requestItems.map(item => {
			if (!item || !item.isChecked) {
				return null;
			}

			const copy = { ...item };
			delete copy.isChecked;

			if (copy.deliveryDateActual) {
				copy.deliveryDateActual = formatISO(copy.deliveryDateActual, { representation: 'date' });
			}

			return copy;
		});

		const preparedPaymentSchedule = paymentSchedule ? paymentSchedule.map(ps => {
			const copy = { ...ps };
			delete copy.title;

			if (!copy.days) {
				copy.days = "0";
			}

			return copy;
		}) : [];

		// Аттачим файл
		this.props.dispatch(api.attachments().create({ files: [file] }))
			.then(attachment => {
				// Делаем транзакцию
				const createOrder = api.tbsPurchaseOrders().create({
					projectId,
					items: preparedRequestItems.map((item, idx) => (item ? {
						purchaseRequestItemId: purchaseRequestItems[idx].id,
						amount: /* item?.amountProposed ||  */purchaseRequestItems[idx].amount || 0, // Тут был баг, но вообще можно в amount хранить первоначальное количество из заявки, в purposed которое логист в вёл и его юзать. И тогда после отжима согласования можно будет отсюда забирать и накатывать обратно.
						...item,
					} : null)).filter(item => !!item),
				});

				const createInvoice = (orderId) => api.tbsPurchaseInvoices().create({
					purchaseOrderId: orderId,
					attachments: [{ id: attachment.id }],
					supplierTitle,
					number,
					date: formatISO(date, { representation: 'date' }),
					currencyId,
					paymentTermsType,
					paymentSchedule: preparedPaymentSchedule,
				});

				const sendToApprove = (invoiceId) => api.tbsPurchaseInvoices().sendToApprove({
					purchaseInvoiceId: invoiceId,
				});

				this.props.dispatch(createOrder)
					.then(order => this.props.dispatch(createInvoice(order.id)))
					.then(invoice => this.props.dispatch(sendToApprove(invoice.id)))
					.then(() => this.props.onClose())
				;

			})
			.then(() => {
				notify(this.props.employees, 'new-invoice');
			})
			.catch((err) => console.log('error', err))
		;

		return true;
	};

	updateInvoice = (order, valuesChanged = {}, intialRequestItems) => {
		const transaction = [];
		const { requestItems = [], ...invoiceChanges } = valuesChanged;
		const initialValues = this.props.initialValues;

		if (invoiceChanges.date) {
			invoiceChanges.date = formatISO(invoiceChanges.date, { representation: 'date' });
		}

		if (invoiceChanges.paymentSchedule) {
			const copy = { ...invoiceChanges.paymentSchedule };
			delete copy.title;

			const updatedSchedule = this.props.initialValues.paymentSchedule.map((item, index) => {
				return {
					amount: copy[index] && copy[index] !== null ? copy[index].amount || item.amount : item.amount,
					days: copy[index] && copy[index] !== null ? copy[index].days || item.days : item.days,
				};
			});

			invoiceChanges.paymentSchedule = updatedSchedule;
		}

		const invoice = order.invoice;

		const preparedRequestItems = requestItems.map(item => {
			if (!item) {
				return null;
			}

			if (!isNil(item.isChecked) && item.isChecked) {
				item.isDeleted = true;
			}

			const copy = { ...item };
			delete copy.isChecked;

			if (copy.deliveryDateActual) {
				copy.deliveryDateActual = formatISO(copy.deliveryDateActual, { representation: 'date' });
			}

			return copy;
		});

		if (requestItems.length) {
			for (let i = 0; i < intialRequestItems.length; ++i) {
				if (requestItems[i]) {

					transaction.push(api.tbsPurchaseOrderItems().update({
						purchaseOrderItemId: intialRequestItems[i].id,
						changes: preparedRequestItems[i],
					}));

				}
			}
		}

		if (invoiceChanges) {
			if (invoiceChanges.file) {
				this.props.dispatch(api.attachments().create({ files: [invoiceChanges.file] }))
					.then(attachment => {

						delete invoiceChanges.file;

						if (!isEmpty(invoiceChanges)) {
							if (initialValues.attachments.length > 0) {
								transaction.push(api.tbsPurchaseInvoices().update({
									purchaseInvoiceId: invoice.id,
									changes: {
										attachments: {
											deleted: [{ id: initialValues.attachments[0].id }],
										}
									}
								}));
							}

							transaction.push(api.tbsPurchaseInvoices().update({
								purchaseInvoiceId: invoice.id,
								changes: {
									...invoiceChanges,
									attachments: {
										new: [{ id: attachment.id }],
									}
								}
							}));

							this.props.dispatch(api.transaction().execute(transaction))
								.then(() => this.props.onClose())
								.catch((err) => console.log('error', err))
							;
						} else {
							this.props.onClose();
						}
					})
					.catch((err) => console.log('error', err))
				;
			} else {
				transaction.push(api.tbsPurchaseInvoices().update({
					purchaseInvoiceId: invoice.id,
					changes: invoiceChanges,
				}));
				this.props.dispatch(api.transaction().execute(transaction))
					.then(() => this.props.onClose())
					.catch((err) => console.log('error', err))
				;
			}
		} else {
			this.props.dispatch(api.transaction().execute(transaction))
				.then(() => this.props.onClose())
				.catch((err) => console.log('error', err))
			;
		}
	}

	render()
	{
		const {
			visible,
			projectId,
			onClose,
			currencies,
			currenciesMap,
			requestItems,
			changesOnly,
			initialValues,
			invoice,
		} = this.props;

		const btnList = [
			{
				title: 'Ок',
				props: {
					onClick: () => this.editOrderFormProps.handleSubmit(),
				}
			},
			{
				title: 'Отмена',
				props: {
					onClick: () => onClose(),
				}
			}
		];

		const onFormReady = props => {
			this.editOrderFormProps = props;
		};

		return (
			<NewModal
				title="Новый счёт"
				show={visible}
				onHide={() => onClose()}
				btnList={btnList}
				size="xl"
			>
				<InvoiceEditForm
					onFormCreated={onFormReady}
					onSubmit={
						(values) => (invoice ? this.updateInvoice(invoice, values, requestItems) : this.createInvoice(values, requestItems, projectId))
					}
					onCancel={() => onClose()}
					currencies={currencies}
					currenciesMap={currenciesMap}
					requestItems={requestItems}
					changesOnly={changesOnly}
					initialValues={initialValues}
				/>
			</NewModal>
		);
	}
}

export default connect()(InvoiceModal);
