import React from 'react';
import FormikForm from 'components/ui/forms/formik/Form';
import { parseISO } from 'date-fns/esm';
import { pickBy, identity } from 'lodash';


const prepareInitialValues = values => {
	return pickBy({
		...values,
		date: values?.date ? parseISO(values.date) : null,
	}, identity);
};

const EditForm = (props) => {
	const inputsProps = {
		date: { readOnly: true }
	};

	const simplifiedSchema = [
		[{ id: 'title', type: 'text', label: 'Наименование' }],
		[
			{ id: 'numberOfEmployedWorkers', type: 'text', label: 'Количество сотрудников' },
			{ id: 'currentProgress', type: 'text', label: 'Объём работы' },
			{ id: 'isFullProgress', type: 'check', label: ' ' },
		],
		[{ id: 'date', type: 'date', label: 'Дата выполнения работ' }],
	];

	return <FormikForm
		{...props}
		simplifiedSchema={simplifiedSchema}
		inputsProps={inputsProps}
		initialValues={prepareInitialValues(props.initialValues)}
	/>;

};

export default EditForm;
