import React from 'react';
import style from '../styles/custom-cell.module.sass';


class NumberCellEditor extends React.PureComponent
{
	state = {
		value: undefined
	}

	componentDidMount()
	{
		const ch = this.props.charPress;
		const re = /^\d$/;
		const isDigit = re.test(ch);

		this.timer = setTimeout(() => {
			this.input.focus();

			if (ch && isDigit) {
				this.input.value = ch;
				this.setState({ value: ch});
			} else {
				this.input.value = this.props.value;
				this.setState({ value: this.props.value});
				this.input.select();
			}
		});
	}

	componentWillUnmount()
	{
		delete this.timer;
	}

	getValue() {
		return this.state.value;
	}

	isPopup()
	{
		return false;
	}

	isCancelBeforeStart()
	{
		return false;
	}

	isCancelAfterEnd()
	{
		return !this.input.checkValidity();
	}

	render()
	{
		const onChange = (e) => {
			const value = e.target.value;
			this.setState({...this.state, value});
		};

		return (
			<div
				className={style.wrapper}
				style={{padding: 0}}
			>
				<input
					style={{flex: '1 1 auto'}}
					ref={ref => { this.input = ref; }}
					type="number"
					onChange={onChange}
					{...this.props.inputProps}
				/>
			</div>
		);
	}
}

export default NumberCellEditor;
