import { faEdit, faTimes, faUndoAlt } from '@fortawesome/free-solid-svg-icons';

import { UserCellRenderer, ActionsCellRenderer } from 'components/ui/grid/renderers';
import { longDateFormatter } from 'components/ui/grid/formatters';

import StatusCellRenderer from './components/StatusCellRenderer';

export const getGridOptions = params => {

	const frameworkComponents = {
		userCellRenderer: UserCellRenderer,
		actionsCellRenderer: ActionsCellRenderer,
		statusCellRenderer: StatusCellRenderer,
	};

	const defaultColDef = {
		minWidth: 40,
		resizable: false,
		sortable: true,
		editable: false,
	};

	const gridOptions = {
		frameworkComponents,
		defaultColDef,
	};

	return gridOptions;
};

export const getColumnDefs = params => {

	const {
		onTitleCellClicked,
		onArchiveProjectClicked,
		onUnarchiveProjectClicked,
		onEditProjectClicked
	} = params;

	const dateComparator = (a, b) => (a < b ? 1 : -1);

	const strComparator = (a, b) => {
		const strA = String(a);
		const strB = String(b);

		return strA.localeCompare(strB);

	};

	const userComparator = (a, b) => {
		const strA = String(a?.fullName || a?.phoneNumber);
		const strB = String(b?.fullName || b?.phoneNumber);

		return strA.localeCompare(strB);
	};

	const actions = [
		{
			icon: faEdit,
			onClick: onEditProjectClicked,
		},
		{
			icon: faTimes,
			onClick: onArchiveProjectClicked,
			hoverStyle: { color: 'red' },
		},
	];

	const archivedActions = [
		{
			icon: faUndoAlt,
			onClick: onUnarchiveProjectClicked,
		},
	];

	const onTitleClicked = params => {
		const isArchived = params.data.isArchived;

		if (isArchived) {
			return;
		}

		onTitleCellClicked(params);
	};

	const getActions = params => {
		const isArchived = params.data.isArchived;

		if (isArchived) {
			return { actions: archivedActions };
		} else {
			return { actions };
		}
	};

	const columnDefs = [
		{
			headerName: "Статус",
			cellRenderer: 'statusCellRenderer',
			sortable: false,
		},
		{
			headerName: "Название",
			field: "title",
			cellClass: 'font-weight-bold',
			cellStyle: { cursor: 'pointer'},
			comparator: strComparator,
			onCellClicked: onTitleClicked,
		},
		{
			headerName: "Адрес",
			field: "address",
			comparator: strComparator,
		},
		{
			headerName: "Ответственный",
			field: "assignedUser",
			cellRenderer: "userCellRenderer",
			comparator: userComparator,
		},
		{
			headerName: "Начало",
			field: "estimatedStartDate",
			valueFormatter: longDateFormatter,
			comparator: dateComparator,
		},
		{
			headerName: "Завершение",
			field: "estimatedEndDate",
			valueFormatter: longDateFormatter,
			comparator: dateComparator,
		}, {
			minWidth: 80,
			cellRenderer: 'actionsCellRenderer',
			cellRendererParams: getActions,
			sortable: false,
		}
	];

	return columnDefs;
};
