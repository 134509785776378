import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/ui';
import styles from './style.module.sass';
import { isNil } from 'lodash';

const format = value => {
	return isNil(value) ? "-" : Math.round(Number(value) * 1000) / 1000 ;
};

const Calculations = (props) => {
	return (
		<div className={styles.Calculations}>
			<Container layout="row" between width="70%" padding="0 15px" margin="0 50px">
				<Container layout="column">
					<div>
						<div className={styles.Calculations__title}>
							Итоговые трудозатраты
						</div>
						<div className={styles.Calculations__subTitle}>
							В человеко-часах
						</div>
					</div>
					<Container layout="row" between>
						<div className={styles.Calculations__valueTitle}>
							На весь объём
						</div>
						<div className={styles.Calculations__value}>
							{format(props.totalLaborCosts)}
						</div>
					</Container>
					<Container layout="row" between>
						<div className={styles.Calculations__valueTitle}>
							На единицу объёма
						</div>
						<div className={styles.Calculations__value}>
							{format(props.laborCostsPerUnit)}
						</div>
					</Container>
				</Container>
				<Container layout="column">
					<div>
						<div className={styles.Calculations__title}>
							Выработка в день
						</div>
						<div className={styles.Calculations__subTitle}>
							В единицах объёма работ
						</div>
					</div>
					<Container layout="row" between>
						<div className={styles.Calculations__valueTitle}>
							Всего
						</div>
						<div className={styles.Calculations__value}>
							{`${format(props.totalProgress)} ${props.jobUnits?.suffix || ''}`}
						</div>
					</Container>
					<Container layout="row" between>
						<div className={styles.Calculations__valueTitle}>
							Один рабочий
						</div>
						<div className={styles.Calculations__value}>
							{`${format(props.dailyProgress)} ${props.jobUnits?.suffix || ''}`}
						</div>
					</Container>
				</Container>
			</Container>
		</div>
	);
};

Calculations.propTypes = {
	totalLaborCosts: PropTypes.any,
	laborCostsPerUnit: PropTypes.any,
	jobUnits: PropTypes.object,
	totalProgress: PropTypes.any,
	dailyProgress: PropTypes.any
};

export default Calculations;
