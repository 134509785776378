import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { toast } from 'react-toastify';

import api from 'core/api';
import db from 'core/db';
import notify from 'utils/suppliesNotifications';
import { Modal } from 'components/ui/modals';
import { Empty } from 'components/ui';
import Grid from './Grid';

class AmountApproval extends React.PureComponent {
	aggregateRequestedList = () => {
		const {
			consumableSheetItems,
			purchaseRequestItems,
			purchaseRequestsMap,
		} = this.props;

		const sheetItems = consumableSheetItems.map(item => {
			const orderedAmount = purchaseRequestItems.filter(purchaseRequestItem => {
				if (!purchaseRequestItem.isDeleted
				&& purchaseRequestItem.isAmountApproved
				&& purchaseRequestItem.consumableSheetItemId === item.id) {
					return true;
				}

				return false;
			}).reduce((acc, item) => {
				return acc + Number(item.amount);
			}, 0);

			return {
				...item,
				orderedAmount,
			};
		});

		// Сборка заявки
		return {
			// Добавляем информацию о позициях заявки и дополняем их информацией о позициях из сметы
			items: purchaseRequestItems
				.filter(requestItem => purchaseRequestsMap.has(requestItem.purchaseRequestId) && !requestItem.amount !== '0.00')
				.map(item => {
					return {
						...item,
						consumableSheetItem: sheetItems.find(el => el.id === item.consumableSheetItemId),
						request: purchaseRequestsMap.get(item.purchaseRequestId),
					};
				})
				.sort((a, b) => {
					return Number(a.isAmountApproved) - Number(b.isAmountApproved);
				}),
		};
	}

	approveAmount = params => {
		const { purchaseOrderItems, purchaseInvoices } = this.props;
		const orderItem = purchaseOrderItems.find(item => item.purchaseRequestItemId === params.data.id);
		let isOrderReadyToPay = false;

		if (orderItem) {
			isOrderReadyToPay = purchaseOrderItems.filter(item => item.purchaseOrderId === orderItem.purchaseOrderId).every(item => item.isApproved);
		}

		this.props.dispatch(api.tbsPurchaseRequestItems().approveAmount({
			purchaseRequestItemId: params.data.id,
		}))
			.then(() => {
				if (isOrderReadyToPay) {
					const invoice = purchaseInvoices.find(invoice => invoice.purchaseOrderId === orderItem.purchaseOrderId);

					if (invoice) {
						this.props.dispatch(api.tbsPurchaseInvoices().removeFromApprove({
							purchaseInvoiceId: invoice?.id,
						})).then(() => {
							notify(this.props.employees, 'invoice-approved');
						});
					}
				}
			})
			.catch(error => {
				toast.error("Не удалось утвердить количество.", {
					position: toast.POSITION.TOP_CENTER,
					hideProgressBar: true,
				});
				console.error(error);
			})
		;
	}

	onOpenApproveAmountModal = params => {
		const { consumableSheetItem, amount } = params.data;
		const amountTitle = `${amount} ${consumableSheetItem?.consumableUnit?.suffix}`;

		Modal.confirm({
			title: 'Утверждение количества',
			text: `Утвердить "${consumableSheetItem?.title}" ${amountTitle}?`,
			onConfirm: () => this.approveAmount(params),
		});
	}

	render () {
		if (this.props.isLoading) {
			return <Empty
				className={cn('w-100', 'h-100')}
				title="Загружается список позиции заявок..."
			/>;
		}

		const list = this.aggregateRequestedList();

		return (
			<Grid
				items={list.items}
				consumableSheet={this.props.consumableSheet}
				onOpenApproveAmountModal={this.onOpenApproveAmountModal}
			/>
		);
	}
}

export default connect((state, props) => {
	const projectId = props.match.params.projectId;

	// Нужна для таблицы, чтобы взять оттуда курсы валют
	const consumableSheet = db.tbsConsumableSheets.list({ filter: { projectId }})[0];

	const consumableSheetFilter = { filter: { consumableSheetId: consumableSheet?.id } };

	// Нужно в первую очередь для проверки на isLoading
	const collects = {
		consumableSheetItems: db.tbsConsumableSheetItems.listNotDeleted(consumableSheetFilter),
		purchaseRequests: db.tbsPurchaseRequests.listNotDeleted(consumableSheetFilter),
		purchaseRequestItems: db.tbsPurchaseRequestItems.listNotDeleted(),
		purchaseOrderItems: db.tbsPurchaseOrderItems.listNotDeleted(),
		purchaseInvoices: db.tbsPurchaseInvoices.listNotDeleted(),
		currencies: db.currencies.list(),
		employees: db.employees.list(),
	};

	const maps = {
		purchaseRequestsMap: collects.purchaseRequests.hashById(),
		consumableSheetItemsMap: collects.consumableSheetItems.hashById(),
		currenciesMap: collects.currencies.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	if (isLoading) {
		return { isLoading };
	}

	return {
		consumableSheet,
		...collects,
		...maps,
	};
})(AmountApproval);
