import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import style from './style.module.sass';


class ValueCard extends React.PureComponent
{
	static propTypes = {
		color: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.any,
	}

	static defaultProps = {
		color: 'fff',
	}

	render()
	{
		const { value, label, color } = this.props;

		return (
			<div
				style={{ backgroundColor: color }}
				className={style.ValueCard}
			>
				<span className={style.ValueCard_label}>
					{label}
				</span>
				<span className={style.ValueCard_value}>
					{isNil(value) ? '-' : value}
				</span>
			</div>
		);
	}
}

export default ValueCard;
