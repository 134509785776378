import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar } from 'components/ui';

import { requiredFields } from './Grid/defs';


class SheetButtonToolbar extends React.PureComponent
{

	static propsTypes = {
		selectedRows: PropTypes.arrayOf(PropTypes.object),
		me: PropTypes.object,
		isLoading: PropTypes.bool,
		onLockSelected: PropTypes.func,
		onReleaseSelected: PropTypes.func,
		onApproveSheet: PropTypes.func,
		onCreateConsumableItem: PropTypes.func,
		consumableSheetItems: PropTypes.arrayOf(PropTypes.object),
	}

	static defaultProps = {
		selectedRows: [],
		me: {},
		isLoading: false,
	}

	isAllValuesFilled = () => {
		const items = this.props.consumableSheetItems;
		const isValueFilled = (value) => {
			return !(value === null || value === undefined);
		};

		for (const item of items) {
			for (const dataIndex of requiredFields) {
				const value = item[dataIndex];
				if (dataIndex === 'planPrice' || dataIndex === 'retailPrice') {
					if (!(isValueFilled(item.planPrice) || isValueFilled(item.retailPrice))) {
						return false;
					}
				} else if (!isValueFilled(value)) {
					return false;
				}
			}
		}

		return true;
	}

	render()
	{
		const {
			me,
			selectedRows,
			onLockSelected,
			onReleaseSelected,
			onApproveSheet,
			onCreateConsumableItem,
			isLoading
		} = this.props;

		const selectedReleased = selectedRows.filter(row => !row.lockedByUserId);
		const selectedLockedByUser = selectedRows.filter(row => row.lockedByUserId === me.id);

		const btnLockTitle = 'Взять в работу' + (selectedReleased.length ? ` ${selectedReleased.length}` : '');
		const btnReleaseTitle = 'Убрать из работы' + (selectedLockedByUser.length ? ` ${selectedLockedByUser.length}` : '');

		const btnLock = {
			title: btnLockTitle,
			props: {
				disabled: isLoading,
				onClick: () => onLockSelected(selectedReleased),
			}
		};

		const btnRelease = {
			title: btnReleaseTitle,
			props: {
				disabled: isLoading,
				onClick: () => onReleaseSelected(selectedLockedByUser),
			}
		};

		const btnListLeft = [];

		if (selectedReleased.length) {
			btnListLeft.push(btnLock);
		}

		if (selectedLockedByUser.length) {
			btnListLeft.push(btnRelease);
		}

		const isAllFilled = this.isAllValuesFilled();

		const btnListRight = [
			{
				title: 'Добавить позицию',
				props: {
					onClick: () => onCreateConsumableItem(),
				}
			},
			{
				title: 'Утвердить',
				props: {
					disabled: !isAllFilled,
					onClick: () => onApproveSheet(),
				}
			},
		];

		return (
			<div className="d-flex flex-row justify-content-between p-2">
				<ButtonToolbar btnList={btnListLeft} align="left"/>
				<ButtonToolbar btnList={btnListRight}/>
			</div>
		);
	}
}

export default SheetButtonToolbar;
