import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from '../styles/upload-list.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faSpinner, faCheck, faExclamationCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

const getIcon = item => {
	const status = item.status;
	const warning = item.warning;

	if (status === 'done') {
		return warning ? faExclamationTriangle : faCheck;
	} else if (status === 'loading') {
		return faSpinner;
	} else if (status === 'error') {
		return faExclamationCircle;
	}

	return undefined;
};

const getIconClass = item => {
	const status = item.status;
	const warning = item.warning;

	if (status === 'done') {
		return warning ? style.itemIconWarning : style.itemIconDone;
	} else if (status === 'error') {
		return style.itemIconError;
	}

	return style.itemIcon;
};

const UploadListItem = ({fileWrapper}) => {
	const status = fileWrapper.status;
	const icon = getIcon(fileWrapper);
	const iconClassName = getIconClass(fileWrapper);
	const spin = status === 'loading';
	const warning = fileWrapper.warning;
	const error = fileWrapper.status === 'error';
	const errorText = error ? fileWrapper.errorText : warning;

	return (
		<div className={cn(style.uploadListItemWrapper, { [style.error]: error, [style.warning]: warning })}>
			<div
				className={style.uploadListItem}
			>
				<div>{fileWrapper.file.name}</div>
				<div className={style.itemIconsWrapper}>
					{/* visibility of icons switched via styles */}
					<FontAwesomeIcon
						fixedWidth
						className={iconClassName}
						spin={spin}
						icon={icon}
					/>
					<FontAwesomeIcon
						fixedWidth
						className={style.itemIconRemove}
						icon={faTimes}
						onClick={() => fileWrapper.abort()}
					/>
				</div>
			</div>
			<span className={style.errorText}>{errorText}</span>
		</div>
	);
};

UploadListItem.propTypes = {
	fileWrapper: PropTypes.object.isRequired,
	onRemove: PropTypes.func,
};

export default UploadListItem;
