import React from 'react';
import PropTypes from 'prop-types';
import { PhotoGallery } from 'components/ui';
import JobQualifier from 'utils/JobQualifier';

import RadialProgressBar from './components/RadialProgressBar';
import styles from './style.module.sass';

class JobProgressListItem extends React.PureComponent
{
	static propTypes = {
		job: PropTypes.object.isRequired,
	}

	render()
	{
		const { job, ...props } = this.props;
		const { title, attachments, currentProgress, maxProgress, unit } = job;

		return (
			<div className={styles.JobProgressListItem} {...props}>
				<div className={styles.JobProgressListItem_info}>
					<div className={styles.JobProgressListItem_info_title}>
						{title}
					</div>
					<div/>
					<div className={styles.JobProgressListItem_info_valueBox}>
						<div>
							{`${currentProgress || 0}/${maxProgress || 0} ${unit?.suffix}`}
						</div>
					</div>
					<RadialProgressBar
						className={styles.JobProgressListItem_info_progressBox}
						percent={JobQualifier.progressPercents(job)}
						radius={19}
					/>
				</div>
				{ attachments ? <div className={styles.JobProgressListItem_gallery}>
					<PhotoGallery
						visibleCount={5}
						attachments={job?.attachments}
						imgHeight={32}
						imgWidth={48}
					/>
				</div> : null }
			</div>
		);
	}
}

export default JobProgressListItem;
