import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import db from 'core/db';
import cn from 'classnames';
import { Action, PhotoGallery, User } from 'components/ui';
import { Table } from 'react-bootstrap';

import { faChevronRight, faChevronDown, faSave, faEdit, faCheck } from '@fortawesome/free-solid-svg-icons';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

import style from './style.module.sass';


class ReportListItem extends React.PureComponent
{
	static propTypes = {
		periodicReport: PropTypes.object.isRequired,
		periodicReportId: PropTypes.string.isRequired,
		onApproveClicked: PropTypes.func,
		onEditItemClicked: PropTypes.func,
	}

	state = {
		isExpanded: false,
	}

	renderJobsTable = periodicReportItems => {
		return (
			<Table size="sm" className={style.mainTable} borderless>
				<thead className={style.tableHeader}>
					<tr>
						<th></th>
						<th></th>
						<th>Выполнено всего</th>
						<th>Сотрудников на смене</th>
						<th></th>
					</tr>
				</thead>
				{periodicReportItems.map((pri, i) => {
					const progress = Number(pri.currentProgress);
					const suffix = pri.job?.unit?.suffix || '';

					return (
						<tbody key={pri.id} className={style.tableBody}>
							<tr>
								<td>{i + 1}</td>
								<td className="w-75">
									<div className={style.mainRow}>
										{pri.job?.title}
										{pri.attachments.length ? <PhotoGallery
											visibleCount={5}
											attachments={pri.attachments}
											imgHeight={32}
											imgWidth={45}
										/> : null}
									</div>
								</td>
								<td>
									{`${progress || 0} ${suffix}`}
								</td>
								<td>
									{pri.numberOfEmployedWorkers || 0}
								</td>
								<td>
									<Action
										icon={faEdit}
										tooltip="Редактировать"
										onClick={() => this.props.onEditItemClicked({
											...pri,
											title: pri.job.title,
											date: this.props.periodicReport?.date,
										})}
										style={{ opacity: 0.6 }}
									/>
								</td>
							</tr>
						</tbody>
					);
				})}
			</Table>
		);
	}

	renderHeader = () => {
		const { periodicReport, onApproveClicked, employees, jobsMap } = this.props;
		const { isExpanded } = this.state;
		const { isCommitted } = periodicReport;

		const employee = employees.find(emp => emp.user.id === periodicReport.createdByUserId);

		const periodicReportItems = this.props.periodicReportItems.map(pri => ({
			...pri,
			job: jobsMap.get(pri.jobId),
		}));

		return (
			<div className={style.header}>
				<div className={style.action}>
					<Action
						onClick={() => this.setState(state => ({ isExpanded: !state.isExpanded }))}
						icon={isExpanded ? faChevronDown : faChevronRight}
						tooltip={isExpanded ? 'Свернуть' : 'Развернуть'}
					/>
				</div>
				<div className={style.headerRow}>
					<div className={style.headerRowContent}>
						<span className={style.headerRowText}>
							{`Отчёт по ${periodicReportItems.length} видам работ`}
						</span>
						<User user={employee?.user} className={cn('ml-2')}/>
					</div>
					{isCommitted
						? <Action
							icon={faCheck}
							style={{color: 'green', opacity: '0.8' }}
							disabled
						/>
						: <Action
							icon={faSave}
							onClick={() => onApproveClicked(periodicReport)}
							tooltip="Утвердить"
							style={{ opacity: '0.6' }}
						/>
					}
				</div>
			</div>
		);
	}

	render()
	{
		const { jobsMap } = this.props;
		const { isExpanded } = this.state;

		const periodicReportItems = this.props.periodicReportItems.map(pri => ({
			...pri,
			job: jobsMap.get(pri.jobId),
		}));

		const table = this.renderJobsTable(periodicReportItems);

		return (
			<div className={style.list}>
				{this.renderHeader()}
				{isExpanded && <div className={cn('ml-4', 'mr-2', 'my-3')}>
					{table}
				</div>}
			</div>
		);
	}
}

const mapToProps = (state, props) => {
	const { periodicReportId, projectId } = props;
	const projectfilter = { filter: { projectId } };

	const collects = {
		periodicReportItems: db.periodicReportItems.list({ filter: { periodicReportId }}),
		jobs: db.jobs.list({...projectfilter}),
		employees: db.employees.list(),
	};

	const maps = {
		jobsMap: collects.jobs.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	return {
		...collects,
		...maps,
		isLoading,
	};

};

export default connect(mapToProps)(ReportListItem);
