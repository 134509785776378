import React from 'react';
import PropTypes from 'prop-types';
import { format, eachDayOfInterval, formatISO } from 'date-fns';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import api from 'core/api';
import db from 'core/db';
import ProjectQualifier from 'utils/ProjectQualifier';

import { Container } from 'components/ui';
import { ChartLayout, Card } from '../layouts';
import { PerformanceChart } from '../charts';
import HistoryManager from '../../helpers/history/HistoryManager.js';


class PerformanceCard extends React.PureComponent
{
	static propTypes = {
		startDate: PropTypes.any,
		endDate: PropTypes.any,
	}

	state = {
		data: null,
	}

	componentDidMount()
	{
		const { projectId } = this.props;

		if (!projectId) {
			return;
		}

		this.props.dispatch(api.jobsHistoryAggregation().aggregate({
			column: 'currentProgress',
			aggregationFunction: 'delta',
			groupBy: ['jobId', 'date'],
			jobFilter: {
				projectId: projectId,
				isDeleted: false,
			},
		}))
			.then(res => this.setState({ data: res.groups }))
			.catch(err => console.log(err))
		;
	}

	workers = (workerDays, startDate, endDate) => {
		if (!workerDays?.length) {
			return null;
		}

		if (!startDate || !endDate) {
			return null;
		}

		const activeWorkerDays = workerDays.filter(wd => !wd.isAbsence);

		const days = eachDayOfInterval({ start: startDate, end: endDate });
		const workers = new Array(days.length);

		for (let i = 0; i < days.length; ++i) {
			const day = days[i];

			const iso = formatISO(day, { representation: 'date' });
			const count = activeWorkerDays.reduce((acc, wh) => {
				if (wh.date === iso) {
					return acc + 1;
				} else {
					return acc;
				}
			}, 0);

			workers[i] = count;
		}

		return workers;
	}

	getHistoryPerfomance()
	{
		const { project, jobs, startDate, endDate, jobsMap } = this.props;
		const { data } = this.state;

		if (!data) {
			return {};
		}

		const historyManager = new HistoryManager('delta', data, startDate, endDate);

		// ПТ из истории
		const perfomance = historyManager.perfomance({ jobsMap });

		if (!perfomance) {
			return {};
		}

		// ПТ Запланированная
		const plannedPerformance = ProjectQualifier.plannedPerformanceWithinInterval(project, jobs, startDate, new Date());

		if (!plannedPerformance) {
			return {};
		}

		// Относительный процент
		const perfomancePercent = perfomance.map((p, i) => (plannedPerformance[i] ? p / plannedPerformance[i] * 100 : 0));

		const dates = historyManager.range();

		return {
			dates: dates.map(date => format(date, 'dd.MM')),
			perfomancePercent: perfomancePercent.map(p => Number(p).toFixed(1))
		};
	}

	render()
	{
		const { workerDays, startDate, endDate, ...props } = this.props;

		const { dates, perfomancePercent } = this.getHistoryPerfomance();

		const workers = this.workers(workerDays, startDate, endDate);

		return (
			<Card {...props} layout="column">
				<Container height="100%" width="100%">
					<ChartLayout
						header="Производительность труда"
						title="График показывает относительную эффективность для присутствующих людей на объекте. Отношение планового и фактического объема работ к количеству рабочих."
						legend={[
							{ label: 'Эффективность работы', color: '#48B95A' },
							{ label: 'Количество людей на объекте', color: '#EED28A' },
						]}
					>
						<PerformanceChart
							workers={workers}
							perfomance={perfomancePercent}
							dates={dates}
						/>
					</ChartLayout>
				</Container>
			</Card>
		);
	}
}

const mapToProps = (state, props) => {
	const projectId = props.match.params.projectId;
	const projectIdFilter = { filter: { projectId } };

	const collects = {
		jobs: db.jobs.listNotDeleted(projectIdFilter),
		projects: db.projects.list({ filter: { id: projectId } }),
		workerDays: db.workerDays.listNotDeleted(projectIdFilter),
	};

	const maps = {
		jobsMap: collects.jobs.hashById(),
	};

	const isLoading = Array.from(Object.values(collects)).some(p => p.isLoading);

	const project = collects.projects[0];

	return {
		project,
		projectId,
		isLoading,
		...collects,
		...maps,
	};
};

export default withRouter(connect(mapToProps)(PerformanceCard));
