import React from 'react';
import cn from 'classnames';

import styles from './style.module.sass';


class EmptyDayCell extends React.PureComponent
{
	render()
	{
		return <div className={cn(styles.Cell, styles.Cell__empty)}/>;
	}
}

export default EmptyDayCell;
